import { createSelector } from 'reselect';
import { Family } from '../../models/Family';
import { Newspaper } from '../../models/Newspaper';
import { ApplicationState } from '../main/types';
import { UserProfile } from '../users/types';
import { selectLoggedInUserUid } from '../users/user.selectors';
import {
  CreateFamilyInvitationDto,
  CreateFamilyState,
  FamilyDictionary,
  FamilyInvitation,
  FamilyInvitationType,
} from './types';

/**
 * Selects all families that a user is a member of.
 * @param {ApplicationState} state
 */
export const selectFamiliesForActiveUser = (state: ApplicationState) =>
  state.families.ownFamilies;

/**
 * Selects the family that the logged in user is currently 'acting' in.
 * @param {ApplicationState} state
 */
export const selectCurrentlyActiveFamily = (
  state: ApplicationState,
): Family | null => {
  return state.families.currentlyActiveFamily;
};

/**
 * Selects wether the currently active family is flagged for deletion and will be deleted within a grace period of 30 days.
 * @param {ApplicationState} state
 * @returns {boolean} wether the family has been flagged for deletion or not.
 */
export const selectIfCurrentlyActiveFamilyIsDeleted = (
  state: ApplicationState,
): boolean | null => {
  return (
    state.families.currentlyActiveFamily?.isDeleted &&
    state.families.currentlyActiveFamily?.willBeDeletedAt &&
    state.families.currentlyActiveFamily?.willBeDeletedAt !== null
  );
};

/**
 * Returns wether or not a user has reached the maximum number of families he can be a part of.
 * @param state
 * @returns {boolean} wether or not a user has reached the maximum number of families he can be a part of.
 */
export const selectUserHasReachedFamilyLimit = (
  state: ApplicationState,
): boolean => {
  return Object.keys(state.families?.ownFamilies).length >= 20;
};

/**
 * Selects wether or not the logged in user is considered an administrator in the current family.
 */
export const selectUserIsAdminInCurrentlyActiveFamily = createSelector(
  selectCurrentlyActiveFamily,
  selectLoggedInUserUid,
  (currentlyActiveFamily: Family | null, currentlyActiveUserUid: string) => {
    return currentlyActiveFamily?.initiator?.uid === currentlyActiveUserUid;
  },
);

/**
 * Selects wether or not the logged in user is the last member of the currently active family.
 */
export const selectCurrentUserIsLastMemberInCurrentlyActiveFamily = createSelector(
  selectCurrentlyActiveFamily,
  selectLoggedInUserUid,
  (currentlyActiveFamily: Family | null, currentlyActiveUserUid: string) => {
    return (
      currentlyActiveFamily?.members?.length === 1 &&
      currentlyActiveFamily.members[0].uid === currentlyActiveUserUid
    );
  },
);

/**
 * Selects the information wether or not the currently active family has reached the end of its current release cycle.
 * @param {ApplicationState} state
 */
export const selectCurrentlyActiveFamilyHasReachedEndOfReleaseCycle = createSelector(
  selectCurrentlyActiveFamily,
  selectFamiliesForActiveUser,
  (currentlyActiveFamily: Family, ownFamilies: FamilyDictionary) => {
    return ownFamilies[currentlyActiveFamily?.uuid]
      ?.hasReachedEndOfReleaseCycle;
  },
);

/**
 * Selects the family that the user has set to be his default-active Family.
 * @param {ApplicationState} state
 */
export const selectDefaultActiveFamilyForUser = (state: ApplicationState) => {
  const defaultActiveFamily: Family | undefined =
    state.user.userSettings?.defaultActiveFamily;
  return defaultActiveFamily || null;
};

/**
 * Selects family invitations that have been created for the currently active family.
 * @param {ApplicationState} state
 */
export const selectFamilyInvitations = (
  state: ApplicationState,
): FamilyInvitation[] => {
  return state.families.currentlyActiveFamily?.invitations;
};

/**
 * Selects all currently active familyInvitations of type "sharing link".
 * @param  {ApplicationState} state
 * @returns {FamilyInvitation[]} All invitations in the family that are of type "sharing Link", meaning not bound or sent to individuals via email.
 */
export const selectFamilyInvitationLinks = createSelector(
  selectFamilyInvitations,
  (allInvitations: FamilyInvitation[]): FamilyInvitation[] => {
    return allInvitations?.filter((value: FamilyInvitation) => {
      return value.invitationType === FamilyInvitationType.SHARING_LINK;
    });
  },
);

/**
 * Selects the members of the currently active family.
 * @param {ApplicationState} state
 */
export const selectFamilyMembers = (state: ApplicationState): UserProfile[] => {
  return state.families.currentlyActiveFamily?.members;
};

/**
 * Selects all email adresses of the family members of the currently active family.
 */

export const selectUserEmailsOfCurrentlyActiveFamily = createSelector(
  selectCurrentlyActiveFamily,
  (currentlyActiveFamily: Family) => {
    return currentlyActiveFamily?.members?.map((userProfile: UserProfile) => {
      return userProfile.userName;
    });
  },
);

export const selectNumFamilyMembers = (
  state: ApplicationState,
  numberOfMembers: number,
): UserProfile[] => {
  return (
    state.families.currentlyActiveFamily?.members?.filter((_element, index) => {
      return index < numberOfMembers;
    }) || null
  );
};
/**
 * Selects the members of the currently active family as well as the invites and returns them together in an array.
 */
export const selectFamilyMembersAndInvites = createSelector(
  selectFamilyMembers,
  selectFamilyInvitations,
  (members: UserProfile[], invitations: FamilyInvitation[]) => {
    let result: Array<UserProfile | FamilyInvitation> = [];
    if (members && invitations) {
      result = [...members, ...invitations];
      return result;
    }
    if (members) {
      return members;
    }

    if (invitations) {
      return invitations;
    }

    return [];
  },
);

/**
 * Selects only the Uuids of the profile Images of the currently active families members.
 */
export const selectFamilyMemberProfileImageUuids = createSelector(
  selectFamilyMembers,
  (members: UserProfile[]) => {
    if (members) {
      const profileImageUuids: string[] = [];
      members.map((element, _index) => {
        profileImageUuids.push(element.profileImage?.uuid);
        return null;
      });
      return profileImageUuids;
    }

    return null;
  },
);

/**
 * Selects the "current" newspaper of the family that the user is acting in.
 * Current means that it is the edition to which articles can be posted in the current release cycle.
 * @param {ApplicationState} state
 */
export const selectCurrentNewspaperOfCurrentlyActiveFamily = (
  state: ApplicationState,
): Newspaper => {
  return state.families.currentNewspaper;
};

/**
 * Selects the last published newspaper of the family that the user is acting in.
 * Last published means it is the edition that was already printed and can no longer be extended by posting new articles.
 * @param {ApplicationState} state
 */
export const selectLastPublishedNewspaperOfCurrentlyActiveFamily = (
  state: ApplicationState,
): Newspaper => {
  return state.families.lastPublishedNewspaper;
};

/**
 * Selects the subtree of the application state that deals with the creation of a new family.
 * @param state
 */
export const selectCurrentlyCreatedFamily = (
  state: ApplicationState,
): CreateFamilyState => {
  return state.families.currentlyCreatedFamily;
};

/**
 * Selecte the subtree of the application state dealing with crafting invites to a family.
 * @param {ApplicationState} state - global application state, stored in redux.
 * @returns {CreateFamilyInvitationDto} - the currently edited invitation that is cleared after sending it.
 */
export const selectCurrentlyDraftedFamilyInvitation = (
  state: ApplicationState,
): CreateFamilyInvitationDto => {
  return state.families.currentlyCreatedFamily.multiReceiverInvitation;
};

/**
 * Selects the articles of the current newspaper that have the logged in user as an author.
 
export const selectUserContributionsInCurrentNewspaperOfCurrentlyActiveFamily = createSelector(
  selectLoggedInUser,
  selectCurrentNewspaperOfCurrentlyActiveFamily,

  (loggedInUser: User, currentNewspaper: Newspaper) => {
    const newspaper = currentNewspaper;
    newspaper.articles = currentNewspaper.articles.filter(
      (element: Article) => {},
    );
  },
);
*/
