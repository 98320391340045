// src/InitiateResetPasswordFlowFormForm.jsx

import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import colors from '../../../../00_Constants/colors';
import FZSpinner from '../../../../01_atoms/FZSpinner';

const useStyles = makeStyles(theme => {
  return {
    formContainer: {
      position: 'relative',
      top: 0,
    },

    form: {
      paddingTop: 32,
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
        width: '90%',
      },

      [theme.breakpoints.down('md')]: {
        margin: '0 auto',
        width: '80%',
      },

      [theme.breakpoints.up('lg')]: {
        margin: '0 auto',
        width: '40%',
      },
    },

    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },

    formInputFieldContainer: {
      paddingTop: 8,
      paddingBottom: 32,
      paddingLeft: 16,
      paddingRight: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '80%',
    },

    formInputField: {
      width: '100%',
    },

    formHelper: {
      maxWidth: '80%',
    },

    media: {
      borderRadius: 4,
      marginTop: 16,
      marginBottom: 32,
      marginLeft: 16,
      marginRight: 16,
    },

    resetPasswordNoticeContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      paddingTop: 8,
    },

    needHelpLink: {},

    formButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap-reverse',

      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-around',
      },
    },

    formButton: {
      [theme.breakpoints.down('md')]: {
        marginTop: 16,
      },
    },
  };
});

export interface InitiateResetPasswordFlowFormFormValues {
  username: string;
}

interface Props {
  authService?: any;
  onSubmit: (
    values: InitiateResetPasswordFlowFormFormValues,
    helpers: FormikHelpers<InitiateResetPasswordFlowFormFormValues>,
  ) => void;
  enabled: boolean;
}

const InitiateResetPasswordForm = ({ onSubmit, enabled }: Props) => {
  const [sessionToken] = useState();
  const classes = useStyles({});
  const { t } = useTranslation();
  const history = useHistory();

  function handleAbortPasswortReset() {
    history.goBack();
  }

  if (sessionToken) {
    // Hide form while sessionToken is converted into id/access tokens
    return null;
  }

  return (
    <Container className={classes.formContainer} style={{ marginBottom: 16 }}>
      <Formik
        initialValues={{
          username: '',
        }}
        validate={values => {
          const errors: Partial<InitiateResetPasswordFlowFormFormValues> = {};

          if (!values.username) {
            errors.username = t(
              'initiateResetPasswordFlowScreen-username-required-message',
            );
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)
          ) {
            errors.username = t(
              'initiateResetPasswordFlowScreen-username-does-not-match-email-pattern-message',
            );
          }

          return errors;
        }}
        onSubmit={(values, helpers) => {
          // TODO: Add request to send an email that allows to reset the users password.
          onSubmit(values, helpers);
        }}
      >
        {({ submitForm, isSubmitting, errors }) => (
          <Form data-testid="InitiateResetPasswordFlowForm">
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              className={classes.form}
            >
              <Card className={classes.card}>
                <Typography
                  variant={'h4'}
                  style={{ paddingTop: 32, paddingBottom: 16 }}
                >
                  {t('initiateResetPasswordFlowScreen-headline')}
                </Typography>
                <Grid xs={12} className={classes.formInputFieldContainer}>
                  <Field
                    component={TextField}
                    className={classes.formInputField}
                    name="username"
                    type="email"
                    label={t(
                      'initiateResetPasswordFlowScreen-username-field-label',
                    )}
                    variant="outlined"
                    color="secondary"
                    placeholder={t(
                      'initiateResetPasswordFlowScreen-username-placeholder-label',
                    )}
                  />
                </Grid>

                {isSubmitting && (
                  <FZSpinner
                    color={colors.quartiary}
                    statusMessage={t(
                      'initiateResetPasswordFlowScreen-reset-in-progress-status-message',
                    )}
                  />
                )}

                <Grid
                  container
                  /* spacing={2} */

                  className={clsx(
                    classes.formInputFieldContainer,
                    classes.formButtonContainer,
                  )}
                >
                  <Button
                    className={classes.formButton}
                    variant="outlined"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={handleAbortPasswortReset}
                  >
                    {t('initiateResetPasswordFlowScreen-abort-label')}
                  </Button>

                  <Button
                    className={classes.formButton}
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting || enabled === false}
                    onClick={submitForm}
                  >
                    {t(
                      'initiateResetPasswordFlowScreen-resetPasswordButton-label',
                    )}
                  </Button>
                </Grid>

                <Grid xs={12} className={classes.formInputFieldContainer}>
                  <Link
                    className={classes.needHelpLink}
                    href="/help"
                    color="secondary"
                  >
                    {t(
                      'initiateResetPasswordFlowScreen-do-you-need-help-resetting-your-password-label',
                    )}
                  </Link>
                </Grid>
              </Card>
              <Box className={classes.resetPasswordNoticeContainer}>
                <Link href="/login" color="primary" style={{ paddingLeft: 8 }}>
                  {t('initiateResetPasswordFlowScreen-back-to-sign-in-label')}
                </Link>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
export default InitiateResetPasswordForm;
