// src/SignupForm.jsx

import {
  Box,
  Button,
  Card,
  Container,
  FormHelperText,
  Grid,
  Link,
  makeStyles,
  Popper,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { Checkbox, TextField } from 'formik-material-ui';
import { PersistFormikValues } from 'formik-persist-values';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { registrationRequest } from '../../../../common/redux/login/login.actions';
import { selectFamilyInvitationToken } from '../../../../common/redux/login/login.selectors';
import { RegisterAccountDto } from '../../../../common/redux/login/types';
import { ActionCallbacks } from '../../../../common/redux/main/types';
import { showSuccessSnackbar } from '../../../../common/redux/ui/ui.actions';
import colors from '../../../00_Constants/colors';
import FZFieldLabel from '../../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZRevealablePasswordInput from '../../../01_atoms/FZRevealablePasswordInput/FZRevealablePasswordInput';
import FZSpinner from '../../../01_atoms/FZSpinner';
import FZToggleLabel from '../../../01_atoms/FZToggleLabel/FZToggleLabel';
import useConfig from '../../../Hooks/useConfig';
import { useInputValidationService } from '../../../Hooks/useInputValidation';

const useStyles = makeStyles(theme => {
  return {
    formContainer: {
      position: 'relative',
      top: 0,
      paddingBottom: 32,
    },

    form: {
      paddingTop: 64,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '0 auto',
      },

      [theme.breakpoints.up('sm')]: {
        width: '75%',
        margin: '0 auto',
      },

      [theme.breakpoints.up('lg')]: {
        width: '40%',
        margin: '0 auto',
      },
    },

    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },

    formInputFieldContainer: {
      paddingTop: 8,
      paddingBottom: 32,
      paddingLeft: 16,
      paddingRight: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '80%',
    },

    horizontalFormInputFieldContainer: {
      paddingTop: 8,
      paddingBottom: 32,
      paddingLeft: 16,
      paddingRight: 16,
      width: '80%',

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
    },

    formInputField: {
      width: '100%',
    },

    formHelper: {
      maxWidth: '80%',
    },

    media: {
      borderRadius: 4,
      marginTop: 16,
      marginBottom: 32,
      marginLeft: 16,
      marginRight: 16,
    },

    signupNoticeContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      paddingTop: 8,
    },

    needHelpLink: {},

    serviceNameLabel: {
      paddingTop: 32,
      paddingBottom: 32,
    },

    /**
     * Styles for honeypot fields that will only be filled by bots
     */
    ohnoHNY: {
      opcacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      zIndex: -1,
    },
  };
});

interface SignupFormValues {
  /**
   * Human readable fields
   */
  firstNameForHmns: '';
  usernameForHmns: '';
  passwordForHmns: '';
  repeatedPasswordForHmns: '';
  consentToTermsOfServiceAndDataProtectionAgreementGiven: boolean;
  inviteCodeForHmns: '';

  /**
   * Hidden honeypot fields
   */
  firstName: string;
  username: string;
  password: string;
  repeatedPassword: string;
}

interface Props {
  signupEnabled: boolean;
  onSignupSuccess: () => void;
  onSignupFailure: () => void;
}

const SignupForm = ({
  signupEnabled,
  onSignupSuccess,
  onSignupFailure,
}: Props) => {
  const [passwordFieldFocused, setpasswordFieldFocused] = useState<boolean>(
    false,
  );
  const classes = useStyles({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputValidationService = useInputValidationService();
  const passwordFieldRef = useRef();
  const appConfig = useConfig();
  const familyInvitationToken = useSelector(selectFamilyInvitationToken);

  useEffect(() => {
    if (familyInvitationToken) {
      dispatch(
        showSuccessSnackbar(
          t(
            'loginScreen-new-user-joined-after-being-invited-by-family-member-label',
          ),
          false,
        ),
      );
    }
    return () => {};
  }, [familyInvitationToken, dispatch, t]);

  return (
    <Container className={classes.formContainer}>
      <Formik
        initialValues={{
          /**
           * Values for form fields visible to humans
           */
          firstNameForHmns: '',
          usernameForHmns: '',
          passwordForHmns: '',
          repeatedPasswordForHmns: '',
          consentToTermsOfServiceAndDataProtectionAgreementGiven: false,
          inviteCodeForHmns: '',
          /**
           * Fake values for Form fileds which are hidden to humans but will be filled by bots.
           * These are called "honeypot" Fields and used to protect form spam.
           */
          firstName: '',
          username: '',
          password: '',
          repeatedPassword: '',
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={values => {
          const errors: Partial<SignupFormValues> = {};

          if (!values.usernameForHmns) {
            errors.usernameForHmns = t(
              'signupScreen-username-required-message',
            );
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
              values.usernameForHmns,
            )
          ) {
            errors.usernameForHmns = t(
              'signupScreen-username-does-not-match-email-pattern-message',
            );
          }

          const passwordIsStrongEnough =
            inputValidationService.testIfPasswordHasEightCharacters(
              values.passwordForHmns,
            ) &&
            inputValidationService.testIfPasswordHasLowercaseLetter(
              values.passwordForHmns,
            ) &&
            inputValidationService.testIfPasswordHasUpperCaseLetter(
              values.passwordForHmns,
            ) &&
            inputValidationService.testIfPasswordHasNumber(
              values.passwordForHmns,
            ) &&
            inputValidationService.testIfPasswordHasSpecialCharacter(
              values.passwordForHmns,
            );

          // Regex for at least 8 digits, one uppercase, one lowercase letter, one digit
          if (!passwordIsStrongEnough) {
            errors.passwordForHmns = t(
              'signupScreen-password-too-weak-message',
            );
          }

          if (!values.repeatedPasswordForHmns) {
            errors.repeatedPasswordForHmns = t(
              'signupScreen-repeated-password-required-message',
            );
          }

          if (values.passwordForHmns !== values.repeatedPasswordForHmns) {
            errors.repeatedPasswordForHmns = t(
              'signupScreen-passwords-dont-match-message',
            );
          }

          if (!values.consentToTermsOfServiceAndDataProtectionAgreementGiven) {
            errors.consentToTermsOfServiceAndDataProtectionAgreementGiven = true;
          }

          if (!values.inviteCodeForHmns) {
            errors.inviteCodeForHmns = t(
              'signupScreen-invitecode-missing-error-message',
            );
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
          setSubmitting(true);

          const successCallback = () => {
            setSubmitting(false);
            onSignupSuccess();
            //history.push('/signup/activation-email-sent');
          };

          const errorCallback = (
            statusCode: number,
            errorCode: string,
            errorSummary: string,
          ) => {
            setSubmitting(false);
            onSignupFailure();
          };

          const actionCallbacks: ActionCallbacks = {
            successCallback,
            errorCallback,
          };

          const registerAccountDto: RegisterAccountDto = {
            firstName: values.firstNameForHmns,
            username: values.usernameForHmns.toLocaleLowerCase(),
            password: values.repeatedPasswordForHmns,
            consentToDataProcessingAgreementGiven:
              values.consentToTermsOfServiceAndDataProtectionAgreementGiven,
            dpaConsentIsForVersionOfDpa:
              appConfig.LEGAL_CONFIG.DATA_PROCESSING_AGREEMENT_DOCUMENT_VERSION,
            consentToTermsOfServiceGiven:
              values.consentToTermsOfServiceAndDataProtectionAgreementGiven,
            tosConsentIsForVersionOfToS:
              appConfig.LEGAL_CONFIG.TERMS_OF_SERVICE_DOCUMENT_VERSION,
            inviteCode: values.inviteCodeForHmns.toUpperCase(),
            firstNameHoneypot: values.firstName,
            emailHoneypot: values.username,
            passwordHoneypot: values.password,
            repeatedPasswordHoneypot: values.repeatedPassword,
          };

          dispatch(registrationRequest(registerAccountDto, actionCallbacks));
        }}
      >
        {({ submitForm, isSubmitting, errors, values }) => (
          <Form autoComplete="off">
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              className={classes.form}
            >
              <Card className={classes.card}>
                {/* <img src={logo} className={classes.media} alt="logo"></img> */}

                <Typography variant="h1" className={classes.serviceNameLabel}>
                  {t('loginScreen-serviceName-label')}
                </Typography>

                <Grid xs={12} item className={classes.formInputFieldContainer}>
                  <Field
                    component={TextField}
                    className={classes.formInputField}
                    name="firstNameForHmns"
                    type="text"
                    label={t('signupScreen-firstName-field-label')}
                    variant="outlined"
                    color="secondary"
                    placeholder={t('signupScreen-firstName-placeholder-label')}
                    onFocus={() => {
                      setpasswordFieldFocused(false);
                    }}
                    autoFocus
                  />
                </Grid>

                <Grid xs={12} item className={classes.formInputFieldContainer}>
                  <Field
                    component={TextField}
                    className={classes.formInputField}
                    name="usernameForHmns"
                    type="email"
                    label={t('signupScreen-username-field-label')}
                    variant="outlined"
                    color="secondary"
                    placeholder={t('signupScreen-username-placeholder-label')}
                    onFocus={() => {
                      setpasswordFieldFocused(false);
                    }}
                    autoComplete={'email'}
                  />
                </Grid>

                <Grid xs={12} item className={classes.formInputFieldContainer}>
                  <FZRevealablePasswordInput
                    variant="outlined"
                    color="secondary"
                    className={classes.formInputField}
                    label={t('signupScreen-password-field-label')}
                    name="passwordForHmns"
                    inputRef={passwordFieldRef}
                    onFocus={() => {
                      setpasswordFieldFocused(true);
                    }}
                    onBlur={(event: any) => {
                      setpasswordFieldFocused(false);
                    }}
                    autoComplete={'new-password'}
                  ></FZRevealablePasswordInput>
                </Grid>

                <Grid xs={12} item className={classes.formInputFieldContainer}>
                  <Field
                    component={TextField}
                    className={classes.formInputField}
                    type="password"
                    label={t('signupScreen-repeat-password-field-label')}
                    name="repeatedPasswordForHmns"
                    variant="outlined"
                    color="secondary"
                    onFocus={() => {
                      setpasswordFieldFocused(false);
                    }}
                    autoComplete={'new-password'}
                  />
                </Grid>

                <Field
                  component={TextField}
                  className={classes.ohnoHNY}
                  name="username"
                  type="email"
                  label={t('signupScreen-username-field-label')}
                  variant="outlined"
                  color="secondary"
                  placeholder={t('signupScreen-username-placeholder-label')}
                  autoComplete={'none'}
                />

                <Field
                  component={TextField}
                  className={classes.ohnoHNY}
                  type="password"
                  label={t('signupScreen-repeat-password-field-label')}
                  name="repeatedPassword"
                  variant="outlined"
                  color="secondary"
                  autoComplete={'none'}
                />

                <Grid
                  xs={12}
                  item
                  className={classes.horizontalFormInputFieldContainer}
                >
                  <Field
                    component={Checkbox}
                    name="consentToTermsOfServiceAndDataProtectionAgreementGiven"
                    variant="outlined"
                    color="secondary"
                    onFocus={() => {
                      setpasswordFieldFocused(false);
                    }}
                    type="checkbox"
                  />
                  <Typography variant="body1">
                    <Trans i18nKey="signupScreen-consentToTermsOfServiceAndDataProtectionAgreementGiven-field-label">
                      <Link
                        href="/data-processing-agreement"
                        component="a"
                        color="secondary"
                        /* 
                    Enable this prop to make dataprocessingagreement link open in a new tab. Not sure yet if this is desired over opening it in the same window. The Form also has the ability to persist its values (other than the password values) in between route changes.                     
                     target="_blank" */
                      >
                        {{
                          DataProcessingAgreement: t(
                            'generic-vocabulary-DataProcessingAgreement',
                          ),
                        }}
                      </Link>
                      <Link
                        href="/terms-of-service"
                        component="a"
                        color="secondary"
                        /* 
                    Enable this prop to make ToS link open in a new tab. Not sure yet if this is desired over opening it in the same window. The Form also has the ability to persist its values (other than the password values) in between route changes.                     
                     target="_blank" */
                      >
                        {{
                          TermsOfService: t(
                            'generic-vocabulary-TermsOfService',
                          ),
                        }}
                      </Link>
                    </Trans>
                  </Typography>
                </Grid>

                <Grid xs={12} item className={classes.formInputFieldContainer}>
                  <Field
                    component={TextField}
                    className={classes.formInputField}
                    name="inviteCodeForHmns"
                    type="text"
                    label={t('signupScreen-inviteCode-field-label')}
                    variant="outlined"
                    color="secondary"
                    placeholder={t('signupScreen-inviteCode-placeholder-label')}
                    onFocus={() => {
                      setpasswordFieldFocused(false);
                    }}
                    autoFocus
                  />
                </Grid>

                {errors.consentToTermsOfServiceAndDataProtectionAgreementGiven && (
                  <Grid
                    item
                    xs={12}
                    className={classes.formInputFieldContainer}
                  >
                    <FormHelperText style={{ color: colors.danger }}>
                      {t(
                        'signupScreen-consentToTermsOfServiceAndDataProtectionAgreementGiven-error',
                      )}
                    </FormHelperText>
                  </Grid>
                )}

                {isSubmitting && (
                  <FZSpinner
                    color={colors.quartiary}
                    statusMessage={t(
                      'signupScreen-login-in-progress-status-message',
                    )}
                  />
                )}

                <Grid xs={12} item className={classes.formInputFieldContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={signupEnabled === false || isSubmitting}
                    type="submit"
                  >
                    {t('signupScreen-signInButton-label')}
                  </Button>
                </Grid>

                <Grid xs={12} item className={classes.formInputFieldContainer}>
                  <Link
                    className={classes.needHelpLink}
                    href="/help"
                    color="secondary"
                  >
                    {t('signupScreen-do-you-need-help-signing-up-label')}
                  </Link>
                </Grid>
              </Card>
              <Box className={classes.signupNoticeContainer}>
                <Typography>
                  {t('signupScreen-already-have-an-account-label')}
                </Typography>

                <Link
                  href="/login"
                  style={{ paddingLeft: 8, color: '#EFEFEF' }}
                >
                  {t('signupScreen-sign-in-label')}
                </Link>
              </Box>
            </Grid>

            <Popper
              disablePortal
              open={passwordFieldFocused}
              anchorEl={passwordFieldRef.current}
              placement={'top-start'}
              modifiers={{
                flip: {
                  enabled: false,
                },
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'scrollParent',
                },
                arrow: {
                  enabled: false,
                  element: passwordFieldRef.current,
                },
              }}
              style={{ zIndex: 5, marginBottom: 8 }}
              children={
                <Card style={{ padding: 16 }}>
                  <FZFieldLabel
                    label={t(
                      'resetPasswordScreen-passwordRequirements-headline',
                    )}
                  ></FZFieldLabel>
                  <FZToggleLabel
                    toggleEnabled={
                      !inputValidationService.testIfPasswordHasEightCharacters(
                        values.passwordForHmns,
                      )
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-eight-characters-message',
                    )}
                  ></FZToggleLabel>
                  <FZToggleLabel
                    toggleEnabled={
                      !inputValidationService.testIfPasswordHasLowercaseLetter(
                        values.passwordForHmns,
                      )
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-one-lowercase-letter-message',
                    )}
                  ></FZToggleLabel>
                  <FZToggleLabel
                    toggleEnabled={
                      !inputValidationService.testIfPasswordHasUpperCaseLetter(
                        values.passwordForHmns,
                      )
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-one-uppercase-letter-message',
                    )}
                  ></FZToggleLabel>
                  <FZToggleLabel
                    toggleEnabled={
                      !inputValidationService.testIfPasswordHasNumber(
                        values.passwordForHmns,
                      )
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-one-digit-message',
                    )}
                  ></FZToggleLabel>

                  <FZToggleLabel
                    toggleEnabled={
                      !inputValidationService.testIfPasswordHasSpecialCharacter(
                        values.passwordForHmns,
                      )
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-one-special-letter-message',
                    )}
                  ></FZToggleLabel>
                </Card>
              }
              transition
            ></Popper>

            <PersistFormikValues
              name="signup-form"
              ignoreValues={['passwordForHmns', 'repeatedPasswordForHmns']}
              persistInvalid
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
};
export default SignupForm;
