import { Card, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { ReactElement } from 'react';

interface Props {
  actions?: JSX.Element;
  children: JSX.Element | JSX.Element[];
}

const useStyles = makeStyles(() => {
  return {
    statusImage: {
      maxWidth: '25%',
    },
    card: {
      marginTop: 32,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 32,
      paddingBottom: 32,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    bodyContainer: {
      paddingTop: 32,
      maxWidth: '50%',
    },

    actionArea: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      padding: 32,
    },
  };
});

export default function FZLegalPageTemplate({ children }: Props): ReactElement {
  const classes = useStyles({});

  return (
    <Container>
      <Grid container>
        <Card className={classes.card}>
          <span className={classes.bodyContainer}>{children}</span>
        </Card>
      </Grid>
    </Container>
  );
}
