import { IconButton, InputAdornment } from '@material-ui/core';
import { Field } from 'formik';
import React, { ReactElement, useState } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { TextField } from 'formik-material-ui';

interface Props {
  className: string;
  label: string;
  name: string;
  color?: string;
  variant?: string;
  // 'Indexer'
  [x: string]: any;
}

export default function FZRevealablePasswordInput({
  className,
  label,
  name,
  color,
  variant,
  ...otherProps
}: Props): ReactElement {
  const [passwordRevealed, setPasswordRevealed] = useState<boolean>(false);

  return (
    <Field
      component={TextField}
      className={className}
      type={passwordRevealed ? 'text' : 'password'}
      label={label}
      name={name}
      variant={variant || 'outlined'}
      color={color || 'primary'}
      {...otherProps}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setPasswordRevealed(
                  prevPasswordRevealed => !prevPasswordRevealed,
                );
              }}
            >
              {passwordRevealed ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    ></Field>
  );
}
