import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../00_Constants/colors';

const useStyles = makeStyles((props: Props) => {
  return {
    labelContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: (props: Props) => props.labelAlign || 'flex-start',
      width: '100%',
    },

    fieldLabel: {
      fontFamily: 'OpenSansRegular',
      letterSpacing: 2.18,
      color: (props: Props) => {
        return props.disabled ? colors.disabled : '#000000C9';
      },
      textTransform: 'uppercase',
      fontSize: '14px',
    },

    optionalLabel: {
      fontFamily: 'Sans Serif',
      letterSpacing: 1.18,
      color: (props: Props) => {
        return props.disabled ? colors.disabled : '#000000C9';
      },
      alignSelf: 'flex-end',
      fontSize: '10px',
      paddingLeft: 8,
    },
  };
});

interface Props {
  label: string;
  className?: string;
  classes?: { container: string };
  style?: React.CSSProperties;
  disabled?: boolean;
  tooltip?: string;
  isOptional?: boolean;
  labelAlign?: string;
}

export default function FZFieldLabel(props: Props): ReactElement {
  const { label, className, style, tooltip, disabled } = props;
  const { t } = useTranslation();

  const classes = useStyles(props);
  return (
    <Fragment>
      {tooltip && disabled === false ? (
        <Typography
          className={clsx(classes.fieldLabel, className)}
          style={style}
        >
          <Tooltip title={tooltip} arrow>
            <span>{label}</span>
          </Tooltip>
          {props.isOptional && <span>{t('FZFieldLabel-optional-label')}</span>}
        </Typography>
      ) : (
        <div className={clsx(classes.labelContainer, props.classes?.container)}>
          <Typography
            className={clsx(classes.fieldLabel, className)}
            style={style}
          >
            {label}
          </Typography>

          {props.isOptional && (
            <span className={classes.optionalLabel}>
              {t('FZFieldLabel-optional-label')}
            </span>
          )}
        </div>
      )}
    </Fragment>
  );
}
