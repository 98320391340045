import { Badge, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import fonts from '../../00_Constants/fonts';

const useStyles = makeStyles({
  FZBadgeRoot: {},

  FZBadge: {
    backgroundColor: (props: Props) => props.backgroundColor,
    color: (props: Props) => props.contentColor,
    border: (props: Props) => `1px solid ${props.borderColor}`,
    fontFamily: fonts.FONT_FAMILY_BOLD,
  },

  FZBadgeLabel: {},
});

interface Props {
  children: JSX.Element | JSX.Element[];
  badgeContent: string | number;
  backgroundColor: string;
  contentColor: string;
  borderColor: string;
}

export default function FZBadge(props: Props): ReactElement {
  const classes = useStyles(props);
  const { badgeContent, children } = props;

  return (
    <Badge
      classes={{
        root: classes.FZBadgeRoot,
        badge: classes.FZBadge,
      }}
      badgeContent={badgeContent}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <span className={classes.FZBadgeLabel}>{children}</span>
    </Badge>
  );
}
