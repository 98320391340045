import { List, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((props: Props) => {
  return {
    settingsCardContainer: {
      boxShadow: '0px 3px 6px #00000029',
    },

    accordionHeadlineContainer: { display: 'flex', flexDirection: 'row' },

    accordionHeadline: {
      color: (props: Props) => {
        return props.keyColor;
      },
    },

    accordionSubHeadline: {
      textAlign: 'center',
    },

    accordionSummaryContent: {
      justifyContent: 'center',
    },

    accordionSummaryContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      marginTop: 10,

      borderTop: (props: Props) => {
        return props.disableOverline ? '' : '1px solid #0000001F';
      },
      paddingTop: 21,
    },

    accordionContentContainer: {
      width: '100%',
    },
  };
});

type Props = {
  headline: string;
  subheadline: string;
  children?: JSX.Element | JSX.Element[];
  keyColor: string;
  disableOverline?: boolean;
};

export default function FZSettingsCard(props: Props): ReactElement {
  const classes = useStyles(props);

  const { children, headline, subheadline } = props;

  return (
    <span className={classes.settingsCardContainer}>
      <span className={classes.accordionSummaryContentContainer}>
        <Typography variant={'h6'} className={classes.accordionHeadline}>
          {headline}
        </Typography>

        <Typography variant={'body1'} className={classes.accordionSubHeadline}>
          {subheadline}
        </Typography>
      </span>

      <span className={classes.accordionContentContainer}>
        <List component="ul">{children}</List>
      </span>
    </span>
  );
}
