import React from 'react';
import useTracker from '../Hooks/useTracker';
import { MatomoInstance } from '@datapunt/matomo-tracker-react/lib/types';
import { ApplicationConfig } from '../../common/config/types';
import useConfig from '../Hooks/useConfig';
export interface WithHooksProps {
  matomoAnalyticsTracker: MatomoInstance;
  appConfig: ApplicationConfig;
}

export const withHooks = (Component: any) => {
  return (props: any) => {
    const tracker = useTracker();
    const appConfig = useConfig();

    return (
      <Component
        matomoAnalyticsTracker={tracker}
        appConfig={appConfig}
        {...props}
      />
    );
  };
};
