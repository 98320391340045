import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

export interface AccordionItem {
  key: string;
  summary: string;
  details: string;
}

export interface Props {
  data: AccordionItem[];
}

export default function FZStackedAccordion(props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.data.map((element: AccordionItem, index) => {
        return (
          <Accordion key={element.key}>
            <AccordionSummary
              expandIcon={<AddRoundedIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {element.summary}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{element.details}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
