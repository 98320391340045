import { makeStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import authorIllustration from '../../../../assets/images/author_illustration.png';
import { UserSettings } from '../../../../common/models/UserSettings';
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../../common/redux/ui/ui.actions';
import { updateUserSettingsRequest } from '../../../../common/redux/users/user.actions';
import {
  selectDefaultAuthorPseudonymForActiveUser,
  selectUserSettings,
} from '../../../../common/redux/users/user.selectors';
import FZEditSettingPageTemplate from '../../../04_templates/EditSettingPageTemplate/FZEditSettingPageTemplate';
import FZEditAuthorNameForm, {
  EditAuthorNameValues,
} from './FZEditAuthorNameForm';

/* const useStyles = makeStyles((theme: Theme) => {
  return {
    emailValue: {
      marginBottom: 50,
      marginTop: 22,
    },
  };
});
 */
interface Props {}

const useStyles = makeStyles({
  imageClassName: {
    paddingLeft: 60,
    paddingTop: 30,
    width: '84%',
    height: 'auto',
    //transform: 'scaleX(-1)',
    float: 'right',
  },
});

export default function EditAuthorNameScreen(props: Props): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();

  const defaultAuthorName: string = useSelector(
    selectDefaultAuthorPseudonymForActiveUser,
  );
  const currentSettings: UserSettings = useSelector(selectUserSettings);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleFinishEditingButtonClick = React.useCallback(
    (
      values: EditAuthorNameValues,
      helpers: FormikHelpers<EditAuthorNameValues>,
    ) => {
      dispatch(
        updateUserSettingsRequest(
          {
            releaseCycleReminderNotificationEnabled:
              currentSettings?.releaseCycleReminderNotificationEnabled,
            defaultActiveFamilyUuid:
              currentSettings?.defaultActiveFamily?.uuid || undefined,
            defaultAuthorPseudonym: values.newAuthorName,
          },
          () => {
            helpers.setSubmitting(false);
            history.push('/profile');
            dispatch(
              showSuccessSnackbar(
                t('profileScreen-updating-author-name-successful-message'),
                true,
              ),
            );
          },
          () => {
            helpers.setSubmitting(false);
            dispatch(
              showErrorSnackbar(
                'profileScreen-updating-author-name-unsuccessful-message',
                true,
              ),
            );
          },
        ),
      );
    },
    [dispatch, history, t, currentSettings],
  );

  return (
    <FZEditSettingPageTemplate
      backgroundImageSource={authorIllustration}
      headline={t('editAuthorNameScreen-headline')}
      subheadline={t('editAuthorNameScreen-subheadline')}
      backDestinationLabel={t('editAuthorNameScreen-navigate-back-to-label')}
      imageClassName={classes.imageClassName}
    >
      <FZEditAuthorNameForm
        onSubmit={handleFinishEditingButtonClick}
        currentAuthorName={
          defaultAuthorName ||
          t('editAuthorNameScreen-no-authorName-given-yet-label')
        }
      ></FZEditAuthorNameForm>
    </FZEditSettingPageTemplate>
  );
}
