import { Theme, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    fieldLabel: {
      fontFamily: 'OpenSansRegular',
      letterSpacing: 0.15,
      color: '#000000C9',
      fontWeight: 600,
      fontSize: 16,
    },
  };
});

interface Props {
  label: string;
  className: any;
}

export default function FZFieldValue({
  label,
  className,
  ...rest
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <Typography className={clsx(classes.fieldLabel, className)} {...rest}>
      {label}
    </Typography>
  );
}
