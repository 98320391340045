import { makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import colors from '../../00_Constants/colors';

const useStyles = makeStyles((theme: Theme) => {
  return {
    FZTypographyBase: {
      color: (props: Props) => props.color || colors.textMedium,
    },
  };
});

interface Props {
  color?: string;
  component?: React.ElementType<any>;
  variant:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline';
  children: JSX.Element | JSX.Element[] | string;
  style?: React.CSSProperties;
  className?: string;
  id?: string;
  onClick?: () => void;
}

export default function FZTypography(props: Props): ReactElement {
  const classes = useStyles(props);
  const { variant, children, style, className, component, id, onClick } = props;

  return (
    <Typography
      variant={variant}
      className={clsx(classes.FZTypographyBase, className)}
      style={style}
      component={component || 'p'}
      id={id}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
}
