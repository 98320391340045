import { AxiosResponse } from 'axios';
import { apiClient, API_CONFIG } from '../api';
import {
  CreateFamilyDto,
  CreateFamilyInvitationDto,
  EditFamilyDto,
} from '../redux/families/types';
class FamilyService {
  /**
   * Fetches a single family identified by a given uuid.
   * @param familyUuid - The public identifier of the family to fetch
   * @param accessToken - the personal access token of the requester.
   */
  async getFamily(familyUuid: string, accessToken: string) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const uriString = API_CONFIG.FAMILY_SERVICE.GET_FAMILY_URL(familyUuid);
    return apiClient.get(uriString, { headers });
  }

  /**
   * Fetches all families that a user is member of or invited in
   *
   */
  async getOwnFamilies(userUid: string, accessToken: string) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const uriString = API_CONFIG.FAMILY_SERVICE.GET_FAMILIES_FOR_USER_URI(
      userUid,
    );

    return apiClient.get(uriString, { headers });
  }

  /**
   * Creates a new family
   * @param {CreateFamilyDto} familyToCreate
   * @param {string} accessToken
   */
  async createFamily(familyToCreate: CreateFamilyDto, accessToken: string) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const uriString = API_CONFIG.FAMILY_SERVICE.CREATE_FAMILY_URI();
    return apiClient.post(uriString, familyToCreate, { headers });
  }

  /**
   *
   * @param {string} uuidOfFamilyToEdit
   * @param {editFamilyDto} familyToEdit
   * @param {string} accessToken
   */
  async editFamily(editFamilyDto: EditFamilyDto, accessToken: string) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const uriString = API_CONFIG.FAMILY_SERVICE.EDIT_FAMILY_URI(
      editFamilyDto.uuid,
    );

    delete editFamilyDto.uuid;
    return apiClient.put(uriString, editFamilyDto, { headers });
  }

  /**
   * Issues an HTTP-Request to create a new invitation to a family.
   * @param {string} familyUuidOfFamilyThatTheInviteIsFor
   * @param {CreateFamilyInvitationDto} invitationToCreate
   * @param {string} accessToken
   */
  async createInviteToFamily(
    familyUuidOfFamilyThatTheInviteIsFor: string,
    invitationToCreate: CreateFamilyInvitationDto,
    accessToken: string,
  ): Promise<AxiosResponse<any>> {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const uriString = API_CONFIG.FAMILY_SERVICE.CREATE_INVITE_TO_FAMILY_URI(
      familyUuidOfFamilyThatTheInviteIsFor,
    );

    return apiClient.post(uriString, invitationToCreate, { headers });
  }

  /**
   * Issues an HTTP-Request to delete a previously created invitation to a family.
   * @param {string} familyUuidOfFamilyThatTheInviteIsFor
   * @param {string} invitationUuidToDelete
   * @param {string} accessToken
   */
  async deleteInviteToFamily(
    familyUuidOfFamilyThatTheInviteIsFor: string,
    invitationUuidToDelete: string,
    accessToken: string,
  ) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const uriString = API_CONFIG.FAMILY_SERVICE.DELETE_INVITE_TO_FAMILY_URI(
      familyUuidOfFamilyThatTheInviteIsFor,
      invitationUuidToDelete,
    );

    return apiClient.delete(uriString, { headers });
  }

  /**
   * Sends an HTTP Request to leave a family that the user is a member in.
   * @param {string} familyToLeaveUuid - UUid of the family that shall be left.
   * @param {string} accessToken - Unique user access token
   */
  async leaveFamily(familyToLeaveUuid: string, accessToken: string) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const uriString = API_CONFIG.FAMILY_SERVICE.LEAVE_FAMILY_URI(
      familyToLeaveUuid,
    );
    return apiClient.delete(uriString, { headers });
  }

  /**
   *  Sends an HTTP request to end a user's memberhip in ia family.
   */
  async endOthersMembershipInFamily(
    familyUuid: string,
    customerUid: string,
    accessToken: string,
  ) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const uriString = API_CONFIG.FAMILY_SERVICE.END_OTHERS_MEMBERSHIP_IN_FAMILY_URI(
      familyUuid,
      customerUid,
    );
    return apiClient.delete(uriString, { headers });
  }

  /**
   * Sends an HTTP Request to join a family using an invite code
   * @param {string} inviteCode - the unique invitation code created by one of the family members
   */
  async joinFamilyWithInviteCode(
    inviteCode: string,
    inviteCodeIsGeneralAccess: boolean,
    accessToken: string,
  ) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const uriString = API_CONFIG.FAMILY_SERVICE.JOIN_FAMILY_URI();
    return apiClient.post(
      uriString,
      {
        inviteCode: inviteCode,
        inviteCodeIsGeneralAccess: inviteCodeIsGeneralAccess,
      },
      { headers },
    );
  }
}
export default FamilyService;
