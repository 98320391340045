import { AnyAction } from 'redux';
import { authenticationActionTypes } from '../login/login.actions';
import { UserReducerState } from './types';
import {
  UpdateUserProfileSuccessAction,
  userActionTypes,
} from './user.actions';
import { Image } from '../../models/Image';
import { familyActionTypes } from '../families/family.actions';

const initialState: UserReducerState = {
  userProfile: null,
  userSettings: null,
  isFetchingUserSettings: false,
  fetchingUserSettingsWasSuccessful: false,
};

const user = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case userActionTypes.FETCH_USER_SETTINGS_REQUEST: {
      return {
        ...state,
        isFetchingUserSettings: true,
        fetchingUserSettingsWasSuccessful: false,
      };
    }

    case userActionTypes.FETCH_USER_SETTINGS_SUCCESS: {
      return {
        ...state,
        userSettings: action.payload.newSettings,
        isFetchingUserSettings: false,
        fetchingUserSettingsWasSuccessful: true,
      };
    }

    case userActionTypes.UPDATE_USER_SETTINGS_SUCCESS: {
      return { ...state, userSettings: action.payload.updatedSettings };
    }

    case userActionTypes.FETCH_USER_SETTINGS_FAILURE: {
      return {
        ...state,
        isFetchingUserSettings: false,
      };
    }

    case userActionTypes.FETCH_USER_SETTINGS_FULFILL: {
      return {
        ...state,
        isFetchingUserSettings: false,
      };
    }

    case userActionTypes.FETCH_OWN_PROFILE_SUCCESS: {
      return {
        ...state,

        userProfile: action.payload.userProfile,
      };
    }

    case userActionTypes.PUT_PROFILE_IMAGE_SUCCESS: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          profileImage: new Image({ ...action.payload }),
        },
      };
    }

    case userActionTypes.UPDATE_USER_PROFILE_SUCCESS: {
      const typedAction = action as UpdateUserProfileSuccessAction;
      return {
        ...state,
        userProfile: {
          profileImage: state.userProfile?.profileImage,
          ...typedAction.payload,
        },
      };
    }

    case familyActionTypes.LEAVE_FAMILY_SUCCESS: {
      const familyUuidOfFamilyToLeave =
        action.payload.familyUuidOfFamilyToLeave;

      if (
        state.userSettings?.defaultActiveFamily?.uuid ===
        familyUuidOfFamilyToLeave
      ) {
        return {
          ...state,
          userSettings: { ...state.userSettings, defaultActiveFamily: null },
        };
      }

      return state;
    }

    case authenticationActionTypes.LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default user;
