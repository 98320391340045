import { createSelector } from 'reselect';
import { Image } from '../../models/Image';
import { User } from '../../models/User';
import { UserSettings } from '../../models/UserSettings';
import { LoginReducerState } from '../login/types';
import { ApplicationState } from '../main/types';
import { UserProfile } from './types';

export const selectUserProfile = (
  state: ApplicationState,
): UserProfile | null => {
  return state.user.userProfile;
};

export const selectUserSettings = (
  state: ApplicationState,
): UserSettings | null => {
  return state.user.userSettings;
};

export const selectProfileImage = (state: any) =>
  state.user.userProfile?.profileImage;

export const selectLoggedInUser = (state: ApplicationState): User =>
  state.login.loggedInUser;

export const selectPrimaryUserEmail = (
  state: ApplicationState,
): string | '' => {
  return state.login.loggedInUser?.email || '';
};

export const selectAccessToken = (state: any) => state.login.accessToken;

export const selectAuthState = createSelector(
  (state: ApplicationState) => state.login,
  (login: LoginReducerState) => {
    return login;
  },
);

/* export const selectAuthState = (
  state: ApplicationState,
): {
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  accessToken: string;
  tokenExpiry: number;
} => {
  return 
}; */

export const selectLoggedInUserUid = (state: ApplicationState) =>
  state.login.loggedInUser?.customerUid;

export const selectLoggedInAccountUid = (state: ApplicationState) =>
  state.login.loggedInUser?.accountUid;

export const getUserProfileImageUrl = (state: any): Image | null => {
  return state.user.userProfileImage;
};

export const selectDefaultAuthorPseudonymForActiveUser = (
  state: ApplicationState,
): string => {
  return state.user.userSettings?.defaultAuthorPseudonym;
};
