// src/Signup.tsx

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import fzColorfulBackgroundImage from '../../../../assets/images/FZ_colorful_background.jpg';
import { showInfoSnackbar } from '../../../../common/redux/ui/ui.actions';
import { FullWidthBackground } from '../../../01_atoms/FullWidthBackground/FullWidthBackground';
import useConfig from '../../../Hooks/useConfig';
import SignupForm from './SignupForm';

interface Props {}

const SignupScreen: React.FC<Props> = () => {
  const config = useConfig();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const signupEnabled: boolean =
    config.FEATURE_FLAGS.AUTH.REACT_APP_FEATURE_FLAG_REGISTRATION_ENABLED;

  if (!signupEnabled) {
    dispatch(
      showInfoSnackbar(t('signupScreen-403-signup-currently-closed'), false),
    );
  }

  const handleSignupSuccess = useCallback(() => {
    history.push('/signup/activation-email-sent');
  }, [history]);

  const handleSignupFailure = useCallback(() => {}, []);

  return (
    <FullWidthBackground
      imageSource={fzColorfulBackgroundImage}
      position="absolute"
    >
      {signupEnabled && (
        <SignupForm
          signupEnabled={signupEnabled}
          onSignupSuccess={handleSignupSuccess}
          onSignupFailure={handleSignupFailure}
        />
      )}
    </FullWidthBackground>
  );
};

export default SignupScreen;
