import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FZLegalPageTemplate from '../../04_templates/LegalPageTemplate/FZLegalPageTemplate';

interface Props {}

const TermsOfServiceScreen = (props: Props) => {
  const { t } = useTranslation();
  return (
    <FZLegalPageTemplate>
      <Typography variant="h1">{t('termsOfServiceScreen-headline')}</Typography>
    </FZLegalPageTemplate>
  );
};

export default TermsOfServiceScreen;
