import { CREATED, OK } from 'http-status-codes';
import {
  call,
  delay,
  getContext,
  put,
  race,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import ApiUtilities from '../../api/ApiUtilities';
import { Image } from '../../models/Image';
import { User, UserData } from '../../models/User';
import { UserSettings } from '../../models/UserSettings';
import UserService, {
  PutProfileImageRequest,
} from '../../services/UserService';
import { authenticationActionTypes } from '../login/login.actions';
import { selectAccessToken } from '../login/login.selectors';
import {
  fetchOwnProfileFailure,
  fetchOwnProfileFulfill,
  fetchOwnProfileSuccess,
  fetchUserSettingsSuccess,
  putProfileImageAction,
  putProfileImageFailure,
  putProfileImageFulfill,
  putProfileImageSuccess,
  UpdateUserProfileAction,
  updateUserProfileFailure,
  updateUserProfileFulfill,
  updateUserProfileSuccess,
  UpdateUserSettingsAction,
  updateUserSettingsFailure,
  updateUserSettingsFulfill,
  updateUserSettingsSuccess,
  userActionTypes,
} from './user.actions';
import { selectLoggedInUser, selectLoggedInUserUid } from './user.selectors';

//#region User Settings
function* getUserSettingsSaga() {
  // Get Service from redux-saga-context
  const userService: UserService = yield getContext('userService');
  const accessToken: string = yield select(selectAccessToken);

  if (accessToken) {
    try {
      const introduceUserResponse = yield userService.introduceUser(
        accessToken,
      );

      if (introduceUserResponse && introduceUserResponse.status === 201) {
        const loggedInUser: User = yield select(selectLoggedInUser);

        /**
         * Refresh user settings with the "truth" from the server
         */
        const userData: UserData = introduceUserResponse.data;

        if (userData.profileImage) {
          loggedInUser.profileImage = new Image(userData.profileImage);
        }

        const userSettings: UserSettings = {
          defaultAuthorPseudonym: userData.settings?.defaultAuthorPseudonym,
          defaultActiveFamily: userData.settings?.defaultActiveFamily,
          releaseCycleReminderNotificationEnabled:
            userData.settings?.releaseCycleReminderNotificationEnabled,
        };

        yield put(fetchUserSettingsSuccess(userSettings));
      }
    } catch (error) {
      yield put({
        type: userActionTypes.FETCH_USER_SETTINGS_FAILURE,
        payload: { error },
      });
    } finally {
      yield put({
        type: userActionTypes.FETCH_USER_SETTINGS_FULFILL,
      });
    }
  }
}

function* watchGetUserSettings() {
  yield takeLatest(
    userActionTypes.FETCH_USER_SETTINGS_REQUEST,
    getUserSettingsSaga,
  );
}

function* updateUserSettings(action: UpdateUserSettingsAction) {
  try {
    // Get Service from redux-saga-context
    const userService: UserService = yield getContext('userService');
    // Perform async side effect
    const accessToken = yield select(selectAccessToken);
    const userUid = yield select(selectLoggedInUserUid);
    const response = yield call(
      userService.updateUserSettings,
      userUid,
      action.payload.newSettings,
      accessToken,
    );

    // Check if the response yields a successful status
    if (response && response.status === OK) {
      yield put(updateUserSettingsSuccess(response.data));
      if (action.payload.successCallback) {
        yield call(action.payload.successCallback);
      }
    }
  } catch (error) {
    yield put(updateUserSettingsFailure(error.response.data));

    if (action.payload.errorCallback) {
      yield call(action.payload.errorCallback);
    }
  } finally {
    yield put(updateUserSettingsFulfill());
  }
}

function* watchUpdateUserSettings() {
  yield takeLatest(
    userActionTypes.UPDATE_USER_SETTINGS_REQUEST,
    updateUserSettings,
  );
}
//#endregion

//#region User Profile
function* getOwnProfileSaga(action: any) {
  try {
    // Get Service from redux-saga-context
    const userService = yield getContext('userService');
    const userUuid: string = yield select(selectLoggedInUserUid);
    const accessToken: string = yield select(selectAccessToken);

    const response = yield call(
      userService.fetchUserProfile,
      userUuid,
      accessToken,
    );

    if (response && response.status === OK) {
      yield put(fetchOwnProfileSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchOwnProfileFailure(error));
  } finally {
    yield put(fetchOwnProfileFulfill());
  }
}

function* watchGetOwnProfile() {
  yield takeLatest(
    userActionTypes.FETCH_OWN_PROFILE_REQUEST,
    getOwnProfileSaga,
  );
}

function* updateOwnProfileSaga(action: UpdateUserProfileAction) {
  try {
    // Get Service from redux-saga-context
    const userService: UserService = yield getContext('userService');
    const accessToken = yield select(selectAccessToken);
    const userUid = yield select(selectLoggedInUserUid);

    // Perform async side effect
    const { response, timeout } = yield race({
      response: call(
        userService.updateUserProfile,
        userUid,
        action.payload.newProfile,
        accessToken,
      ),
      timeout: delay(6000),
    });

    if (timeout) {
      yield put(updateUserProfileFailure(new Error('TIMEOUT')));
    }

    // Check if the response yields a successful status
    if (response && response.status === OK) {
      yield put(updateUserProfileSuccess(response.data));
      if (action.payload.successCallback) {
        yield call(action.payload.successCallback);
      }
    }
  } catch (error) {
    yield put(updateUserProfileFailure(error.response.data));

    if (action.payload.errorCallback) {
      yield call(action.payload.errorCallback);
    }
  } finally {
    yield put(updateUserProfileFulfill());
  }
}

function* watchUpdateOwnProfile() {
  yield takeLatest(
    userActionTypes.UPDATE_USER_PROFILE_REQUEST,
    updateOwnProfileSaga,
  );
}

function* watchSigninSuccess() {
  yield takeEvery(authenticationActionTypes.LOGIN_SUCCESS, getOwnProfileSaga);
}

// --

function* putProfileImageSaga(
  action: putProfileImageAction,
): Generator<any, any, any> {
  try {
    // Get Service from redux-saga-context
    const userService = yield getContext('userService');
    const imageService = yield getContext('imageService');
    const accessToken: string = yield select(selectAccessToken);
    const imageToUpload: Image = action.payload.image;
    const userUid = yield select(selectLoggedInUserUid);

    /** Step 1 - Generate a presigned-upload url that allows us to
     *  upload a resource directly to a designated storage provider.
     */

    const presignedImageUploadUrlResponse = yield call(
      imageService.getPresignedImageUploadUrl,
      accessToken,
      imageToUpload.mimeType,
    );

    if (
      presignedImageUploadUrlResponse &&
      presignedImageUploadUrlResponse.status === OK
    ) {
      /**
       * Step 2 - One the upload url could be obtained,
       * perform the upload request.
       */

      const { presignedUploadUrl } = presignedImageUploadUrlResponse.data;

      const imageUploadResponse = yield call(
        imageService.uploadImageToDirectlyToStorageProvider,
        presignedUploadUrl,
        imageToUpload.blobRepresentation,
        imageToUpload.mimeType,
        action.payload.uploadProgressCallback,
      );

      if (imageUploadResponse && imageUploadResponse.status === OK) {
        /* Step 3 - Update the backend with the information
         * that there is a new image that should be linked to an article.
         */
        const linkImagesToArticleRequestBody: PutProfileImageRequest = {
          url: presignedUploadUrl.split('?')[0],
          contentType: imageToUpload.mimeType,
        };

        // Perform async side effect
        const { response, timeout } = yield race({
          response: call(
            userService.updateProfilePicture,
            linkImagesToArticleRequestBody,
            userUid,
            accessToken,
          ),
          timeout: delay(10000),
        });

        if (timeout) {
          yield call(ApiUtilities.callErrorCallbackIfDefined, action);
          yield put(putProfileImageFailure(new Error('TIMEOUT')));
        }

        // Check if the response yields a successful status, then the article has successfully been enriched with an image.
        if (response.status === CREATED || response.status === OK) {
          yield put(putProfileImageSuccess(response.data));
          yield call(ApiUtilities.callSuccessCallbackIfDefined, action);
          yield call(action.payload.uploadImageSuccessCallback);
        } else {
          yield call(ApiUtilities.callErrorCallbackIfDefined, action);
          yield put(
            putProfileImageFailure(
              new Error('FAILED TO FETCH PRESIGNED IMAGE UPLOAD URL'),
            ),
          );
        }
      }
    } else {
      yield call(ApiUtilities.callErrorCallbackIfDefined, action);
      yield put(
        putProfileImageFailure(
          new Error('FAILED TO FETCH PRESIGNED IMAGE UPLOAD URL'),
        ),
      );
    }
  } catch (error) {
    yield call(ApiUtilities.callErrorCallbackIfDefined, action);
    yield put(putProfileImageFailure(error));
  } finally {
    yield put(putProfileImageFulfill());
  }
}

function* watchUpdateProfileImage() {
  yield takeLatest(
    userActionTypes.PUT_PROFILE_IMAGE_REQUEST,
    putProfileImageSaga,
  );
}
//#endregion

const userSagas = {
  watchGetOwnProfile,
  watchUpdateOwnProfile,
  watchGetUserSettings,
  watchUpdateProfileImage,
  watchSigninSuccess,
  watchUpdateUserSettings,
};

export default userSagas;
