import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  screenContainer: {
    position: (props: Props) => {
      return props.position || 'relative';
    },
    /*  top: 0,
    bottom: 0,
    left: 0,
    right: 0, */
    minWidth: '100%',
    //marginLeft: -50,
    minHeight: '100%',
    backgroundImage: (props: Props) => `url(${props.imageSource})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    //paddingTop: 16,
    paddingBottom: 32,
    //height: '105vh',
  },

  childrenContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
});

interface Props {
  position?:
    | '-webkit-sticky'
    | 'absolute'
    | 'fixed'
    | 'relative'
    | 'static'
    | 'sticky';
  imageSource: string;
  wrapChildren?: boolean;
  children?: JSX.Element | JSX.Element[];
}

export const FullWidthBackground = (props: Props) => {
  const classes = useStyles(props);
  const { children, wrapChildren } = props;
  return (
    <div className={classes.screenContainer}>
      {wrapChildren && (
        <div className={classes.childrenContainer}> {children}</div>
      )}
      {!wrapChildren && children}
    </div>
  );
};
