import {
  Button,
  ButtonProps,
  darken,
  lighten,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import colors from '../../00_Constants/colors';
import FZSpinner from '../FZSpinner';

const useStyles = makeStyles((props: Props) => {
  return {
    FZButtonRoot: {
      backgroundColor: (props: Props) => {
        if (props.variant === 'outlined') {
          return colors.white;
        }
        return props.backgroundColor || colors.white;
      },

      color: (props: Props) => {
        if (props.variant === 'contained') {
          return colors.white;
        }

        return props.labelColor || colors.textMedium;
      },

      border: (props: Props) => {
        if (props.variant === 'outlined') {
          return `1px solid ${props.labelColor}`;
        }

        return `1px solid ${props.backgroundColor}`;
      },

      '&:hover': {
        backgroundColor: (props: Props) => {
          if (props.variant === 'outlined') {
            return props.backgroundColor;
          } else if (props.variant === 'contained') {
            return props.backgroundColor
              ? darken(props.backgroundColor, 0.1)
              : props.labelColor;
          } else {
            return colors.white;
          }
        },
        opacity: 1,
        color: (props: Props) => {
          return props.variant === 'outlined' && props.labelColor
            ? lighten(props.labelColor, 0.5)
            : props.labelColor;
        },
      },

      '&:active': {
        backgroundColor: colors.white,
        borderWidth: 2,
        borderStyle: 'solid',
      },

      '&:focus': {},
    },

    FZButton: {
      marginTop: 16,
      marginBottom: 16,
    },
  };
});

interface Props extends ButtonProps {
  backgroundColor?: string;
  labelColor?: string;
  label: string;
  onClick?: () => void;
  className?: any;
  isSubmitting?: boolean;
  type?: 'button' | 'reset' | 'submit';
}

export default function FZButton(props: Props): ReactElement {
  const {
    backgroundColor,
    label,
    labelColor,
    onClick,
    className,
    isSubmitting,
    type,
    disabled,

    ...rest
  } = props;
  const classes = useStyles(props);

  return (
    <Button
      classes={{ root: classes.FZButtonRoot }}
      className={clsx(classes.FZButton, className)}
      onClick={onClick}
      disabled={isSubmitting || disabled}
      type={type}
      {...rest}
    >
      {label}

      {isSubmitting && (
        <FZSpinner
          style={{ marginLeft: 8 }}
          color={backgroundColor || '#000'}
          statusMessage={''}
        ></FZSpinner>
      )}
    </Button>
  );
}
