// src/CustomLoginForm.jsx

import {
  Box,
  Button,
  Card,
  Container,
  FormHelperText,
  Grid,
  Link,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { loginRequest } from '../../../../common/redux/login/login.actions';
import { LoginDto } from '../../../../common/redux/login/types';
import { ActionCallbacks } from '../../../../common/redux/main/types';
import colors from '../../../00_Constants/colors';
import FZRevealablePasswordInput from '../../../01_atoms/FZRevealablePasswordInput/FZRevealablePasswordInput';
import FZSpinner from '../../../01_atoms/FZSpinner';

const useStyles = makeStyles(theme => {
  return {
    formContainer: {
      position: 'relative',
      top: 0,
    },

    form: {
      paddingTop: 64,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '0 auto',
      },

      [theme.breakpoints.up('sm')]: {
        width: '75%',
        margin: '0 auto',
      },

      [theme.breakpoints.up('lg')]: {
        width: '40%',
        margin: '0 auto',
      },
    },

    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },

    serviceNameLabel: {
      paddingTop: 32,
      paddingBottom: 32,
    },

    formInputFieldContainer: {
      paddingTop: 8,
      paddingBottom: 32,
      paddingLeft: 16,
      paddingRight: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '80%',
    },

    formInputField: {
      width: '100%',
    },

    media: {
      borderRadius: 4,
      marginTop: 16,
      marginBottom: 32,
      marginLeft: 16,
      marginRight: 16,
    },

    signupNoticeContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      paddingTop: 8,
    },
  };
});

interface LoginFormValues {
  username: string;
  password: string;
}

export interface Props {
  issuer: string;
  loginEnabled: boolean;
  resetPasswordEnabled: boolean;

  onLoginSuccess?: () => void;
  onLoginFailure?: () => void;
}

const LoginForm = ({
  issuer,
  loginEnabled,
  resetPasswordEnabled,
  onLoginSuccess,
  onLoginFailure,
}: Props) => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Container className={classes.formContainer} style={{ marginBottom: 16 }}>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={values => {
          const errors: Partial<LoginFormValues> = {};
          if (!values.username) {
            errors.username = t('loginScreen-username-required-message');
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)
          ) {
            errors.username = t(
              'loginScreen-username-does-not-match-email-pattern-message',
            );
          }

          if (!values.password) {
            errors.password = t('loginScreen-password-required-message');
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          const issueLoginRequest = (values: Partial<LoginFormValues>) => {
            const loginDto: LoginDto = {
              username: values.username.toLocaleLowerCase(),
              password: values.password,
            };

            const actionCallbacks: ActionCallbacks = {
              successCallback: () => {
                setSubmitting(false);
                onLoginSuccess();
              },
              errorCallback: (errorStatus, errorCode, errorSummary) => {
                setSubmitting(false);
                onLoginFailure();
              },
            };

            dispatch(loginRequest(loginDto, actionCallbacks));
          };

          issueLoginRequest(values);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form data-testid="login-form">
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              className={classes.form}
            >
              <Card className={classes.card}>
                <Typography variant="h1" className={classes.serviceNameLabel}>
                  {t('loginScreen-serviceName-label')}
                </Typography>

                <Grid item xs={12} className={classes.formInputFieldContainer}>
                  <Field
                    data-testid="username-input"
                    component={TextField}
                    className={classes.formInputField}
                    name="username"
                    type="email"
                    label={t('loginScreen-username-field-label')}
                    variant="outlined"
                    color="secondary"
                    disabled={loginEnabled === false}
                    id="username"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12} className={classes.formInputFieldContainer}>
                  <FZRevealablePasswordInput
                    data-testid="password-input"
                    className={classes.formInputField}
                    label={t('loginScreen-password-field-label')}
                    name="password"
                    variant="outlined"
                    color="secondary"
                    disabled={loginEnabled === false}
                    id="password"
                    autoComplete="current-password"
                  />

                  <FormHelperText
                    title={t('loginScreen-fortgot-password-label')}
                    style={{ alignSelf: 'flex-end' }}
                  >
                    {resetPasswordEnabled ? (
                      <Link
                        href="/forgot-password"
                        color="secondary"
                        component="a"
                        variant="body2"
                      >
                        {t('loginScreen-fortgot-password-label')}
                      </Link>
                    ) : (
                      <Tooltip
                        title={t(
                          'loginScreen-resetPasswordLink-disabled-tooltip',
                        )}
                      >
                        <Link
                          href="#"
                          style={{ color: colors.disabled }}
                          variant="body2"
                        >
                          {t('loginScreen-fortgot-password-label')}
                        </Link>
                      </Tooltip>
                    )}
                  </FormHelperText>
                </Grid>
                {isSubmitting && (
                  <FZSpinner
                    color={colors.quartiary}
                    statusMessage={t(
                      'loginScreen-login-in-progress-status-message',
                    )}
                  />
                )}

                <Grid xs={12} item className={classes.formInputFieldContainer}>
                  <Button
                    type="submit"
                    data-testid="login-button"
                    variant="contained"
                    color="secondary"
                    disabled={loginEnabled === false || isSubmitting}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    {t('loginScreen-signinButton-label')}
                  </Button>
                </Grid>

                <Grid xs={12} item className={classes.formInputFieldContainer}>
                  <Link href="/help" color="secondary" variant="body2">
                    {t('loginScreen-do-you-need-help-logging-in-label')}
                  </Link>
                </Grid>
              </Card>
              <Box className={classes.signupNoticeContainer}>
                <Typography>
                  {t('loginScreen-not-registered-yet-label')}
                </Typography>

                <Link
                  href="/signup"
                  /* color="primary"  */ style={{
                    paddingLeft: 8,
                    color: '#EFEFEF',
                  }}
                  variant="body2"
                >
                  {t('loginScreen-sign-up-label')}
                </Link>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
export default LoginForm;
