import { Point } from 'geojson';
import { Image, ImageDto } from './Image';
import { User } from './User';

export enum ArticleType {
  SHORT_ARTICLE = 'SHORT_ARTICLE',
  LONG_ARTICLE = 'LONG_ARTICLE',
}

export type ArticleDto = {
  id?: string;
  uuid?: string;
  title: string;
  description: string;
  createdAt?: string;
  locationName?: string;
  locationPoint?: Point;
  createdBy?: string | User;
  images?: Image[];
  isDraft?: boolean;
  freelyAssignedAuthor?: string;
  freelyAssignedDate?: string;
  photos?: ImageDto[];
  updatedAt?: string;
  answerTo?: Article | null;
  answerToArticleUuid?: string;
  articleType?: ArticleType;
};

export class Article {
  id?: string;

  uuid?: string;

  title: string;

  description: string;

  createdAt?: string;

  locationName?: string;

  locationPoint?: Point;

  images?: Image[];

  createdBy?: string | User;

  isDraft?: boolean;

  freelyAssignedAuthor?: string;

  freelyAssignedDate?: string;

  photos?: Image[];

  updatedAt?: string;

  answerTo?: Article | null;

  answerToArticleUuid?: string;

  articleType?: ArticleType;

  /**
   * @param {object} articleBody - Object representing various data about an article or opportunity.
   *
   * Containing the following fields:
   * @param {string} id - OPTIONAL - Unique public articlentifier for the article
   * @param {string} title - The titel of the article, a short yet descriptive text between 0..30 characters
   * @param {string} description - The content of the article, leaving room to descibe it in greater detail 0..200 characters
   * @param {Location} location - Location that the article is "located at", meaning a place that shall be adressed in the article.
   * @param {number} latitude - OPTIONAL - The latitude of a possible location that shall be attached to provide context to the article
   * @param {number} longitude - OPTIONAL - The longitude of a possible location that shall be attached to provide context to the article
   * @param {string[]} Images - OPTIONAL - A set of images that can be used to describe an article using imagery.
   */
  constructor(articleDto: ArticleDto) {
    this.id = articleDto.id;
    this.uuid = articleDto.uuid;
    this.title = articleDto.title;
    this.description = articleDto.description;
    this.createdAt = articleDto.createdAt;

    this.locationName = articleDto.locationName;
    this.locationPoint = articleDto.locationPoint;
    this.createdBy = articleDto.createdBy || '';
    this.images = articleDto.images;
    this.isDraft = articleDto.isDraft;
    this.freelyAssignedAuthor = articleDto.freelyAssignedAuthor;
    this.freelyAssignedDate = articleDto.freelyAssignedDate;
    this.updatedAt = articleDto.updatedAt;
    this.photos = articleDto.photos?.map((photo: ImageDto) => {
      return new Image(photo);
    });

    this.answerTo = articleDto.answerTo
      ? new Article(articleDto.answerTo)
      : null;
    this.answerToArticleUuid = articleDto.answerToArticleUuid;
    this.articleType = articleDto.articleType;
  }
}
