import { Card, IconButton, makeStyles } from '@material-ui/core';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FZStepDenominator from '../../01_atoms/FZStepDenominator/FZStepDenominator';
import FZTypography from '../../01_atoms/FZTypography/FZTypography';

const useStyles = makeStyles({
  cardContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 250, // 158 in design spec, but thats too small to contain all the texts without the element pushing content below it down by growing,
    padding: 32,
  },
  cardCenterContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  cardTypographyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  nextContentPieceIcon: {
    flexBasis: '10%',
  },
});

export interface FZUserTipp {
  headline: string;
  message: string;
}

interface Props {
  contentItems: FZUserTipp[];
  keyColor: string;
  textColor: string;
  style?: React.CSSProperties;
  className?: any;
}

export default function FZSliderWidget({
  contentItems,
  keyColor,
  textColor,
  className,
  style,
}: Props): ReactElement {
  const [activeTipp, setActiveTipp] = useState(0);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={className} style={style}>
      <div className={classes.cardContentContainer}>
        <div className={classes.cardCenterContentContainer}>
          <IconButton
            aria-label="display previous tipp button"
            className={classes.nextContentPieceIcon}
            onClick={() => {
              setActiveTipp(activeTipp - 1);
            }}
            disabled={activeTipp === 0}
          >
            <KeyboardArrowLeftRoundedIcon />
          </IconButton>

          <div className={classes.cardTypographyContainer}>
            <FZTypography color={keyColor} variant="h6">
              {contentItems[activeTipp].headline}
            </FZTypography>
            <FZTypography
              color={textColor}
              variant="body1"
              style={{ textAlign: 'center' }}
            >
              {contentItems[activeTipp].message}
            </FZTypography>

            <FZStepDenominator
              stepLabel={t('FZSliderWidget-step-label')}
              stepOfLabel={t('FZSliderWidget-step-of-label')}
              currentStep={activeTipp}
              totalSteps={contentItems.length}
            ></FZStepDenominator>
          </div>

          <IconButton
            aria-label="display next tipp button"
            className={classes.nextContentPieceIcon}
            onClick={() => {
              setActiveTipp(activeTipp + 1);
            }}
            disabled={activeTipp === contentItems.length - 1}
          >
            <KeyboardArrowRightRoundedIcon />
          </IconButton>
        </div>
      </div>
    </Card>
  );
}
