import React from 'react';
import { ApplicationConfig } from '../../common/config/types';
import useConfig from '../Hooks/useConfig';

export interface WithConfigProps {
  appConfig: ApplicationConfig;
}

// Mark the function as a generic using P (or whatever variable you want)
export function withConfig<P>(
  // Then we need to type the incoming component.
  // This creates a union type of whatever the component
  // already accepts AND our extraInfo prop
  WrappedComponent: React.ComponentType<P & WithConfigProps>,
): any {
  return (props: P): any => {
    const appConfig = useConfig();
    // At this point, the props being passed in are the original props the component expects.
    return (
      <>
        {WrappedComponent && (
          <WrappedComponent {...props} appConfig={appConfig} />
        )}
      </>
    );
  };
}
