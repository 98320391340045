// src/Login.jsx

import memoizeOne from 'memoize-one';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
//import { makeStyles } from '@material-ui/core';
import fzColorfulBackgroundImage from '../../../../assets/images/FZ_colorful_background.jpg';
import { joinFamilyRequest } from '../../../../common/redux/families/family.actions';
import {
  selectFamilyInvitationToken,
  selectFamilyInvitationTokenIsPersonalized,
} from '../../../../common/redux/login/login.selectors';
import {
  showInfoSnackbar,
  showSuccessSnackbar,
} from '../../../../common/redux/ui/ui.actions';
import { selectAuthState } from '../../../../common/redux/users/user.selectors';
import { FullWidthBackground } from '../../../01_atoms/FullWidthBackground/FullWidthBackground';
import useConfig from '../../../Hooks/useConfig';
import LoginForm from './LoginForm';

/* const useStyles = makeStyles(theme => {
  return {};
}); */

interface Props {
  authState?: any;
  issuer: string;
}

const LoginScreen: React.FC<Props> = ({ issuer }) => {
  const authState = useSelector(selectAuthState);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const memoizedUseConfig = memoizeOne(useConfig);
  const config = memoizedUseConfig();
  const familyInvitationToken = useSelector(selectFamilyInvitationToken);
  const familyInvitationTokenIsPersonalized = useSelector(
    selectFamilyInvitationTokenIsPersonalized,
  );
  const history = useHistory();

  const loginEnabled =
    config.FEATURE_FLAGS.AUTH.REACT_APP_FEATURE_FLAG_LOGIN_ENABLED;

  const resetPasswordEnabled =
    config.FEATURE_FLAGS.AUTH.REACT_APP_FEATURE_FLAG_RESET_PASSWORD_ENABLED;

  useEffect(() => {
    if (familyInvitationToken) {
      dispatch(
        showSuccessSnackbar(
          t(
            'loginScreen-new-user-joined-after-being-invited-by-family-member-label',
          ),
          false,
        ),
      );
    }

    return;
  }, [familyInvitationToken, dispatch, t]);

  useEffect(() => {
    if (!loginEnabled) {
      dispatch(
        showInfoSnackbar(t('loginScreen-403-login-currently-closed'), false),
      );
    }

    return;
  }, [loginEnabled, dispatch, t]);

  const handleLoginSuccess = useCallback(() => {
    if (familyInvitationToken) {
      dispatch(
        joinFamilyRequest(
          {
            inviteCode: familyInvitationToken,
            inviteCodeIsGeneralAccess: !familyInvitationTokenIsPersonalized,
          },
          {},
        ),
      );

      history.push('/family');
    } else {
      history.push({
        pathname: '/home',
        state: {
          shouldRefetchCurrentNewspaper: true,
          shouldRefetchOwnArticles: true,
          shouldRefetchLatestPublishedNewspaper: true,
        },
      });
    }
  }, [
    dispatch,
    history,
    familyInvitationToken,
    familyInvitationTokenIsPersonalized,
  ]);

  const handleLoginFailure = useCallback(() => {
    console.log('Login Failure!');
  }, []);

  return authState.isLoggedIn ? (
    <Redirect to={{ pathname: '/login' }} />
  ) : (
    <FullWidthBackground
      imageSource={fzColorfulBackgroundImage}
      position="absolute"
    >
      <LoginForm
        issuer={issuer}
        loginEnabled={loginEnabled}
        resetPasswordEnabled={resetPasswordEnabled}
        onLoginSuccess={handleLoginSuccess}
        onLoginFailure={handleLoginFailure}
      />
      {/*   <FZLegalNoticeFooter/> */}
    </FullWidthBackground>
  );
};

export default LoginScreen;
