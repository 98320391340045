import { Grid, Container } from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import React, { Component } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, compose } from 'redux';
import i18n from '../../../common/internationalization/i18n_config';
import {
  BlogPost,
  ContentResource,
  FeedContent,
} from '../../../common/models/FeedContent';
import { ApplicationState } from '../../../common/redux/main/types';
import {
  fetchPersonalizedFeedRequest,
  setCurrentlyViewedPost,
  setNumberOfUnseenFeedItems,
} from '../../../common/redux/feed/feed.actions';
import { MuiGridWidth } from '../../../types';
import colors from '../../00_Constants/colors';
import FZSpinner from '../../01_atoms/FZSpinner/FZSpinner';
import FZBlogPostPreview from '../../02_molecules/FZBlogpostPreview/FZBlogPostPreview';
import { withHooks, WithHooksProps } from '../../HOCs/withHooks';
import FZAppBar from '../../03_organisms/FZAppBar/FZAppBar';

/* import FeedPageTemplate from '../../04_templates/FeedPageTemplate'; */

type Props = DispatchProp<AnyAction> &
  RouteComponentProps<any> &
  WithHooksProps & {
    auth: any;
    history: any;
    isFetchingFeed: boolean;
    feedContent: FeedContent;
  };

type State = {
  feedContentLength: number;
};

//const styles = (theme: Theme) => createStyles({});

class NewsScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { feedContentLength: 0 };
    this.renderFeedContent = this.renderFeedContent.bind(this);
  }

  componentDidMount() {
    const { matomoAnalyticsTracker, dispatch, feedContent } = this.props;

    matomoAnalyticsTracker.trackPageView({
      documentTitle: 'News Screen', // optional
    });

    if (!feedContent) {
      dispatch(fetchPersonalizedFeedRequest());
      dispatch(setNumberOfUnseenFeedItems(0));
    }
  }

  renderFeedContent(feedContent: FeedContent) {
    const articleWidths = [12, 12, 12, 12];

    return (
      <Container style={{ padding: 0 }}>
        <Grid
          container
          spacing={2}
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            marginTop: 16,
            marginBottom: 84,
            //backgroundColor: colors.backgroundLight,
          }}
        >
          {feedContent.resources.results.map(
            (element: ContentResource, index: number) => {
              if (element.type === 'post') {
                const contentWidth =
                  articleWidths[index % articleWidths.length];

                const elementToReturn = (
                  <FZBlogPostPreview
                    key={element.id}
                    blogPostToRender={element.data}
                    columns={contentWidth as MuiGridWidth}
                    blogPostSlug={element.slugs[0]}
                    onReadMoreButtonClicked={(
                      blogPost: BlogPost,
                      slug: string,
                    ) => {
                      this.props.dispatch(setCurrentlyViewedPost(blogPost));
                      this.props.history.push(`/news/${slug}`);
                    }}
                  />
                );

                return elementToReturn;
              }

              return null;
            },
          )}
        </Grid>
      </Container>
    );
  }

  render() {
    const { isFetchingFeed, feedContent } = this.props;

    return (
      <React.Fragment>
        <FZAppBar activeTab={0}></FZAppBar>
        {isFetchingFeed && (
          <FZSpinner
            style={{ marginTop: '16px', marginBottom: '16px' }}
            color={colors.tertiary}
            statusMessage={i18n.t('newsScreen-isFetchingFeed-message')}
          ></FZSpinner>
        )}

        {feedContent && this.renderFeedContent(feedContent as FeedContent)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    isFetchingFeed: state.feed.isFetchingFeed,
    feedContent: state.feed.feedContent,
  };
};

export default compose(
  connect(mapStateToProps),
  withOktaAuth,
  withRouter,
  withHooks,
)(NewsScreen);
