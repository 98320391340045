import { Button, Card, CardActions, Grid, Typography } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../common/internationalization/i18n_config';
import { Article } from '../../../common/models/Article';
import { fetchPresignedImageUrlRequest } from '../../../common/redux/images/image.actions';
import { selectPresignedUrlForImage } from '../../../common/redux/images/image.selectors';
import { ApplicationState } from '../../../common/redux/main/types';
import colors from '../../00_Constants/colors';
import fonts from '../../00_Constants/fonts';

//const articlePreviewPlaceholderImage = require('../../../assets/images/articlePreviewPlaceholderImage.png');
const articlePreviewPlaceholderImage = require('../../../assets/images/FZ_colorful_background2.jpg');

const styles = (theme: Theme) =>
  createStyles({
    gridStyle: {
      [theme.breakpoints.up('xs')]: {
        minWidth: '83.333333333%',
      },

      [theme.breakpoints.up('sm')]: {
        minWidth: '50%',
      },

      [theme.breakpoints.up('md')]: {
        minWidth: '25%',
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: '12.5%',
      },
    },

    cardContainerStyle: {
      minHeight: 285,

      borderWidth: 1,
      borderColor: '#55555533',

      //opacity: 0.9,
      borderRadius: 5,
      marginTop: 10,
      //marginRight: 16,
      marginLeft: 0,
      backgroundSize: 'cover',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      backgroundBlendMode: 'overlay',
    },

    cardContentContainerStyle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'space-between',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0)',
      opacity: 1,
      height: 231,
      paddingTop: 29,
      paddingLeft: 22,
      paddingRight: 24,
    },

    // Title Flexbox Row
    titleContainerStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: 10,
      marginBottom: 12,
    },

    title: {
      flex: 1,
      fontFamily: fonts.FONT_FAMILY_BOLD,
      fontWeight: fonts.FONT_WEIGHT_BOLD,
      fontSize: fonts.FONT_SIZE_LARGE,
      color: colors.textImageoverlay,
      textTransform: 'uppercase',
      overflowX: 'hidden',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    // Content And meta Flexbox Row
    metaAndContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      maxHeight: 90,

      overflowY: 'hidden',
      flex: 1,
    },

    metaContainer: {
      borderBottomColor: colors.textImageoverlay,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },

    metaDate: {
      color: colors.textImageoverlay,
      alignSelf: 'flex-start',
      textAlign: 'left',
      overflowX: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: fonts.FONT_SIZE_SMALL,
    },

    metaAuthor: {
      color: colors.textImageoverlay,
      alignSelf: 'flex-end',
      textAlign: 'right',
      marginLeft: 10,
      overflowX: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: fonts.FONT_SIZE_SMALL,
    },

    contentContainer: {
      paddingRight: 20,
      maxHeight: 70,
      alignSelf: 'flex-start',
      color: colors.textImageoverlay,
      fontSize: fonts.FONT_SIZE_MEDIUM,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      overflowX: 'hidden',
      overflowY: 'hidden',
    },

    // Metadata labels & icons Flexbox Row
    metaDataIndicatorsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottomWidth: 1,
      borderBottomColor: colors.textImageoverlay,
      borderBottomStyle: 'solid',
      letterSpacing: 0.6,
      fontSize: fonts.FONT_SIZE_SMALL,
    },

    indicatorIcon: {
      color: 'gray',
    },

    locationFragment: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    locationLabel: {
      paddingLeft: 5,
      color: 'gray',
    },

    commentsFragment: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    commentsLabel: {
      paddingLeft: 5,
      color: 'gray',
    },

    cardActionContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },

    overlayButtonsTextStyle: {
      color: colors.textImageoverlay,
      fontFamily: fonts.FONT_FAMILY_REGULAR,
      fontSize: 14,
      letterSpacing: 1.25,

      '&:hover': {
        color: colors.active,
      },
    },

    '@keyframes blinker': {
      '0%': {
        backgroundPosition: '200%',
      },

      '100%': {
        backgroundPosition: '-200%',
      },
    },

    blinkingImageLoadingBackground: {
      animation: `$blinker 6s infinite linear`,
      backgroundColor: '#555555',
      backgroundImage: `linear-gradient(90deg, #555555 0%, rgba(255,255,255,0.25) 15%,#555555 20%)`,

      [theme.breakpoints.up('xs')]: {
        backgroundSize: '1600px',
      },

      [theme.breakpoints.up('sm')]: {
        backgroundSize: '900px',
      },

      [theme.breakpoints.up('md')]: {
        backgroundSize: '800px',
      },
    },
  });

type Props = WithTranslation & {
  dispatch?: any;
  classes?: any;
  id: string | number;
  selected: boolean;
  onClickItem: (event: any, articleUuid: string) => void;
  onLongPressItem: (articleThatWasLongPressed: Article) => void;
  articleToRender: Article;
  editable: boolean;
  onEditTriggered?: (articleThatShallBeEdited: Article) => void;
  onAnswerTriggered?: (articleThatShallBeAnswererd: Article) => void;
  answerable: boolean;
  ownerFamilyUuid: string | null;
  publishedInNewspaperWithUuid: string | null;
  presignedArticleImageDownloadUrl?: string | null;
  isVisible?: boolean;

  // HoC Procs
  t?: any;
};

type State = {
  backgroundImageLoaded: boolean;
  cardContainerClassNames: any;
  cardBackgroundImageStyle: any;
};

class FZArticleListItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { classes } = props;
    this.state = {
      backgroundImageLoaded: false,
      cardBackgroundImageStyle: {},
      cardContainerClassNames: `${classes.cardContainerStyle} ${classes.blinkingImageLoadingBackground}`,
    };
  }

  async componentDidMount() {
    const {
      articleToRender,
      ownerFamilyUuid,
      publishedInNewspaperWithUuid,
      dispatch,
      presignedArticleImageDownloadUrl,
      classes,
    } = this.props;

    // If there is an image attached to the article that shal be rendered, but there is no presigned download url yet, ask for one.
    if (
      articleToRender.photos &&
      articleToRender.photos[0] &&
      !presignedArticleImageDownloadUrl
    ) {
      if (ownerFamilyUuid && publishedInNewspaperWithUuid) {
        dispatch(
          fetchPresignedImageUrlRequest(
            articleToRender.photos[0].uuid,
            articleToRender.uuid,
            publishedInNewspaperWithUuid,
            ownerFamilyUuid,
          ),
        );
      } else {
        dispatch(
          fetchPresignedImageUrlRequest(
            articleToRender.photos[0].uuid,
            articleToRender.uuid,
            null,
            null,
          ),
        );
      }
    }

    // If there is no article image present, just display a default image.
    if (!articleToRender.photos || !articleToRender.photos[0]) {
      this.setState({
        cardContainerClassNames: `${classes.cardContainerStyle}`,
        cardBackgroundImageStyle: {
          backgroundBlendMode: 'multiply',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundImage: `radial-gradient(closest-side at 50% 40%, rgba(157, 157, 157, 0.5) 0%, #BEBEBE 25%, rgba(82, 81, 81, 0.5) 100%), url(${articlePreviewPlaceholderImage})`,
        },
      });
    }

    // Start Prefetching the image once a download url is availble. Update the card background as soon as the image is loaded.
    if (presignedArticleImageDownloadUrl) {
      // Preload the background image so that it doesnt slowly load in the background, but appears all at once.
      const preloadedImage = new Image();
      preloadedImage.src = presignedArticleImageDownloadUrl;
      preloadedImage.onload = () => {
        this.setState({
          backgroundImageLoaded: true,
        });
      };
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { presignedArticleImageDownloadUrl, classes } = this.props;

    if (this.state.backgroundImageLoaded !== prevState.backgroundImageLoaded) {
      this.setState({
        cardContainerClassNames: `${classes.cardContainerStyle}`,
        cardBackgroundImageStyle: {
          backgroundBlendMode: 'multiply',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundImage: `radial-gradient(closest-side at 50% 40%, rgba(157, 157, 157, 0.5) 0%, #BEBEBE 25%, rgba(82, 81, 81, 0.5) 100%), url(${presignedArticleImageDownloadUrl})`,
        },
      });
    }

    if (
      prevProps.presignedArticleImageDownloadUrl !==
      presignedArticleImageDownloadUrl
    ) {
      // Preload the background image so that it doesnt slowly load in the background, but appears all at once.
      const preloadedImage = new Image();
      preloadedImage.src = presignedArticleImageDownloadUrl;
      preloadedImage.onload = () => {
        this.setState({
          backgroundImageLoaded: true,
        });
      };
    }
  }

  handleItemClicked = (event: React.MouseEvent<HTMLElement>) => {
    this.props.onClickItem(event, this.props.articleToRender?.uuid!);
  };

  _onLongPress = () => {
    this.props.onLongPressItem(this.props.articleToRender);
  };

  _handleEditButtonPressed = () => {
    if (this.props.onEditTriggered) {
      this.props.onEditTriggered(this.props.articleToRender);
    }
  };

  _handleAsnweredButtonPressed = () => {
    if (this.props.onAnswerTriggered) {
      this.props.onAnswerTriggered(this.props.articleToRender);
    }
  };

  public render(): JSX.Element {
    const { articleToRender, classes, t } = this.props;
    const { cardBackgroundImageStyle, cardContainerClassNames } = this.state;

    return (
      <Grid
        item
        xs={10}
        sm={6}
        md={3}
        lg={2}
        style={{ paddingRight: 16 }}
        className={classes.gridStyle}
      >
        <Card
          className={cardContainerClassNames}
          onClick={this.handleItemClicked}
          style={cardBackgroundImageStyle}
        >
          <div className={classes.cardContentContainerStyle}>
            <div className={classes.titleContainerStyle}>
              <Typography className={classes.title}>
                {articleToRender.title ||
                  t('fzArticleListItem-title-placeholder')}
              </Typography>
            </div>

            <div className={classes.metaAndContentContainer}>
              <div className={classes.metaContainer}>
                <Typography className={classes.metaDate}>
                  {articleToRender.freelyAssignedDate ||
                    t('fzArticleListItem-freelyAssignedDate-placeholder')}
                </Typography>

                <Typography className={classes.metaAuthor}>
                  {articleToRender.freelyAssignedAuthor ||
                    t('fzArticleListItem-freelyAssignedAuthor-placeholder')}
                </Typography>
              </div>

              <Typography className={classes.contentContainer}>
                {articleToRender.description ||
                  t('fzArticleListItem-articleContent-placeholder')}
              </Typography>
            </div>
          </div>

          {(this.props.answerable || this.props.editable) && (
            <CardActions className={classes.cardActionContainer}>
              {this.props.editable && (
                <Button onClick={this._handleEditButtonPressed}>
                  <Typography className={classes.overlayButtonsTextStyle}>
                    {i18n.t('articleListItem-EditButton-Label')}
                  </Typography>
                </Button>
              )}

              {this.props.answerable && (
                <Button onClick={this._handleAsnweredButtonPressed}>
                  <Typography className={classes.overlayButtonsTextStyle}>
                    {i18n.t('articleListItem-AnswerButton-Label')}
                  </Typography>
                </Button>
              )}
            </CardActions>
          )}
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: Props) => {
  const articleImageUuid =
    ownProps.articleToRender?.photos && ownProps.articleToRender?.photos[0]
      ? ownProps.articleToRender.photos[0].uuid
      : null;

  return {
    presignedArticleImageDownloadUrl: selectPresignedUrlForImage(
      state,
      articleImageUuid,
    ),
  };
};

//const styledComponent = withStyles(styles)(FZArticleListItem);

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps)(FZArticleListItem)),
);
