import { makeStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import socialIllustration from '../../../../assets/images/social_illustration.png';
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../../common/redux/ui/ui.actions';
import { updateUserProfileRequest } from '../../../../common/redux/users/user.actions';
import { selectUserProfile } from '../../../../common/redux/users/user.selectors';
import FZEditSettingPageTemplate from '../../../04_templates/EditSettingPageTemplate/FZEditSettingPageTemplate';
import FZEditDisplayNameForm from '../editDisplayName/FZEditDisplayNameForm';
import { EditDisplayNameValues } from './FZEditDisplayNameForm';

/* const useStyles = makeStyles((theme: Theme) => {
  return {
    emailValue: {
      marginBottom: 50,
      marginTop: 22,
    },
  };
});
 */
interface Props {}

const useStyles = makeStyles({
  imageClassName: {
    paddingLeft: 60,
    paddingTop: 30,
    width: '84%',
    height: 'auto',
    //transform: 'scaleX(-1)',
    float: 'right',
  },
});

export default function EditDisplayNameScreen(props: Props): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();

  const defaultDisplayName: string = useSelector(selectUserProfile)
    ?.displayName;
  /*   const currentSettings: UserSettings = useSelector(selectUserSettings); */
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFinishEditingButtonClick = React.useCallback(
    (
      values: EditDisplayNameValues,
      helpers: FormikHelpers<EditDisplayNameValues>,
    ) => {
      dispatch(
        updateUserProfileRequest(
          {
            displayName: values.newDisplayName,
          },
          () => {
            helpers.setSubmitting(false);
            history.push('/profile');
            dispatch(
              showSuccessSnackbar(
                t('profileScreen-updating-display-name-successful-message'),
                true,
              ),
            );
          },
          () => {
            helpers.setSubmitting(false);
            dispatch(
              showErrorSnackbar(
                'profileScreen-updating-display-name-unsuccessful-message',
                true,
              ),
            );
          },
        ),
      );
    },
    [dispatch, history, t],
  );

  return (
    <FZEditSettingPageTemplate
      backgroundImageSource={socialIllustration}
      headline={t('editDisplayNameScreen-headline')}
      subheadline={t('editDisplayNameScreen-subheadline')}
      backDestinationLabel={t('editDisplayNameScreen-navigate-back-to-label')}
      imageClassName={classes.imageClassName}
    >
      <FZEditDisplayNameForm
        onSubmit={handleFinishEditingButtonClick}
        currentDisplayName={
          defaultDisplayName ||
          t('editDisplayNameScreen-no-displayName-given-yet-label')
        }
      ></FZEditDisplayNameForm>
    </FZEditSettingPageTemplate>
  );
}
