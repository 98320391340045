import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import fzColorfulBackgroundImage from '../../../../../assets/images/FZ_colorful_background.jpg';
import { resetPasswordWithRecoveryTokenRequest } from '../../../../../common/redux/login/login.actions';
import {
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
} from '../../../../../common/redux/ui/ui.actions';
import { FullWidthBackground } from '../../../../01_atoms/FullWidthBackground/FullWidthBackground';
import useConfig from '../../../../Hooks/useConfig';
import { useQueryString } from '../../../../Hooks/useQueryString';
import ResetPasswordForm, {
  ResetPasswordFormValues,
} from './ResetPasswordForm';

interface Props {}

const ResetPasswordScreen: React.FC<Props> = () => {
  const config = useConfig();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const resetPasswordEnabled: boolean =
    config.FEATURE_FLAGS.AUTH.REACT_APP_FEATURE_FLAG_RESET_PASSWORD_ENABLED;
  const queryStringParameters = useQueryString();
  const recoveryToken = queryStringParameters.get('recovery');

  const history = useHistory();
  if (!resetPasswordEnabled) {
    dispatch(
      showInfoSnackbar(
        t('initiateResetPasswordScreen-403-signup-currently-closed'),
        false,
      ),
    );
  }

  const handleSubmitButtonClick = React.useCallback(
    (values: ResetPasswordFormValues, helpers) => {
      dispatch(
        resetPasswordWithRecoveryTokenRequest(
          {
            newPassword: values.repeatedPassword,
            recoveryToken: recoveryToken,
          },
          () => {
            history.push('/login');
            dispatch(
              showSuccessSnackbar(
                t('resetPasswordScreen-password-reset-successful-message'),
                true,
              ),
            );
          },
          () => {
            dispatch(
              showErrorSnackbar(
                t('resetPasswordScreen-password-reset-unsuccessful-message'),
                true,
              ),
            );
          },
        ),
      );
    },
    [dispatch, history, t, recoveryToken],
  );

  return (
    <FullWidthBackground
      imageSource={fzColorfulBackgroundImage}
      position="absolute"
    >
      <ResetPasswordForm
        onSubmit={handleSubmitButtonClick}
        enabled={resetPasswordEnabled}
      />
    </FullWidthBackground>
  );
};

export default ResetPasswordScreen;
