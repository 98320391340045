import React, { ReactElement } from 'react';
import { Label, Tag, Text } from 'react-konva';

interface Props {
  text: string;
  x?: number;
  y?: number;
  offsetX?: number;
  offsetY?: number;
  fillColor?: string;
}

export default function FZCanvasTooltip({
  text,
  x,
  y,
  offsetX,
  offsetY,
  fillColor,
}: Props): ReactElement {
  return (
    <Label x={x} y={y} offsetX={offsetX} offsetY={offsetY}>
      <Tag fill={fillColor} cornerRadius={8}></Tag>
      <Text
        //stroke="#FFFFFF"
        fill="#FFFFFF"
        text={text}
        verticalAlign="middle"
        align="center"
        fontFamily={'OpenSansRegular'}
        fontSize={12}
        fontStyle="regular"
        padding={8}
        //width={240}
        wrap="none"
      ></Text>
    </Label>
  );
}
