import { darken, makeStyles, Switch as MuiSwitch } from '@material-ui/core';
import { fieldToSwitch, SwitchProps } from 'formik-material-ui';
import React, { ReactElement } from 'react';
import colors from '../../00_Constants/colors';

const useStyles = makeStyles({
  root: {},

  switchBase: {
    color: colors.white,
    '&$checked': {
      color: (props: Props) => darken(props.accentColor, 0.2),
    },
    '&$checked + $track': {
      backgroundColor: (props: Props) => darken(props.accentColor, 0.2),
    },
  },
  checked: {
    color: (props: Props) => darken(props.accentColor, 0.2),
  },
  track: {
    backgroundColor: colors.disabled,
    '&$checked': {
      backgroundColor: (props: Props) => darken(props.accentColor, 0.2),
    },
  },
});

type Props = SwitchProps & {
  accentColor: string;
};

export default function FZSwitch(props: Props): ReactElement {
  const { accentColor, ...otherProps } = props;
  const classes = useStyles(props);

  return (
    <MuiSwitch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        checked: classes.checked,
        track: classes.track,
      }}
      {...fieldToSwitch(otherProps)}
    />
  );
}
