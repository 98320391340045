import { makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import deliveryIllustration from '../../../../assets/images/delivery_illustration.png';
import FZEditSettingPageTemplate from '../../../04_templates/EditSettingPageTemplate/FZEditSettingPageTemplate';
import useConfig from '../../../Hooks/useConfig';
import EditAdressForm from './EditAdressForm';

const useStyles = makeStyles({
  imageClassName: {
    paddingLeft: 60,
    paddingTop: 60,
    width: '97%',
    height: 'auto',
    //transform: 'scaleX(-1)',
    float: 'right',
  },
});

interface Props {}

export default function EditAdressScreen(props: Props): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();

  const editAdressEnabled: boolean = useConfig().FEATURE_FLAGS.USER
    .REACT_APP_FEATURE_FLAG_EDIT_ADRESS_ENABLED;

  const history = useHistory();
  const handleFinishEditingButtonClick = React.useCallback(() => {
    history.push('/profile');
  }, [history]);

  return (
    <FZEditSettingPageTemplate
      backgroundImageSource={deliveryIllustration}
      headline={t('editAdressScreen-headline')}
      subheadline={t('editAdressScreen-subheadline')}
      backDestinationLabel={t('editAdressScreen-backDestination-label')}
      imageClassName={classes.imageClassName}
    >
      <EditAdressForm
        currentCity=""
        currentPostalCode=""
        currentStreetName=""
        onSubmit={handleFinishEditingButtonClick}
        formEnabled={editAdressEnabled}
      />
    </FZEditSettingPageTemplate>
  );
}
