// components/SuccessSnackbar.js or whatever you wanna call it
import { makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSnackbar } from '../../../common/redux/ui/ui.actions';

const useStyles = makeStyles({
  snackbarRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default function FZStatusFlash() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    successSnackbarMessage,
    successSnackbarOpen,
    successSnackbarShouldAutoClose,
    errorSnackbarMessage,
    errorSnackbarOpen,
    errorSnackbarShouldAutoClose,
    infoSnackbarMessage,
    infoSnackbarOpen,
    infoSnackbarShouldAutoClose,
  } = useSelector<any, any>(state => state.ui);

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{
          marginTop: 48,
          marginBottom: 64,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'absolute',
        }}
        classes={{ root: classes.snackbarRoot }}
        open={successSnackbarOpen}
        autoHideDuration={successSnackbarShouldAutoClose ? 4000 : undefined}
        onClose={handleClose}
        aria-describedby="client-snackbar"
      >
        <Alert onClose={handleClose} severity="success">
          {/*  <Icon>check_circle</Icon> */}
          {successSnackbarMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{
          marginTop: 48,
          marginBottom: 64,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'absolute',
        }}
        open={errorSnackbarOpen}
        autoHideDuration={errorSnackbarShouldAutoClose ? 4000 : undefined}
        onClose={handleClose}
        aria-describedby="client-snackbar"
      >
        <Alert onClose={handleClose} severity="error">
          {/*  <Icon>check_circle</Icon> */}
          {errorSnackbarMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{
          marginTop: 48,
          marginBottom: 64,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'absolute',
        }}
        open={infoSnackbarOpen}
        autoHideDuration={infoSnackbarShouldAutoClose ? 4000 : undefined}
        onClose={handleClose}
        aria-describedby="client-snackbar"
      >
        <Alert onClose={handleClose} severity="info">
          {/*  <Icon>check_circle</Icon> */}
          {infoSnackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
