import { TOO_MANY_REQUESTS, UNAUTHORIZED } from 'http-status-codes';
import { put, takeEvery } from 'redux-saga/effects';
import i18n from '../../internationalization/i18n_config';
import { showErrorSnackbar } from '../ui/ui.actions';

/**
 * This is trigged on any action, filters for SUCCESS or FAILURE at the end
 * of the action type (example: LOGIN_FAILURE) and dispatches an action to
 * display Status Snackbars with internationalized messages.
 * @param action
 */
function* handleGenericAction(action: any) {
  //const actionTypeEnding = action.type.split('_')[2];

  const actionTypeEnding = action.type.substr(
    action.type.lastIndexOf('_') + 1,
    action.type.length - 1,
  );

  switch (actionTypeEnding) {
    case 'SUCCESS': {
      //yield put(showSuccessSnackbar(i18n.t(''), true));
      return;
    }

    case 'FAILURE': {
      if (action.payload && action.payload.statusCode) {
        switch (action.payload.statusCode) {
          case TOO_MANY_REQUESTS:
            yield put(
              // This uses Keys defined in ./apiErrorTypes.enum.ts
              showErrorSnackbar(
                i18n.t(`API_ERROR_429_TOO_MANY_REQUESTS_MESSAGE`),
                false,
              ),
            );

            break;

          case UNAUTHORIZED:
            //yield put(logoutRequest());
            break;

          /* Default case is ommitted intentionally, since we want to catch only some generic responses by Http-Status, others with custom error codes that 
         allow a specific feedback for the user on what exactly went wrong. 
         default:
            return; */
        }
      }

      if (action.payload && action.payload.errorCode) {
        yield put(
          showErrorSnackbar(
            i18n.t(`API_ERROR_ERRORCODE_${action.payload.errorCode}_MESSAGE`),
            false,
          ),
        );
      } else {
        yield put(
          // This uses Keys defined in ./apiErrorTypes.enum.ts
          showErrorSnackbar(i18n.t(`API_ERROR_${action.type}_MESSAGE`), false),
        );
      }

      break;
    }

    default: {
      return;
    }
  }
}

function* watchAllToCatchApiErrors() {
  yield takeEvery('*', handleGenericAction);
}

// --

const apiSagas = {
  watchAllToCatchApiErrors,
};

export default apiSagas;
