import { Tooltip } from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    countdownContainer: {
      background: `${palette.secondary.main} 0% 0% no-repeat padding-box`,
      boxShadow: '0px 3px 6px #00000029',
      borderRadius: '0px 0px 4px 4px',
      opacity: 1,
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 8,
      paddingBottom: 8,
    },
    countdownColumn: {},

    countdownColumnElement: {
      textTransform: 'uppercase',
      color: 'white',
    },
  });

type Props = WithStyles<typeof styles> &
  WithTranslation & {
    date: string;
    countdownFinishedCallback: () => void;
    t?: any;
  };

type State = {
  countdownFinished: boolean;
  years: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

class FZCountdown extends Component<Props, State> {
  interval: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      years: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      countdownFinished: false,
    };

    this.calculateCountdown = this.calculateCountdown.bind(this);
    this.renderCountdownOverwidget = this.renderCountdownOverwidget.bind(this);
  }

  componentDidMount() {
    const dateToStartFrom = new Date(this.props.date);

    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(dateToStartFrom);

      date ? this.setState(date) : this.stop();
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate: Date) {
    const difference = +endDate - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
        countdownFinished: false,
      };
    } else {
      this.setState({ countdownFinished: true });
    }

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.countdownFinished !== this.state.countdownFinished) {
      this.props.countdownFinishedCallback();
    }
  }

  addLeadingZeros(value: string | number) {
    value = String(value);
    while (value.length < 2) {
      value = `0${value}`;
    }
    return value;
  }

  renderCountdown() {
    const { classes, t } = this.props;
    const countDown = this.state;
    return (
      <>
        <span className={classes.countdownColumn}>
          <span className={classes.countdownColumnElement}>
            <strong>{t('countdown-days-label')}</strong>
            <span>{this.addLeadingZeros(countDown.days)}</span>
          </span>
        </span>
        <span className={classes.countdownColumn}>
          <span className={classes.countdownColumnElement}>
            <strong>{t('countdown-hours-label')}</strong>
            <span>{this.addLeadingZeros(countDown.hours)}</span>
          </span>
        </span>
        <span className={classes.countdownColumn}>
          <span className={classes.countdownColumnElement}>
            <strong>{t('countdown-minutes-label')}</strong>
            <span>{this.addLeadingZeros(countDown.minutes)}</span>
          </span>
        </span>
        <span className={classes.countdownColumn}>
          <span className={classes.countdownColumnElement}>
            <strong>{t('countdown-seconds-label')}</strong>
            <span>{this.addLeadingZeros(countDown.seconds)}</span>
          </span>
        </span>
      </>
    );
  }

  renderCountdownOverwidget() {
    const { classes, t } = this.props;
    return (
      <span className={classes.countdownColumn}>
        <span className={classes.countdownColumnElement}>
          <strong>{t('countdown-over-label')}</strong>
        </span>
      </span>
    );
  }

  render() {
    const { classes, t } = this.props;
    const finished = this.state.countdownFinished;
    return (
      <Tooltip title={t('countdown-tooltip')} arrow>
        <div className={classes.countdownContainer}>
          {finished ? this.renderCountdownOverwidget() : this.renderCountdown()}
        </div>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(withTranslation()(FZCountdown));
