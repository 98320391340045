import { CREATED, FORBIDDEN, NO_CONTENT, OK } from 'http-status-codes';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { Family } from '../../models/Family';
import { User } from '../../models/User';
import FamilyService from '../../services/FamilyService';
import NewspaperService from '../../services/NewspaperService';
import ApiUtilities from '../../api/ApiUtilities';
import { setFirstLoadFinished } from '../app/app.actions';
import { selectFirstLoadFinished } from '../app/app.selectors';
import { selectAccessToken } from '../login/login.selectors';
import { selectLoggedInUser } from '../users/user.selectors';
import {
  addFamilyMemberInvitationFailure,
  AddFamilyMemberInvitationRequestAction,
  addFamilyMemberInvitationSuccess,
  createFamilyFailure,
  CreateFamilyRequestAction,
  createFamilySuccess,
  editFamilyFailure,
  EditFamilyRequestAction,
  editFamilySuccess,
  familyActionTypes,
  FetchFamilyAction,
  fetchFamilyFailure,
  fetchFamilyFulfill,
  fetchFamilySuccess,
  fetchLastPublishedNewspaperForFamilyFailure,
  fetchLastPublishedNewspaperForFamilySuccess,
  fetchUserContributionsInNewspaperOfFamilyFailure,
  fetchUserContributionsInNewspaperOfFamilyFulfill,
  fetchUserContributionsInNewspaperOfFamilySuccess,
  joinFamilyFailure,
  JoinFamilyRequestAction,
  joinFamilySuccess,
  leaveFamilyFailure,
  LeaveFamilyRequestAction,
  leaveFamilySuccess,
  removeFamilyMemberInvitationFailure,
  RemoveFamilyMemberInvitationRequestAction,
  removeFamilyMemberInvitationSuccess,
  RemoveFamilyMemberRequestAction,
  removeFamilyMemberSuccess,
} from './family.actions';
import {
  selectCurrentlyActiveFamily,
  selectDefaultActiveFamilyForUser,
} from './family.selectors';
import { CreateFamilyInvitationDto, FamilyInvitation } from './types';

//#region Fetch single Family

function* fetchFamilySaga(fetchFamilyAction: FetchFamilyAction) {
  // Get Service from redux-saga-context
  const familyService: FamilyService = yield getContext('familyService');
  const accessToken: string = yield select(selectAccessToken);

  try {
    const response = yield call(
      familyService.getFamily,
      fetchFamilyAction.payload.familyUuidToFetch,
      accessToken,
    );

    if (response?.status === OK) {
      yield put(fetchFamilySuccess(response.data));
    }
  } catch (error) {
    if (error.response?.status === FORBIDDEN) {
      //yield put(clearActiveFamily());
      yield put(fetchFamilyFailure(error));
    }
  } finally {
    yield put(fetchFamilyFulfill());
  }
}

function* watchFetchFamily() {
  yield takeLatest(familyActionTypes.FETCH_FAMILY_REQUEST, fetchFamilySaga);
}

//#endregion

//#region create Family

function* createFamilySaga(createFamilyAction: CreateFamilyRequestAction): any {
  // Get Service from redux-saga-context
  const familyService = yield getContext('familyService');
  const accessToken = yield select(selectAccessToken);

  try {
    const response = yield call(
      familyService.createFamily,
      createFamilyAction.payload,
      accessToken,
    );

    if (response?.status === CREATED) {
      yield call(createFamilyAction.meta.successCallback, response.data);
      yield put(createFamilySuccess(response.data));
    } else {
      yield call(createFamilyAction.meta.errorCallback);
      yield put(createFamilyFailure(response));
    }
  } catch (error) {
    yield call(createFamilyAction.meta.errorCallback);
    yield put(createFamilyFailure(error));
  }
}

function* watchCreateFamily() {
  yield takeLatest(familyActionTypes.CREATE_FAMILY_REQUEST, createFamilySaga);
}

//#endregion

//#region edit Family

function* editFamilySaga(editFamilyAction: EditFamilyRequestAction): any {
  // Get Service from redux-saga-context
  const familyService: FamilyService = yield getContext('familyService');
  const accessToken = yield select(selectAccessToken);

  try {
    const response = yield call(
      familyService.editFamily,
      editFamilyAction.payload,
      accessToken,
    );

    if (response?.status === OK) {
      yield call(editFamilyAction.meta.successCallback, response.data);
      yield put(editFamilySuccess(response.data));
    } else {
      yield call(editFamilyAction.meta.errorCallback);
      yield put(editFamilyFailure(response));
    }
  } catch (error) {
    yield call(editFamilyAction.meta.errorCallback);
    yield put(editFamilyFailure(error));
  }
}

function* watchEditFamily() {
  yield takeLatest(familyActionTypes.EDIT_FAMILY_REQUEST, editFamilySaga);
}

//#endregion

//#region Fetch Own Families

function* fetchOwnFamiliesSaga(action: any) {
  try {
    // Get Service from redux-saga-context
    const familyService = yield getContext('familyService');
    const loggedInUser: User = yield select(selectLoggedInUser);
    const accessToken: string = yield select(selectAccessToken);

    if (!loggedInUser) {
      yield put({
        type: familyActionTypes.FETCH_OWN_FAMILIES_FAILURE,
        payload: {
          error: { message: 'No User Logged in!' },
        },
      });
      return;
    }

    const response = yield call(
      familyService.getOwnFamilies,
      loggedInUser.customerUid,
      accessToken,
    );

    if (response && response.status === OK) {
      yield put({
        type: familyActionTypes.FETCH_OWN_FAMILIES_SUCCESS,
        payload: { ownFamilies: response.data },
      });

      const currentlyActiveFamily = yield select(selectCurrentlyActiveFamily);
      const firstLoadFinished: boolean = yield select(selectFirstLoadFinished);

      // Switch the active family according to user settings
      if (!currentlyActiveFamily && !firstLoadFinished) {
        /**
         * Select the family that the user has set as his default family
         */
        const defaultActiveFamily = yield select(
          selectDefaultActiveFamilyForUser,
        );

        yield put({
          type: familyActionTypes.SWITCH_ACTIVE_FAMILY,
          payload: {
            familyToSwitchTo: defaultActiveFamily,
          },
        });

        yield put(setFirstLoadFinished(true));
      }
    }
  } catch (error) {
    yield put({
      type: familyActionTypes.FETCH_OWN_FAMILIES_FAILURE,
      payload: {
        error,
      },
    });
  }
}

function* watchFetchOwnFamilies() {
  yield takeLatest(
    familyActionTypes.FETCH_OWN_FAMILIES_REQUEST,
    fetchOwnFamiliesSaga,
  );
}

//#endregion

//#region Fetch Newspapeprs & Newspaper Articles

/**
 * Saga that requests the last published newspaper of the currently active family (of the currently logged in user)
 */
function* fetchLastNewspaperForFamilySaga(action: any) {
  // Get Service from redux-saga-context
  const newspaperService: NewspaperService = yield getContext(
    'newspaperService',
  );
  const currentlyActiveFamily: Family = yield select(
    selectCurrentlyActiveFamily,
  );
  const accessToken = yield select(selectAccessToken);

  try {
    const response = yield call(
      newspaperService.fetchLastFamilyNewspaperOfFamily,
      currentlyActiveFamily.uuid,
      accessToken,
    );

    if (response && response.status === OK) {
      yield put(fetchLastPublishedNewspaperForFamilySuccess(response.data));
    }
  } catch (error) {
    yield put(fetchLastPublishedNewspaperForFamilyFailure(error));
  }
}

function* watchFetchLastNewspaperForFamily() {
  yield takeLatest(
    familyActionTypes.FETCH_LAST_NEWSPAPER_FOR_FAMILY_REQUEST,
    fetchLastNewspaperForFamilySaga,
  );
}

function* fetchUserContributionsInCurrentNewspaperOfFamily() {
  // Get Service from redux-saga-context
  const newspaperService = yield getContext('newspaperService');
  const currentlyActiveFamily: Family = yield select(
    selectCurrentlyActiveFamily,
  );
  const accessToken = yield select(selectAccessToken);

  try {
    const response = yield call(
      newspaperService.getUserContributionsInCurrentNewspaperOfFamily,
      currentlyActiveFamily?.uuid,
      accessToken,
    );

    if (response && response.status === OK) {
      yield put(
        fetchUserContributionsInNewspaperOfFamilySuccess(response.data),
      );
    }
  } catch (error) {
    yield put(fetchUserContributionsInNewspaperOfFamilyFailure(error));
  } finally {
    yield put(fetchUserContributionsInNewspaperOfFamilyFulfill());
  }
}

function* watchfetchUserContributionsInCurrentNewspaperOfFamily() {
  yield takeLatest(
    familyActionTypes.FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_REQUEST,
    fetchUserContributionsInCurrentNewspaperOfFamily,
  );
}

function* fetchNewspapersForActiveFamilySaga(action: any) {
  /* if (action.payload.familyToSwitchTo) {
    yield put(fetchLastPublishedNewspaperForFamilyRequest());
    yield put(fetchUserContributionsInNewspaperOfFamilyRequest());
    yield put(fetchOwnArticlesRequest());
  } */
}

//#endregion

//#region Invitations and Membership in Families

function* addFamilyInvitationSaga(
  action: AddFamilyMemberInvitationRequestAction,
) {
  const familyService: FamilyService = yield getContext('familyService');
  const accessToken = yield select(selectAccessToken);

  const requestBody: CreateFamilyInvitationDto = {
    receiverEmailAdresses: action.payload.invitationToAdd.receiverEmailAdresses,
    invitationSubject: action.payload.invitationToAdd.invitationSubject,
    personalMessage: action.payload.invitationToAdd.personalMessage,
    invitationType: action.payload.invitationToAdd.invitationType,
  };

  try {
    const response = yield call(
      familyService.createInviteToFamily,
      action.payload?.familyUuidForFamilyThatInviteIsFor,
      requestBody,
      accessToken,
    );

    if (response.status === CREATED) {
      yield put(
        addFamilyMemberInvitationSuccess(response.data as FamilyInvitation),
      );

      if (action.meta.successCallback) {
        yield call(action.meta.successCallback, response.data);
      }
    } else {
      yield put(addFamilyMemberInvitationFailure(response));
      if (action.meta.errorCallback) {
        yield call(action.meta.errorCallback);
      }
    }
  } catch (error) {
    yield put(addFamilyMemberInvitationFailure(error));
    if (action.meta.errorCallback) {
      yield call(action.meta.errorCallback);
    }
  }
}

function* watchAddFamilyInvitation() {
  yield takeLatest(
    familyActionTypes.ADD_FAMILY_MEMBER_INVITATION_REQUEST,
    addFamilyInvitationSaga,
  );
}

function* removeFamilyInvitationSaga(
  action: RemoveFamilyMemberInvitationRequestAction,
) {
  // Get Service from redux-saga-context
  const familyService = yield getContext('familyService');
  const accessToken = yield select(selectAccessToken);

  try {
    const response = yield call(
      familyService.deleteInviteToFamily,
      action.payload.familyUuidThatTheInviteIsFor,
      action.payload.invitationToRemove.uuid,
      accessToken,
    );
    if (response?.status === OK) {
      yield put(
        removeFamilyMemberInvitationSuccess(
          action.payload.familyUuidThatTheInviteIsFor,
          action.payload.invitationToRemove.uuid,
        ),
      );

      if (action.meta.successCallback) {
        yield call(action.meta.successCallback);
      }
    }
  } catch (error) {
    yield put(removeFamilyMemberInvitationFailure(error));
    if (action.meta.errorCallback) {
      yield call(action.meta.errorCallback);
    }
  }
}

function* watchRemoveFamilyInvitation() {
  yield takeLatest(
    familyActionTypes.REMOVE_FAMILY_MEMBER_INVITATION_REQUEST,
    removeFamilyInvitationSaga,
  );
}

function* joinFamilyWithInvitationCodeSaga(action: JoinFamilyRequestAction) {
  const familyService = yield getContext('familyService');
  const accessToken = yield select(selectAccessToken);

  try {
    const response = yield call(
      familyService.joinFamilyWithInviteCode,
      action.payload.inviteCode,
      action.payload.inviteCodeIsGeneralAccess,
      accessToken,
    );

    if (response?.status === CREATED) {
      yield call(ApiUtilities.callSuccessCallbackIfDefined, action);
      yield put(joinFamilySuccess(response.data));
    } else {
      yield call(ApiUtilities.callErrorCallbackIfDefined, action);
      yield put(joinFamilyFailure(null));
    }
  } catch (error) {
    const handledError = ApiUtilities.handleApiError(error);
    yield call(ApiUtilities.callErrorCallbackIfDefined, action);
    yield put(joinFamilyFailure(handledError));
  }
}

function* watchJoinFamilyWithInviteCode() {
  yield takeLatest(
    familyActionTypes.JOIN_FAMILY_REQUEST,
    joinFamilyWithInvitationCodeSaga,
  );
}

function* leaveFamilySaga(action: LeaveFamilyRequestAction) {
  // Get Service from redux-saga-context
  const familyService = yield getContext('familyService');
  const accessToken = yield select(selectAccessToken);

  let response;
  try {
    response = yield call(
      familyService.leaveFamily,
      action.payload.familyUuidOfFamilyToLeave,
      accessToken,
    );
    if (response?.status === OK || response?.status === NO_CONTENT) {
      yield call(action.meta.successCallback);
      yield put(leaveFamilySuccess(action.payload.familyUuidOfFamilyToLeave));
    }
  } catch (error) {
    const handledError = yield call(ApiUtilities.handleApiError, error);
    yield call(action.meta.errorCallback, handledError);
    yield put(leaveFamilyFailure(handledError));
  }
}

function* watchLeaveFamily() {
  yield takeLatest(familyActionTypes.LEAVE_FAMILY_REQUEST, leaveFamilySaga);
}

function* endUserMembershipInFamilySaga(
  action: RemoveFamilyMemberRequestAction,
) {
  // Get Service from redux-saga-context
  const familyService = yield getContext('familyService');
  const accessToken = yield select(selectAccessToken);

  try {
    const response = yield call(
      familyService.endOthersMembershipInFamily,
      action.payload.familyUuid,
      action.payload.customerUid,
      accessToken,
    );
    if (response?.status === OK) {
      yield call(action.meta.successCallback);
      yield put(
        removeFamilyMemberSuccess(
          action.payload.familyUuid,
          action.payload.customerUid,
        ),
      );
    } else {
    }
  } catch (error) {
    yield call(action.meta.errorCallback);
    yield put(leaveFamilyFailure(error));
  }
}

function* watchEndOthersMembershipInFamily() {
  yield takeLatest(
    familyActionTypes.REMOVE_FAMILY_MEMBER_REQUEST,
    endUserMembershipInFamilySaga,
  );
}

//#region Switch between families

function* watchSwitchActiveFamily() {
  yield takeLatest(
    familyActionTypes.SWITCH_ACTIVE_FAMILY,
    fetchNewspapersForActiveFamilySaga,
  );
}

//#endregion

const familySagas = {
  watchFetchFamily,
  watchFetchOwnFamilies,
  watchCreateFamily,
  watchEditFamily,
  watchAddFamilyInvitation,
  watchRemoveFamilyInvitation,
  watchFetchLastNewspaperForFamily,
  watchfetchUserContributionsInCurrentNewspaperOfFamily,
  watchSwitchActiveFamily,
  watchLeaveFamily,
  watchEndOthersMembershipInFamily,
  watchJoinFamilyWithInviteCode,
};

export default familySagas;
