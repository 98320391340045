/**
 * A component used to render consitent looking buttons
 * onto a React-Konva Canvas that comes with some additional functionality known
 * from material-ui, such as tooltips and the ability to disable them.
 */

import React, { ReactElement, useState } from 'react';
import { Circle, Group, Path } from 'react-konva';
import colors from '../../00_Constants/colors';
import FZCanvasTooltip from '../../02_molecules/FZCanvasTooltip/FZCanvasTooltip';

interface Props {
  color: string;
  onClick?: () => void;
  outlined?: boolean;
  outlineColor?: string;
  fillColor?: string;
  iconPath: string;
  disabled?: boolean;
  offsetX: number;
  offsetY: number;
  hasTooltip?: boolean;
  tooltipWhenButtonEnabledText?: string;
  tooltipWhenButtonDisabledText?: string;
  scaleX: number;
  scaleY: number;
  hoverable?: boolean;
}

export default function FZCanvasButton(props: Props): ReactElement {
  const {
    color,
    onClick,
    outlined,
    outlineColor,
    fillColor,
    iconPath,
    disabled,
    tooltipWhenButtonDisabledText,
    tooltipWhenButtonEnabledText,
    offsetX,
    offsetY,
    scaleX,
    scaleY,
    hasTooltip,
    hoverable,
  } = props;
  const [hovered, setHovered] = useState(false);

  return (
    <Group>
      <Group
        onClick={onClick}
        onMouseEnter={(e: any) => {
          if (disabled === false && hoverable) {
            // style stage container:
            const container = e.target.getStage().container();
            container.style.cursor = 'pointer';
            setHovered(true);
          }
        }}
        onMouseLeave={(e: any) => {
          if (disabled === false && hoverable) {
            const container = e.target.getStage().container();
            container.style.cursor = 'default';
            setHovered(false);
          }
        }}
        scaleX={scaleX}
        scaleY={scaleY}
      >
        {outlined && (
          <Circle
            fill={hoverable && hovered ? '#D5D5D5' : fillColor}
            offsetX={offsetX}
            offsetY={offsetY}
            radius={20}
            stroke={outlineColor}
            strokeEnabled={true}
          ></Circle>
        )}

        <Path
          data={iconPath}
          strokeEnabled={false}
          fill={
            hoverable && hovered
              ? disabled
                ? colors.disabled
                : colors.primary
              : color
          }
          offsetX={offsetX + 12}
          offsetY={offsetY + 12}
        ></Path>
      </Group>
      {hovered && hasTooltip && (
        <FZCanvasTooltip
          fillColor={'#707070'}
          //offsetX={60}
          offsetY={offsetY + 72}
          text={
            disabled
              ? tooltipWhenButtonDisabledText
              : tooltipWhenButtonEnabledText
          }
        ></FZCanvasTooltip>
      )}
    </Group>
  );
}
