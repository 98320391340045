import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MenuIcon from '@material-ui/icons/Menu';
import React, { ReactInstance } from 'react';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },

    drawerContainer: {
      width: '100%',
    },

    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },

    list: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },

    appBar: {
      [theme.breakpoints.up('sm')]: {
        //width: `calc(100% - ${drawerWidth}px)`,
        //marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },

    closeButton: {
      marginRight: theme.spacing(4),
      marginTop: theme.spacing(1),
      float: 'right',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,

    toolbarContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
      },
    },
    drawerPaper: {
      width: '100%',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    navItemContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      float: 'right',
    },

    drawerTopContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      maxHeight: 60,
      float: 'left',
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },

    navbarLeftContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      maxHeight: 60,
      float: 'left',
      /*  [theme.breakpoints.up('sm')]: {
       
      }, */
    },
  }),
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;

  drawerItems: JSX.Element[];
  navItems: JSX.Element[];
  navbarLeftContent: JSX.Element[];

  /**
   * A ref to the element that shall contain the drawer Modal
   */
  containerRef: ReactInstance;

  /**
   * Open state of the navigation drawer Controlled component
   */
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export default function FZDrawerAppBar(props: Props) {
  const { drawerItems, containerRef, open, onOpen, onClose } = props;
  const classes = useStyles();

  const drawer = (
    <div className={classes.drawerContainer}>
      <span className={classes.drawerTopContent}>
        {props.navbarLeftContent}
      </span>
      <IconButton
        color="inherit"
        aria-label="close drawer"
        edge="start"
        onClick={onClose}
        className={classes.closeButton}
      >
        <CloseRoundedIcon />
      </IconButton>
      <div className={classes.toolbar} />
      <List className={classes.list}>{drawerItems}</List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={2}>
        <Toolbar className={classes.toolbarContent}>
          <span className={classes.navbarLeftContent}>
            {props.navbarLeftContent}
          </span>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onOpen}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Hidden smDown implementation="css">
            <span className={classes.navItemContainer}>{props.navItems}</span>
          </Hidden>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            //container={container}
            variant="temporary"
            anchor={'top'}
            open={open}
            onClose={onClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
              container: containerRef,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}
