import { action } from 'typesafe-actions';
import { NavbarOptions } from '../../../components/03_organisms/FZAppBar/types';

//#region Display Snackbars Action Creators
export const showSuccessSnackbar = (
  message: string,
  shouldAutoClose: boolean,
) => {
  return action('SNACKBAR_SUCCESS', {
    message: message,
    shouldAutoClose: shouldAutoClose,
  });
};

export const showInfoSnackbar = (message: string, shouldAutoClose: boolean) => {
  return action('SNACKBAR_INFO', {
    message: message,
    shouldAutoClose: shouldAutoClose,
  });
};

export const showErrorSnackbar = (
  message: string,
  shouldAutoClose: boolean,
) => {
  return action('SNACKBAR_ERROR', {
    message: message,
    shouldAutoClose: shouldAutoClose,
  });
};

export const clearSnackbar = () => {
  return action('SNACKBAR_CLEAR');
};

//#endregion

//#region Set Navbar Options Action Creators
export const setShouldBlockNavigation = (shouldBlockNavigation: boolean) => {
  return action('SET_SHOULD_BLOCK_NAVIGATION', {
    shouldBlockNavigation: shouldBlockNavigation,
  });
};

export const setNavbarOptions = (navbarOptions: NavbarOptions) => {
  return action('SET_NAVBAR_OPTIONS', {
    navbarOptions: navbarOptions,
  });
};
//#endregion
