import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import constructionSiteIllustration from '../../../assets/images/construction_site.png'; // Tell webpack this JS file uses this image
import fzColorfulBackgroundImage from '../../../assets/images/FZ_colorful_background.jpg';

import FZStatusPageTemplate from '../../04_templates/StatusPageTemplate/FZStatusPage/FZStatusPageTemplate';
import { FullWidthBackground } from '../../01_atoms/FullWidthBackground/FullWidthBackground';

interface Props {}

const HelpScreen = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <FullWidthBackground
      imageSource={fzColorfulBackgroundImage}
      position="absolute"
    >
      <FZStatusPageTemplate
        imageSource={constructionSiteIllustration}
        body={
          <>
            {/*  <Typography variant="h6">{t('helpScreen-headline')}</Typography> */}
            <Typography variant="body1">{t('helpScreen-message')}</Typography>
          </>
        }
        actions={
          <Button
            onClick={() => {
              history.push('login');
            }}
          >
            {t('helpScreen-back-to-login-button-label')}
          </Button>
        }
      ></FZStatusPageTemplate>
    </FullWidthBackground>
  );
};

export default HelpScreen;
