import { Action, action, PayloadMetaAction } from 'typesafe-actions';
import { FZApiErrorResponse } from '../../api/types';
import { User } from '../../models/User';
import { ActionCallbacks } from '../main/types';
import {
  ActivateAccountDto,
  ForgotPasswordDto,
  LoginDto,
  RegisterAccountDto,
  UpdateCustomerPasswordWithRecoveryTokenDto,
  UpdatePasswordDto,
  UpdatePrimaryEmailWithVerificationTokenDto,
} from './types';

/**
 * Constants used as action types in ../_actions/user.actions.js
 */

export enum authenticationActionTypes {
  REGISTRATION_REQUEST = 'REGISTRATION_REQUEST',
  REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
  REGISTRATION_CANCELLED = 'REGISTRATION_CANCELLED',
  REGISTRATION_FAILURE = 'REGISTRATION_FAILURE',

  ACTIVATE_ACCOUNT_REQUEST = 'ACTIVATE_ACCOUNT_REQUEST',
  ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS',
  ACTIVATE_ACCOUNT_CANCELLED = 'ACTIVATE_ACCOUNT_CANCELLED',
  ACTIVATE_ACCOUNT_FAILURE = 'ACTIVATE_ACCOUNT_FAILURE',

  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_CANCELLED = 'LOGIN_CANCELLED',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  INITIATE_FORGOT_PASSWORD_REQUEST = 'INITIATE_FORGOT_PASSWORD_REQUEST',
  INITIATE_FORGOT_PASSWORD_SUCCESS = 'INITIATE_FORGOT_PASSWORD_SUCCESS',
  INITIATE_FORGOT_PASSWORD_CANCELLED = 'INITIATE_FORGOT_PASSWORD_CANCELLED',
  INITIATE_FORGOT_PASSWORD_FAILURE = 'INITIATE_FORGOT_PASSWORD_FAILURE',
  INITIATE_FORGOT_PASSWORD_FULFILL = 'INITIATE_FORGOT_PASSWORD_FULFILL',

  RESET_PASSWORD_WITH_RECOVERY_TOKEN_REQUEST = 'RESET_PASSWORD_WITH_RECOVERY_TOKEN_REQUEST',
  RESET_PASSWORD_WITH_RECOVERY_TOKEN_SUCCESS = 'RESET_PASSWORD_WITH_RECOVERY_TOKEN_SUCCESS',
  RESET_PASSWORD_WITH_RECOVERY_TOKEN_CANCELLED = 'RESET_PASSWORD_WITH_RECOVERY_TOKEN_CANCELLED',
  RESET_PASSWORD_WITH_RECOVERY_TOKEN_FAILURE = 'RESET_PASSWORD_WITH_RECOVERY_TOKEN_FAILURE',
  RESET_PASSWORD_WITH_RECOVERY_TOKEN_FULFILL = 'RESET_PASSWORD_WITH_RECOVERY_TOKEN_FULFILL',

  UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_CANCELLED = 'UPDATE_PASSWORD_CANCELLED',
  UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE',
  UPDATE_PASSWORD_FULFILL = 'UPDATE_PASSWORD_FULFILL',

  UPDATE_PRIMARY_EMAIL_WITH_VERIFICATION_TOKEN_REQUEST = 'UPDATE_PRIMARY_EMAIL_WITH_VERIFICATION_TOKEN_REQUEST',
  UPDATE_PRIMARY_EMAIL_WITH_VERIFICATION_TOKEN_SUCCESS = 'UPDATE_PRIMARY_EMAIL_WITH_VERIFICATION_TOKEN_SUCCESS',
  UPDATE_PRIMARY_EMAIL_WITH_VERIFICATION_TOKEN_FAILURE = 'UPDATE_PRIMARY_EMAIL_WITH_VERIFICATION_TOKEN_FAILURE',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',

  SET_FAMILY_INVITATION_TOKEN = 'SET_FAMILY_INVITATION_TOKEN',
}

//#region Login Action Creators
export const loginRequest = (
  loginDto: LoginDto,
  actionCallbacks: ActionCallbacks,
) => {
  return action(
    authenticationActionTypes.LOGIN_REQUEST,
    loginDto,
    actionCallbacks,
  );
};

export const loginError = (error: any) => {
  return action(authenticationActionTypes.LOGIN_FAILURE, error);
};

export const loginCancelled = () => {
  return action(authenticationActionTypes.LOGIN_CANCELLED);
};

export type SignInSuccessAction = Action & {
  type: authenticationActionTypes.LOGIN_SUCCESS;
  payload: {
    accessToken: string;
    idToken: string;
    loggedInUser: User;
    successCallback?: () => void;
  };
};

export const loginSuccess = (
  accessToken: string,
  tokenExpiry: number,
  idToken: string,
  loggedInUser: User,
  successCallback?: () => void,
): SignInSuccessAction => {
  return action(authenticationActionTypes.LOGIN_SUCCESS, {
    accessToken,
    tokenExpiry,
    idToken,
    loggedInUser,
    successCallback,
  });
};
//#endregion

//#region Registration Action Creators
/**
 * Registration Action Creators
 */

export type RegisterUserAccountRequestAction = PayloadMetaAction<
  authenticationActionTypes.REGISTRATION_REQUEST,
  RegisterAccountDto,
  ActionCallbacks
>;

export const registrationRequest = (
  registerAccountDto: RegisterAccountDto,
  actionCallbacks: ActionCallbacks,
): RegisterUserAccountRequestAction => {
  return action(
    authenticationActionTypes.REGISTRATION_REQUEST,
    {
      ...registerAccountDto,
    },
    {
      ...actionCallbacks,
    },
  );
};

export const registrationFailure = (error: FZApiErrorResponse) => {
  return action(authenticationActionTypes.REGISTRATION_FAILURE, error);
};

export const registrationCancelled = () => {
  return action(authenticationActionTypes.REGISTRATION_CANCELLED, {
    error: Error,
  });
};

export const registrationSuccess = () => {
  return action(authenticationActionTypes.REGISTRATION_SUCCESS, {});
};
//#endregion

//#region Account Activation action creators

export type AccountActivationRequestAction = PayloadMetaAction<
  authenticationActionTypes.ACTIVATE_ACCOUNT_REQUEST,
  ActivateAccountDto,
  ActionCallbacks
>;

export const accountActivationRequest = (
  activateAccountDto: ActivateAccountDto,
  actionCallbacks: ActionCallbacks,
): AccountActivationRequestAction => {
  return action(
    authenticationActionTypes.ACTIVATE_ACCOUNT_REQUEST,
    {
      ...activateAccountDto,
    },
    {
      ...actionCallbacks,
    },
  );
};

export const accountActivationError = (error: Error) => {
  return action(authenticationActionTypes.ACTIVATE_ACCOUNT_FAILURE, {
    error: Error,
  });
};

export const accountActivationCancelled = () => {
  return action(authenticationActionTypes.ACTIVATE_ACCOUNT_CANCELLED, {
    error: Error,
  });
};

export const accountActivationSuccess = () => {
  return action(authenticationActionTypes.ACTIVATE_ACCOUNT_SUCCESS, {});
};

//#endregion

//#region Update Password Action Creators

export type UpdatePasswordAction = PayloadMetaAction<
  authenticationActionTypes.UPDATE_PASSWORD_REQUEST,
  UpdatePasswordDto,
  ActionCallbacks
>;
export const updatePasswordRequest = (
  updatePasswordDto: UpdatePasswordDto,
  actionCallbacks: ActionCallbacks,
): UpdatePasswordAction => {
  return action(
    authenticationActionTypes.UPDATE_PASSWORD_REQUEST,
    { ...updatePasswordDto },
    { ...actionCallbacks },
  );
};

export const updatePasswordError = (error: any) => {
  return action(authenticationActionTypes.UPDATE_PASSWORD_FAILURE, error);
};

export const updatePasswordCancelled = () => {
  return action(authenticationActionTypes.UPDATE_PASSWORD_CANCELLED, {
    error: Error,
  });
};

export const updatePasswordSuccess = (successCallback?: () => void) => {
  return action(authenticationActionTypes.UPDATE_PASSWORD_SUCCESS, {
    successCallback,
  });
};

export const updatePasswordFulfill = () => {
  return action(authenticationActionTypes.UPDATE_PASSWORD_FULFILL);
};

//#endregion

//#region Update primary email with verification token Action Creators Creators

export type UpdatePrimaryEmailRequestAction = PayloadMetaAction<
  authenticationActionTypes.UPDATE_PRIMARY_EMAIL_WITH_VERIFICATION_TOKEN_REQUEST,
  UpdatePrimaryEmailWithVerificationTokenDto,
  ActionCallbacks
>;
export const updatePrimaryEmailRequest = (
  updatePrimaryEmailDto: UpdatePrimaryEmailWithVerificationTokenDto,
  actionCallbacks: ActionCallbacks,
): UpdatePrimaryEmailRequestAction => {
  return action(
    authenticationActionTypes.UPDATE_PRIMARY_EMAIL_WITH_VERIFICATION_TOKEN_REQUEST,
    { ...updatePrimaryEmailDto },
    { ...actionCallbacks },
  );
};

export const updatePrimaryEmailSuccess = () => {
  return action(
    authenticationActionTypes.UPDATE_PRIMARY_EMAIL_WITH_VERIFICATION_TOKEN_SUCCESS,
  );
};

export const updatePrimaryEmailError = (error: Error) => {
  return action(
    authenticationActionTypes.UPDATE_PRIMARY_EMAIL_WITH_VERIFICATION_TOKEN_FAILURE,
    {
      error: Error,
    },
  );
};

//#endregion

//#region Initiate Forgot Password Flow Action Creators

export type InitiateForgotPasswordAction = Action & {
  type: authenticationActionTypes.INITIATE_FORGOT_PASSWORD_REQUEST;
  payload: {
    requestBody: ForgotPasswordDto;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
};

export const initiateForgotPasswordRequest = (
  requestBody: ForgotPasswordDto,
  successCallback?: () => void,
  errorCallback?: () => void,
): InitiateForgotPasswordAction => {
  return action(authenticationActionTypes.INITIATE_FORGOT_PASSWORD_REQUEST, {
    requestBody: requestBody,
    successCallback: successCallback,
    errorCallback: errorCallback,
  });
};

export const initiateForgotPasswordError = (error: Error) => {
  return action(authenticationActionTypes.INITIATE_FORGOT_PASSWORD_FAILURE, {
    error: Error,
  });
};

export const initiateForgotPasswordCancelled = () => {
  return action(authenticationActionTypes.INITIATE_FORGOT_PASSWORD_CANCELLED, {
    error: Error,
  });
};

export const initiateForgotPasswordSuccess = (successCallback?: () => void) => {
  return action(authenticationActionTypes.INITIATE_FORGOT_PASSWORD_SUCCESS, {
    successCallback,
  });
};

export const initiateForgotPasswordFulfill = () => {
  return action(authenticationActionTypes.INITIATE_FORGOT_PASSWORD_FULFILL);
};

//#region Reset Passwort with recoveryToken Action Creators

export type ResetPasswordWithRecoveryTokenAction = Action & {
  type: authenticationActionTypes.RESET_PASSWORD_WITH_RECOVERY_TOKEN_REQUEST;
  payload: {
    requestBody: UpdateCustomerPasswordWithRecoveryTokenDto;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
};

export const resetPasswordWithRecoveryTokenRequest = (
  requestBody: UpdateCustomerPasswordWithRecoveryTokenDto,
  successCallback?: () => void,
  errorCallback?: () => void,
): ResetPasswordWithRecoveryTokenAction => {
  return action(
    authenticationActionTypes.RESET_PASSWORD_WITH_RECOVERY_TOKEN_REQUEST,
    {
      requestBody: requestBody,
      successCallback: successCallback,
      errorCallback: errorCallback,
    },
  );
};

export const resetPasswordWithRecoveryTokenError = (error: Error) => {
  return action(
    authenticationActionTypes.RESET_PASSWORD_WITH_RECOVERY_TOKEN_FAILURE,
    {
      error: Error,
    },
  );
};

export const resetPasswordWithRecoveryTokenCancelled = () => {
  return action(
    authenticationActionTypes.RESET_PASSWORD_WITH_RECOVERY_TOKEN_CANCELLED,
    {
      error: Error,
    },
  );
};

export const resetPasswordWithRecoveryTokenSuccess = (
  successCallback?: () => void,
) => {
  return action(
    authenticationActionTypes.RESET_PASSWORD_WITH_RECOVERY_TOKEN_SUCCESS,
    {
      successCallback,
    },
  );
};

export const resetPasswordWithRecoveryTokenFulfill = () => {
  return action(
    authenticationActionTypes.RESET_PASSWORD_WITH_RECOVERY_TOKEN_FULFILL,
  );
};

//#endregion

//#region

// Set invitation Token Action Creator

export const setInvitationToken = (
  familyInvitationToken: string,
  invitationTokenIsPersonalized: boolean,
  invitationTokenIsForReceiverEmail?: string | null,
) => {
  return action(authenticationActionTypes.SET_FAMILY_INVITATION_TOKEN, {
    invitationToken: familyInvitationToken,
    invitationTokenIsPersonalized: invitationTokenIsPersonalized,
    invitationTokenIsForReceiverEmail: invitationTokenIsForReceiverEmail,
  });
};

//#endregion

//#region Logout Action Creators
/**
 * Logout Action Creators
 */
export const logoutRequest = (callbacks?: ActionCallbacks) => {
  return action(authenticationActionTypes.LOGOUT_REQUEST, {}, callbacks);
};
export const logoutSuccess = () => {
  return action(authenticationActionTypes.LOGOUT_SUCCESS);
};
export const logoutFailure = (error: any) => {
  return action(authenticationActionTypes.LOGOUT_FAILURE, error);
};
//#endregion
