import {
  action,
  Action,
  PayloadAction,
  PayloadMetaAction,
} from 'typesafe-actions';
import { FZApiErrorResponse } from '../../api/types';
import { Family } from '../../models/Family';
import { Newspaper } from '../../models/Newspaper';
import { ActionCallbacks } from '../main/types';
import {
  CreateFamilyDto,
  EditFamilyDto,
  CreateFamilyInvitationDto,
  FamilyInvitation,
  FamilyInvitationType,
} from './types';

export enum familyActionTypes {
  /**
   * Action types for the request to fetch all families that a user is part of.
   */
  FETCH_OWN_FAMILIES_REQUEST = 'FETCH_OWN_FAMILIES_REQUEST',
  FETCH_OWN_FAMILIES_SUCCESS = 'FETCH_OWN_FAMILIES_SUCCESS',
  FETCH_OWN_FAMILIES_FAILURE = 'FETCH_OWN_FAMILIES_FAILURE',
  FETCH_OWN_FAMILIES_FULFILL = 'FETCH_OWN_FAMILIES_FULFILL',

  /**
   * Action types to fetch a single family.
   */
  FETCH_FAMILY_REQUEST = 'FETCH_FAMILY_REQUEST',
  FETCH_FAMILY_SUCCESS = 'FETCH_FAMILY_SUCCESS',
  FETCH_FAMILY_FAILURE = 'FETCH_FAMILY_FAILURE',
  FETCH_FAMILY_FULFILL = 'FETCH_FAMILY_FULFILL',

  /**
   * Action types to fetch all articles that a current user is a member of.
   */
  FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_REQUEST = 'FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_REQUEST',
  FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_SUCCESS = 'FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_SUCCESS',
  FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_FAILURE = 'FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_FAILURE',
  FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_FULFILL = 'FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_FULFILL',

  /**
   * Action types to fetch the newspaper that was last published ("the previous edition") in a family.
   */
  FETCH_LAST_NEWSPAPER_FOR_FAMILY_REQUEST = 'FETCH_LAST_NEWSPAPER_FOR_FAMILY_REQUEST',
  FETCH_LAST_NEWSPAPER_FOR_FAMILY_SUCCESS = 'FETCH_LAST_NEWSPAPER_FOR_FAMILY_SUCCESS',
  FETCH_LAST_NEWSPAPER_FOR_FAMILY_FAILURE = 'FETCH_LAST_NEWSPAPER_FOR_FAMILY_FAILURE',
  FETCH_LAST_NEWSPAPER_FOR_FAMILY_FULFILL = 'FETCH_LAST_NEWSPAPER_FOR_FAMILY_FULFILL',

  /**
   * Action type to switch the family that a user is currently working in.
   */
  SWITCH_ACTIVE_FAMILY = 'SWITCH_ACTIVE_FAMILY',

  /**
   * Action types to create a new family.
   */
  CREATE_FAMILY_REQUEST = 'CREATE_FAMILY_REQUEST',
  CREATE_FAMILY_SUCCESS = 'CREATE_FAMILY_SUCCESS',
  CREATE_FAMILY_FAILURE = 'CREATE_FAMILY_FAILURE',

  /**
   * Action types to edit a family.
   */
  EDIT_FAMILY_REQUEST = 'EDIT_FAMILY_REQUEST',
  EDIT_FAMILY_SUCCESS = 'EDIT_FAMILY_SUCCESS',
  EDIT_FAMILY_FAILURE = 'EDIT_FAMILY_FAILURE',

  /**
   * Action types to join a family using an invite code
   */
  JOIN_FAMILY_REQUEST = 'JOIN_FAMILY_REQUEST',
  JOIN_FAMILY_SUCCESS = 'JOIN_FAMILY_SUCCESS',
  JOIN_FAMILY_FAILURE = 'JOIN_FAMILY_FAILURE',

  /**
   * Action types to leave a family that a user is part in.
   */
  LEAVE_FAMILY_REQUEST = 'LEAVE_FAMILY_REQUEST',
  LEAVE_FAMILY_SUCCESS = 'LEAVE_FAMILY_SUCCESS',
  LEAVE_FAMILY_FAILURE = 'LEAVE_FAMILY_FAILURE',

  /**
   * Action types to remove another  family member (other than the current user) from a family.
   */
  REMOVE_FAMILY_MEMBER_REQUEST = 'REMOVE_FAMILY_MEMBER_REQUEST',
  REMOVE_FAMILY_MEMBER_SUCCESS = 'REMOVE_FAMILY_MEMBER_SUCCESS',
  REMOVE_FAMILY_MEMBER_FAILURE = 'REMOVE_FAMILY_MEMBER_FAILURE',

  /**
   * Action types to clear access to a family (can be used when the user lost access to a family )
   */
  CLEAR_ACTIVE_FAMILY = 'CLEAR_ACTIVE_FAMILY',

  /**
   * Action types for family-specific UI actions that are managed in redux.
   */
  SET_FAMILY_HAS_REACHED_END_OF_RELEASE_CYCLE = 'SET_FAMILY_HAS_REACHED_END_OF_RELEASE_CYCLE',
  SET_END_OF_RELEASE_REMINDER_HAS_FLASHED = 'SET_END_OF_RELEASE_REMINDER_HAS_FLASHED',

  /**
   * Action types used to add information about a family when it is created.
   *
   */
  SET_FAMILY_NAME = 'SET_FAMILY_NAME',

  /**
   * Action types to add or remove family-member invitations directed towards individual family members
   * -> intdended to be used as invitations after the initial creation of a family.
   */
  ADD_FAMILY_MEMBER_INVITATION_REQUEST = 'ADD_FAMILY_MEMBER_INVITATION_REQUEST',
  ADD_FAMILY_MEMBER_INVITATION_SUCCESS = 'ADD_FAMILY_MEMBER_INVITATION_SUCCESS',
  ADD_FAMILY_MEMBER_INVITATION_FAILURE = 'ADD_FAMILY_MEMBER_INVITATION_FAILURE',

  REMOVE_FAMILY_MEMBER_INVITATION_REQUEST = 'REMOVE_FAMILY_MEMBER_INVITATION_REQUEST',
  REMOVE_FAMILY_MEMBER_INVITATION_SUCCESS = 'REMOVE_FAMILY_MEMBER_INVITATION_SUCCESS',
  REMOVE_FAMILY_MEMBER_INVITATION_FAILURE = 'REMOVE_FAMILY_MEMBER_INVITATION_FAILURE',

  /**
   * Action types to add or remove receiver adresses to "broadcast" invites that are sent
   * to multiple adresses with the same message.
   */

  SET_MEANS_OF_FAMILY_MEMBER_INVITATION = 'SET_MEANS_OF_FAMILY_MEMBER_INVITATION',
  ADD_RECEIVER_TO_MULTI_RECEIVER_INVITATION = 'ADD_RECEIVER_TO_MULTI_RECEIVER_INVITATION',
  REMOVE_RECEIVER_FROM_MULTI_RECEIVER_INVITATION = 'REMOVE_RECEIVER_FROM_MULTI_RECEIVER_INVITATION',
  SET_MULTI_RECEIVER_INVITATION_SUBJECT = 'SET_MULTI_RECEIVER_INVITATION_SUBJECT',
  SET_MULTI_RECEIVER_INVITATION_MESSAGE = 'SET_MULTI_RECEIVER_INVITATION_MESSAGE',
}

//#region Fetch single Family Action Creators
// Typed actions to create a new family.
export type FetchFamilyAction = Action & {
  payload: {
    familyUuidToFetch: string;
  };
};

// Typed actions for a GET Request onto a family
export const fetchFamilyRequest = (familyUuid: string): FetchFamilyAction => {
  return action(familyActionTypes.FETCH_FAMILY_REQUEST, {
    familyUuidToFetch: familyUuid,
  });
};

export const fetchFamilySuccess = (family: Family) => {
  return action(familyActionTypes.FETCH_FAMILY_SUCCESS, {
    fetchedFamily: family,
  });
};

export const fetchFamilyFailure = (error: Error) => {
  return action(familyActionTypes.FETCH_FAMILY_FAILURE, error);
};

export const fetchFamilyFulfill = () => {
  return action(familyActionTypes.FETCH_FAMILY_FULFILL);
};

//#endregion

//#region Fetch families for User Action Creators
export const fetchOwnFamiliesRequest = () => {
  return action(familyActionTypes.FETCH_OWN_FAMILIES_REQUEST);
};
export const fetchOwnFamiliesSucceess = (ownFamilies: Family[]) => {
  return action(familyActionTypes.FETCH_OWN_FAMILIES_SUCCESS, ownFamilies);
};

//#endregion

//#region Fetch Last Published Newspaper for Family Action Creators
export const fetchLastPublishedNewspaperForFamilyRequest = () => {
  return action(familyActionTypes.FETCH_LAST_NEWSPAPER_FOR_FAMILY_REQUEST);
};
export const fetchLastPublishedNewspaperForFamilySuccess = (
  fetchedNewspaper: Newspaper,
) => {
  return action(familyActionTypes.FETCH_LAST_NEWSPAPER_FOR_FAMILY_SUCCESS, {
    lastPublishedNewspaper: fetchedNewspaper,
  });
};
export const fetchLastPublishedNewspaperForFamilyFailure = (error: Error) => {
  return action(
    familyActionTypes.FETCH_LAST_NEWSPAPER_FOR_FAMILY_FAILURE,
    error,
  );
};
//#endregion

//#region Switch Active Family Action Creators
export const switchActiveFamily = (familyToSwitchTo: Family) => {
  return action(familyActionTypes.SWITCH_ACTIVE_FAMILY, {
    familyToSwitchTo: familyToSwitchTo,
  });
};
//#endregion

//#region Create new Family Action Creators

export type SetFamilyNameAction = PayloadAction<
  familyActionTypes.SET_FAMILY_NAME,
  { familyNameToSet: string }
>;

export const setFamilyName = (familyNameToSet: string): SetFamilyNameAction => {
  return action(familyActionTypes.SET_FAMILY_NAME, {
    familyNameToSet: familyNameToSet,
  });
};

export type CreateFamilyRequestAction = PayloadMetaAction<
  familyActionTypes.CREATE_FAMILY_REQUEST,
  CreateFamilyDto,
  ActionCallbacks
>;

export const createFamilyRequest = (
  createFamilyDto: CreateFamilyDto,
  actionCallbacks: ActionCallbacks,
): CreateFamilyRequestAction => {
  return action(
    familyActionTypes.CREATE_FAMILY_REQUEST,
    {
      ...createFamilyDto,
    },
    {
      ...actionCallbacks,
    },
  );
};

export type CreateFamilySuccessAction = Action & {
  payload: {
    createdFamily: Family;
  };
};

export const createFamilySuccess = (createdFamily: Family) => {
  return action(familyActionTypes.CREATE_FAMILY_SUCCESS, {
    createdFamily,
  });
};
export const createFamilyFailure = (error: Error) => {
  return action(familyActionTypes.CREATE_FAMILY_FAILURE, error);
};

//#endregion

//#region Edit Family Action Creators

export type EditFamilyRequestAction = PayloadMetaAction<
  familyActionTypes.EDIT_FAMILY_REQUEST,
  EditFamilyDto,
  ActionCallbacks
>;

export const editFamilyRequest = (
  editFamilyDto: EditFamilyDto,
  actionCallbacks: ActionCallbacks,
): EditFamilyRequestAction => {
  return action(
    familyActionTypes.EDIT_FAMILY_REQUEST,
    {
      ...editFamilyDto,
    },
    {
      ...actionCallbacks,
    },
  );
};

export type EditFamilySuccessAction = Action & {
  payload: {
    editedFamily: Family;
  };
};

export const editFamilySuccess = (editedFamily: Family) => {
  return action(familyActionTypes.EDIT_FAMILY_SUCCESS, {
    editedFamily,
  });
};
export const editFamilyFailure = (error: Error) => {
  return action(familyActionTypes.EDIT_FAMILY_FAILURE, error);
};

//#endregion

//#region Add or Remove Family Member Invitations
export type AddFamilyMemberInvitationRequestAction = PayloadMetaAction<
  familyActionTypes.ADD_FAMILY_MEMBER_INVITATION_REQUEST,
  {
    familyUuidForFamilyThatInviteIsFor: string;
    invitationToAdd: CreateFamilyInvitationDto;
  },
  ActionCallbacks
>;

export const addFamilyMemberInvitationRequest = (
  familyUuidForFamilyThatInviteIsFor: string,
  invitationToAdd: CreateFamilyInvitationDto,
  actionCallbacks: ActionCallbacks,
): AddFamilyMemberInvitationRequestAction => {
  return action(
    familyActionTypes.ADD_FAMILY_MEMBER_INVITATION_REQUEST,
    {
      familyUuidForFamilyThatInviteIsFor: familyUuidForFamilyThatInviteIsFor,
      invitationToAdd: invitationToAdd,
    },
    { ...actionCallbacks },
  );
};

export type AddFamilyMemberInvitationSuccessAction = PayloadAction<
  familyActionTypes.ADD_FAMILY_MEMBER_INVITATION_SUCCESS,
  { createdInvitation: FamilyInvitation }
>;

export const addFamilyMemberInvitationSuccess = (
  createdInvitation: FamilyInvitation,
): AddFamilyMemberInvitationSuccessAction => {
  const addFamilyMemberInvitationSuccessAction = action(
    familyActionTypes.ADD_FAMILY_MEMBER_INVITATION_SUCCESS,
    {
      createdInvitation: createdInvitation,
    },
  );

  return addFamilyMemberInvitationSuccessAction;
};

export const addFamilyMemberInvitationFailure = (error: any) => {
  return action(familyActionTypes.ADD_FAMILY_MEMBER_INVITATION_FAILURE, error);
};

export type RemoveFamilyMemberInvitationRequestAction = PayloadMetaAction<
  familyActionTypes.REMOVE_FAMILY_MEMBER_INVITATION_REQUEST,
  {
    familyUuidThatTheInviteIsFor: string;
    invitationToRemove: FamilyInvitation;
  },
  ActionCallbacks
>;

export const removeFamilyMemberInvitationRequest = (
  familyUuidThatTheInviteIsFor: string,
  invitationToRemove: FamilyInvitation,
  actionCallbacks: ActionCallbacks,
): RemoveFamilyMemberInvitationRequestAction => {
  return action(
    familyActionTypes.REMOVE_FAMILY_MEMBER_INVITATION_REQUEST,
    {
      familyUuidThatTheInviteIsFor: familyUuidThatTheInviteIsFor,
      invitationToRemove: invitationToRemove,
    },
    { ...actionCallbacks },
  );
};

export type RemoveFamilyMemberInvitationSuccessAction = PayloadAction<
  familyActionTypes.REMOVE_FAMILY_MEMBER_INVITATION_SUCCESS,
  {
    familyThatTheDeletedInvitationBelongedToUuid: string;
    deletedInvitationUuid: string;
  }
>;

export const removeFamilyMemberInvitationSuccess = (
  familyThatTheDeletedInvitationBelongedToUuid: string,
  deletedInvitationUuid: string,
): RemoveFamilyMemberInvitationSuccessAction => {
  return action(familyActionTypes.REMOVE_FAMILY_MEMBER_INVITATION_SUCCESS, {
    familyThatTheDeletedInvitationBelongedToUuid,
    deletedInvitationUuid,
  });
};

export const removeFamilyMemberInvitationFailure = (error: Error) => {
  return action(
    familyActionTypes.REMOVE_FAMILY_MEMBER_INVITATION_FAILURE,
    error,
  );
};

export type AddReceiverToMultiReceiverInvitationAction = PayloadAction<
  familyActionTypes.ADD_RECEIVER_TO_MULTI_RECEIVER_INVITATION,
  { receiverToAdd: string }
>;

export const addReceiverToMultiReceiverInvitation = (
  receiverToAdd: string,
): AddReceiverToMultiReceiverInvitationAction => {
  return action(familyActionTypes.ADD_RECEIVER_TO_MULTI_RECEIVER_INVITATION, {
    receiverToAdd: receiverToAdd,
  });
};

export type RemoveReceiverFromMultiReceiverInvitationAction = PayloadAction<
  familyActionTypes.REMOVE_RECEIVER_FROM_MULTI_RECEIVER_INVITATION,
  { receiverToRemove: string }
>;

export const removeReceiverFromMultiReceiverInvitation = (
  receiverToRemove: string,
): RemoveReceiverFromMultiReceiverInvitationAction => {
  return action(
    familyActionTypes.REMOVE_RECEIVER_FROM_MULTI_RECEIVER_INVITATION,
    {
      receiverToRemove: receiverToRemove,
    },
  );
};

export const clearActiveFamily = () => {
  return action(familyActionTypes.CLEAR_ACTIVE_FAMILY, {});
};

export type SetMeansOfFamilyMemberInvitationAction = PayloadAction<
  familyActionTypes.SET_MEANS_OF_FAMILY_MEMBER_INVITATION,
  {
    meansOfInvitation: FamilyInvitationType;
  }
>;

export const setMeansOfFamilyMemberInvitation = (
  meansOfInvitation: FamilyInvitationType,
): SetMeansOfFamilyMemberInvitationAction => {
  return action(familyActionTypes.SET_MEANS_OF_FAMILY_MEMBER_INVITATION, {
    meansOfInvitation: meansOfInvitation,
  });
};

export type SetMultiReceiverInvitationSubjectAction = PayloadAction<
  familyActionTypes.SET_MULTI_RECEIVER_INVITATION_SUBJECT,
  {
    invitationSubject: string;
  }
>;

export const SetMultiReceiverInvitationSubject = (
  invitationSubject: string,
): SetMultiReceiverInvitationSubjectAction => {
  return action(familyActionTypes.SET_MULTI_RECEIVER_INVITATION_SUBJECT, {
    invitationSubject: invitationSubject,
  });
};

export type SetMultiReceiverInvitationMessageAction = PayloadAction<
  familyActionTypes.SET_MULTI_RECEIVER_INVITATION_MESSAGE,
  {
    invitationMessage: string;
  }
>;

export const SetMultiReceiverInvitationMessage = (
  invitationMessage: string,
): SetMultiReceiverInvitationMessageAction => {
  return action(familyActionTypes.SET_MULTI_RECEIVER_INVITATION_MESSAGE, {
    invitationMessage: invitationMessage,
  });
};

//#endregion

//#region Join Family with invite Code Action Creators

export type JoinFamilyActionPayload = {
  inviteCode: string;
  inviteCodeIsGeneralAccess: boolean;
};

export type JoinFamilyRequestAction = PayloadMetaAction<
  familyActionTypes.JOIN_FAMILY_REQUEST,
  JoinFamilyActionPayload,
  ActionCallbacks
>;

export const joinFamilyRequest = (
  payload: JoinFamilyActionPayload,
  actionCallbacks: ActionCallbacks,
): JoinFamilyRequestAction => {
  return action(
    familyActionTypes.JOIN_FAMILY_REQUEST,
    {
      ...payload,
    },
    {
      ...actionCallbacks,
    },
  );
};

export type JoinFamilySuccessPayload = {
  joinedFamily: Family;
};

export type JoinFamilySuccessAction = PayloadAction<
  familyActionTypes.JOIN_FAMILY_SUCCESS,
  JoinFamilySuccessPayload
>;
export const joinFamilySuccess = (
  joinedFamily: Family,
): JoinFamilySuccessAction => {
  return action(familyActionTypes.JOIN_FAMILY_SUCCESS, {
    joinedFamily: joinedFamily,
  });
};

export const joinFamilyFailure = (error: FZApiErrorResponse) => {
  return action(familyActionTypes.JOIN_FAMILY_FAILURE, error);
};

//#endregion

//#region Leave Family Action Creators

export type LeaveFamilyActionPayload = {
  familyUuidOfFamilyToLeave: string;
};

export type LeaveFamilyRequestAction = PayloadMetaAction<
  familyActionTypes.LEAVE_FAMILY_REQUEST,
  LeaveFamilyActionPayload,
  ActionCallbacks
>;

export type LeaveFamilySuccessAction = PayloadAction<
  familyActionTypes.LEAVE_FAMILY_SUCCESS,
  LeaveFamilyActionPayload
>;

export const leaveFamilyRequest = (
  payload: LeaveFamilyActionPayload,
  actionCallbacks: ActionCallbacks,
): LeaveFamilyRequestAction => {
  return action(
    familyActionTypes.LEAVE_FAMILY_REQUEST,
    {
      ...payload,
    },
    {
      ...actionCallbacks,
    },
  );
};

export const leaveFamilySuccess = (
  familyUuidOfLeftFamily: string,
): LeaveFamilySuccessAction => {
  return action(familyActionTypes.LEAVE_FAMILY_SUCCESS, {
    familyUuidOfFamilyToLeave: familyUuidOfLeftFamily,
  });
};

export const leaveFamilyFailure = (error: FZApiErrorResponse) => {
  return action(familyActionTypes.LEAVE_FAMILY_FAILURE, error);
};
//#endregion

//#region Remove family member from family Action Creators

export type RemoveFamilyMemberActionPayload = {
  familyUuid: string;
  customerUid: string;
};

export type RemoveFamilyMemberRequestAction = PayloadMetaAction<
  familyActionTypes.REMOVE_FAMILY_MEMBER_REQUEST,
  RemoveFamilyMemberActionPayload,
  ActionCallbacks
>;

export type RemoveFamilyMemberSuccessAction = PayloadAction<
  familyActionTypes.REMOVE_FAMILY_MEMBER_SUCCESS,
  RemoveFamilyMemberActionPayload
>;

export const removeFamilyMemberRequest = (
  payload: RemoveFamilyMemberActionPayload,
  actionCallbacks: ActionCallbacks,
): RemoveFamilyMemberRequestAction => {
  return action(
    familyActionTypes.REMOVE_FAMILY_MEMBER_REQUEST,
    {
      ...payload,
    },
    {
      ...actionCallbacks,
    },
  );
};

export const removeFamilyMemberSuccess = (
  familyUuid: string,
  customerUid: string,
): RemoveFamilyMemberSuccessAction => {
  return action(familyActionTypes.REMOVE_FAMILY_MEMBER_SUCCESS, {
    familyUuid: familyUuid,
    customerUid: customerUid,
  });
};
export const removeFamilyMemberFailure = (error: Error) => {
  return action(familyActionTypes.REMOVE_FAMILY_MEMBER_FAILURE, error);
};

//#region Fetch User Contributions in Newspapers Action Creators
export const fetchUserContributionsInNewspaperOfFamilyRequest = () => {
  return action(
    familyActionTypes.FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_REQUEST,
  );
};

export const fetchUserContributionsInNewspaperOfFamilySuccess = (
  newspaper: Newspaper,
) => {
  return action(
    familyActionTypes.FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_SUCCESS,
    {
      newspaperWithOnlyArticlesOfCurrentUser: newspaper,
    },
  );
};

export const fetchUserContributionsInNewspaperOfFamilyFailure = (
  error: Error,
) => {
  return action(
    familyActionTypes.FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_FAILURE,
    {
      error,
    },
  );
};

export const fetchUserContributionsInNewspaperOfFamilyFulfill = () => {
  return action(
    familyActionTypes.FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_FULFILL,
  );
};
//#endregion

//#region Set End Of Release Cycle For Family Action Creators
/**
 * Sets a flag if a family has reached the end of its release cycle.
 * @param familyForWhichTheCountdownIsCloseToFinish - the family which has reached the end of its current release cycle
 * @param hasReachedEndOfReleaseCycle - flag to show wether or not the releas cycle has finished.
 *
 * ExampleAction (to copy into redux devtools and dispatch manually):
 * type: "SET_FAMILY_HAS_REACHED_END_OF_RELEASE_CYCLE",
 * payload: {family: {uuid:"9bfe86fa-ace9-4fc6-9ad0-761176ecc4ee"}, hasReachedEndOfReleaseCycle: true }
 */
export const setFamilyHasReachedEndOfReleaseCycle = (
  familyForWhichTheCountdownIsCloseToFinish: Family,
  hasReachedEndOfReleaseCycle: boolean,
) => {
  return action(familyActionTypes.SET_FAMILY_HAS_REACHED_END_OF_RELEASE_CYCLE, {
    family: familyForWhichTheCountdownIsCloseToFinish,
    hasReachedEndOfReleaseCycle: hasReachedEndOfReleaseCycle,
  });
};

/**
 *
 * @param familyForWhichItHasFlashed
 * @param hasFlashed
 */
export const setEndOfReleaseCycleReminderHasFlashed = (
  familyForWhichItHasFlashed: Family,
  hasFlashed: boolean,
) => {
  return action(familyActionTypes.SET_END_OF_RELEASE_REMINDER_HAS_FLASHED, {
    family: familyForWhichItHasFlashed,
    hasFlashed: hasFlashed,
  });
};

//#endregion
