import moment from 'moment';

/**
 * A set of utilites to render null, undefined, enum, dateTime or date
 * values in a human readable format.
 */
class FormattingUtilities {
  // Returns a given boolean as a String
  static stringValueForBoolean(boolValue: boolean) {
    return boolValue ? 'true' : 'false';
  }

  // Returns a string or an empty string, if the parameter is undefined or null
  static stringOrEmpty(stringValue: string) {
    return stringValue || '';
  }

  // Returns a number or the string "none", if the parameter is undefined or null
  static numberOrEmpty(numberValue: number) {
    return numberValue || 'none';
  }

  // Utilizes moment.js to format a given date in the format DD.MM.YYYY
  static getFormattedDate(dateString: string | number): string {
    return moment(dateString).format('DD.MM.YYYY');
  }

  // Utilizes moment.js to format a given datetimestring in the format DD.MM.YYYY hh:mm
  static getFormattedDateTime(dateString: string): string {
    return moment(dateString).format('llll'); // DD.MM.YYYY hh:mm
  }

  // Gets a localized string describing the time that has elapsed since a given date or dateTime.
  static getTimeSinceCreated(dateString: string | undefined): string {
    if (!dateString) return '';
    return moment(dateString).fromNow();
  }
}

export default FormattingUtilities;
