import {
  Card,
  CardContent,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import colors from '../../00_Constants/colors';

const useStyles = makeStyles((theme: Theme) => {
  return {
    cardContainer: {
      //borderBottomStyle: 'solid',
      //borderBottomWidth: '4px',
      //borderBottomColor: colors.accent,
    },

    cardMediaBackground: {},
  };
});

interface FZEmptyListPlaceholderProps {
  cardStyle?: any;
  title: string;
  content: string;
}

const FZEmptyListPlaceholder: FunctionComponent<FZEmptyListPlaceholderProps> = props => {
  const classes = useStyles();
  return (
    <Card
      style={props.cardStyle}
      className={classes.cardContainer}
      elevation={2}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="body1"
          style={{ color: colors.accent }}
        >
          {props.title}
        </Typography>
        <Typography variant="body2">{props.content}</Typography>
      </CardContent>
    </Card>
  );
};

export default FZEmptyListPlaceholder;
