import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import FZNavigateBackButton from '../../../01_atoms/FZNavigateBackButton/FZNavigateBackButton';
import FZPageContainer from '../../../01_atoms/FZPageContainer/FZPageContainer';
import FZTypography from '../../../01_atoms/FZTypography/FZTypography';
import FZAppBar from '../../../03_organisms/FZAppBar/FZAppBar';
import ContentPageTemplate from '../../../04_templates/ContentPageTemplate/ContentPageTemplate';

interface Props {}

export default function EditSubscriptionsScreen(props: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <FZPageContainer>
      <FZAppBar
        navbarLeftContent={
          <FZNavigateBackButton
            backDestinationLabel={t(
              'editSubscriptionScreen-backDestinationLabel',
            )}
          />
        }
      ></FZAppBar>
      <ContentPageTemplate>
        <FZTypography variant="h5">
          {t('editSubscriptionScreen-headline')}
        </FZTypography>
        <FZTypography variant="body1">
          {t('editSubscriptionScreen-subheadline')}
        </FZTypography>
      </ContentPageTemplate>
    </FZPageContainer>
  );
}
