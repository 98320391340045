// Tracker Categories that group events
export const AUTHENTICATION = 'AUTHENTICATION';
export const ARTICLES = 'ARTICLES';
export const FAMILY = 'FAMILY';
export const NEWS = 'NEWS';

// -- Tracker Events that describe certain actions

// Authentication Events
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// Article related events
export const CREATE_NEW_ARTICLE = 'CREATE_NEW_ARTICLE';
export const START_EDITING_ARTICLE = 'START_EDITING_ARTICLE';
export const TOUCH_DATE_INPUT = 'TOUCH_DATE_INPUT';
export const TOUCH_LOCATION_INPUT = 'TOUCH_LOCATION_INPUT';
export const TOUCH_TITLE_INPUT = 'TOUCH_TITLE_INPUT';
export const TOUCH_CONTENT_INPUT = 'TOUCH_CONTENT_INPUT';
export const TOUCH_AUTHOR_INPUT = 'TOUCH_AUTHOR_INPUT';
export const SAVE_ARTICLE_AS_DRAFT = 'SAVE_ARTICLE_AS_DRAFT';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const PUBLISH_ARTICLE = 'PUBLISH_ARTICLE';

//
