import { action } from 'typesafe-actions';

export enum appActionTypes {
  SET_FIRST_LOAD_FINISHED = 'SET_FIRST_LOAD_FINISHED',
  SET_LOADING = 'SET_LOADING',
}

export const setFirstLoadFinished = (value: boolean) =>
  action(appActionTypes.SET_FIRST_LOAD_FINISHED, value);

export const setLoading = (isLoading: boolean, loadingMessage: string) =>
  action(appActionTypes.SET_LOADING, {
    isLoading: isLoading,
    loadingMessage: loadingMessage,
  });
