import { Card, CardActions, CardContent, makeStyles } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

const useStyles = makeStyles({
  container: {
    marginLeft: 16,
    marginRight: 16,
    padding: 16,
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  infoIcon: {
    color: (props: Props) => props.keyColor,
    marginRight: 8,
  },

  actionArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

interface Props {
  keyColor?: string;
  children: JSX.Element | JSX.Element[];
  actions?: JSX.Element | JSX.Element[];
  className?: string;
}

export default function FZInfoCard(props: Props): ReactElement {
  const classes = useStyles(props);
  const { children, actions, className } = props;

  return (
    <Card className={clsx(classes.container, className)} elevation={1}>
      <CardContent className={classes.contentContainer}>
        <InfoRoundedIcon className={classes.infoIcon}></InfoRoundedIcon>
        {children}
      </CardContent>
      <CardActions classes={{ root: classes.actionArea }}>
        {actions}
      </CardActions>
    </Card>
  );
}
