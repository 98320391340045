import { action, PayloadAction } from 'typesafe-actions';
import { BlogPost, FeedContent } from '../../models/FeedContent';

/**
 * Constants used as action types in ../_actions/user.actions.js
 */
export enum feedActionTypes {
  FETCH_PERSONALIZED_FEED_REQUEST = 'FETCH_PERSONALIZED_FEED_REQUEST',
  FETCH_PERSONALIZED_FEED_SUCCESS = 'FETCH_PERSONALIZED_FEED_SUCCESS',
  FETCH_PERSONALIZED_FEED_FAILURE = 'FETCH_PERSONALIZED_FEED_FAILURE',
  FETCH_PERSONALIZED_FEED_FULFILL = 'FETCH_PERSONALIZED_FEED_FULFILL',
  SET_CURRENTLY_VIEWED_POST = 'SET_CURRENTLY_VIEWED_POST',
  SET_UNSEEN_FEED_ITEMS = 'SET_UNSEEN_FEED_ITEM',
}

//#region Fetch Feed Action Creators
export const fetchPersonalizedFeedRequest = () =>
  action(feedActionTypes.FETCH_PERSONALIZED_FEED_REQUEST);

export const fetchPersonalizedFeedSuccess = (feedContent: FeedContent) =>
  action(feedActionTypes.FETCH_PERSONALIZED_FEED_SUCCESS, feedContent);

export const fetchPersonalizedFeedFailure = (error: Error) => {
  return action(feedActionTypes.FETCH_PERSONALIZED_FEED_FAILURE, error);
};

export const fetchPersonalizedFeedFulfill = () => {
  return action(feedActionTypes.FETCH_PERSONALIZED_FEED_FULFILL);
};
//#endregion

//#region Set Currently Viewed Post Action Creators

export const setCurrentlyViewedPost = (postToSet: BlogPost) => {
  return action(feedActionTypes.SET_CURRENTLY_VIEWED_POST, {
    currentlyViewedPost: postToSet,
  });
};
//#endregion

//#region

export const setNumberOfUnseenFeedItems = (
  numberOfUnseenFeedItems: number,
): PayloadAction<
  feedActionTypes.SET_UNSEEN_FEED_ITEMS,
  { numberOfUnseenFeedItems: number }
> => {
  return action(feedActionTypes.SET_UNSEEN_FEED_ITEMS, {
    numberOfUnseenFeedItems: numberOfUnseenFeedItems,
  });
};

//#endregion
