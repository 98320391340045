import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import i18next from 'i18next';
import { IconFlagDE, IconFlagUK } from 'material-ui-flags';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  FZLanguageSwitcherRoot: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  MenuItemLanguageLabel: {
    marginRight: 8,
    marginLeft: 8,
  },

  menuItemLanguageFlag: {
    width: 16,
    height: 16,
  },
});

interface LanguageSwitcherProps {
  style?: CSSProperties;
}

/**
 * A component capable of switching the current locale
 * in the used internationalization solution i18next.
 */
const FZLanguageSwitcher = (props: LanguageSwitcherProps) => {
  const currentLanguage = i18next.language || 'de';

  // Declare a new state variable, which we'll call "count"
  const [language, setLanguage] = useState(currentLanguage);
  const { t } = useTranslation();

  const handleLanguageChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: ReactNode,
  ) => {
    setLanguage(event.target.value as string);
    i18next.changeLanguage(event.target.value as string);
  };

  const classes = useStyles(props);

  return (
    <FormControl variant="standard" style={props.style}>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={language}
        onChange={handleLanguageChange}
        autoWidth
        style={{ padding: '5px', margin: 0 }}
        classes={{ root: classes.FZLanguageSwitcherRoot }}
        disableUnderline
      >
        <MenuItem className={classes.MenuItemLanguageLabel} value="de">
          <IconFlagDE className={classes.menuItemLanguageFlag} />{' '}
          <span className={classes.MenuItemLanguageLabel}>
            {t('FZLanguageSelector-option-german')}
          </span>
        </MenuItem>
        <MenuItem className={classes.MenuItemLanguageLabel} value="en">
          <IconFlagUK className={classes.menuItemLanguageFlag} />{' '}
          <span className={classes.MenuItemLanguageLabel}>
            {t('FZLanguageSelector-option-english')}
          </span>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

// extended main view with translate hoc
export default FZLanguageSwitcher;
