import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  FormHelperText,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import PhotoCameraRoundedIcon from '@material-ui/icons/PhotoCameraRounded';
import clsx from 'clsx';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import LazyLoad from 'react-lazy-load';
import { connect } from 'react-redux';
import { Image as CustomImageType } from '../../../common/models/Image';
import { fetchPresignedImageUrlRequest } from '../../../common/redux/images/image.actions';
import { selectPresignedUrlForImage } from '../../../common/redux/images/image.selectors';
import { ApplicationState } from '../../../common/redux/main/types';
import { showSuccessSnackbar } from '../../../common/redux/ui/ui.actions';
import { putProfileImageRequest } from '../../../common/redux/users/user.actions';
import colors from '../../00_Constants/colors';

/* 
const useCircularProgressStyles = makeStyles({}); */

type CircularProgressWithLabelProps = {
  value: number;
};

function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
  //const classes = useCircularProgressStyles({});
  return (
    <Box
      position="absolute"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress
        style={{ zIndex: 1, color: colors.quartiary }}
        variant="static"
        value={props.value}
        thickness={0.75}
        size={200}
      />

      <CircularProgress
        style={{ zIndex: 1, color: colors.quartiary, position: 'absolute' }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: 'white',
      color: 'black',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      borderRadius: 16,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',

        border: '1px solid grey',
      },
    },
  }),
)(Badge);

const styles = (theme: Theme) =>
  createStyles({
    avatarImageStyles: {
      objectFit: 'cover',
    },

    editBadgeStyles: {
      backgroundColor: 'white',
      padding: 0,
      height: 'inherit',
      borderRadius: 32,
    },

    editBadgeIconStyles: {
      width: 16,
      height: 16,
    },

    imageInputErrorMessageStyles: {
      marginTop: 16,
      marginBottom: 16,
      color: theme.palette.warning.main,
      maxWidth: '50%',
    },
  });

export type Props = WithTranslation & {
  profilePictureUuid: string;
  editable: boolean;
  customClasses?: { container?: any; imageContainer?: any; image?: any };

  dispatch?: any;
  classes?: any;
  onClick?: (event: any) => void;
  onLongPressItem?: (imageUuid: string) => void;
  onEditTriggered?: (imageUuid: string) => void;

  imageStyle?: object;

  // Redux Props
  presignedProfileImageDownloadUrl?: string;
  ref?: any;

  // HoC Procs
  t?: any;
};

type State = {
  imageInputObjectUrl: string | null;
  imageInputError: string | null;
  imageUploadProgress: number;
  imageUploadStarted: boolean;
  imageUploadFinished: boolean;
  fileInputKey: number;
  imagePreviewOpen: boolean;
};

class FZProfilePictureWidget extends Component<Props, State> {
  fileUploadRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      imageUploadProgress: 0,
      imageInputObjectUrl: null,
      imageInputError: null,
      imageUploadStarted: false,
      imageUploadFinished: false,
      fileInputKey: Date.now(),
      imagePreviewOpen: false,
    };
    this.fileUploadRef = React.createRef<HTMLInputElement>();
  }

  async componentDidMount() {
    const {
      dispatch,
      presignedProfileImageDownloadUrl,
      profilePictureUuid,
    } = this.props;

    if (profilePictureUuid && !presignedProfileImageDownloadUrl) {
      dispatch(
        fetchPresignedImageUrlRequest(profilePictureUuid, null, null, null),
      );
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.profilePictureUuid !== prevProps.profilePictureUuid) {
      this.props.dispatch(
        fetchPresignedImageUrlRequest(
          this.props.profilePictureUuid,
          null,
          null,
          null,
        ),
      );
    }
  }

  /**
   * Confirms a selected profile image and starts its upload
   */
  private handleConfirmImageButtonPress = async () => {
    const { dispatch, t } = this.props;

    this.setState({ imageUploadStarted: true });

    // Create a blob from the image Url
    const blob = await fetch(this.state.imageInputObjectUrl).then(r =>
      r.blob(),
    );

    // Create a new image from that blob
    const profileImage = new CustomImageType();
    profileImage.blobRepresentation = blob;
    profileImage.mimeType = blob.type;

    // Register an Upload done callback
    const uploadImageDoneCallback = () => {
      this.setState({
        imageUploadFinished: true,
        imageUploadProgress: 100,
        imageUploadStarted: false,
      });
      dispatch(
        showSuccessSnackbar(
          t('FZProfilePictureWidget-update-profile-image-success-message'),
          true,
        ),
      );
    };

    // Register an Upload Progress Callback
    const uploadImageProgressCallback = (progressEvent: any) => {
      // This upload progress callback only tracks the upload to the storage provider.
      // Since another network call is used to link it to the profile in our own backend,
      // we let the progress only get to 95% and finish it in the "uploadImageDoneCallback"
      // that is called after said network call was performed.

      const percentCompleted: number = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100,
      );

      let displayedPercentCompleted: number = percentCompleted;

      if (percentCompleted > 25) {
        displayedPercentCompleted = 25;
      }

      if (percentCompleted > 50) {
        displayedPercentCompleted = 50;
      }

      if (percentCompleted > 75) {
        displayedPercentCompleted = 75;
      }

      if (percentCompleted > 95) {
        displayedPercentCompleted = 95;
      }

      this.setState({ imageUploadProgress: displayedPercentCompleted });
    };

    const updateImageErrorCallback = () => {
      this.setState({
        imageInputObjectUrl: null,
        imageUploadStarted: false,
        imageUploadFinished: false,
        imageUploadProgress: 0,
      });
    };

    // Trigger Upload
    this.props.dispatch(
      putProfileImageRequest(
        profileImage,
        uploadImageProgressCallback,
        uploadImageDoneCallback,
        { errorCallback: updateImageErrorCallback },
      ),
    );
  };

  /**
   * Clears a selected profile image
   */
  private handleClearImageButtonPress = () => {
    this.setState({
      imageInputObjectUrl: this.props.presignedProfileImageDownloadUrl || null,
      imageUploadFinished: false,
      imageUploadStarted: false,
      imageUploadProgress: 0,
    });
  };

  /**
   * Handles a press onto the add-images button in the placeholder on top of the page.
   * NOTE: This does not yet trigger the upload of an image, but rather opens the file explorer to pick an image.
   */
  private handleAddImagesButtonPress = (event: any) => {
    this.setState({
      imageUploadFinished: false,
      imageUploadProgress: 0,
      imageInputObjectUrl: this.props.presignedProfileImageDownloadUrl || null,
    });

    this.fileUploadRef!.current.click();
  };

  /**
   * Handles a change in the file input component
   */
  private handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    event.target.value = '';

    const objectUrl = URL.createObjectURL(file);
    const img = new Image();
    const imageOnloadCallback = () => {
      const width = img.naturalWidth,
        height = img.naturalHeight;

      // check its dimensions
      if (width >= 2048 || height >= 2048) {
        // it doesn't fit, unset the value
        // post an error

        const imageInputErrorMessage = this.props.t(
          'FZProfilePictureWidget-error-imageTooLarge',
        );

        this.setState({
          imagePreviewOpen: false,
          imageInputObjectUrl: null,
          imageInputError: imageInputErrorMessage,
          fileInputKey: Date.now(),
        });
        // unload it
        URL.revokeObjectURL(img.src);
      } else {
        this.setState({
          imageInputObjectUrl: objectUrl,
          imagePreviewOpen: true,
          imageInputError: null,
          fileInputKey: Date.now(),
        });
      }
    };

    img.onload = imageOnloadCallback;
    img.src = objectUrl;
    img.alt = 'image-preload';
  };

  /**
   * Renders the profilePictureWidget with buttons that make it editable
   */
  private renderEditableProfileImageWidget = (): JSX.Element => {
    const { classes, t, presignedProfileImageDownloadUrl } = this.props;

    const {
      imageInputObjectUrl,
      imageUploadStarted,
      imageUploadFinished,
      imageUploadProgress,
    } = this.state;

    const shouldRenderUploadProgressSpinner =
      imageUploadStarted ||
      (!imageUploadFinished &&
        imageUploadProgress !== 0 &&
        !isNaN(imageUploadProgress));

    if (
      imageInputObjectUrl &&
      imageInputObjectUrl !== presignedProfileImageDownloadUrl &&
      !imageUploadFinished
    ) {
      return (
        <StyledBadge
          data-testid="FZProfilePictureWidget-confirm-selected-image-badge"
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <span>
              <Tooltip
                title={t(
                  'FZProfilePictureWidget-confirm-profile-picture-tooltip',
                )}
              >
                <IconButton
                  aria-label="confirm profile picture selection"
                  onClick={this.handleConfirmImageButtonPress}
                  disabled={shouldRenderUploadProgressSpinner}
                >
                  <CheckRoundedIcon />
                </IconButton>
              </Tooltip>
            </span>
          }
          classes={{ badge: classes.editBadgeStyles }}
        >
          <StyledBadge
            data-testid="FZProfilePictureWidget-clear-selected-image-badge"
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClick={this.handleClearImageButtonPress}
            badgeContent={
              <span>
                <Tooltip
                  title={t(
                    'FZProfilePictureWidget-clear-profile-picture-tooltip',
                  )}
                >
                  <IconButton
                    aria-label="clear profile picture selection"
                    onClick={this.handleClearImageButtonPress}
                    disabled={shouldRenderUploadProgressSpinner}
                  >
                    <ClearRoundedIcon />
                  </IconButton>
                </Tooltip>
              </span>
            }
            classes={{ badge: classes.editBadgeStyles }}
          >
            {shouldRenderUploadProgressSpinner && (
              <CircularProgressWithLabel
                value={imageUploadProgress}
              ></CircularProgressWithLabel>
            )}

            {this.renderAvatar()}
          </StyledBadge>
        </StyledBadge>
      );
    }

    return (
      <StyledBadge
        data-testid="FZProfilePictureWidget-update-image-badge"
        overlap="circle"
        onClick={this.handleAddImagesButtonPress}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <span>
            <Tooltip
              title={t('FZProfilePictureWidget-edit-profile-picture-tooltip')}
            >
              <IconButton aria-label="change profile picture">
                <PhotoCameraRoundedIcon />
              </IconButton>
            </Tooltip>

            <form>
              <input
                data-testid="FZProfilePictureWidget-image-input"
                id="fileUploader"
                type="file"
                ref={this.fileUploadRef}
                style={{ display: 'none', position: 'fixed', top: '-100em' }}
                onChange={this.handleFileInputChange}
                accept="image/png, image/jpg, image/jpeg"
                hidden
              />
            </form>
          </span>
        }
        classes={{ badge: classes.editBadgeStyles }}
      >
        {this.renderAvatar()}
      </StyledBadge>
    );
  };

  /**
   * Renders an informative error statement when there is any error with uploading a profile image.
   */
  private renderImageErrorText = (): JSX.Element => {
    return (
      <FormHelperText
        data-testid="FZProfilePictureWidget-error-message-ui"
        className={this.props.classes.imageInputErrorMessageStyles}
      >{`${this.state.imageInputError}`}</FormHelperText>
    );
  };

  /**
   * Renders the profile picture itself, without any buttons that make it editable.
   */
  private renderAvatar = (): JSX.Element => {
    const {
      presignedProfileImageDownloadUrl,
      classes,
      customClasses,
      onClick,
      editable,
    } = this.props;

    const { imageInputObjectUrl, imagePreviewOpen, fileInputKey } = this.state;

    if (editable) {
      return (
        <Avatar
          data-testid="FZProfilePictureWidget-avatar-ui"
          imgProps={{ role: 'img' }}
          aria-label="Your personal profile image"
          classes={{
            root: customClasses?.imageContainer,
            img: clsx(customClasses?.image, classes.avatarImageStyles),
          }}
          src={
            imagePreviewOpen
              ? imageInputObjectUrl
              : presignedProfileImageDownloadUrl
          }
          key={fileInputKey}
          onClick={onClick}
        />
      );
    } else {
      return (
        <LazyLoad>
          <Avatar
            data-testid="FZProfilePictureWidget-avatar-ui"
            imgProps={{ role: 'img' }}
            aria-label="Your personal profile image"
            classes={{
              root: customClasses?.imageContainer,
              img: clsx(customClasses?.image, classes.avatarImageStyles),
            }}
            src={
              imagePreviewOpen
                ? imageInputObjectUrl
                : presignedProfileImageDownloadUrl
            }
            key={fileInputKey}
            onClick={onClick}
          />
        </LazyLoad>
      );
    }
  };

  public render(): JSX.Element {
    const { editable, customClasses } = this.props;

    return (
      <div className={customClasses?.container}>
        {!editable && this.renderAvatar()}
        {editable && this.renderEditableProfileImageWidget()}
        {this.state.imageInputError && this.renderImageErrorText()}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: Props) => {
  const profileImageUuid = ownProps.profilePictureUuid;

  return {
    presignedProfileImageDownloadUrl: selectPresignedUrlForImage(
      state,
      profileImageUuid,
    ),
  };
};

//const styledComponent = withStyles(styles)(FZArticleListItem);

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps)(FZProfilePictureWidget)),
);
