import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { Field } from 'formik';
import React, { ReactElement } from 'react';
import colors from '../../00_Constants/colors';
import FZFieldLabel from '../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZTextInputField from '../../01_atoms/FZTextInputField/FZTextInputField';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: { width: '80%' },

    formInputField: {
      width: '100%',
    },

    familyNameLabel: {},
  };
});

export interface CreateFamilyFormValues {
  familyName: string;
}

interface Props {
  children?: ReactElement;

  // Form Props
  familyNameFieldName: string;
  familyNameFieldCharacterLimit: number;

  // Display-Texts Props
  familyNameFieldLabel?: string;
  familyNameFieldPlaceholder?: string;

  // Styling Props
  familyNameLabelClassName?: string;
  familyNameFieldClassName?: string;
}

export default function FZCreateFamilyForm({
  familyNameFieldName,
  familyNameFieldLabel,
  familyNameFieldCharacterLimit,
  familyNameFieldPlaceholder,
  familyNameLabelClassName,
  familyNameFieldClassName,
}: Props): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FZFieldLabel
        label={familyNameFieldLabel}
        className={clsx(classes.familyNameLabel, familyNameLabelClassName)}
      ></FZFieldLabel>
      <Field
        id={familyNameFieldName}
        name={familyNameFieldName}
        data-testid={familyNameFieldName}
        component={FZTextInputField}
        className={clsx(classes.formInputField, familyNameFieldClassName)}
        placeholder={familyNameFieldPlaceholder}
        color={colors.secondary}
        displayCharacterCount
        characterLimit={familyNameFieldCharacterLimit}
        enforceCharacterLimit
      />
    </div>
  );
}
