import { Card, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { ReactElement } from 'react';

interface Props {
  imageSource?: string;
  body: JSX.Element;
  actions?: JSX.Element;
  isLoading?: boolean;
  loadingMessage?: string;
}

const useStyles = makeStyles(() => {
  return {
    statusImage: {
      maxWidth: '25%',
    },
    card: {
      marginTop: 32,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 32,
      paddingBottom: 32,

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2,
    },

    bodyContainer: {
      paddingTop: 32,
      maxWidth: '50%',
    },

    actionArea: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      padding: 32,
    },
  };
});

export default function FZStatusPageTemplate({
  imageSource,
  body,
  actions,
}: Props): ReactElement {
  const classes = useStyles({});

  return (
    <Container style={{ justifySelf: 'center', alignSelf: 'center' }}>
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Card className={classes.card}>
            {imageSource && (
              <img
                src={imageSource}
                className={classes.statusImage}
                alt="app-status"
              />
            )}

            <span className={classes.bodyContainer}>{body}</span>
            {actions && <div className={classes.actionArea}>{actions}</div>}
          </Card>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Container>
  );
}
