import { UserSettings } from '../../models/UserSettings';
import { Image } from '../../models/Image';

export interface UserProfile {
  uid?: string;
  firstName?: string;
  displayName: string;
  profileImage: Image;
  userName?: string;
}

export type UserReducerState = {
  isFetchingUserSettings: boolean;
  fetchingUserSettingsWasSuccessful: boolean;
  userProfile: UserProfile | null;
  userSettings: UserSettings | null;
};

export interface UpdateUserSettingsDto {
  /**
   * The default-naming of this user which is put into the "author" field when creating new articles.
   */
  defaultAuthorPseudonym?: string;

  /**
   * The Uuid of the family that shall be active by default for this user.
   */
  defaultActiveFamilyUuid?: string;

  /**
   * Notification related settings
   */
  releaseCycleReminderNotificationEnabled?: boolean;
}

export interface UpdateUserProfileDto {
  login?: string;
  firstName?: string;
  lastName?: string;
  nickName?: string;
  displayName?: string;
  email?: string;
  secondEmail?: string;
  profileUrl?: string;
  preferredLanguage?: string;
  userType?: string;
  organization?: string;
  title?: string;
  division?: string;
  department?: string;
  mobilePhone?: string;
  primaryPhone?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  countryCode?: string;
}

export const isUserProfile = (p: any): p is UserProfile => p && p.profileImage;
