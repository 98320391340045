type fontConfig = {
  FONT_SIZE_SMALL: number;
  FONT_SIZE_MEDIUM: number;
  FONT_SIZE_LARGE: number;
  FONT_SIZE_EXTRA_LARGE: number;

  FONT_WEIGHT_LIGHT: number;
  FONT_WEIGHT_MEDIUM: number;
  FONT_WEIGHT_BOLD: number;

  FONT_FAMILY_THIN: string;
  FONT_FAMILY_LIGHT: string;
  FONT_FAMILY_MEDIUM: string;
  FONT_FAMILY_REGULAR: string;
  FONT_FAMILY_BOLD: string;
  FONT_FAMILY_HEADLINE: string;
  FONT_FAMILY_HEADLINE_BOLD: string;
};

const fonts: fontConfig = {
  FONT_SIZE_SMALL: 18,
  FONT_SIZE_MEDIUM: 21,
  FONT_SIZE_LARGE: 24,
  FONT_SIZE_EXTRA_LARGE: 20,

  FONT_WEIGHT_LIGHT: 200,
  FONT_WEIGHT_MEDIUM: 400,
  FONT_WEIGHT_BOLD: 600,

  FONT_FAMILY_THIN: 'OpenSans-Light',
  FONT_FAMILY_LIGHT: 'OpenSans-Light',
  FONT_FAMILY_MEDIUM: 'Muli-Regular',
  FONT_FAMILY_REGULAR: 'OpenSansRegular',
  FONT_FAMILY_BOLD: 'OpenSansBold',
  FONT_FAMILY_HEADLINE: 'BebasNeueBold',
  FONT_FAMILY_HEADLINE_BOLD: 'BebasNeueBold',
};

export default fonts;
