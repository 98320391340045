import { createStyles, makeStyles, Theme } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import clsx from 'clsx';
import { Field, Formik, FormikHelpers } from 'formik';
import React, { ReactElement, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  JoinFamilyActionPayload,
  joinFamilyRequest,
} from '../../../common/redux/families/family.actions';
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../common/redux/ui/ui.actions';
import colors from '../../00_Constants/colors';
import FZButton from '../../01_atoms/FZButton/FZButton';
import FZFieldLabel from '../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZSpinner from '../../01_atoms/FZSpinner';
import FZTextInputField from '../../01_atoms/FZTextInputField/FZTextInputField';
import FZTypography from '../../01_atoms/FZTypography/FZTypography';
import FZDialog from '../../02_molecules/FZConfirmDialog/FZDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputAndLabelContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    codeInputRowContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',

      [theme.breakpoints.up('md')]: {
        paddingLeft: '25%',
        paddingRight: '25%',
      },
    },

    linkInputRowContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 32,

      [theme.breakpoints.up('md')]: {
        paddingLeft: '25%',
        paddingRight: '25%',
      },
    },

    inviteCodeInputLabel: {
      marginTop: 32,
      marginBottom: 8,
      alignSelf: 'center',
    },

    inviteCodeInputField: {
      maxWidth: 93,
      marginBottom: 32,
      paddingLeft: 8,
      paddingRight: 8,
    },

    inviteLinkInputField: {
      width: '100%',
    },

    toggleButtonRoot: {},

    toggleButtonSelected: {
      backgroundColor: `${colors.secondary} !important`,
    },
  }),
);

interface Props {
  open: boolean;
  onClose: () => void;
  color?: string;
  onJoinSuccessful: () => void;
}

export interface JoinFamilyWithLinkFormValues {
  familyInvitationLink: string;
}

export interface JoinFamilyWithCodeFormValues {
  familyInvitationLink1: string;
  familyInvitationLink2: string;
  familyInvitationLink3: string;
  familyInvitationLink4: string;
}

export enum PossibleMeansToJoinFamily {
  INVITATION_LINK = 'INVITATION_LINK',
  INVITATION_CODE = 'INVITATION_CODE',
}

export default function FZJoinFamilyWithCodeDialog({
  open,
  color,
  onClose,
  onJoinSuccessful,
}: Props): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();

  const [meansToJoinFamily, setMeansToJoinFamily] = useState<
    PossibleMeansToJoinFamily
  >(PossibleMeansToJoinFamily.INVITATION_LINK);

  const codeInput1Ref = useRef<HTMLInputElement>();
  const codeInput2Ref = useRef<HTMLInputElement>();
  const codeInput3Ref = useRef<HTMLInputElement>();
  const codeInput4Ref = useRef<HTMLInputElement>();

  const handlePaste = useCallback(async (event: any, setFieldValue: any) => {
    // The user may be attempting to paste an invite code
    const value = event.clipboardData.getData('text');

    if (value.length === 16) {
      const valueSplitOnEveryFourthCharacter = value.match(/.{1,4}/g);

      setFieldValue(
        'familyInvitationLink1',
        valueSplitOnEveryFourthCharacter[0],
      );
      setFieldValue(
        'familyInvitationLink2',
        valueSplitOnEveryFourthCharacter[1],
      );
      setFieldValue(
        'familyInvitationLink3',
        valueSplitOnEveryFourthCharacter[2],
      );
      setFieldValue(
        'familyInvitationLink4',
        valueSplitOnEveryFourthCharacter[3],
      );
    }
  }, []);

  return (
    <div>
      <FZDialog
        title={t('FZJoinFamilyWithCodeDialog-title')}
        variant="generic"
        open={open}
        onClose={onClose}
        displayCloseIcon
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <FZTypography variant="body1">
            {t('FZJoinFamilyWithCodeDialog-explanation-message')}
          </FZTypography>

          <ToggleButtonGroup
            exclusive
            onChange={(event, newValue: PossibleMeansToJoinFamily) => {
              if (newValue) {
                setMeansToJoinFamily(newValue);
              }
            }}
            value={meansToJoinFamily}
            style={{ marginBottom: 16, marginTop: 32, alignSelf: 'center' }}
          >
            <ToggleButton
              classes={{
                root: classes.toggleButtonRoot,
                selected: classes.toggleButtonSelected,
              }}
              value={PossibleMeansToJoinFamily.INVITATION_LINK}
              aria-label="color"
            >
              <>{t('FZJoinFamilyWithCodeDialog-join-via-link-label')}</>
            </ToggleButton>

            <ToggleButton
              classes={{
                root: classes.toggleButtonRoot,
                selected: classes.toggleButtonSelected,
              }}
              value={PossibleMeansToJoinFamily.INVITATION_CODE}
              aria-label="color"
            >
              <>{t('FZJoinFamilyWithCodeDialog-join-via-code-label')}</>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        {meansToJoinFamily === PossibleMeansToJoinFamily.INVITATION_LINK && (
          <Formik
            initialValues={{
              familyInvitationLink: '',
            }}
            validate={values => {
              const errors: Partial<JoinFamilyWithLinkFormValues> = {};
              return errors;
            }}
            onSubmit={(
              values: JoinFamilyWithLinkFormValues,
              helpers: FormikHelpers<JoinFamilyWithLinkFormValues>,
            ) => {
              // Splits an invitation code from a link in the shape of https://DomainName.TLD/accept-invite/invitationCode
              const joinFamilyRequestBody: JoinFamilyActionPayload = {
                inviteCode: values.familyInvitationLink.split('/')[4],
                inviteCodeIsGeneralAccess: true,
              };

              const joinFamilyCallbacks = {
                successCallback: () => {
                  helpers.setSubmitting(true);

                  setTimeout(() => {
                    dispatch(
                      showSuccessSnackbar(
                        t('acceptInvitationScreen-joining-family-successful'),
                        true,
                      ),
                    );

                    setLoading(false);
                    helpers.setSubmitting(false);
                    onJoinSuccessful();
                  }, 1000);
                },
                errorCallback: () => {
                  dispatch(
                    showErrorSnackbar(
                      t('acceptInvitationScreen-joining-family-failed-error'),
                      true,
                    ),
                  );
                  setLoading(false);
                  helpers.setSubmitting(false);
                },
              };

              setLoading(true);
              // Join a family using the code
              dispatch(
                joinFamilyRequest(joinFamilyRequestBody, joinFamilyCallbacks),
              );
            }}
          >
            {({
              submitForm,
              isSubmitting,
              errors,
              handleReset,
              setFieldValue,
            }) => (
              <div className={classes.inputAndLabelContainer}>
                <FZFieldLabel
                  label={t(
                    'FZJoinFamilyWithCodeDialog-invite-code-field-label',
                  )}
                  className={clsx(classes.inviteCodeInputLabel)}
                  labelAlign="center"
                ></FZFieldLabel>

                <div className={classes.linkInputRowContainer}>
                  <Field
                    inputProps={{
                      onPaste: (e: any) => {
                        handlePaste(e, setFieldValue);
                      },
                    }}
                    id={'familyInvitationLink'}
                    name={'familyInvitationLink'}
                    data-testid={'familyInvitationLink'}
                    component={FZTextInputField}
                    className={clsx(classes.inviteLinkInputField)}
                    placeholder={t(
                      'FZJoinFamilyWithCodeDialog-invite-code-field-placeholder',
                    )}
                    color={colors.secondary}
                    backgroundColor={colors.white}
                    multiline={false}
                    variant="outlined"
                  />
                </div>

                {loading ? (
                  <FZSpinner color={colors.secondary}></FZSpinner>
                ) : (
                  <FZButton
                    style={{ float: 'right' }}
                    backgroundColor={color}
                    label={t(
                      'FZJoinFamilyWithCodeDialog-confirm-join-family-with-code',
                    )}
                    onClick={() => {
                      submitForm();
                    }}
                  ></FZButton>
                )}
              </div>
            )}
          </Formik>
        )}

        {meansToJoinFamily === PossibleMeansToJoinFamily.INVITATION_CODE && (
          <Formik
            initialValues={{
              familyInvitationLink1: '',
              familyInvitationLink2: '',
              familyInvitationLink3: '',
              familyInvitationLink4: '',
            }}
            validate={values => {
              const errors: Partial<JoinFamilyWithCodeFormValues> = {};

              return errors;
            }}
            onSubmit={(
              values: JoinFamilyWithCodeFormValues,
              helpers: FormikHelpers<JoinFamilyWithCodeFormValues>,
            ) => {
              const joinFamilyRequestBody: JoinFamilyActionPayload = {
                inviteCode: values.familyInvitationLink1.concat(
                  values.familyInvitationLink2,
                  values.familyInvitationLink3,
                  values.familyInvitationLink4,
                ),
                inviteCodeIsGeneralAccess: true,
              };

              const joinFamilyCallbacks = {
                successCallback: () => {
                  helpers.setSubmitting(true);

                  setTimeout(() => {
                    dispatch(
                      showSuccessSnackbar(
                        t('acceptInvitationScreen-joining-family-successful'),
                        true,
                      ),
                    );

                    setLoading(false);
                    helpers.setSubmitting(false);
                    onJoinSuccessful();
                  }, 1000);
                },
                errorCallback: () => {
                  dispatch(
                    showErrorSnackbar(
                      t('acceptInvitationScreen-joining-family-failed-error'),
                      true,
                    ),
                  );
                  setLoading(false);
                  helpers.setSubmitting(false);
                },
              };

              setLoading(true);
              // Join a family using the code
              dispatch(
                joinFamilyRequest(joinFamilyRequestBody, joinFamilyCallbacks),
              );
            }}
          >
            {({
              submitForm,
              isSubmitting,
              errors,
              handleReset,
              setFieldValue,
            }) => (
              <div className={classes.inputAndLabelContainer}>
                <FZFieldLabel
                  label={t(
                    'FZJoinFamilyWithCodeDialog-invite-link-field-label',
                  )}
                  className={clsx(classes.inviteCodeInputLabel)}
                  labelAlign="center"
                ></FZFieldLabel>

                <div className={classes.codeInputRowContainer}>
                  <Field
                    inputProps={{
                      onPaste: (e: any) => {
                        handlePaste(e, setFieldValue);
                      },
                    }}
                    id={'familyInvitationLink1'}
                    name={'familyInvitationLink1'}
                    data-testid={'familyInvitationLink1'}
                    component={FZTextInputField}
                    className={clsx(classes.inviteCodeInputField)}
                    placeholder={t(
                      'FZJoinFamilyWithCodeDialog-invite-link-field-placeholder1',
                    )}
                    color={colors.secondary}
                    backgroundColor={colors.white}
                    multiline={false}
                    variant="outlined"
                    characterLimit={4}
                    enforceCharacterLimit
                    disableFullWidth
                    inputRef={codeInput1Ref}
                    onCharacterLimitHit={() => {
                      codeInput2Ref?.current?.focus();
                    }}
                  />

                  <Field
                    id={'familyInvitationLink2'}
                    name={'familyInvitationLink2'}
                    data-testid={'familyInvitationLink2'}
                    component={FZTextInputField}
                    className={clsx(classes.inviteCodeInputField)}
                    placeholder={t(
                      'FZJoinFamilyWithCodeDialog-invite-link-field-placeholder2',
                    )}
                    color={colors.secondary}
                    backgroundColor={colors.white}
                    multiline={false}
                    variant="outlined"
                    characterLimit={4}
                    enforceCharacterLimit
                    disableFullWidth
                    inputRef={codeInput2Ref}
                    onCharacterLimitHit={() => {
                      codeInput3Ref?.current?.focus();
                    }}
                  />

                  <Field
                    id={'familyInvitationLink3'}
                    name={'familyInvitationLink3'}
                    data-testid={'familyInvitationLink3'}
                    component={FZTextInputField}
                    className={clsx(classes.inviteCodeInputField)}
                    placeholder={t(
                      'FZJoinFamilyWithCodeDialog-invite-link-field-placeholder3',
                    )}
                    color={colors.secondary}
                    backgroundColor={colors.white}
                    multiline={false}
                    variant="outlined"
                    characterLimit={4}
                    enforceCharacterLimit
                    disableFullWidth
                    inputRef={codeInput3Ref}
                    onCharacterLimitHit={() => {
                      codeInput4Ref?.current?.focus();
                    }}
                  />

                  <Field
                    id={'familyInvitationLink4'}
                    name={'familyInvitationLink4'}
                    data-testid={'familyInvitationLink4'}
                    component={FZTextInputField}
                    className={clsx(classes.inviteCodeInputField)}
                    placeholder={t(
                      'FZJoinFamilyWithCodeDialog-invite-link-field-placeholder4',
                    )}
                    color={colors.secondary}
                    backgroundColor={colors.white}
                    multiline={false}
                    variant="outlined"
                    characterLimit={4}
                    enforceCharacterLimit
                    disableFullWidth
                    inputRef={codeInput4Ref}
                  />
                </div>

                {loading ? (
                  <FZSpinner color={colors.secondary}></FZSpinner>
                ) : (
                  <FZButton
                    style={{ float: 'right' }}
                    backgroundColor={color}
                    label={t(
                      'FZJoinFamilyWithCodeDialog-confirm-join-family-with-code',
                    )}
                    onClick={() => {
                      submitForm();
                    }}
                  ></FZButton>
                )}
              </div>
            )}
          </Formik>
        )}
      </FZDialog>
    </div>
  );
}
