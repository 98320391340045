import { Backdrop, Box, makeStyles, Typography } from '@material-ui/core';
import DonutSmallRoundedIcon from '@material-ui/icons/DonutSmallRounded';
import React from 'react';
import './FZSpinner.css';

const useStyles = makeStyles({
  modalSpinnerRoot: {
    zIndex: 1051,
  },
});

interface FZSpinnerProps {
  statusMessage?: string;
  className?: any;

  style?: any;
  color: string;
  modal?: boolean;
}

function FZSpinner(props: FZSpinnerProps) {
  const { modal } = props;
  let containerStyle: any;
  const innerContainerStyle = props.style;
  const classes = useStyles();

  const Spinner = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={props.className}
      style={innerContainerStyle}
    >
      {props.statusMessage ? (
        <span
          style={{
            backgroundColor: 'rgba(255,255,255,0.75)',
            border: '2px solid rgba(255,255,255,0.75)',
            padding: '20px',
            marginTop: '4px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DonutSmallRoundedIcon
            className="rotate"
            style={{ color: props.color }}
          />
          {props.statusMessage && (
            <Typography component="p">{props.statusMessage}</Typography>
          )}
        </span>
      ) : (
        <DonutSmallRoundedIcon
          className="rotate"
          style={{ color: props.color }}
        />
      )}
    </Box>
  );

  return (
    <div style={containerStyle}>
      {modal ? (
        <Backdrop open={true} className={classes.modalSpinnerRoot}>
          {Spinner}
        </Backdrop>
      ) : (
        <>{Spinner}</>
      )}
    </div>
  );
}

export default FZSpinner;
