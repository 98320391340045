import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import colors from '../../components/00_Constants/colors';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.accent,
    },
    success: {
      light: colors.success,
      main: colors.success,
      dark: colors.success,
    },
    error: {
      light: colors.danger,
      main: colors.danger,
      dark: colors.danger,
    },
    info: {
      light: colors.info,
      main: colors.info,
      dark: colors.info,
    },
  },

  typography: {
    h1: {
      fontFamily: 'BebasNeueBold',
      fontSize: '2.25rem',
      //letterSpacing: '-1.55px',
    },
    h2: { fontFamily: 'BebasNeueBold' },
    h3: { fontFamily: 'BebasNeueBold' },
    h4: { fontFamily: 'BebasNeueBold' },
    h5: { fontFamily: 'BebasNeueBold' },
    h6: { fontFamily: 'BebasNeueBold', letterSpacing: '3.13px' },
    body1: { fontFamily: 'OpenSansRegular', lineHeight: '26px' },
    body2: { fontFamily: 'OpenSansRegular', lineHeight: '26px' },
    caption: { fontFamily: 'OpenSansRegular' },

    fontFamily: [
      'OpenSansRegular',
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  overrides: {},
});

theme = responsiveFontSizes(theme);

export default theme;
