import { Divider, makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement } from 'react';
import colors from '../../00_Constants/colors';
import fonts from '../../00_Constants/fonts';
import FZButton from '../../01_atoms/FZButton/FZButton';
import FZTypography from '../../01_atoms/FZTypography/FZTypography';

const usePricingTableItemStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      backgroundColor: 'rgba(0,0,0,0)',
      minHeight: 300,
      padding: 16,
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flex: 0.33,
      //border: `1px solid ${colors.accent}`,

      boxShadow: '0px 8px 10px #00000024',

      [theme.breakpoints.down('md')]: {
        marginTop: 64,
      },
    },

    itemName: {
      color: '#FFF',
    },

    itemSubtitle: {
      color: '#FFF',
    },

    advantageList: {
      listStyle: 'none',
      flex: 1,
      padding: 0,
      color: '#FFF',

      '& > :nth-child(even)': {
        //backgroundColor: '#EFEFEF',
      },

      '& > li': {
        padding: 4,
      },
    },

    priceTag: {
      color: '#FFF',
      borderTop: '1px solid #FFF',
      width: '100%',
      paddingTop: 16,
      paddingBottom: 16,
      textAlign: 'center',
      fontWeight: 'bold',
      fontFamily: fonts.FONT_FAMILY_BOLD,
    },
  };
});

interface FZPricingTableItemProps {
  pricingItemName: string;
  pricingItemDescription: string;
  pricingItemImage: string;
  pricingItemIncludedAdvantages?: JSX.Element[];
  pricingItemPrice: string;
  includeCtaButton?: boolean;
  ctaButtonLabel?: string;
  ctaButtonOnClick?: () => void;
}

export default function FZPricingTableItem(
  props: FZPricingTableItemProps,
): ReactElement {
  const classes = usePricingTableItemStyles();
  const {
    pricingItemName,
    pricingItemDescription,

    pricingItemIncludedAdvantages,
    pricingItemPrice,
    includeCtaButton,
    ctaButtonLabel,
    ctaButtonOnClick,
  } = props;
  return (
    <div className={classes.container}>
      <FZTypography variant="h4" component="h4" className={classes.itemName}>
        {pricingItemName}
      </FZTypography>

      <FZTypography variant="body1" className={classes.itemSubtitle}>
        {pricingItemDescription}
      </FZTypography>

      <Divider></Divider>

      <ul className={classes.advantageList}>{pricingItemIncludedAdvantages}</ul>

      <Divider></Divider>
      <FZTypography variant="body1" component="p" className={classes.priceTag}>
        {pricingItemPrice}
      </FZTypography>

      {includeCtaButton && (
        <FZButton
          label={ctaButtonLabel}
          onClick={ctaButtonOnClick}
          labelColor={colors.white}
          backgroundColor={colors.accent}
          variant="contained"
        ></FZButton>
      )}
    </div>
  );
}
