import { Article } from '../../models/Article';
import { ApplicationState } from '../main/types';

export const selectCurrentlyCreatedArticle = (state: any): Article =>
  state.articles.currentlyDraftedArticle;

export const selectOwnArticles = (state: ApplicationState): Article[] =>
  state.articles.ownArticles;

export const selectOwnArticleDrafts = (state: ApplicationState): Article[] => {
  return state.articles.ownArticles.filter((value: Article) => {
    return value.isDraft === true;
  });
};
