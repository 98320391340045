import applicationConfig from '../config/application_config';

interface AuthConfig {
  issuer: string;
  clientId: string;
  redirectUri: string;
  pkce: boolean;
  scopes: string[];
}

const authConfig: AuthConfig = {
  clientId:
    applicationConfig.AUTH_CONFIG.OKTA_CLIENT_ID || 'NOT DEFINED IN EVIRONMENT',
  redirectUri:
    applicationConfig.AUTH_CONFIG.OKTA_REDIRECT_URI ||
    'NOT DEFINED IN ENVIRONMENT',
  issuer:
    applicationConfig.AUTH_CONFIG.OKTA_ISSUER || 'NOT DEFINED IN ENVIRONMENT',
  pkce: true,
  scopes: ['openid', 'profile', 'offline_access'],
};

export default authConfig;
