// src/Home.js

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import { withOktaAuth } from '@okta/okta-react';
import { FormikHelpers, Formik, FormikProps, Form, Field } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, compose } from 'redux';
import { Family } from '../../../common/models/Family';
import { Image } from '../../../common/models/Image';
import { User } from '../../../common/models/User';
import { UserSettings } from '../../../common/models/UserSettings';
import { selectFirstLoadFinished } from '../../../common/redux/app/app.selectors';
import {
  fetchOwnFamiliesRequest,
  switchActiveFamily,
} from '../../../common/redux/families/family.actions';
import {
  selectCurrentlyActiveFamily,
  selectDefaultActiveFamilyForUser,
  selectFamiliesForActiveUser,
} from '../../../common/redux/families/family.selectors';
import { FamilyDictionary } from '../../../common/redux/families/types';
import { ApplicationState } from '../../../common/redux/main/types';
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../common/redux/ui/ui.actions';
import {
  UpdateUserSettingsDto,
  UserProfile,
} from '../../../common/redux/users/types';
import {
  fetchOwnProfileRequest,
  fetchUserSettingsRequest,
  updateUserSettingsRequest,
} from '../../../common/redux/users/user.actions';
import {
  selectLoggedInUser,
  selectProfileImage,
  selectDefaultAuthorPseudonymForActiveUser,
  selectUserSettings,
  selectUserProfile,
} from '../../../common/redux/users/user.selectors';
import colors from '../../00_Constants/colors';
import { FZAutoSavingForm } from '../../01_atoms/FZAutoSavingForm/FZAutoSavingForm';
import FZSelectInputField from '../../01_atoms/FZSelectInputField/FZSelectInputField';
import FZSwitch from '../../01_atoms/FZSwitch/FZSwitch';
import FZProfilePictureWidget from '../../02_molecules/FZProfilePictureWidget/FZProfilePictureWidget';
import FZAppBar from '../../03_organisms/FZAppBar/FZAppBar';
import FZSettingsCard from '../../03_organisms/FZSettingsCard/FZSettingsCard';
import FZSettingsCardItem from '../../03_organisms/FZSettingsCard/FZSettingsCardItem';
import { withHooks, WithHooksProps } from '../../HOCs/withHooks';

const styles = (theme: Theme) =>
  createStyles({
    profilePictureWidgetContainerStyle: {
      backgroundColor: '#FFFFFF',
      paddingTop: 128,
    },

    profilePictureAvatarContainerStyle: {
      maxWidth: '100%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    profilePictureImageContainerStyle: {
      width: 200,
      height: 200,
    },

    profilePicture: {
      objectFit: 'cover',
    },

    usernameLabel: {
      paddingLeft: 8,
      paddingRight: 8,
      marginTop: 32,
      marginBottom: 24,
      color: '#000000C9',
      fontSize: '1.5rem',
      letterSpacing: '3.6px',
      textAlign: 'center',
    },

    familynameLabel: {
      textTransform: 'uppercase',
    },

    accordionRoot: {
      boxShadow: 'none',
      border: 'none',

      backgroundColor: '#F9F9F9',

      '&::before': {
        backgroundColor: '#F9F9F9',
      },

      '&.MuiPaper-elevation1': {
        boxShadow: 'none',
        border: 'none',
      },
    },

    accordionContent: {
      flexGrow: 0,
    },

    profilePictureGrid: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    defaultActiveFamilySelector: {
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        width: '75%',
      },
    },
  });

type Props = DispatchProp<AnyAction> &
  WithHooksProps &
  RouteComponentProps<any> &
  WithStyles<typeof styles> &
  WithTranslation & {
    firstAppLoadFinished: boolean;
    auth: any;
    history: any;
    isFetchingArticles: boolean;
    profileImage: Image | null;
    loggedInUser: User | null;
    defaultAuthorPseudonym: string;
    defaultActiveFamily: Family | null;
    t?: any;
    currentlyActiveFamily: Family | null;
    loggedInUserProfile: UserProfile | null;
    ownFamilies: FamilyDictionary;
    userSettings: UserSettings;
  };

type State = {
  accordionExpanded: boolean;
};

type SettingsFormValues = {
  defaultActiveFamilyUuid: string;
  endOfReleaseCycleReminderNotificationEnabled: boolean;
};

class ProfileScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      accordionExpanded: false,
    };

    this.handleChangeUserSettingSubmitted = this.handleChangeUserSettingSubmitted.bind(
      this,
    );
    this.validateSettingsFormSync = this.validateSettingsFormSync.bind(this);
  }

  componentDidMount() {
    const { matomoAnalyticsTracker, dispatch } = this.props;

    matomoAnalyticsTracker.trackPageView({
      documentTitle: 'Profile Screen', // optional
    });

    dispatch(fetchOwnFamiliesRequest());
    dispatch(fetchOwnProfileRequest());
    dispatch(fetchUserSettingsRequest());
  }

  /**
   * Handle the selection of a new family that shall be the "active" one.
   */
  handleSwitchFamilyButtonClicked = (familyToSwitchTo: Family) => {
    const { dispatch } = this.props;
    this.setState({ accordionExpanded: false });
    dispatch(switchActiveFamily(familyToSwitchTo));
  };

  /**
   * Handle the submission of the form embedded in this screen
   * that handles updates to personal settings
   */
  handleChangeUserSettingSubmitted = (
    newSettings: UpdateUserSettingsDto,
    { setSubmitting }: FormikHelpers<any>,
  ) => {
    const { dispatch, t } = this.props;

    dispatch(
      updateUserSettingsRequest(
        newSettings,
        () => {
          dispatch(
            showSuccessSnackbar(
              t('profileScreen-update-settings-success'),
              true,
            ),
          );
        },
        () => {
          dispatch(
            showErrorSnackbar(t('profileScreen-update-settings-error'), true),
          );
        },
      ),
    );
  };

  /**
   * Handle a change of the <open> state of the accordion that is used as a family selector.
   */
  handleAccordionExpandedChange = () => {
    this.setState({ accordionExpanded: !this.state.accordionExpanded });
  };

  validateSettingsFormSync = (values: Partial<SettingsFormValues>) => {
    const { userSettings } = this.props;

    let formValid = false;

    if (
      values.endOfReleaseCycleReminderNotificationEnabled !==
        userSettings?.releaseCycleReminderNotificationEnabled ||
      values.defaultActiveFamilyUuid !== userSettings?.defaultActiveFamily?.uuid
    ) {
      formValid = true;
    }

    return formValid;
  };

  render() {
    const {
      classes,
      profileImage,
      t,
      loggedInUser,
      defaultAuthorPseudonym,
      defaultActiveFamily,
      currentlyActiveFamily,
      ownFamilies,
      history,
      userSettings,
      appConfig,
      loggedInUserProfile,
    } = this.props;

    const { accordionExpanded } = this.state;

    return (
      <React.Fragment>
        <FZAppBar activeTab={3}></FZAppBar>
        <Container
          style={{
            marginBottom: 70,
            paddingTop: 33,
          }}
        >
          <Grid container>
            <Grid item xs={12} className={classes.profilePictureGrid}>
              <FZProfilePictureWidget
                profilePictureUuid={profileImage?.uuid}
                editable={true}
                customClasses={{
                  container: classes.profilePictureAvatarContainerStyle,
                  imageContainer: classes.profilePictureImageContainerStyle,
                  image: classes.profilePicture,
                }}
              ></FZProfilePictureWidget>

              <Typography variant={'body1'} className={classes.usernameLabel}>
                {`${loggedInUserProfile?.displayName}`.toUpperCase()}
              </Typography>

              <Accordion
                classes={{ root: classes.accordionRoot }}
                expanded={accordionExpanded}
                onChange={this.handleAccordionExpandedChange}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreRoundedIcon />}
                  classes={{
                    root: classes.accordionRoot,
                    content: classes.accordionContent,
                  }}
                >
                  <Typography
                    variant={'body1'}
                    className={classes.familynameLabel}
                  >
                    {currentlyActiveFamily?.name ||
                      t(
                        'profileScreen-familySelector-no-family-selected-label',
                      )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {ownFamilies && (
                    <List aria-label="main mailbox folders" component="nav">
                      <ListItem
                        key={null}
                        button
                        selected={currentlyActiveFamily == null}
                        onClick={() => {
                          this.handleSwitchFamilyButtonClicked(null);
                        }}
                      >
                        {/*  <ListItemIcon>
                          <GroupRoundedIcon />
                        </ListItemIcon> */}
                        <ListItemText
                          primary={t(
                            'profileScreen-familySelector-no-family-selected-label',
                          )}
                        />
                      </ListItem>

                      {Object.keys(ownFamilies).map((familyUuid: string) => {
                        return (
                          <ListItem
                            key={familyUuid}
                            button
                            selected={
                              currentlyActiveFamily?.uuid === familyUuid
                            }
                            onClick={() => {
                              this.handleSwitchFamilyButtonClicked(
                                ownFamilies[familyUuid],
                              );
                            }}
                          >
                            <ListItemIcon>
                              <GroupRoundedIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={ownFamilies[familyUuid].name}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid md={2} item></Grid>
            <Grid item xs={12} md={8}>
              <Formik
                initialTouched={{
                  defaultActiveFamilyUuid: false,
                  endOfReleaseCycleReminderNotificationEnabled: false,
                }}
                initialValues={{
                  defaultActiveFamilyUuid:
                    userSettings?.defaultActiveFamily?.uuid,

                  endOfReleaseCycleReminderNotificationEnabled:
                    userSettings?.releaseCycleReminderNotificationEnabled,
                }}
                validate={(values: SettingsFormValues) => {
                  const errors: Partial<SettingsFormValues> = {};

                  return errors;
                }}
                onSubmit={(values, helpers) => {
                  const formValid = this.validateSettingsFormSync(values);

                  if (formValid) {
                    const newSettings: UpdateUserSettingsDto = {
                      releaseCycleReminderNotificationEnabled:
                        values.endOfReleaseCycleReminderNotificationEnabled,
                      defaultAuthorPseudonym:
                        userSettings.defaultAuthorPseudonym,
                      defaultActiveFamilyUuid: values.defaultActiveFamilyUuid,
                    };

                    this.handleChangeUserSettingSubmitted(newSettings, helpers);
                  }
                  helpers.setSubmitting(false);
                }}
              >
                {(formikProps: FormikProps<SettingsFormValues>) => {
                  return (
                    <Form>
                      <FZAutoSavingForm
                        debounceMs={500}
                        loadingMessage={t(
                          'profileScreen-saving-settings-message',
                        )}
                        shouldSave={() => {
                          return true;
                          /*
                          return (
                            formikProps.touched.defaultActiveFamilyUuid ||
                            formikProps.touched
                              .endOfReleaseCycleReminderNotificationEnabled
                          ); */
                        }}
                      />
                      <FZSettingsCard
                        headline={t(
                          'settingsWidget-personal-settings-headline',
                        )}
                        subheadline={t(
                          'settingsWidget-personal-settings-subheadline',
                        )}
                        keyColor={colors.quartiary}
                      >
                        <FZSettingsCardItem
                          label={t(
                            'settingsWidget-personal-settings-displayName',
                          )}
                          tooltip={t(
                            'settingsWidget-personal-settings-displayName-tooltip',
                          )}
                          value={loggedInUserProfile?.displayName}
                          onSecondaryAction={() => {
                            history.push('/profile/settings/edit-displayName');
                          }}
                          secondaryActionIcon={<NavigateNextRoundedIcon />}
                          elementIsButton
                          disabled={
                            !appConfig.FEATURE_FLAGS.USER
                              .REACT_APP_FEATURE_FLAG_EDIT_DISPLAY_NAME_ENABLED
                          }
                        ></FZSettingsCardItem>

                        <FZSettingsCardItem
                          label={t('settingsWidget-personal-settings-email')}
                          tooltip={t(
                            'settingsWidget-personal-settings-email-tooltip',
                          )}
                          value={loggedInUser?.email}
                          onSecondaryAction={() => {
                            history.push('/profile/settings/edit-email');
                          }}
                          secondaryActionIcon={<NavigateNextRoundedIcon />}
                          elementIsButton
                          disabled={
                            !appConfig.FEATURE_FLAGS.USER
                              .REACT_APP_FEATURE_FLAG_UPDATE_PRIMARY_EMAIL_ENABLED
                          }
                        ></FZSettingsCardItem>
                        {
                          <FZSettingsCardItem
                            label={t(
                              'settingsWidget-personal-settings-password',
                            )}
                            tooltip={t(
                              'settingsWidget-personal-settings-password-tooltip',
                            )}
                            value={'*********'}
                            onSecondaryAction={() => {
                              history.push('/profile/settings/edit-password');
                            }}
                            secondaryActionIcon={<NavigateNextRoundedIcon />}
                            elementIsButton
                            disabled={
                              !appConfig.FEATURE_FLAGS.USER
                                .REACT_APP_FEATURE_FLAG_UPDATE_PASSWORD_ENABLED
                            }
                          ></FZSettingsCardItem>
                        }
                        <FZSettingsCardItem
                          label={t('settingsWidget-personal-settings-address')}
                          value={t(
                            'settingsWidget-personal-settings-address-not-yet-specified-label',
                          )}
                          onSecondaryAction={() => {
                            history.push('/profile/settings/edit-adress');
                          }}
                          secondaryActionIcon={<NavigateNextRoundedIcon />}
                          elementIsButton
                          disabled={
                            !appConfig.FEATURE_FLAGS.USER
                              .REACT_APP_FEATURE_FLAG_EDIT_ADRESS_ENABLED
                          }
                        ></FZSettingsCardItem>
                      </FZSettingsCard>
                      <FZSettingsCard
                        headline={t('settingsWidget-general-settings-headline')}
                        subheadline={t(
                          'settingsWidget-general-settings-subheadline',
                        )}
                        keyColor={colors.quartiary}
                      >
                        <FZSettingsCardItem
                          label={t(
                            'settingsWidget-general-settings-defaultAuthorPseudonym-label',
                          )}
                          tooltip={t(
                            'settingsWidget-general-settings-defaultAuthorPseudonym-tooltip',
                          )}
                          value={defaultAuthorPseudonym}
                          secondaryActionIcon={<NavigateNextRoundedIcon />}
                          onSecondaryAction={() => {
                            history.push('/profile/settings/edit-authorName');
                          }}
                          disabled={false}
                          elementIsButton
                        ></FZSettingsCardItem>
                        <FZSettingsCardItem
                          label={t(
                            'settingsWidget-general-settings-releaseCycleEndReminder-label',
                          )}
                          value={
                            <span style={{ marginRight: 32 }}>
                              {t(
                                'settingsWidget-general-settings-releaseCycleEndReminder-value',
                              )}
                            </span>
                          }
                          tooltip={t(
                            'settingsWidget-general-settings-releaseCycleReminder-tooltip',
                          )}
                          secondaryActionElement={
                            <Field
                              name="endOfReleaseCycleReminderNotificationEnabled"
                              accentColor={colors.quartiary}
                              component={FZSwitch}
                              checked={
                                formikProps.values
                                  .endOfReleaseCycleReminderNotificationEnabled
                              }
                              type="checkbox"
                              inputProps={{
                                id:
                                  'endOfReleaseCycleReminderNotificationEnabled',
                              }}
                              disabled={true}
                            ></Field>
                          }
                          elementIsButton={false}
                          disabled={true}
                        ></FZSettingsCardItem>

                        <FZSettingsCardItem
                          label={t(
                            'settingsWidget-general-settings-defaultActiveFamily-label',
                          )}
                          disabled={
                            !appConfig.FEATURE_FLAGS.USER
                              .REACT_APP_FEATURE_FLAG_UPDATE_DEFAULT_ACTIVE_FAMILY_ENABLED
                          }
                          tooltip={t(
                            'settingsWidget-general-settings-defaultActiveFamily-tooltip',
                          )}
                          value={
                            <Field
                              className={classes.defaultActiveFamilySelector}
                              name="defaultActiveFamilyUuid"
                              component={FZSelectInputField}
                              color={colors.white}
                              disableUnderline
                              inputProps={{
                                id: 'defaultActiveFamilyUuid',
                              }}
                              displayEmpty={true}
                              placeholder={t(
                                'profileScreen-defaultActiveFamilySelector-no-family-created-yet',
                              )}
                              defaultValue={defaultActiveFamily?.uuid || null}
                              disabled={
                                !appConfig.FEATURE_FLAGS.USER
                                  .REACT_APP_FEATURE_FLAG_UPDATE_DEFAULT_ACTIVE_FAMILY_ENABLED
                              }
                            >
                              {ownFamilies &&
                              Object.keys(ownFamilies).length > 0 ? (
                                [
                                  <MenuItem
                                    key={
                                      'profileScreen-defaultActiveFamilySelector-no-family-selected-label'
                                    }
                                    value={null}
                                    selected={defaultActiveFamily === null}
                                  >
                                    <Typography variant="body2">
                                      {t(
                                        'profileScreen-defaultActiveFamilySelector-no-family-selected-label',
                                      )}
                                    </Typography>
                                  </MenuItem>,
                                ].concat(
                                  Object.keys(ownFamilies).map(
                                    (familyUuid: string) => {
                                      return (
                                        <MenuItem
                                          key={familyUuid}
                                          value={familyUuid}
                                          selected={
                                            ownFamilies[familyUuid].uuid ===
                                            familyUuid
                                          }
                                        >
                                          <Typography variant="body2">
                                            {ownFamilies[familyUuid].name}
                                          </Typography>
                                        </MenuItem>
                                      );
                                    },
                                  ),
                                )
                              ) : (
                                <MenuItem value={null} selected={false}>
                                  <Typography variant="body2">
                                    {t(
                                      'profileScreen-defaultActiveFamilySelector-no-families-created-yet',
                                    )}
                                  </Typography>
                                </MenuItem>
                              )}
                            </Field>
                          }
                          elementIsButton={false}
                        ></FZSettingsCardItem>
                      </FZSettingsCard>
                      {/*   <FZSettingsCard
                        headline={t(
                          'settingsWidget-financial-settings-headline',
                        )}
                        subheadline={t(
                          'settingsWidget-financial-settings-subheadline',
                        )}
                        keyColor={colors.quartiary}
                      >
                        <FZSettingsCardItem
                          label={t(
                            'settingWidget-financial-settings-payment-method',
                          )}
                          value={t('generic-not-specified')}
                          elementIsButton
                          disabled={
                            !appConfig.FEATURE_FLAGS.USER
                              .REACT_APP_FEATURE_FLAG_UPDATE_PAYMENT_METHOD_ENABLED
                          }
                        ></FZSettingsCardItem>
                        <FZSettingsCardItem
                          label={t(
                            'settingsWidget-financial-settings-kind-of-subscription',
                          )}
                          value={t('generic-not-specified')}
                          elementIsButton
                          disabled={
                            !appConfig.FEATURE_FLAGS.USER
                              .REACT_APP_FEATURE_FLAG_UPDATE_SUBSCRIPTION_ENABLED
                          }
                        ></FZSettingsCardItem>
                      </FZSettingsCard> */}
                      <FZSettingsCard
                        headline={t('settingsWidget-info-headline')}
                        subheadline={t('settingsWidget-info-subheadline')}
                        keyColor={colors.quartiary}
                      >
                        <FZSettingsCardItem
                          label={t(
                            'settingsWidget-info-data-protection-agreement',
                          )}
                          secondaryActionIcon={<NavigateNextRoundedIcon />}
                          onSecondaryAction={() => {
                            history.push('/data-processing-agreement');
                          }}
                          elementIsButton
                          disabled
                        ></FZSettingsCardItem>

                        <FZSettingsCardItem
                          label={t('settingsWidget-info-terms-of-service')}
                          secondaryActionIcon={<NavigateNextRoundedIcon />}
                          onSecondaryAction={() => {
                            history.push('/terms-of-service');
                          }}
                          elementIsButton
                          disabled
                        ></FZSettingsCardItem>
                        <FZSettingsCardItem
                          label={t('settingsWidget-info-imprint')}
                          secondaryActionIcon={<NavigateNextRoundedIcon />}
                          onSecondaryAction={() => {
                            history.push('/imprint');
                          }}
                          elementIsButton
                          disabled
                        ></FZSettingsCardItem>
                        <FZSettingsCardItem
                          label={t('settingsWidget-info-contact')}
                          secondaryActionIcon={<NavigateNextRoundedIcon />}
                          onSecondaryAction={() => {
                            history.push('/contact');
                          }}
                          elementIsButton
                          disabled
                        ></FZSettingsCardItem>
                      </FZSettingsCard>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    firstAppLoadFinished: selectFirstLoadFinished(state),
    profileImage: selectProfileImage(state),
    loggedInUser: selectLoggedInUser(state),
    loggedInUserProfile: selectUserProfile(state),
    defaultAuthorPseudonym: selectDefaultAuthorPseudonymForActiveUser(state),
    defaultActiveFamily: selectDefaultActiveFamilyForUser(state),
    userSettings: selectUserSettings(state),
    currentlyActiveFamily: selectCurrentlyActiveFamily(state),
    ownFamilies: selectFamiliesForActiveUser(state),
  };
};

export default compose(
  connect(mapStateToProps),
  withOktaAuth,
  withRouter,
  withHooks,
  withStyles(styles),
  withTranslation(),
)(ProfileScreen);
