import {
  AppBar,
  Divider,
  LinearProgress,
  ListItemIcon,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AnnouncementRoundedIcon from '@material-ui/icons/AnnouncementRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import { StyleRules } from '@material-ui/styles/withStyles';
import React, { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, compose } from 'redux';
import logo from '../../../assets/images/FZ_Logo.jpg';
import {
  selectIsLoading,
  selectLoadingMessage,
} from '../../../common/redux/app/app.selectors';
import { selectNumberOfUnseenFeedItems } from '../../../common/redux/feed/feed.selectors';
import { logoutRequest } from '../../../common/redux/login/login.actions';
import { ActionCallbacks } from '../../../common/redux/main/types';
import { selectNavbarOptions } from '../../../common/redux/ui/ui.selectors';
import { selectUserProfile } from '../../../common/redux/users/user.selectors';
import colors from '../../00_Constants/colors';
import fonts from '../../00_Constants/fonts';
import FZBadge from '../../01_atoms/FZBadge/FZBadge';
import AvatarMenu from '../../02_molecules/FZAvatarMenu/AvatarMenu';
import FZLanguageSwitcher from '../../02_molecules/FZLanguageSwitcher/FZLanguageSwitcher';
import { withServices, WithServicesProps } from '../../HOCs/withServices';
import { NavbarOptions } from './types';

const styles: (theme: Theme) => StyleRules<object> = theme =>
  createStyles({
    [theme.breakpoints.down('sm')]: {
      serviceName: {
        //display: 'none',
      },

      topNavigationWrapper: {
        display: 'none',
      },

      avatarMenuWrapper: {
        //display: 'none',
      },
    },

    toolBarRoot: {
      minHeight: 63,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    serviceName: {
      '&:hover': {
        cursor: 'pointer',
      },
    },

    avatarMenuWrapper: {
      //borderColor: colors.quartiary,
      //borderStyle: 'solid',
      //borderWidth: '1px',
      //marginLeft: 32,
      /*    display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start', */
    },

    mobileBottomNavigationRoot: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      zIndex: 10,
      height: 72,
      borderTop: '1px solid #DEDEDE',
    },

    bottomNavigationLabel0: {
      color: colors.tertiary,
    },

    bottomNavigationItemSelected0: {
      color: colors.tertiary,
    },

    bottomNavigationLabel1: {
      color: colors.accent,
    },

    bottomNavigationItemSelected1: {
      color: colors.accent,
    },

    bottomNavigationLabel2: {
      color: colors.secondary,
    },

    bottomNavigationItemSelected2: {
      color: colors.secondary,
    },

    bottomNavigationLabel3: {
      color: colors.quartiary,
    },

    bottomNavigationItemSelected3: {
      color: colors.quartiary,
    },

    tabRoot: {
      paddingTop: '12px',
      zIndex: 6,
    },

    tabWrapper0: {
      paddingBottom: 11,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: fonts.FONT_FAMILY_REGULAR,
      letterSpacing: '1.78px',
      fontWeight: 'bold',
      fontSize: fonts.FONT_SIZE_EXTRA_LARGE,
      color: colors.tertiary,
      minWidth: 200,
    },

    tabWrapperSelected0: {
      paddingBottom: 11,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: fonts.FONT_FAMILY_BOLD,
      letterSpacing: '1.78px',
      fontSize: fonts.FONT_SIZE_EXTRA_LARGE,
      color: colors.tertiary,
      minWidth: 200,
    },

    tabWrapper1: {
      paddingBottom: 11,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: fonts.FONT_FAMILY_REGULAR,
      letterSpacing: '1.78px',
      fontWeight: 'bold',
      fontSize: fonts.FONT_SIZE_EXTRA_LARGE,
      color: theme.palette.secondary.main,
      minWidth: 200,
    },

    tabWrapperSelected1: {
      paddingBottom: 11,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: fonts.FONT_FAMILY_BOLD,
      letterSpacing: '1.78px',
      fontSize: fonts.FONT_SIZE_EXTRA_LARGE,
      color: theme.palette.secondary.main,
      minWidth: 200,
    },

    tabWrapper2: {
      paddingBottom: 11,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: fonts.FONT_FAMILY_REGULAR,
      letterSpacing: '1.78px',
      fontWeight: 'bold',
      fontSize: fonts.FONT_SIZE_EXTRA_LARGE,
      color: colors.secondary,
      minWidth: 200,
    },

    tabWrapperSelected2: {
      paddingBottom: 11,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: fonts.FONT_FAMILY_BOLD,
      letterSpacing: '1.78px',
      fontSize: fonts.FONT_SIZE_EXTRA_LARGE,
      color: colors.secondary,
      minWidth: 200,
    },

    tabWrapper3: {
      display: 'hidden',
    },

    tabIndicator0: {
      backgroundColor: colors.tertiary,
      height: '4px',
    },

    tabIndicator1: {
      backgroundColor: colors.accent,
      height: '4px',
    },

    tabIndicator2: {
      backgroundColor: colors.secondary,
      height: '4px',
    },

    signedInAsLink: {
      '&:hover': {
        cursor: 'pointer',
      },
    },

    tabLabel: {},

    tabIcon: {},

    activeInFamilyLink: {
      //textDecoration: 'underline',
    },

    pushRight: {
      flex: 1,
    },

    avatarImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    avatarMenuItem: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 16,
      paddingRight: 16,
    },

    usernameLabel: {
      marginTop: 8,
      marginBottom: 8,
      marginRight: 4,
      marginLeft: 4,
      color: '#000000C9',
      fontSize: '16px',
      letterSpacing: '1.8px',
      alignSelf: 'center',
    },
  });

interface Props
  extends RouteComponentProps<any>,
    DispatchProp<AnyAction>,
    WithTranslation,
    WithServicesProps {
  authService: any;
  authState: any;
  history: any;
  classes?: any;
  width?: any;

  appIsWorking: boolean;
  appIsWorkingMessage: string;
  avatarImageSource: string;
  loggedInUserFirstName: string;
  currentlyActiveFamilyName: string;
  shouldBlockNavigation: boolean;
  navbarOptions: NavbarOptions;

  navbarLeftContent?: JSX.Element;
  hideTopNavigation?: boolean;
  activeTab: 0 | 1 | 2 | 3;

  numberOfUnseenFeedItems: number;

  // HoC Procs
  t?: any;
}
interface State {
  avatarMenuOpen: boolean;
  activeTab: number;
}

class FZAppBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.handleAvatarMenuClose = this.handleAvatarMenuClose.bind(this);
    this.handleAvatarIconClicked = this.handleAvatarIconClicked.bind(this);
    this.handleTabChanged = this.handleTabChanged.bind(this);

    this.state = {
      avatarMenuOpen: false,
      activeTab: this.props.activeTab,
    };
  }

  handleAvatarMenuClose() {
    this.setState({ avatarMenuOpen: false });
  }

  handleAvatarIconClicked() {
    //this.props.history.push('/profile');
    this.setState({ avatarMenuOpen: true });
  }

  handleTabChanged(event: ChangeEvent<{}>, newActiveTabIndex: number) {
    if (!this.props.shouldBlockNavigation) {
      this.setState({ activeTab: newActiveTabIndex });
    }
  }

  componentDidMount() {
    //this.setActiveTabAccordingToBrowserLocation();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    /*const locationChanged = this.props.location !== prevProps.location;

    if (locationChanged) {
      this.setActiveTabAccordingToBrowserLocation();
    } */
  }

  setActiveTabAccordingToBrowserLocation() {
    const pathName: string = this.props.history.location.pathname;

    if (pathName.match('/news')) {
      this.setState({ activeTab: 0 });
    } else if (pathName.match('/home')) {
      this.setState({ activeTab: 1 });
    } else if (pathName.match('/family')) {
      this.setState({ activeTab: 2 });
    } else if (pathName.match('/profile')) {
      this.setState({ activeTab: 3 });
    }
  }

  render() {
    const {
      classes,
      width,
      navbarOptions,
      t,
      history,
      loggedInUserFirstName,

      navbarLeftContent,
      appIsWorking,
      hideTopNavigation,
      numberOfUnseenFeedItems,
      dispatch,
    } = this.props;

    const viewportIsMobile = isWidthDown('sm', width, true);

    return (
      <>
        <AppBar
          position="sticky"
          style={{ justifyContent: 'space-between' }}
          elevation={2}
        >
          {appIsWorking && (
            <LinearProgress
              color="secondary"
              style={{
                width: '100%',
                zIndex: 5,
                position: 'absolute',
                height: 4,
              }}
            />
          )}
          <Toolbar classes={{ regular: classes.toolBarRoot }}>
            {viewportIsMobile && navbarOptions?.displayBackIcon && (
              <ArrowBackRoundedIcon
                style={{ marginRight: 16 }}
                onClick={() => {
                  history.goBack(1);
                  //  history.push('home');
                }}
              ></ArrowBackRoundedIcon>
            )}

            {/**
             * Display the brand and active family when no explicit content for the left area of the navbar is set.
             */}
            {!navbarLeftContent && (
              <>
                <img
                  alt="logo"
                  src={logo}
                  style={{
                    borderRadius: 8,
                    width: 50,
                    height: 50,
                    marginRight: 8,
                  }}
                ></img>

                <span
                  style={{
                    flex: 1,
                    verticalAlign: 'middle',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Typography
                    variant="h1"
                    style={{ paddingTop: 7 }}
                    className={classes.serviceName}
                    onClick={() => {
                      history.push('/home');
                    }}
                  >
                    {t('appBar-service-name-label')}
                  </Typography>

                  {this.props.currentlyActiveFamilyName ? (
                    <Typography
                      variant="body2"
                      style={{ marginTop: '-13px', whiteSpace: 'nowrap' }}
                    >
                      {`${t('appBar-activeInFamily-label')}
    ${this.props.currentlyActiveFamilyName}`}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      className={classes.activeInFamilyLink}
                      style={{ marginTop: '-13px' }}
                      onClick={() => {
                        // this.props.history.push("/family");
                      }}
                    >
                      {`${t('appBar-notYetActiveInFamily-label')}`}
                    </Typography>
                  )}
                </span>
              </>
            )}

            {navbarLeftContent}

            <div className={classes.pushRight}></div>

            {!hideTopNavigation && (
              <div className={classes.topNavigationWrapper}>
                <Tabs
                  value={this.state.activeTab}
                  onChange={this.handleTabChanged}
                  classes={{
                    root: classes.tabRoot,
                    indicator: classes[`tabIndicator${this.state.activeTab}`],
                  }}
                >
                  <Tab
                    label={
                      <FZBadge
                        badgeContent={numberOfUnseenFeedItems}
                        backgroundColor={colors.tertiary}
                        contentColor={colors.textStrong}
                        borderColor={colors.tertiary}
                      >
                        {t('appBar-news-label')}{' '}
                      </FZBadge>
                    }
                    classes={{
                      selected: classes.tabWrapperSelected0,
                    }}
                    className={classes.tabWrapper0}
                    component={Link}
                    to="/news"
                    value={0}
                    selected={this.state.activeTab === 0}
                  />

                  <Tab
                    label={t('appBar-articles-label')}
                    classes={{
                      selected: classes.tabWrapperSelected1,
                    }}
                    className={classes.tabWrapper1}
                    component={Link}
                    to="/home"
                    value={1}
                    selected={this.state.activeTab === 1}
                  />
                  <Tab
                    label={t('appBar-family-label')}
                    classes={{
                      //wrapper: classes.tabWrapper2,
                      selected: classes.tabWrapperSelected2,
                    }}
                    component={Link}
                    className={classes.tabWrapper2}
                    to="/family"
                    value={2}
                    selected={this.state.activeTab === 2}
                  />
                </Tabs>
              </div>
            )}

            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 12,
                paddingRight: 12,
              }}
            >
              <AvatarMenu
                avatarMenuOpen={this.state.avatarMenuOpen}
                onAvatarMenuClose={this.handleAvatarMenuClose}
                onAvatarIconClick={this.handleAvatarIconClicked}
                avatarImageSource={this.props.avatarImageSource}
                customClasses={{
                  imageContainer: classes.avatarMenuWrapper,
                  image: classes.avatarImage,
                }}
              >
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    variant={'body1'}
                    className={classes.usernameLabel}
                  >
                    {loggedInUserFirstName ||
                      t('appBar-no-displayname-given-placeholder')}
                  </Typography>
                  <Divider
                    style={{ width: '80%', alignSelf: 'center' }}
                  ></Divider>
                </span>

                <MenuItem
                  onClick={() => {
                    history.push('/profile');
                    this.setState({ avatarMenuOpen: false });
                  }}
                  className={classes.avatarMenuItem}
                >
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  {t('appBar-profile-label')}
                </MenuItem>
                {/*     <Divider style={{ width: '80%' }}></Divider> */}
                <MenuItem
                  onClick={() => {
                    this.setState({ avatarMenuOpen: false });

                    const callbacks: ActionCallbacks = {
                      successCallback: () => {
                        history.push('/');
                      },
                      errorCallback: null,
                    };

                    dispatch(logoutRequest(callbacks));
                  }}
                  className={classes.avatarMenuItem}
                >
                  <ListItemIcon>
                    <ExitToAppRoundedIcon />
                  </ListItemIcon>
                  {t('appBar-sign-out-label')}
                </MenuItem>

                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    variant={'body1'}
                    className={classes.usernameLabel}
                  >
                    {t('appBar-contextMenu-languageSettings-label')}
                  </Typography>

                  <Divider style={{ width: '80%', margin: '0 auto' }}></Divider>
                </span>
                <MenuItem>
                  <FZLanguageSwitcher />
                </MenuItem>
              </AvatarMenu>
            </span>
          </Toolbar>
        </AppBar>

        {/** TODO: Make the top navbar more configurable, so that it wont just "hide" bottom navigation when navbarLeftContent is set, but explicitly allows to configure the visiblity of "back" options and such. */}
        {viewportIsMobile &&
          !navbarOptions?.displayBackIcon &&
          !navbarLeftContent && (
            <BottomNavigation
              classes={{
                root: classes.mobileBottomNavigationRoot,
              }}
              value={this.state.activeTab}
              onChange={this.handleTabChanged}
              showLabels
            >
              <BottomNavigationAction
                label={t('appBar-news-label')}
                onClick={() => {
                  history.push('/news');
                }}
                icon={
                  <FZBadge
                    badgeContent={numberOfUnseenFeedItems}
                    backgroundColor={colors.tertiary}
                    contentColor={colors.textStrong}
                    borderColor={colors.tertiary}
                  >
                    <AnnouncementRoundedIcon
                      className={
                        this.state.activeTab === 0
                          ? classes.bottomNavigationItemSelected0
                          : ''
                      }
                    />
                  </FZBadge>
                }
                classes={{
                  selected:
                    classes[
                      `bottomNavigationItemSelected${this.state.activeTab}`
                    ],
                }}
              />
              <BottomNavigationAction
                label={t('appBar-articles-label')}
                onClick={() => {
                  history.push('/home');
                }}
                icon={
                  <CreateRoundedIcon
                    className={
                      this.state.activeTab === 1
                        ? classes.bottomNavigationItemSelected1
                        : ''
                    }
                  />
                }
                classes={{
                  selected:
                    classes[
                      `bottomNavigationItemSelected${this.state.activeTab}`
                    ],
                }}
              />
              <BottomNavigationAction
                label={t('appBar-family-label')}
                onClick={() => {
                  history.push('/family');
                }}
                icon={
                  <FavoriteRoundedIcon
                    className={
                      this.state.activeTab === 2
                        ? classes.bottomNavigationItemSelected2
                        : ''
                    }
                  />
                }
                classes={{
                  selected:
                    classes[
                      `bottomNavigationItemSelected${this.state.activeTab}`
                    ],
                }}
              />
              <BottomNavigationAction
                label={t('appBar-profile-label')}
                onClick={() => {
                  history.push('/profile');
                }}
                icon={
                  <AccountCircleIcon
                    className={
                      this.state.activeTab === 3
                        ? classes.bottomNavigationItemSelected3
                        : ''
                    }
                  />
                }
                classes={{
                  selected:
                    classes[
                      `bottomNavigationItemSelected${this.state.activeTab}`
                    ],
                }}
              />
            </BottomNavigation>
          )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    appIsWorking: selectIsLoading(state),
    appIsWorkingMessage: selectLoadingMessage(state),
    avatarImageSource: state.user.userProfile?.profileImage?.uuid,
    loggedInUserFirstName: selectUserProfile(state)?.displayName,
    currentlyActiveFamilyName: state.families.currentlyActiveFamily?.name,
    shouldBlockNavigation: state.ui.shouldBlockNavigation,
    navbarOptions: selectNavbarOptions(state),
    numberOfUnseenFeedItems: selectNumberOfUnseenFeedItems(state),
  };
};

const composedComponent = compose(
  connect(mapStateToProps),
  withWidth(),
  withRouter,
  withServices,
  withTranslation(),
  withStyles(styles),
)(FZAppBar);

export default composedComponent as React.ComponentType<any>;
