/**
 * A 'smart' component that is connected to redux
 * and able to collect a set of email adresses and a written invitation
 * to join the family paper, consisting of a title and a message.
 */

import { FormHelperText, makeStyles, Theme } from '@material-ui/core';
import SentimentVerySatisfiedRoundedIcon from '@material-ui/icons/SentimentVerySatisfiedRounded';
import { Formik, FormikHelpers } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  addReceiverToMultiReceiverInvitation,
  removeReceiverFromMultiReceiverInvitation,
  SetMultiReceiverInvitationMessage,
  SetMultiReceiverInvitationSubject,
} from '../../../common/redux/families/family.actions';
import {
  selectCurrentlyCreatedFamily,
  selectUserEmailsOfCurrentlyActiveFamily,
} from '../../../common/redux/families/family.selectors';
import { CreateFamilyState } from '../../../common/redux/families/types';
import { selectUserProfile } from '../../../common/redux/users/user.selectors';
import colors from '../../00_Constants/colors';
import FZButton from '../../01_atoms/FZButton/FZButton';
import FZFieldLabel from '../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZChipList from '../../02_molecules/FZChipList/FZChipList';
import { useInputValidationService } from '../../Hooks/useInputValidation';
import FZInviteFamilyMemberForm from '../FZInviteFamilyMemberForm/FZInviteFamilyMemberForm';
import FZListEnterer, {
  ListEntererValues,
} from '../FZListEnterer/FZListEnterer';

const useStyles = makeStyles((theme: Theme) => {
  return {
    receiverAdressListContainer: {
      width: '100%',
      minHeight: 121,
    },

    receiverAdressInputField: {
      flexBasis: '80%',
    },

    invitationInputField: {
      width: '100%',
    },

    invitationSubjectInputLabel: {},

    invitationMessageInputField: {},

    invitationInputLabel: {
      marginTop: 32,
    },

    horizontalRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  };
});

interface Props {
  onSubmit: () => void;
}

export interface InviteFamilyMemberWidgetValues {
  currentListEntryValue: string;
  invitationSubject: string;
  invitationMessage: string;
  receivers: string[];
}

export default function FZInviteFamilyMemberWidget({
  onSubmit,
}: Props): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const loggedInUserProfile = useSelector(selectUserProfile);
  const currentlyCreatedFamily: CreateFamilyState = useSelector(
    selectCurrentlyCreatedFamily,
  );
  const inputValidationService = useInputValidationService();
  const invitationChipData =
    currentlyCreatedFamily.multiReceiverInvitation.receiverEmailAdresses;
  const emailsInCurrentlyActiveFamily = useSelector(
    selectUserEmailsOfCurrentlyActiveFamily,
  );

  return (
    <Formik
      initialValues={{
        invitationSubject:
          currentlyCreatedFamily.multiReceiverInvitation.invitationSubject ||
          '',
        invitationMessage:
          currentlyCreatedFamily.multiReceiverInvitation.personalMessage || '',

        receivers:
          currentlyCreatedFamily.multiReceiverInvitation
            .receiverEmailAdresses || [],

        currentListEntryValue: '',
      }}
      validateOnChange
      validate={values => {
        const errors: Partial<InviteFamilyMemberWidgetValues> = {};
        const noReceiversPresent =
          currentlyCreatedFamily.multiReceiverInvitation.receiverEmailAdresses
            .length === 0;

        if (noReceiversPresent) {
          errors.receivers = t(
            'createFamilyScreen-no-family-member-email-adress-added-error-message',
          );
        }

        if (
          !inputValidationService.validateEmail(values.currentListEntryValue) &&
          noReceiversPresent
        ) {
          errors.currentListEntryValue = t(
            'inviteFamilyMemberForm-receiverAdress-is-not-an-email-error-message',
          );
        }

        if (
          currentlyCreatedFamily.multiReceiverInvitation.receiverEmailAdresses.includes(
            values.currentListEntryValue.toLocaleLowerCase(),
          )
        ) {
          errors.currentListEntryValue = t(
            'inviteFamilyMemberForm-receiverAdress-is-already-present-error-message',
          );
        }

        if (
          emailsInCurrentlyActiveFamily.includes(
            values.currentListEntryValue.toLocaleLowerCase(),
          )
        ) {
          errors.currentListEntryValue = t(
            'inviteFamilyMemberForm-receiverAdress-is-already-present-in-family-error-message',
          );
        }

        if (
          !inputValidationService.testIfStringHasMinimumLength(
            values.invitationSubject,
            1,
          )
        ) {
          errors.invitationSubject = t(
            'inviteFamilyMemberForm-invitationSubject-missing-error-message',
          );
        }

        if (
          !inputValidationService.testIfStringHasMinimumLength(
            values.invitationSubject,
            10,
          )
        ) {
          errors.invitationSubject = t(
            'inviteFamilyMemberForm-invitationSubject-too-short-error-message',
          );
        }

        if (
          !inputValidationService.testIfStringIsWithinMaximimumLenth(
            values.invitationSubject,
            500,
          )
        ) {
          errors.invitationSubject = t(
            'inviteFamilyMemberForm-invitationSubject-too-long-error-message',
          );
        }

        if (
          !inputValidationService.testIfStringHasMinimumLength(
            values.invitationMessage,
            1,
          )
        ) {
          errors.invitationMessage = t(
            'inviteFamilyMemberForm-invitationMessage-missing-error-message',
          );
        }

        if (
          !inputValidationService.testIfStringHasMinimumLength(
            values.invitationMessage,
            10,
          )
        ) {
          errors.invitationMessage = t(
            'inviteFamilyMemberForm-invitationMessage-too-short-error-message',
          );
        }

        if (
          !inputValidationService.testIfStringIsWithinMaximimumLenth(
            values.invitationMessage,
            500,
          )
        ) {
          errors.invitationMessage = t(
            'inviteFamilyMemberForm-invitationMessage-too-long-error-message',
          );
        }

        return errors;
      }}
      onSubmit={(
        values,
        helpers: FormikHelpers<InviteFamilyMemberWidgetValues>,
      ) => {
        dispatch(SetMultiReceiverInvitationSubject(values.invitationSubject));
        dispatch(SetMultiReceiverInvitationMessage(values.invitationMessage));

        onSubmit();
      }}
    >
      {({ submitForm, isSubmitting, errors, handleReset, setFieldValue }) => (
        <>
          <FZFieldLabel
            label={t(
              'FZinviteFamilyMemberWidet-add-family-member-email-adress-textfield-headline',
            )}
          ></FZFieldLabel>
          <FZListEnterer
            validate={(
              values: ListEntererValues,
            ): Partial<ListEntererValues> => {
              const errors: Partial<ListEntererValues> = {};

              if (
                !inputValidationService.validateEmail(
                  values.currentListEntryValue,
                )
              ) {
                errors.currentListEntryValue = t(
                  'inviteFamilyMemberForm-receiverAdress-is-not-an-email-error-message',
                );
              }

              if (
                currentlyCreatedFamily.multiReceiverInvitation.receiverEmailAdresses.includes(
                  values.currentListEntryValue.toLocaleLowerCase(),
                )
              ) {
                errors.currentListEntryValue = t(
                  'inviteFamilyMemberForm-receiverAdress-is-already-present-error-message',
                );
              }

              if (
                emailsInCurrentlyActiveFamily.includes(
                  values.currentListEntryValue.toLocaleLowerCase(),
                )
              ) {
                errors.currentListEntryValue = t(
                  'inviteFamilyMemberForm-receiverAdress-is-already-present-in-family-error-message',
                );
              }

              return errors;
            }}
            buttonLabel={t(
              'createFamilyScreen-add-family-member-email-adress-button-label',
            )}
            buttonBackgroundColor={colors.secondary}
            buttonLabelColor={colors.textMedium}
            keyColor={colors.secondary}
            textFieldEnabled={true}
            textFieldLabel={''}
            textFieldPlaceholder={t(
              'createFamilyScreen-add-family-member-email-adress-textField-placeholder',
            )}
            onElementSubmitted={(addedReceiverEmailAdress: string) => {
              errors.receivers = null;
              dispatch(
                addReceiverToMultiReceiverInvitation(
                  addedReceiverEmailAdress.toLowerCase(),
                ),
              );
            }}
          ></FZListEnterer>

          <FZChipList
            variant="horizontal"
            chipIcon={<SentimentVerySatisfiedRoundedIcon />}
            containerClassName={classes.receiverAdressListContainer}
            chipListHeadline={t(
              'createFamilyScreen-add-family-member-email-adress-list-headline',
            )}
            chipData={invitationChipData}
            chipListEmptyLabel={t(
              'createFamilyScreen-add-family-member-email-adress-list-empty-placeholder',
            )}
            itemsDeletable
            onDeleteItem={(deletedReceiverEmailAdress: string) => {
              dispatch(
                removeReceiverFromMultiReceiverInvitation(
                  deletedReceiverEmailAdress,
                ),
              );
            }}
          />
          {errors.receivers && (
            <FormHelperText style={{ color: colors.danger }}>
              {errors.receivers}
            </FormHelperText>
          )}

          <FZInviteFamilyMemberForm
            invitationSubjectFieldName="invitationSubject"
            invitationMessageFieldName="invitationMessage"
            invitationSubjectFieldLabel={t(
              'inviteFamilyMemberForm-invitationSubject-field-label',
            )}
            invitationSubjectFieldPlaceholder={t(
              'inviteFamilyMemberForm-invitationSubject-field-placeholder',
            )}
            invitationMessageFieldLabel={t(
              'inviteFamilyMemberForm-invitationMessage-field-label',
            )}
            invitationMessageFieldPlaceholder={t(
              'inviteFamilyMemberForm-invitationMessage-field-placeholder',
              { username: loggedInUserProfile.displayName },
            )}
            invitationMessageFieldCharacterLimit={500}
          />

          <div className={classes.horizontalRow}>
            <FZButton
              style={{ marginRight: 16 }}
              label={t(
                'inviteFamilyMemberForm-invitationMessage-field-copyPlaceholderToValueButton-label',
              )}
              labelColor={colors.textMedium}
              variant="text"
              onClick={() => {
                setFieldValue(
                  'invitationSubject',
                  t(
                    'inviteFamilyMemberForm-invitationSubject-field-placeholder',
                  ),
                  false,
                );
                setFieldValue(
                  'invitationMessage',
                  t(
                    'inviteFamilyMemberForm-invitationMessage-field-placeholder',
                    { username: loggedInUserProfile.displayName },
                  ),
                  false,
                );
              }}
            ></FZButton>

            <FZButton
              onClick={() => {
                submitForm();
              }}
              label={t('inviteFamilyMemberForm-submitButton-label')}
              backgroundColor={colors.secondary}
              labelColor={colors.textMedium}
              style={{ alignSelf: 'flex-end' }}
            ></FZButton>
          </div>
        </>
      )}
    </Formik>
  );
}
