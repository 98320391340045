import { UserSettings } from './UserSettings';
import { Image, ImageDto } from './Image';

export type UserData = {
  accountUid?: string;
  customerUid?: string;
  email: string;
  oktaName?: string;
  settings?: UserSettings;
  profileImage?: ImageDto;
  firstName?: string;
  lastName?: string;
  displayName?: string;
};

export class User {
  /**
   * The primary email given upon registration of the user.
   */
  email?: string;

  /**
   * Unique identifier for the account stripped from his access token.
   */
  accountUid?: string;

  /**
   * Unique identifier for the customer entitty associated with the currently logged in account.
   */
  customerUid?: string;

  /**
   * Name for the user stripped form the sub-field of his access token.
   */
  oktaName?: string;

  /**
   * A set of settings that can be changed by the user.
   */
  settings?: UserSettings;

  /**
   * A set of settings that can be changed by the user.
   */
  profileImage?: Image;

  /**
   * The first name of the user that he has specified during registration
   *
   */
  firstName?: string;

  /**
   * The last name of the user that he has specified during registration
   *
   */
  lastName?: string;

  /**
   * The displayname of the user under which he will be presented to other users within the system
   */
  displayName?: string;

  /**
   * @param {UserData} userBody - Object representing various data about a user.
   *
   */
  constructor(userData: UserData) {
    this.email = userData.email;
    this.accountUid = userData.accountUid;
    this.customerUid = userData.customerUid;
    this.oktaName = userData.oktaName;
    this.settings = userData.settings;
    if (userData.profileImage) {
      this.profileImage = new Image(userData.profileImage);
    }
    this.firstName = userData.firstName;
    this.lastName = userData.lastName;
    this.displayName = userData.displayName;
  }

  /**
   * Converts an article into a JSON-representation
   */
  toJson() {
    return JSON.stringify(this);
  }

  /**
   * Creates a string representation of this user
   */
  toString() {
    return `AccountUid: ${this.accountUid} username: ${this.email}`;
  }

  /**
   * Returns a unique identifier for this user that can be used to fetch information
   * about his activity from backend services.
   */
  getUniqueIdentifier() {
    return this.accountUid;
  }

  /**
   * Returns a set of settings that the user can set himself.
   */

  getUserSettings(): UserSettings | undefined {
    return this.settings;
  }

  /**
   * Returns the default author name set for this user.
   */
  getDefaultAuthorPseudonym(): string | undefined {
    return this.settings?.defaultAuthorPseudonym;
  }
}
