import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import fzColorfulBackgroundImage from '../../../../../assets/images/FZ_colorful_background.jpg';
import { updatePrimaryEmailRequest } from '../../../../../common/redux/login/login.actions';
import {
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
} from '../../../../../common/redux/ui/ui.actions';
import colors from '../../../../00_Constants/colors';
import { FullWidthBackground } from '../../../../01_atoms/FullWidthBackground/FullWidthBackground';
import FZButton from '../../../../01_atoms/FZButton/FZButton';
import FZSpinner from '../../../../01_atoms/FZSpinner';
import FZTypography from '../../../../01_atoms/FZTypography/FZTypography';
import FZStatusPageTemplate from '../../../../04_templates/StatusPageTemplate/FZStatusPage/FZStatusPageTemplate';
import useConfig from '../../../../Hooks/useConfig';
import { useQueryString } from '../../../../Hooks/useQueryString';

interface Props {}

const UpdateEmailScreen: React.FC<Props> = () => {
  const config = useConfig();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updatePrimaryEmailEnabled: boolean =
    config.FEATURE_FLAGS.USER
      .REACT_APP_FEATURE_FLAG_UPDATE_PRIMARY_EMAIL_ENABLED;
  const queryStringParameters = useQueryString();
  const verificationToken = queryStringParameters.get('activation');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestSuccesful, setRequestSuccessful] = useState<boolean>(false);
  const history = useHistory();
  if (!updatePrimaryEmailEnabled) {
    dispatch(
      showInfoSnackbar(
        t('updateEmailScreen-403-update-email-currently-closed'),
        false,
      ),
    );
  }

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      updatePrimaryEmailRequest(
        {
          verificationToken: verificationToken,
        },
        {
          successCallback: () => {
            setIsLoading(false);
            setRequestSuccessful(true);
            dispatch(
              showSuccessSnackbar(
                t('updateEmailScreen-update-primary-email-success-message'),
                true,
              ),
            );
          },
          errorCallback: () => {
            setIsLoading(false);
            dispatch(
              showErrorSnackbar(
                t('updateEmailScreen-update-primary-email-error-message'),
                true,
              ),
            );
          },
        },
      ),
    );
  }, [dispatch, t, verificationToken]);

  return (
    <FullWidthBackground
      imageSource={fzColorfulBackgroundImage}
      position="absolute"
    >
      <FZStatusPageTemplate
        body={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'space-between',
              justifyContent: 'center',
            }}
          >
            <FZTypography variant="h5" style={{ marginBottom: 8 }}>
              {t('updateEmailScreen-headline')}
            </FZTypography>
            <FZTypography variant="body1" style={{ marginBottom: 16 }}>
              {t('updateEmailScreen-message')}
            </FZTypography>

            {isLoading ? (
              <FZSpinner
                color={colors.quartiary}
                statusMessage={t(
                  'updateEmailScreen-isVerifyingEmailChange-message',
                )}
              ></FZSpinner>
            ) : requestSuccesful ? (
              <FZButton
                backgroundColor={colors.white}
                label={t(
                  'updateEmailScreen-go-to-login-with-new-email-button-label',
                )}
                onClick={() => {
                  history.push('/login');
                }}
              ></FZButton>
            ) : (
              <FZButton
                backgroundColor={colors.white}
                label={t('updateEmailScreen-go-to-login-button-label')}
                onClick={() => {
                  history.push('/login');
                }}
              ></FZButton>
            )}
          </div>
        }
      ></FZStatusPageTemplate>
    </FullWidthBackground>
  );
};

export default UpdateEmailScreen;
