import { AxiosResponse } from 'axios';
import { Article } from '../models/Article';
import { SortingClause } from '../models/enums/SortingOrders';
import { Image } from '../models/Image';
import { API_CONFIG, apiClient } from '../api';

export interface PostImageRequestBody {
  url: string;
  contentType: string;
}

export type PostPreuploadedImagesRequest = {
  preuploadedImages: PostImageRequestBody[];
};

class ArticleService {
  /**
   * Fetches a list of articles from the API
   */
  async getArticles(
    sortingClause?: SortingClause,
  ): Promise<AxiosResponse<Article[] | undefined>> {
    let uriString = '';
    if (sortingClause) {
      uriString = `${API_CONFIG.ARTICLE_SERVICE.GET_ARTICLES_URI()}?ORDER_BY=${sortingClause.attribute.toUpperCase()}_${
        sortingClause.order
      }`;
    } else {
      uriString = API_CONFIG.ARTICLE_SERVICE.GET_ARTICLES_URI();
    }

    try {
      const response = apiClient.get(uriString);
      return response;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * Fetches the articles that a speciic user has authored from the API
   */
  async getArticlesForUser(
    userUuid: string,
    accessToken: string,
    sortingClause?: SortingClause,
  ): Promise<AxiosResponse<Article[] | undefined>> {
    let uriString = '';
    if (sortingClause) {
      uriString = `${API_CONFIG.ARTICLE_SERVICE.GET_ARTICLES_FOR_USER_URI(
        userUuid,
      )}?ORDER_BY=${sortingClause.attribute.toUpperCase()}_${
        sortingClause.order
      }`;
    } else {
      uriString = API_CONFIG.ARTICLE_SERVICE.GET_ARTICLES_FOR_USER_URI(
        userUuid,
      );
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = apiClient.get(uriString, { headers });
      return response;
    } catch (error) {
      return undefined;
    }
  }

  /** Fetches a single article-entity.
   * @param articleId - Unique public identifier for the diea
   */
  async getArticle(
    articleId: string,
  ): Promise<AxiosResponse<Article | undefined>> {
    try {
      const response = apiClient.get(
        API_CONFIG.ARTICLE_SERVICE.GET_ARTICLE_URI(articleId),
      );
      return response;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * Creates a new article-entity.
   * @param article - The article that shall be created
   */
  async postArticle(
    familyUuid: string,
    articleToPost: Article,
    accessToken: string,
  ): Promise<AxiosResponse<Article | undefined>> {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const postIdeaUri = API_CONFIG.ARTICLE_SERVICE.POST_ARTICLE_URI(
        familyUuid,
      );

      const postArticleResponse = apiClient.post(postIdeaUri, articleToPost, {
        headers,
      });

      return postArticleResponse;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * Allows to post 1..5 Images to an Article by uploading them to
   * the service's API.
   * @param imagesToPost - The image entities that shall be posted
   * @param articleUuid - The unique identifier for the article to which the images shall be added
   * @param accessToken - The personal access token for api access.
   * @param userUid - The user Uid who uploads the images.
   */
  async postArticleImages(
    imagesToPost: Image[],
    articleUuid: string,
    accessToken: string,
    userUid: string,
  ) {
    const requestBody = new FormData();

    imagesToPost.forEach((image, index) => {
      const file = image.localFilePath;

      requestBody.append('images', file as any);
    });

    requestBody.append('spaceName', userUid);

    const url = API_CONFIG.ARTICLE_SERVICE.POST_ARTICLE_IMAGE_URI(articleUuid);
    return fetch(url, {
      method: 'POST',
      body: requestBody,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  /**
   * Allows to link a set of Images that were uploaded directly to a storage provider.
   */
  async postArticleImagesAsUrls(
    requestBody: PostPreuploadedImagesRequest,
    articleUuid: string,
    accessToken: string,
  ) {
    const url = API_CONFIG.ARTICLE_SERVICE.POST_PREUPLOADED_IMAGE_TO_ARTICLE_URI(
      articleUuid,
    );

    return apiClient.post(url, requestBody.preuploadedImages, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  /**
   * Updates a single article-entity
   * @param familyThatArticleBelongsToUuid - Uuid of the family in whichs context the article was created.
   * @param articleThatShallBeUpdated -  Article that shall be updated
   */
  async updateArticle(
    familyThatArticleBelongsToUuid: string,
    articleThatShallBeUpdated: Article,
    accessToken: string,
  ) {
    return apiClient.put(
      API_CONFIG.ARTICLE_SERVICE.PUT_ARTICLE_URI(
        familyThatArticleBelongsToUuid,
        articleThatShallBeUpdated.uuid,
      ),
      articleThatShallBeUpdated,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );
  }

  /**
   * Deletes an image from an article
   *
   */
  async deleteArticleImage(
    articleUuid: string,
    imageUuid: string,
    accessToken: string,
  ) {
    return apiClient.delete(
      API_CONFIG.ARTICLE_SERVICE.DELETE_IMAGE_FROM_ARTICLE_URI(
        articleUuid,
        imageUuid,
      ),
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );
  }

  /** Deletes a single article-entity
   * @param articleId - Public Id of the article that shall be deleted
   */
  async deleteArticle(
    articleUuid: string,
    accessToken: string,
  ): Promise<AxiosResponse<Article | undefined>> {
    return apiClient.delete(
      API_CONFIG.ARTICLE_SERVICE.DELETE_ARTICLE_URI(articleUuid),
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );
  }
}
export default ArticleService;
