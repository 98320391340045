import { Button, createStyles, Theme, Typography } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/styles';
import { withOktaAuth } from '@okta/okta-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, compose } from 'redux';
import explosionIllustration from '../../assets/images/explosion.png';
import { logoutRequest } from '../../common/redux/login/login.actions';
import { ApplicationState } from '../../common/redux/main/types';
import colors from '../00_Constants/colors';
import FZStatusPageTemplate from '../04_templates/StatusPageTemplate/FZStatusPage/FZStatusPageTemplate';

type Props = DispatchProp<AnyAction> &
  RouteComponentProps<any> & {
    authService: any;
    history: any;
    t?: any;
  };
interface State {
  hasError: boolean;
  snackbarOpen: boolean;
  error: Error;
}

const styles: (theme: Theme) => StyleRules<object> = theme =>
  createStyles({
    snackbarStyles: {},
  });

class FZErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, snackbarOpen: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error };
  }

  // Update state so the next render will show the fallback UI.    return { hasError: true };  }
  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ hasError: true, error: error });
  }

  handleSnackbarClose = () => {
    this.setState({
      ...this.state,
      snackbarOpen: false,
      hasError: false,
      error: null,
    });
  };

  handleLogoutButtonPressed = () => {
    const { dispatch, authService } = this.props;

    dispatch(logoutRequest());
    authService.logout('/sign-out-successful');
  };

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      // dispatch(showErrorSnackbar(this.state.error, false));
      return (
        <FZStatusPageTemplate
          imageSource={explosionIllustration}
          body={
            <>
              <Typography variant="h5">
                {t('errorBoundary-unexpected-error-headline')}
              </Typography>

              <Typography variant="body1">
                {t('errorBoundary-unexpected-error-message')}
              </Typography>
            </>
          }
          actions={
            <Button
              style={{
                backgroundColor: colors.quartiary,
                marginTop: 16,
                marginBottom: 16,
                color: '#FFFFFF',
                float: 'right',
              }}
              onClick={this.handleLogoutButtonPressed}
            >
              Abmelden
            </Button>
          }
        />
      );
    } else {
      return this.props.children;
    }
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  withStyles(styles),
  withOktaAuth,
  withRouter,
)(FZErrorBoundary);
