// src/Home.js
import { Box, Container, Grid } from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import { Component, default as React } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, compose } from 'redux';
import { logoutSuccess } from '../../../../common/redux/login/login.actions';
import colors from '../../../00_Constants/colors';
import {
  AUTHENTICATION,
  LOGOUT_SUCCESS,
} from '../../../00_Constants/trackerConstants';
import FZSpinner from '../../../01_atoms/FZSpinner/FZSpinner';
import { withHooks, WithHooksProps } from '../../../HOCs/withHooks';
import './LoginScreen.css';

type ReduxProps = {
  currentUserIdToken: string;
};

interface Props
  extends DispatchProp<AnyAction>,
    WithTranslation,
    RouteComponentProps<any>,
    ReduxProps,
    WithHooksProps {
  authService: any;
  history: any;
  // HoC Procs
  t?: any;
}

type State = {
  authenticated: boolean | null;
};

class SignOutSuccessfulScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { authenticated: false };
  }

  async componentDidMount() {
    const {
      authService,
      currentUserIdToken,
      matomoAnalyticsTracker,
    } = this.props;
    const authenticated = authService.isAuthenticated;
    if (!authenticated && currentUserIdToken) {
      matomoAnalyticsTracker.trackEvent({
        category: AUTHENTICATION,
        action: LOGOUT_SUCCESS,
        documentTitle: '/sign-out-successful', // optional
      });
      this.setState({ authenticated: false });
    }
  }

  render() {
    const { dispatch, history, t } = this.props;

    if (!this.state.authenticated) {
      dispatch(logoutSuccess());

      setTimeout(() => {
        history.push('/');
      }, 500);
    }
    return (
      <Container style={{ padding: 0 }}>
        <Grid container spacing={3} alignItems="flex-start" justify="center">
          <Grid item xs={12}>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              marginTop="40px"
            >
              <FZSpinner
                color={colors.quartiary}
                statusMessage={t(
                  'signoutSuccessfulScreen-SignOutSuccessfulMessage-label',
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default compose(
  withTranslation(),
  connect(),
  withOktaAuth,
  withRouter,
  withHooks,
)(SignOutSuccessfulScreen);
