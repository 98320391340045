import { Menu } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import React, { Component } from 'react';
import FZProfilePictureWidget from '../FZProfilePictureWidget/FZProfilePictureWidget';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';

interface Props {
  avatarMenuOpen: boolean;
  onAvatarMenuClose: () => void;
  onAvatarIconClick: () => void;
  avatarImageSource: string;
  classes?: any;
  customClasses?: { container?: any; imageContainer?: any; image?: any };
}

const styles: (theme: Theme) => StyleRules<object> = theme =>
  createStyles({
    avatarImageStyles: {},
  });

class AvatarMenu extends Component<Props, {}> {
  avatarMenuRef: any;

  state = {};

  render() {
    const { customClasses } = this.props;

    return (
      <>
        <span
          ref={ref => {
            this.avatarMenuRef = ref;
          }}
          aria-controls="avatar-menu"
          onClick={this.props.onAvatarIconClick}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <FZProfilePictureWidget
            editable={false}
            profilePictureUuid={this.props.avatarImageSource}
            customClasses={{
              container: customClasses.container,
              imageContainer: customClasses.imageContainer,
              image: customClasses.image,
            }}
          ></FZProfilePictureWidget>
          <ArrowDropDownRoundedIcon
            style={{ rotate: this.props.avatarMenuOpen ? '180deg' : '' }}
          />
        </span>

        <Menu
          id="avatar-menu"
          anchorEl={this.avatarMenuRef}
          keepMounted
          open={this.props.avatarMenuOpen}
          onClose={this.props.onAvatarMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          //transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          style={{ marginTop: 20, marginLeft: -32 }}
          disableScrollLock
        >
          {this.props.children}
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(AvatarMenu);
