import { Grid, makeStyles, MenuItem, Theme } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../../00_Constants/colors';
import FZButton from '../../../01_atoms/FZButton/FZButton';
import FZSelectInputField from '../../../01_atoms/FZSelectInputField/FZSelectInputField';
import FZTextInputField from '../../../01_atoms/FZTextInputField/FZTextInputField';

const useStyles = makeStyles((theme: Theme) => {
  return {
    passwordValue: {
      marginBottom: 50,
      marginTop: 22,
    },

    formInputField: {
      width: '100%',
      //marginTop: 16,
      //marginBottom: 16,
    },

    submitButton: {
      marginTop: 46,
    },

    newPasswordValue: { marginBottom: 16 },
  };
});

interface EditAdressFormValues {
  newStreetName: string;
  newPostalCode: string;
  newCity: string;
}

interface Props {
  formEnabled: boolean;
  currentStreetName: string;
  currentPostalCode: string;
  currentCity: string;
  onSubmit: () => void;
}

export default function EditAdressForm({
  formEnabled,
  currentStreetName,
  currentPostalCode,
  currentCity,
  onSubmit,
}: Props): ReactElement {
  const { t } = useTranslation();
  //const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          newStreetName: currentStreetName,
          newPostalCode: currentPostalCode,
          newCity: currentCity,
        }}
        validate={values => {
          const errors: Partial<EditAdressFormValues> = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
          setSubmitting(true);

          // TODO: Add request to send an email that allows to reset the users password.

          onSubmit();
        }}
      >
        {({ submitForm, isSubmitting, errors }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  component={FZSelectInputField}
                  name="newCountry"
                  inputProps={{
                    id: 'age-simple',
                  }}
                  defaultValue="DE"
                  disabled={formEnabled === false}
                >
                  <MenuItem value={'DE'}>
                    {t('FZCountrySelector-option-germany')}
                  </MenuItem>
                </Field>
              </Grid>
              <Grid item xs={8}>
                <Field
                  id="newStreetName"
                  name="newStreetName"
                  data-testid="newStreetNameField"
                  component={FZTextInputField}
                  className={classes.formInputField}
                  label={t('editAdressScreen-streetName-field-label')}
                  placeholder={currentStreetName}
                  color={colors.quartiary}
                  backgroundColor={colors.white}
                  disabled={formEnabled === false}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  id="newHouseNumber"
                  name="newHouseNumber"
                  data-testid="newHouseNumberField"
                  component={FZTextInputField}
                  className={classes.formInputField}
                  label={t('editAdressScreen-houseNumber-field-label')}
                  placeholder={currentStreetName}
                  color={colors.quartiary}
                  backgroundColor={colors.white}
                  disabled={formEnabled === false}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  id="newPostalCode"
                  name="newPostalCode"
                  data-testid="postalCodeField"
                  component={FZTextInputField}
                  className={classes.formInputField}
                  label={t('editAdressScreen-postalCode-field-label')}
                  placeholder={currentPostalCode}
                  color={colors.quartiary}
                  backgroundColor={colors.white}
                  disabled={formEnabled === false}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  id="newCity"
                  name="newCity"
                  data-testid="newCityField"
                  component={FZTextInputField}
                  className={classes.formInputField}
                  label={t('editAdressScreen-city-field-label')}
                  placeholder={currentPostalCode}
                  color={colors.quartiary}
                  backgroundColor={colors.white}
                  disabled={formEnabled === false}
                />{' '}
              </Grid>

              <Grid item xs={12}>
                <FZButton
                  className={classes.submitButton}
                  label={t('editAdressScreen-finish-editing-button-label')}
                  backgroundColor={colors.quartiary}
                  isSubmitting={isSubmitting}
                  disabled={formEnabled === false}
                  type="submit"
                  variant="contained"
                ></FZButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
