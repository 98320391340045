import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import fonts from '../../00_Constants/fonts';
import FZFieldLabel from '../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZTypography from '../../01_atoms/FZTypography/FZTypography';
import FZChipList from '../../02_molecules/FZChipList/FZChipList';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  headline: { marginBottom: 32, marginTop: 32, alignSelf: 'flex-start' },
  label: { fontFamily: fonts.FONT_FAMILY_BOLD, marginBottom: 8 },
  content: {},

  subjectContainer: { display: 'flex', flexDirection: 'row', paddingLeft: 32 },
  subject: { paddingLeft: 8 },

  messageContainer: { paddingLeft: 32 },

  receiverAdressListContainerVertical: {
    width: '100%',
    minHeight: 121,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  receiverAdressListVerticalChip: {
    marginTop: 16,
    marginLeft: 24,
  },
});

interface Props {
  headline: string;
  subject: string;
  message: string;
  includeReceiverList: boolean;
  receivers: string[];
  className?: string;
}

export default function FZFamilyInvitationPreview({
  headline,
  subject,
  message,
  includeReceiverList,
  receivers,
  className,
}: Props): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles({});
  return (
    <div className={clsx(classes.container, className)}>
      {includeReceiverList && (
        <FZChipList
          variant="vertical"
          chipListHeadline={t(
            'createFamilyScreen-step-3-familyMemberOverview-label',
          )}
          chipData={receivers}
          itemsDeletable={false}
          containerClassName={classes.receiverAdressListContainerVertical}
          chipClassName={classes.receiverAdressListVerticalChip}
          chipListEmptyLabel={t('FZfamilyInvitationPreview-invitations-empty')}
        ></FZChipList>
      )}

      <FZFieldLabel
        label={headline}
        className={classes.headline}
      ></FZFieldLabel>
      <div className={classes.subjectContainer}>
        <FZTypography variant="body1" className={classes.label}>
          {t('FZfamilyInvitationPreview-subject-label')}
        </FZTypography>
        <FZTypography variant="body1" className={classes.subject}>
          {subject || t('FZfamilyInvitationPreview-subject-empty-label')}
        </FZTypography>
      </div>

      <div className={classes.messageContainer}>
        <FZTypography variant="body1" className={classes.label}>
          {t('FZfamilyInvitationPreview-message-label')}
        </FZTypography>
        <FZTypography
          style={{ whiteSpace: 'pre-line' }}
          variant="body1"
          component="p"
          className={classes.content}
        >
          {message || t('FZfamilyInvitationPreview-message-empty-label')}
        </FZTypography>
      </div>
    </div>
  );
}
