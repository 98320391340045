import React from 'react';
import { ServicesConsumer, ServicesProps } from '../Contexts/ServicesContext';

export interface WithServicesProps {
  services?: ServicesProps;
}
// This function takes a component...
export function withServices<P extends object>(
  Component: React.ComponentType<P>,
) {
  // ...and returns another component...
  return class ComponentBoundWithServices extends React.Component<
    P & WithServicesProps
  > {
    render() {
      const { ...props } = this.props as WithServicesProps;
      return (
        <ServicesConsumer>
          {services => <Component {...(props as P)} services={services} />}
        </ServicesConsumer>
      );
    }
  };
}
