import { action, PayloadMetaAction } from 'typesafe-actions';
import { Article } from '../../models/Article';
import { SortingClause } from '../../models/enums/SortingOrders';
import { Image } from '../../models/Image';
import { ActionCallbacks } from '../main/types';

export enum articleActionTypes {
  // GET Request to list-resource
  FETCH_ARTICLES_REQUEST = 'FETCH_ARTICLES_REQUEST',
  FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS',
  FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE',
  FETCH_ARTICLES_FULFILL = 'FETCH_ARTICLES_FULFILL',

  // Generic GET Request
  FETCH_ARTICLE_REQUEST = 'FETCH_ARTICLE_REQUEST',
  FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS',
  FETCH_ARTICLE_FAILURE = 'FETCH_ARTICLE_FAILURE',
  FETCH_ARTICLE_FULFILL = 'FETCH_ARTICLE_FULFILL',

  // GET Request for articles of a specific user
  FETCH_OWN_ARTICLES_REQUEST = 'FETCH_OWN_ARTICLES_REQUEST',
  FETCH_OWN_ARTICLES_SUCCESS = 'FETCH_OWN_ARTICLES_SUCCESS',
  FETCH_OWN_ARTICLES_FAILURE = 'FETCH_OWN_ARTICLES_FAILURE',
  FETCH_OWN_ARTICLES_FULFILL = 'FETCH_OWN_ARTICLES_FULFILL',

  // POST an empty draft of an article
  POST_ARTICLE_DRAFT_REQUEST = 'POST_ARTICLE_DRAFT_REQUEST',
  POST_ARTICLE_DRAFT_SUCCESS = 'POST_ARTICLE_DRAFT_SUCCESS',
  POST_ARTICLE_DRAFT_FAILURE = 'POST_ARTICLE_DRAFT_FAILURE',
  POST_ARTICLE_DRAFT_FULFILL = 'POST_ARTICLE_DRAFT_FULFILL',

  // DELETE a previously created Draft of an article
  DELETE_ARTICLE_REQUEST = 'DELETE_ARTICLE_REQUEST',
  DELETE_ARTICLE_SUCESS = 'DELETE_ARTICLE_SUCESS',
  DELETE_ARTICLE_FAILURE = 'DELETE_ARTICLE_FAILURE',
  DELETE_ARTICLE_FULFILL = 'DELETE_ARTICLE_FULFILL',

  // POST images to furhter describe and visualze an article
  POST_ARTICLE_IMAGE_REQUEST = 'POST_ARTICLE_IMAGE_REQUEST',
  POST_ARTICLE_IMAGE_SUCCESS = 'POST_ARTICLE_IMAGE_SUCCESS',
  POST_ARTICLE_IMAGE_FAILURE = 'POST_ARTICLE_IMAGE_FAILURE',
  POST_ARTICLE_IMAGE_CANCELLED = 'POST_ARTICLE_IMAGE_CANCELLED',
  POST_ARTICLE_IMAGE_FULFILL = 'POST_ARTICLE_IMAGE_FULFILL',

  // DELETE a previously uploaded image from an article.
  DELETE_IMAGE_FROM_ARTICLE_REQUEST = 'DELETE_IMAGE_FROM_ARTICLE_REQUEST',
  DELETE_IMAGE_FROM_ARTICLE_SUCCESS = 'DELETE_IMAGE_FROM_ARTICLE_SUCCESS',
  DELETE_IMAGE_FROM_ARTICLE_FAILURE = 'DELETE_IMAGE_FROM_ARTICLE_FAILURE',
  DELETE_IMAGE_FROM_ARTICLE_FULFILL = 'DELETE_IMAGE_FROM_ARTICLE_FULFILL',

  // POST Request
  POST_ARTICLE_REQUEST = 'POST_ARTICLE_REQUEST',
  POST_ARTICLE_SUCCESS = 'POST_ARTICLE_SUCCESS',
  POST_ARTICLE_FAILURE = 'POST_ARTICLE_FAILURE',
  POST_ARTICLE_FULFILL = 'POST_ARTICLE_FULFILL',

  // PUT Request
  UPDATE_ARTICLE_REQUEST = 'UPDATE_ARTICLE_REQUEST',
  UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS',
  UPDATE_ARTICLE_FAILURE = 'UPDATE_ARTICLE_FAILURE',
  UPDATE_ARTICLE_FULFILL = 'UPDATE_ARTICLE_FULFILL',

  // Misc Actions to control intra-screen UI state.
  SET_CURRENTLY_EDITED_ARTICLE = 'SET_CURRENTLY_EDITED_ARTICLE',
  RESET_CURRENTLY_EDITED_ARTICLE = 'RESET_CURRENTLY_EDITED_ARTICLE',
}

//#region Fetch Articles Action Creators
// Typed actions for GET Request on LIST Resource
export const fetchArticlesRequest = (sortingClause?: SortingClause) =>
  action(articleActionTypes.FETCH_ARTICLES_REQUEST, sortingClause);
export const fetchArticlesSuccess = (articles: Article[]) =>
  action(articleActionTypes.FETCH_ARTICLES_SUCCESS, { articles });
export const fetchArticlesError = (error: Error) =>
  action(articleActionTypes.FETCH_ARTICLES_FAILURE, error);
//#endregion

//#region Fetch articles authored by user Action Creators
// Typed actions for GET request on a specific user's articles
export const fetchOwnArticlesRequest = () => {
  return action(articleActionTypes.FETCH_OWN_ARTICLES_REQUEST);
};
export const fetchOwnArticlesSuccess = (articles: Article[]) => {
  return action(articleActionTypes.FETCH_OWN_ARTICLES_SUCCESS, {
    articles,
  });
};
export const fetchOwnArticlesFailure = (error: Error) => {
  return action(articleActionTypes.FETCH_OWN_ARTICLES_FAILURE, error);
};
export const fetchOwnArticlesFulfill = () => {
  return action(articleActionTypes.FETCH_OWN_ARTICLES_FULFILL);
};
//#endregion

//#region Create Article (with data) Action Creators
// Typed actions for POST Request
export const postArticleRequest = (data: Article) =>
  action(articleActionTypes.POST_ARTICLE_REQUEST, data);
export const postArticleSuccess = (data: Article) =>
  action(articleActionTypes.POST_ARTICLE_SUCCESS, data);
export const postArticleError = (error: Error) =>
  action(articleActionTypes.POST_ARTICLE_FAILURE, error);
//#endregion

//#region Create empty new draft Article Action Creators
// Typed actions for POST Request of an article draft
export const postArticleDraftRequest = (data: Article) =>
  action(articleActionTypes.POST_ARTICLE_DRAFT_REQUEST, data);
export const postArticleDraftSuccess = (data: Article) =>
  action(articleActionTypes.POST_ARTICLE_DRAFT_SUCCESS, data);
export const postArticleDraftError = (error: Error) =>
  action(articleActionTypes.POST_ARTICLE_DRAFT_FAILURE, error);

//#endregion

//#region Update Article Action Creators
// Typed actions to Update an article
export const updateArticleRequest = (
  article: Article,
  successCallback?: () => void,
  errorCallback?: (error: any) => void,
) => {
  return action(articleActionTypes.UPDATE_ARTICLE_REQUEST, {
    article: article,
    successCallback: successCallback,
    errorCallback: errorCallback,
  });
};
export const updateArticleSuccess = (article: Article) => {
  return action(articleActionTypes.UPDATE_ARTICLE_SUCCESS, {
    article: article,
  });
};
export const updateArticleFailure = (article: Error) => {
  return action(articleActionTypes.UPDATE_ARTICLE_FAILURE, {
    article: article,
  });
};
export const updateArticleFulfill = () => {
  return action(articleActionTypes.UPDATE_ARTICLE_FULFILL);
};
//#endregion

//#region Delete Article Action Creators
// Typed actions to DELETE an article
export const deleteArticleRequest = (data: Article) =>
  action(articleActionTypes.DELETE_ARTICLE_REQUEST, data);
export const deleteArticleSuccess = () =>
  action(articleActionTypes.DELETE_ARTICLE_SUCESS);
export const deleteArticleError = (error: Error) =>
  action(articleActionTypes.DELETE_ARTICLE_FAILURE, error);
export const deleteArticleFulfill = () => {
  return action(articleActionTypes.DELETE_ARTICLE_FULFILL);
};
//#endregion

//#region Create Article Image Action Creators
// Typed action to upload an image that belongs to an article.
export type PostImageAction = PayloadMetaAction<
  articleActionTypes.POST_ARTICLE_IMAGE_REQUEST,
  {
    images: Image[];
    articleUuid: string;
    uploadProgressCallback: (progressEvent: any) => void;
  },
  ActionCallbacks
>;

// Type actions for a POST Request of one or multiple images that belong to an Article.
export const postImageRequest = (
  data: Image[],
  articleUuidToPostTo: string,
  uploadProgressCallback: (progressEvent: any) => void,
  actionCallbacks: ActionCallbacks,
): PostImageAction =>
  action(
    articleActionTypes.POST_ARTICLE_IMAGE_REQUEST,
    {
      images: data,
      articleUuid: articleUuidToPostTo,
      uploadProgressCallback: uploadProgressCallback,
    },
    actionCallbacks,
  );
export const postImageSuccess = (data: Image) =>
  action(articleActionTypes.POST_ARTICLE_IMAGE_SUCCESS, data);
export const postImageFailure = (error: Error) =>
  action(articleActionTypes.POST_ARTICLE_IMAGE_FAILURE, error);
export const postArticleImageCancelled = () => {
  return action(articleActionTypes.POST_ARTICLE_IMAGE_CANCELLED);
};
export const postImageFulfill = () =>
  action(articleActionTypes.POST_ARTICLE_IMAGE_FULFILL);
//#endregion

//#region Delete Article Image Action Creators
// Typed actions to delete an image from an article.
export const deleteImageRequest = (
  articleUuid: string,
  imageUuid: string,
  deleteImageSuccessCallback: () => void,
) => {
  return action(articleActionTypes.DELETE_IMAGE_FROM_ARTICLE_REQUEST, {
    articleUuid,
    imageUuid,
    deleteImageSuccessCallback,
  });
};
export const deleteImageSuccess = (imageUuid: string) => {
  return action(
    articleActionTypes.DELETE_IMAGE_FROM_ARTICLE_SUCCESS,
    imageUuid,
  );
};
export const deleteImageFaiure = (error: Error) => {
  return action(articleActionTypes.DELETE_IMAGE_FROM_ARTICLE_FAILURE, error);
};
export const deleteImageFulFill = () => {
  return action(articleActionTypes.DELETE_IMAGE_FROM_ARTICLE_FULFILL);
};
//#endregion

//#region Set currently edited Article Action Creators
export const setCurrentlyEditedArticle = (
  articleToSetAsCurrentlyEdited: Article,
) => {
  return action(articleActionTypes.SET_CURRENTLY_EDITED_ARTICLE, {
    article: articleToSetAsCurrentlyEdited,
  });
};

export const resetCurrentlyEditedArticle = () => {
  return action(articleActionTypes.RESET_CURRENTLY_EDITED_ARTICLE);
};
//#endregion
