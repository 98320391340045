import { AnyAction, Reducer } from 'redux';

import { ApiReducerState } from './types';

const initialState: ApiReducerState = {};

const apiReducer: Reducer<ApiReducerState> = (
  state = initialState,
  action: AnyAction,
) => {
  return {};
};

export default apiReducer;
