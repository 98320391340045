import {
  Container,
  FormHelperText,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import SentimentVerySatisfiedRoundedIcon from '@material-ui/icons/SentimentVerySatisfiedRounded';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import familyIllustration from '../../../../assets/images/family_illustration.png';
import { FamilyDto } from '../../../../common/models/Family';
import {
  addReceiverToMultiReceiverInvitation,
  createFamilyRequest,
  removeReceiverFromMultiReceiverInvitation,
  setFamilyName,
  setMeansOfFamilyMemberInvitation,
  SetMultiReceiverInvitationMessage,
  SetMultiReceiverInvitationSubject,
} from '../../../../common/redux/families/family.actions';
import {
  selectCurrentlyActiveFamily,
  selectCurrentlyCreatedFamily,
} from '../../../../common/redux/families/family.selectors';
import {
  CreateFamilyState,
  FamilyInvitationType,
} from '../../../../common/redux/families/types';
import { selectUserProfile } from '../../../../common/redux/users/user.selectors';
import colors from '../../../00_Constants/colors';
import FZButton from '../../../01_atoms/FZButton/FZButton';
import FZFieldLabel from '../../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZInfoCard from '../../../01_atoms/FZInfoCard/FZInfoCard';
import FZNavigateBackButton from '../../../01_atoms/FZNavigateBackButton/FZNavigateBackButton';
import FZStepDenominator from '../../../01_atoms/FZStepDenominator/FZStepDenominator';
import FZTypography from '../../../01_atoms/FZTypography/FZTypography';
import ScrollToTop from '../../../01_atoms/ScrollToTop/ScrollToTop';
import FZChipList from '../../../02_molecules/FZChipList/FZChipList';
import FZSliderWidget, {
  FZUserTipp,
} from '../../../02_molecules/FZSliderWidget/FZSliderWidget';
import FZAppBar from '../../../03_organisms/FZAppBar/FZAppBar';
import FZCreateFamilyForm, {
  CreateFamilyFormValues,
} from '../../../03_organisms/FZCreateFamilyForm/FZCreateFamilyForm';
import FZFamilyInvitationPreview from '../../../03_organisms/FZFamilyInvitationPreview/FZFamilyInvitationPreview';
import FZInviteFamilyMemberForm, {
  InvitateFamilyMemberFormValues,
} from '../../../03_organisms/FZInviteFamilyMemberForm/FZInviteFamilyMemberForm';
import FZListEnterer, {
  ListEntererValues,
} from '../../../03_organisms/FZListEnterer/FZListEnterer';
import { useInputValidationService } from '../../../Hooks/useInputValidation';

interface Props {}

const useStyles = makeStyles((theme: Theme) => {
  return {
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 48,
    },

    coverImageContainer: {
      width: '100%',

      height: 161,
      overflow: 'hidden',
      backgroundColor: colors.secondary,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    coverImage: { objectFit: 'scale-down' },

    createFamilyFormFamilyNameLabel: {
      marginTop: 32,
    },

    createFamilyFormFamilyNameField: {
      marginTop: 8,
      marginBottom: 16,
    },

    invitationInputField: {
      width: '100%',
    },

    invitationSubjectInputLabel: {},

    invitationMessageInputField: {},

    invitationInputLabel: {
      marginTop: 32,
    },

    receiverAdressListContainerVertical: {
      width: '100%',
      minHeight: 121,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },

    receiverAdressListVerticalChip: {
      marginTop: 16,
      marginLeft: 24,
    },

    invitationPreviewContainer: {},

    nextStepButtonRowContainer: {
      width: '100%',
      display: 'flex',
      marginTop: 32,
      flexDirection: 'row',
      alignItems: 'baseline',
      justifyContent: 'space-between',
    },

    toggleButtonRoot: {
      color: colors.textMedium,
    },

    toggleButtonSelected: {
      backgroundColor: `${colors.secondary} !important`,
      color: `${colors.textStrong} !important`,
    },
  };
});

const getFamilyCreationTipps = (t: any): FZUserTipp[] => {
  const numberOfTips = 5;

  const familyCreationTipps: FZUserTipp[] = [];

  for (let i = 0; i <= numberOfTips - 1; i++) {
    familyCreationTipps.push({
      headline: t(`createFamilyScreen-createFamilyTipp-${i}-headline`),
      message: t(`createFamilyScreen-createFamilyTipp-${i}-message`),
    });
  }

  return familyCreationTipps;
};

export type FamilyInvitationReceiverFormValues = {
  invitationVia: FamilyInvitationType;
  receivers: string[];
};

export const CreateFamilyScreen = (props: Props) => {
  const { t } = useTranslation();
  const [tipps] = useState(getFamilyCreationTipps(t));
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const currentlyCreatedFamily: CreateFamilyState = useSelector(
    selectCurrentlyCreatedFamily,
  );
  const invitationChipData =
    currentlyCreatedFamily.multiReceiverInvitation.receiverEmailAdresses;

  const dispatch = useDispatch();
  const inputValidationService = useInputValidationService();
  const loggedInUserProfile = useSelector(selectUserProfile);
  const currentlyActiveFamily = useSelector(selectCurrentlyActiveFamily);
  const history = useHistory();

  function handleFinishCreatingFamilyButtonClick(): void {
    // Construct the request body according to the chosen means of invitation, which can be Link, Email or skipped.
    let createFamilyRequestBody;
    if (
      currentlyCreatedFamily.meansOfInvitation ===
      FamilyInvitationType.EMAIL_INVITATION
    ) {
      createFamilyRequestBody = {
        name: currentlyCreatedFamily.familyName,
        invitations: [
          {
            invitationType: FamilyInvitationType.EMAIL_INVITATION,
            ...currentlyCreatedFamily.multiReceiverInvitation,
          },
          { invitationType: FamilyInvitationType.SHARING_LINK },
        ],
      };
    } else if (
      currentlyCreatedFamily.meansOfInvitation ===
      FamilyInvitationType.SHARING_LINK
    ) {
      createFamilyRequestBody = {
        name: currentlyCreatedFamily.familyName,
        invitations: [{ invitationType: FamilyInvitationType.SHARING_LINK }],
      };
    } else {
      createFamilyRequestBody = {
        name: currentlyCreatedFamily.familyName,
        invitations: [],
      };
    }

    dispatch(
      createFamilyRequest(createFamilyRequestBody, {
        successCallback: (createdFamily: FamilyDto) => {
          history.push('/family');

          //setActiveStep(3);
        },
        errorCallback: null,
      }),
    );
  }

  const CreateFamilyStep0: JSX.Element = (
    <React.Fragment>
      <ScrollToTop></ScrollToTop>
      <FZTypography variant="h3" color={colors.textStrong}>
        {t('createFamilyScreen-step-0-headline')}
      </FZTypography>

      <FZTypography
        variant={'body1'}
        color={colors.textMedium}
        style={{ textAlign: 'center', marginBottom: 32 }}
      >
        {t('createFamilyScreen-step-0-message')}
      </FZTypography>

      <Formik
        initialValues={{
          familyName: currentlyCreatedFamily.familyName || '',
        }}
        validate={(values: CreateFamilyFormValues) => {
          const errors: Partial<CreateFamilyFormValues> = {};

          if (
            !inputValidationService.testIfStringHasMinimumLength(
              values.familyName,
              2,
            )
          ) {
            errors.familyName = t(
              'createFamilyScreen-createFamilyForm-family-name-too-short-error-message',
            );
          }

          if (!values.familyName) {
            errors.familyName = t(
              'createFamilyScreen-createFamilyForm-no-family-name-given-error-message',
            );
          }

          if (
            !inputValidationService.testIfStringIsWithinMaximimumLenth(
              values.familyName,
              30,
            )
          ) {
            errors.familyName = t(
              'createFamilyScreen-createFamilyForm-family-name-too-long-error-message',
            );
          }

          return errors;
        }}
        onSubmit={(values, helpers) => {
          dispatch(setFamilyName(values.familyName));
          setActiveStep(activeStep + 1);
        }}
      >
        {({ submitForm, isSubmitting, errors, handleReset }) => (
          <>
            <FZCreateFamilyForm
              familyNameFieldName={'familyName'}
              familyNameFieldCharacterLimit={30}
              familyNameFieldLabel={t(
                'createFamilyScreen-createFamilyForm-familyName-field-label',
              )}
              familyNameFieldPlaceholder={t(
                'createFamilyScreen-createFamilyForm-familyName-field-placeholder',
              )}
              familyNameLabelClassName={classes.createFamilyFormFamilyNameLabel}
              familyNameFieldClassName={classes.createFamilyFormFamilyNameField}
            ></FZCreateFamilyForm>

            <FZButton
              label={t('createFamilyScreen-step-0-nextButton-label')}
              labelColor={colors.textMedium}
              backgroundColor={colors.secondary}
              type="submit"
              onClick={() => {
                submitForm();
              }}
            ></FZButton>
          </>
        )}
      </Formik>
    </React.Fragment>
  );

  const CreateFamilyStep1: JSX.Element = (
    <React.Fragment>
      <ScrollToTop></ScrollToTop>
      <Formik
        initialValues={{
          invitationVia: FamilyInvitationType.SHARING_LINK,

          receivers: [],
        }}
        validate={(values: FamilyInvitationReceiverFormValues) => {
          const errors: Partial<FamilyInvitationReceiverFormValues> = {};

          /* 
          Commented out since its valid to invite absolutely no one
          if (
            currentlyCreatedFamily.multiReceiverInvitation.receiverEmailAdresses
              .length === 0
          ) {
            errors.receivers = t(
              'createFamilyScreen-no-family-member-email-adress-added-error-message',
            );
          } */

          return errors;
        }}
        onSubmit={(values, helpers) => {
          dispatch(setMeansOfFamilyMemberInvitation(values.invitationVia));

          if (
            values.invitationVia === FamilyInvitationType.INVITATION_SKIPPED ||
            values.invitationVia === FamilyInvitationType.SHARING_LINK
          ) {
            setActiveStep(3);
          } else {
            setActiveStep(activeStep + 1);
          }
        }}
      >
        {({
          values,
          submitForm,
          isSubmitting,
          errors,
          handleReset,
          setFieldValue,
        }) => (
          <React.Fragment>
            <FZTypography variant="h3" color={colors.textStrong}>
              {t('createFamilyScreen-step-1-headline')}
            </FZTypography>
            <FZTypography
              variant={'body1'}
              color={colors.textMedium}
              style={{ textAlign: 'center', marginBottom: 32 }}
            >
              {t('createFamilyScreen-step-1-message')}
            </FZTypography>

            <ToggleButtonGroup
              exclusive
              onChange={(event, newValue: FamilyInvitationType) => {
                if (newValue) {
                  setFieldValue('invitationVia', newValue);
                }
              }}
              value={values.invitationVia}
              style={{ marginBottom: 16 }}
            >
              <ToggleButton
                value={FamilyInvitationType.EMAIL_INVITATION}
                classes={{
                  root: classes.toggleButtonRoot,
                  selected: classes.toggleButtonSelected,
                }}
                disabled
              >
                <>
                  {t(
                    'createFamilyScreen-inviteFamilyMemberVia-selector-email-invitation-label',
                  )}
                </>
              </ToggleButton>

              <ToggleButton
                value={FamilyInvitationType.SHARING_LINK}
                classes={{
                  root: classes.toggleButtonRoot,
                  selected: classes.toggleButtonSelected,
                }}
              >
                <>
                  {t(
                    'createFamilyScreen-inviteFamilyMemberVia-selector-link-invitation-label',
                  )}
                </>
              </ToggleButton>

              <ToggleButton
                value={FamilyInvitationType.INVITATION_SKIPPED}
                classes={{
                  root: classes.toggleButtonRoot,
                  selected: classes.toggleButtonSelected,
                }}
              >
                <>
                  {t(
                    'createFamilyScreen-inviteFamilyMemberVia-selector-skip-invitation-label',
                  )}
                </>
              </ToggleButton>
            </ToggleButtonGroup>

            {values.invitationVia === FamilyInvitationType.EMAIL_INVITATION && (
              <>
                <FZSliderWidget
                  contentItems={tipps}
                  keyColor={colors.secondary}
                  textColor={colors.textMedium}
                ></FZSliderWidget>
                <FZListEnterer
                  style={{ marginTop: 32 }}
                  validate={(
                    values: ListEntererValues,
                  ): Partial<ListEntererValues> => {
                    const errors: Partial<ListEntererValues> = {};

                    if (
                      !inputValidationService.validateEmail(
                        values.currentListEntryValue,
                      )
                    ) {
                      errors.currentListEntryValue = t(
                        'inviteFamilyMemberForm-receiverAdress-is-not-an-email-error-message',
                      );
                    }

                    if (
                      currentlyCreatedFamily.multiReceiverInvitation.receiverEmailAdresses.includes(
                        values.currentListEntryValue.toLocaleLowerCase(),
                      )
                    ) {
                      errors.currentListEntryValue = t(
                        'inviteFamilyMemberForm-receiverAdress-is-already-present-error-message',
                      );
                    }

                    return errors;
                  }}
                  buttonLabel={t(
                    'createFamilyScreen-add-family-member-email-adress-button-label',
                  )}
                  buttonBackgroundColor={colors.secondary}
                  buttonLabelColor={colors.textMedium}
                  keyColor={colors.secondary}
                  textFieldEnabled={true}
                  textFieldLabel={''}
                  textFieldPlaceholder={t(
                    'createFamilyScreen-add-family-member-email-adress-textField-placeholder',
                  )}
                  onElementSubmitted={(addedReceiverEmailAdress: string) => {
                    dispatch(
                      addReceiverToMultiReceiverInvitation(
                        addedReceiverEmailAdress.toLowerCase(),
                      ),
                    );
                  }}
                ></FZListEnterer>
                <FZChipList
                  variant="horizontal"
                  chipIcon={<SentimentVerySatisfiedRoundedIcon />}
                  chipListHeadline={t(
                    'createFamilyScreen-add-family-member-email-adress-list-headline',
                  )}
                  chipData={invitationChipData}
                  chipListEmptyLabel={t(
                    'createFamilyScreen-add-family-member-email-adress-list-empty-placeholder',
                  )}
                  itemsDeletable
                  onDeleteItem={(deletedReceiverEmailAdress: string) => {
                    dispatch(
                      removeReceiverFromMultiReceiverInvitation(
                        deletedReceiverEmailAdress,
                      ),
                    );
                  }}
                />
                {errors.receivers && (
                  <FormHelperText style={{ color: colors.danger }}>
                    {errors.receivers}
                  </FormHelperText>
                )}
              </>
            )}

            {values.invitationVia === FamilyInvitationType.SHARING_LINK && (
              <>
                <FZTypography
                  variant="body1"
                  style={{ marginTop: 16, marginBottom: 16 }}
                >
                  {t('createFamilyScreen-step-1-invite-via-link-message')}
                </FZTypography>

                <FZInfoCard keyColor={colors.secondary}>
                  <FZTypography variant="body2">
                    {t(
                      'createFamilyScreen-step-1-invite-via-link-caution-message',
                    )}
                  </FZTypography>
                </FZInfoCard>
              </>
            )}

            <div className={classes.nextStepButtonRowContainer}>
              <FZButton
                variant="outlined"
                label={t('createFamilyScreen-step-1-previousButton-label')}
                labelColor={colors.textMedium}
                backgroundColor={colors.white}
                onClick={() => {
                  setActiveStep(activeStep - 1);
                }}
              />

              <FZButton
                label={t('createFamilyScreen-step-1-nextButton-label')}
                labelColor={colors.textMedium}
                backgroundColor={colors.secondary}
                onClick={() => {
                  submitForm();
                }}
              ></FZButton>
            </div>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );

  const CreateFamilyStep2: JSX.Element = (
    <React.Fragment>
      <ScrollToTop></ScrollToTop>
      <Formik
        initialValues={{
          invitationSubject:
            currentlyCreatedFamily.multiReceiverInvitation.invitationSubject ||
            '',
          invitationMessage:
            currentlyCreatedFamily.multiReceiverInvitation.personalMessage ||
            '',
        }}
        validate={values => {
          const errors: Partial<InvitateFamilyMemberFormValues> = {};

          if (
            !inputValidationService.testIfStringIsWithinMaximimumLenth(
              values.invitationMessage,
              500,
            )
          ) {
            errors.invitationMessage = t(
              'inviteFamilyMemberForm-invitationMessage-too-long-error-message',
            );
          }

          return errors;
        }}
        onSubmit={(
          values,
          helpers: FormikHelpers<InvitateFamilyMemberFormValues>,
        ) => {
          dispatch(SetMultiReceiverInvitationSubject(values.invitationSubject));
          dispatch(SetMultiReceiverInvitationMessage(values.invitationMessage));

          setActiveStep(activeStep + 1);
        }}
      >
        {({ submitForm, isSubmitting, errors, handleReset, setFieldValue }) => (
          <>
            <FZTypography variant="h3" color={colors.textStrong}>
              {t('createFamilyScreen-step-2-headline')}
            </FZTypography>
            <FZTypography
              variant={'body1'}
              color={colors.textMedium}
              style={{ textAlign: 'center', marginBottom: 32 }}
            >
              {t('createFamilyScreen-step-2-message')}
            </FZTypography>
            <FZInviteFamilyMemberForm
              invitationSubjectFieldName="invitationSubject"
              invitationMessageFieldName="invitationMessage"
              invitationSubjectFieldLabel={t(
                'inviteFamilyMemberForm-invitationSubject-field-label',
              )}
              invitationSubjectFieldPlaceholder={t(
                'inviteFamilyMemberForm-invitationSubject-field-placeholder',
              )}
              invitationMessageFieldLabel={t(
                'inviteFamilyMemberForm-invitationMessage-field-label',
              )}
              invitationMessageFieldPlaceholder={t(
                'inviteFamilyMemberForm-invitationMessage-field-placeholder',
                { username: loggedInUserProfile.displayName },
              )}
              invitationSubjectLabelClassName={
                classes.invitationSubjectInputLabel
              }
              invitationSubjectFieldClassName={classes.invitationInputField}
              invitationMessageFieldClassName={
                classes.invitationMessageInputField
              }
              invitationMessageFieldCharacterLimit={500}
            />

            <div className={classes.nextStepButtonRowContainer}>
              <FZButton
                variant="outlined"
                label={t('createFamilyScreen-step-2-previousButton-label')}
                labelColor={colors.textMedium}
                backgroundColor={colors.white}
                onClick={() => {
                  setActiveStep(activeStep - 1);
                }}
              />

              <FZButton
                label={t(
                  'inviteFamilyMemberForm-invitationMessage-field-copyPlaceholderToValueButton-label',
                )}
                labelColor={colors.textMedium}
                variant="outlined"
                onClick={() => {
                  setFieldValue(
                    'invitationSubject',
                    t(
                      'inviteFamilyMemberForm-invitationSubject-field-placeholder',
                    ),
                    false,
                  );
                  setFieldValue(
                    'invitationMessage',
                    t(
                      'inviteFamilyMemberForm-invitationMessage-field-placeholder',
                      { username: loggedInUserProfile.displayName },
                    ),
                    false,
                  );
                }}
              ></FZButton>

              <FZButton
                label={t('createFamilyScreen-step-2-nextButton-label')}
                labelColor={colors.textMedium}
                backgroundColor={colors.secondary}
                type="submit"
                onClick={() => {
                  submitForm();
                }}
              ></FZButton>
            </div>
          </>
        )}
      </Formik>
    </React.Fragment>
  );

  const CreateFamilyStep3: JSX.Element = (
    <React.Fragment>
      <ScrollToTop></ScrollToTop>
      <FZTypography variant="overline" color={colors.textStrong}>
        {t('createFamilyScreen-step-3-overline')}
      </FZTypography>

      <FZTypography variant="h3" color={colors.textStrong}>
        {t('createFamilyScreen-step-3-headline')}
      </FZTypography>
      <FZTypography
        variant={'body1'}
        color={colors.textMedium}
        style={{ textAlign: 'center', marginBottom: 32 }}
      >
        {t('createFamilyScreen-step-3-message')}
      </FZTypography>

      <FZFieldLabel
        label={t(
          'createFamilyScreen-step-3-familyCreationPreview-familyName-label',
        )}
      ></FZFieldLabel>

      <FZTypography
        variant={'body1'}
        style={{ textAlign: 'center', marginTop: 16, marginBottom: 32 }}
      >
        {currentlyCreatedFamily.familyName}
      </FZTypography>

      <FZFieldLabel
        label={t('createFamilyScreen-step-3-chosen-means-of-invitation-label')}
      ></FZFieldLabel>

      <FZTypography
        variant={'body1'}
        style={{ textAlign: 'center', marginTop: 16, marginBottom: 32 }}
      >
        {currentlyCreatedFamily.meansOfInvitation ===
          FamilyInvitationType.INVITATION_SKIPPED &&
          t('createFamilyScreen-step-3-chosen-means-of-invitation-is-skip')}
        {currentlyCreatedFamily.meansOfInvitation ===
          FamilyInvitationType.EMAIL_INVITATION &&
          t('createFamilyScreen-step-3-chosen-means-of-invitation-is-email')}
        {currentlyCreatedFamily.meansOfInvitation ===
          FamilyInvitationType.SHARING_LINK &&
          t('createFamilyScreen-step-3-chosen-means-of-invitation-is-link')}
      </FZTypography>

      {currentlyCreatedFamily.meansOfInvitation ===
        FamilyInvitationType.EMAIL_INVITATION && (
        <FZFamilyInvitationPreview
          includeReceiverList
          receivers={
            currentlyCreatedFamily.multiReceiverInvitation.receiverEmailAdresses
          }
          className={classes.invitationPreviewContainer}
          headline={t(
            'createFamilyScreen-step-3-invitationMessageOverview-label',
          )}
          subject={
            currentlyCreatedFamily.multiReceiverInvitation.invitationSubject
          }
          message={
            currentlyCreatedFamily.multiReceiverInvitation.personalMessage
          }
        />
      )}

      <div className={classes.nextStepButtonRowContainer}>
        <FZButton
          variant="outlined"
          label={t('createFamilyScreen-step-3-previousButton-label')}
          labelColor={colors.textMedium}
          backgroundColor={colors.white}
          onClick={() => {
            setActiveStep(activeStep - 1);
          }}
        />

        <FZButton
          label={t('createFamilyScreen-step-3-nextButton-label')}
          labelColor={colors.textMedium}
          backgroundColor={colors.secondary}
          onClick={handleFinishCreatingFamilyButtonClick}
        ></FZButton>
      </div>
    </React.Fragment>
  );

  const CreateFamilyStep4: JSX.Element = (
    <React.Fragment>
      <ScrollToTop></ScrollToTop>
      <FZTypography variant="overline" color={colors.textStrong}>
        {t('createFamilyScreen-step-4-overline')}
      </FZTypography>

      <FZTypography variant="h3" color={colors.textStrong}>
        {t('createFamilyScreen-step-4-headline')}
      </FZTypography>
      <FZTypography
        variant={'body1'}
        color={colors.textMedium}
        style={{ textAlign: 'center', marginBottom: 32 }}
      >
        {t('createFamilyScreen-step-4-message')}
      </FZTypography>

      <FZFieldLabel
        label={t(
          'createFamilyScreen-step-4-familyCreationPreview-familyName-label',
        )}
      ></FZFieldLabel>

      <FZTypography
        variant={'body1'}
        style={{ textAlign: 'center', marginTop: 16, marginBottom: 32 }}
      >
        {currentlyActiveFamily?.name}
      </FZTypography>

      <FZFieldLabel
        label={t('createFamilyScreen-step-4-chosen-means-of-invitation-label')}
      ></FZFieldLabel>

      <div className={classes.nextStepButtonRowContainer}>
        <FZButton
          label={t('createFamilyScreen-step-4-nextButton-label')}
          labelColor={colors.textMedium}
          backgroundColor={colors.secondary}
          onClick={() => {
            history.push('/family');
          }}
        ></FZButton>
      </div>
    </React.Fragment>
  );

  return (
    <div>
      <FZAppBar
        activeTab={2}
        navbarLeftContent={
          <FZNavigateBackButton
            backDestinationLabel={t(
              'createFamilyScreen-back-destination-label',
            )}
          />
        }
        hideTopNavigation
      ></FZAppBar>
      <Container>
        <Grid container>
          <Grid item xs={1} md={2}></Grid>
          <Grid item xs={10} md={8} className={classes.contentContainer}>
            {activeStep <= 4 && (
              <>
                <div className={classes.coverImageContainer}>
                  <img
                    alt={''}
                    src={familyIllustration}
                    className={classes.coverImage}
                  ></img>
                </div>
                <FZStepDenominator
                  stepLabel={t('createFamilyScreen-step-label')}
                  stepOfLabel={t('createFamilyScreen-step-of-label')}
                  currentStep={activeStep}
                  totalSteps={4}
                  style={{ marginTop: 21, marginBottom: 8 }}
                ></FZStepDenominator>{' '}
              </>
            )}

            {activeStep === 0 && CreateFamilyStep0}
            {activeStep === 1 && CreateFamilyStep1}
            {activeStep === 2 && CreateFamilyStep2}
            {activeStep === 3 && CreateFamilyStep3}
            {activeStep === 4 && CreateFamilyStep4}
          </Grid>
          <Grid item xs={1} md={2}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CreateFamilyScreen;
