import React from 'react';
import { InputValidationService } from '../../common/services';
import AccountService from '../../common/services/AccountService';
import ContactRequestService from '../../common/services/ContactRequestService';
import { DateTransformationService } from '../../common/services/DateTransformationService';
import ImageService from '../../common/services/ImageService';
import UserService from '../../common/services/UserService';

export type ServicesProps = {
  accountService: AccountService;
  userService: UserService;
  imageService: ImageService;
  inputValidationService: InputValidationService;
  dateTransformationService: DateTransformationService;
  contactRequestService: ContactRequestService;
};

const services = React.createContext<ServicesProps | null>(null);

export default services;
export const ServicesProvider = services.Provider;
export const ServicesConsumer = services.Consumer;
