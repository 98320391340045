import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FamilyInvitation,
  isFamilyInvitation,
} from '../../../common/redux/families/types';
import { isUserProfile, UserProfile } from '../../../common/redux/users/types';
import FZTypography from '../../01_atoms/FZTypography/FZTypography';
import FZProfilePictureWidget from '../../02_molecules/FZProfilePictureWidget/FZProfilePictureWidget';

const useStyles = makeStyles((theme: Theme) => {
  return {
    card: {
      //minHeight: 512,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginRight: 16,
      marginLeft: 16,
    },

    avatar: {},
    cardContent: {},
    closeButton: {},
  };
});

interface Props {
  userProfile: UserProfile | FamilyInvitation;
  actions?: JSX.Element | JSX.Element[];
  headerMenuItems?: HeaderMenuItem[];
  open: boolean;
  onClose?: () => void;
}
export interface HeaderMenuItem {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
}

export default function FZFamilyMemberProfileCard({
  userProfile,
  actions,
  headerMenuItems,

  onClose,
}: Props): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (isUserProfile(userProfile)) {
    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            userProfile?.profileImage?.uuid && (
              <FZProfilePictureWidget
                editable={false}
                profilePictureUuid={userProfile?.profileImage?.uuid}
              ></FZProfilePictureWidget>
            )
          }
          action={
            <>
              {headerMenuItems.length > 0 && (
                <IconButton aria-label="settings" onClick={handleClick}>
                  <MoreVertRoundedIcon />
                </IconButton>
              )}

              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseRoundedIcon />
              </IconButton>
            </>
          }
          title={
            userProfile?.displayName ||
            t('FZfamilyMemberProfileCard-no-displayName-given-label')
          }
          subheader={userProfile?.userName}
        />

        <CardContent>
          {/* {!userProfile && (
          <FZSpinner
            color={colors.secondary}
            statusMessage={t(
              'FZFamilyMemberProfileCard-profile-not-yet-fetched-message',
            )}
          ></FZSpinner>
        )} */}

          {userProfile && (
            <>
              <FZTypography variant="h5">{`Familienmitgliedsprofil`}</FZTypography>
              <FZTypography variant="body1">{`Hier wirst du bald Meinungen von ${userProfile?.displayName} zur vergangenen Ausgabe eurer Familienzeitung finden.`}</FZTypography>
            </>
          )}

          {actions}
        </CardContent>

        <CardActions></CardActions>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {headerMenuItems.map(menuItem => {
            return (
              <MenuItem
                key={menuItem.label}
                onClick={() => {
                  menuItem.onClick();
                  handleMenuClose();
                }}
                disabled={menuItem.disabled}
              >
                {menuItem.label}
              </MenuItem>
            );
          })}
        </Menu>
      </Card>
    );
  } else if (isFamilyInvitation(userProfile)) {
    return (
      // Item is not a user profile but only an invitation
      <Card className={classes.card}>
        <CardHeader
          action={
            <>
              {headerMenuItems.length > 0 && (
                <IconButton aria-label="settings" onClick={handleClick}>
                  <MoreVertRoundedIcon />
                </IconButton>
              )}

              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseRoundedIcon />
              </IconButton>
            </>
          }
          title={
            userProfile?.receiverEmailAdress ||
            t('FZfamilyMemberProfileCard-no-displayName-given-label')
          }
          subheader={t('FZFamilyMemberProfileCard-invitation-label')}
        />

        <CardContent>
          {/* {!userProfile && (
            <FZSpinner
              color={colors.secondary}
              statusMessage={t(
                'FZFamilyMemberProfileCard-profile-not-yet-fetched-message',
              )}
            ></FZSpinner>
          )} */}

          {userProfile && (
            <>
              <FZTypography variant="h5">{`Familienmitgliedsprofil`}</FZTypography>
            </>
          )}

          {actions}
        </CardContent>

        <CardActions></CardActions>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {headerMenuItems.map(menuItem => {
            return (
              <MenuItem
                key={menuItem.label}
                onClick={() => {
                  menuItem.onClick();
                  handleMenuClose();
                }}
                disabled={menuItem.disabled}
              >
                {menuItem.label}
              </MenuItem>
            );
          })}
        </Menu>
      </Card>
    );
  }

  return null;
}
