import { apiClient, API_CONFIG } from '../api';
import {
  UpdateUserProfileDto,
  UpdateUserSettingsDto,
} from '../redux/users/types';

export type PutProfileImageRequest = {
  url: string;
  contentType: string;
};

class UserService {
  /**
   * Issues a request to update a users settings
   * @param userUid - Unique identifier for the user whos settings shall be updated
   * @param newSettings - the settings that shall replace the existing ones
   * @param accessToken - an access token to allow api access
   */
  async updateUserSettings(
    userUid: string,
    newSettings: UpdateUserSettingsDto,
    accessToken: string,
  ) {
    const url = API_CONFIG.USER_SERVICE.PUT_USER_SETTINGS_URI(userUid);

    return apiClient.put(url, newSettings, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  /**
   * Issues a request to update a users profile
   * following partial update semantics
   * @param {string} userUid - unique identifier for the user whose profile shall be updated
   * @param profileValuesToUpdatePartially - The values of the profile that shall be updated
   * @param accessToken -  an access token to allow api access
   */
  async updateUserProfile(
    userUid: string,
    profileValuesToUpdatePartially: UpdateUserProfileDto,
    accessToken: string,
  ) {
    const url = API_CONFIG.USER_SERVICE.UPDATE_OWN_PROFILE_URI(userUid);

    return apiClient.put(url, profileValuesToUpdatePartially, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  /**
   * Fetches a users profile image.
   * @param userUid - Unique identifier for the user whos profile shall be fetched
   * @param authToken -  an access token to allow api access
   */
  async fetchUserProfile(userUid: string, authToken: string) {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const uriString = API_CONFIG.USER_SERVICE.GET_OWN_PROFILE_URI(userUid);

    try {
      const response = apiClient.get(uriString, { headers });
      return response;
    } catch (error) {
      return error;
    }
  }

  /**
   * Introduces a new User at the backend.
   * Creates a new user record if this user was previously unknown.
   * @param authToken - an access token to allow api access
   */
  async introduceUser(authToken: string) {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = apiClient.post(
        API_CONFIG.USER_SERVICE.INTRODUCE_SELF_URI(),
        {},
        { headers },
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  /**
   *  Updates a users profile image by fetching a presigned upload url, uploading the image, then issuing a PUT to the myProfileImage Resource.
   * @param requestBody - The requestbody of the updateProfileImageRequest
   * @param userUid - Unique identifier for the user whos profile shall be fetched
   * @param accessToken -  an access token to allow api access
   */
  async updateProfilePicture(
    requestBody: PutProfileImageRequest,
    userUid: string,
    accessToken: string,
  ) {
    const url = API_CONFIG.USER_SERVICE.PUT_PROFILE_IMAGE_URI(userUid);

    return apiClient.put(url, requestBody, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
}
export default UserService;
