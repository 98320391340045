import { makeStyles } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import i18next from 'i18next';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { Family } from '../../../../common/models/Family';
import { editFamilyRequest } from '../../../../common/redux/families/family.actions';
import { selectCurrentlyActiveFamily } from '../../../../common/redux/families/family.selectors';
import { EditFamilyDto } from '../../../../common/redux/families/types';
import { ActionCallbacks } from '../../../../common/redux/main/types';
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../../common/redux/ui/ui.actions';
import colors from '../../../00_Constants/colors';
import { FZAutoSavingForm } from '../../../01_atoms/FZAutoSavingForm/FZAutoSavingForm';
import FZButton from '../../../01_atoms/FZButton/FZButton';
import FZInfoCard from '../../../01_atoms/FZInfoCard/FZInfoCard';
import FZNavigateBackButton from '../../../01_atoms/FZNavigateBackButton/FZNavigateBackButton';
import FZPageContainer from '../../../01_atoms/FZPageContainer/FZPageContainer';
import FZTextInputField from '../../../01_atoms/FZTextInputField/FZTextInputField';
import FZAppBar from '../../../03_organisms/FZAppBar/FZAppBar';
import FZSettingsCard from '../../../03_organisms/FZSettingsCard/FZSettingsCard';
import FZSettingsCardItem from '../../../03_organisms/FZSettingsCard/FZSettingsCardItem';
import ContentPageTemplate from '../../../04_templates/ContentPageTemplate/ContentPageTemplate';
import useConfig from '../../../Hooks/useConfig';

const useStyles = makeStyles({
  paymentRepresentativeSelector: {},
});

type EditFamilyFormValues = {
  familyName: string;
  paymentRepresentativeUid: string;
};

interface Props {}

export default function EditFamilyScreen(props: Props): ReactElement {
  const classes = useStyles();
  const currentlyActiveFamily: Family = useSelector(
    selectCurrentlyActiveFamily,
  );
  /*  const familyMembers: UserProfile[] = useSelector(selectFamilyMembers); */
  /* const history = useHistory(); */
  const { t } = useTranslation();
  const appConfig = useConfig();

  const dispatch = useDispatch();
  const [activeField, setActiveField] = useState('');

  /**
   * Validates the editFamilyForm in a synchronous manner
   * @param {Family} currentlyActiveFamily the family that is currently activ.
   * @param {Partial<EditFamilyFormValues>} the form values
   * @returns {boolean} wether the form is valid for submission or not.
   */
  function validateFormSynchronously(
    currentlyActiveFamily: Family,
    values: Partial<EditFamilyFormValues>,
  ): boolean {
    let formValid = false;

    if (
      values.familyName !== currentlyActiveFamily?.name ||
      values.paymentRepresentativeUid !==
        currentlyActiveFamily.paymentRepresentative?.uid
    ) {
      formValid = true;
    }

    return formValid;
  }

  /**
   * Handles the submission of the EditFamilyForm
   * @param updatedFamily
   * @param param1
   * @param dispatch
   * @param t
   */
  function submitEditFamilySettings(
    editedFamily: EditFamilyDto,
    { setSubmitting }: FormikHelpers<any>,
    dispatch: Dispatch<AnyAction>,
    t: i18next.TFunction,
  ) {
    // Dispatch Update of family settings / subscriptions
    const actionCallbacks: ActionCallbacks = {
      successCallback: () => {
        dispatch(
          showSuccessSnackbar(
            t('editFamilyScreen-updating-family-successful-message'),
            true,
          ),
        );
      },
      errorCallback: () => {
        dispatch(
          showErrorSnackbar(
            t('editFamilyScreen-updating-family-error-message'),
            true,
          ),
        );
      },
    };

    dispatch(editFamilyRequest(editedFamily, actionCallbacks));
  }

  /**
   * Handles a click onto a button that shall stop the deletion of a family
   * that has previously been flagged for deletion.
   */
  function handleStopFamilyDeletionButtonClick() {
    const editedFamily: EditFamilyDto = {
      uuid: currentlyActiveFamily.uuid,
      isDeleted: false,
    };

    // Dispatch Update of family settings / subscriptions
    const actionCallbacks: ActionCallbacks = {
      successCallback: () => {
        dispatch(
          showSuccessSnackbar(
            t('editFamilyScreen-reactivating-family-successful-message'),
            true,
          ),
        );
      },
      errorCallback: () => {
        dispatch(
          showErrorSnackbar(
            t('editFamilyScreen-reactivating-family-error-message'),
            true,
          ),
        );
      },
    };

    dispatch(editFamilyRequest(editedFamily, actionCallbacks));

    return;
  }

  /**
   * Triggers whenever a new form field is focused
   * @param e: the focus-event
   */
  function handleFormFieldFocus(e: any) {
    setActiveField(e.target.name);
  }

  /**
   * Triggers whenever a new form field is no longer focused (blurred)
   * @param e: the focus-event
   */
  function handleFormFieldBlur(e: any) {
    setActiveField('');
  }

  return (
    <FZPageContainer>
      <FZAppBar
        navbarLeftContent={
          <FZNavigateBackButton
            backDestinationLabel={t('editFamilyScreen-backDestinationLabel')}
          />
        }
      ></FZAppBar>
      <ContentPageTemplate>
        {currentlyActiveFamily?.isDeleted && (
          <FZInfoCard
            keyColor={colors.danger}
            actions={
              <FZButton
                label={t(
                  'editFamilyScreen-general-settings-delete-family-secondary-action-reactive-family-button',
                )}
                backgroundColor={colors.secondary}
                variant="contained"
                onClick={handleStopFamilyDeletionButtonClick}
              ></FZButton>
            }
          >
            {t('editFamilyScreen-general-settings-delete-family-value', {
              familyWillBeDeletedAtDate: new Date(
                currentlyActiveFamily.willBeDeletedAt,
              ),
            })}
          </FZInfoCard>
        )}

        <Formik
          initialValues={{
            familyName: currentlyActiveFamily.name,
            paymentRepresentativeUid:
              currentlyActiveFamily.paymentRepresentative?.uid,
          }}
          validate={(values: EditFamilyFormValues) => {
            const errors: Partial<EditFamilyFormValues> = {};

            if (!values.familyName) {
              errors.familyName = t(
                'editFamilyScreen-general-settings-no-family-name-given-error-message',
              );
            }

            return errors;
          }}
          onSubmit={(
            values: EditFamilyFormValues,
            helpers: FormikHelpers<EditFamilyFormValues>,
          ) => {
            const formValid: boolean = validateFormSynchronously(
              currentlyActiveFamily,
              values,
            );

            if (formValid) {
              const newSettings: EditFamilyDto = {
                uuid: currentlyActiveFamily.uuid,
                name: values.familyName,
                paymentRepresentativeUid: values.paymentRepresentativeUid,
              };

              submitEditFamilySettings(newSettings, helpers, dispatch, t);
            }
            helpers.setSubmitting(false);
          }}
        >
          {(formikProps: FormikProps<EditFamilyFormValues>) => {
            const entireFormDisabled = currentlyActiveFamily?.isDeleted;

            return (
              <Form>
                <FZAutoSavingForm
                  debounceMs={1000}
                  loadingMessage={t('editFamilyScreen-saving-settings-message')}
                  shouldSave={() => {
                    return activeField === '';
                  }}
                />

                <FZSettingsCard
                  headline={t('editFamilyScreen-general-settings-headline')}
                  subheadline={t(
                    'editFamilyScreen-general-settings-subheadline',
                  )}
                  keyColor={colors.secondary}
                  disableOverline
                >
                  <FZSettingsCardItem
                    elementIsButton={false}
                    label={t(
                      'editFamilyScreen-general-settings-familyName-label',
                    )}
                    tooltip={t(
                      'editFamilyScreen-general-settings-familyName-tooltip',
                    )}
                    disabled={entireFormDisabled}
                    value={
                      <Field
                        className={classes.paymentRepresentativeSelector}
                        name="familyName"
                        component={FZTextInputField}
                        color={colors.secondary}
                        disableUnderline
                        inputProps={{
                          id: 'familyName',
                        }}
                        displayEmpty={true}
                        /*   placeholder={currentlyActiveFamily?.name} */
                        defaultValue={currentlyActiveFamily?.name}
                        disabled={
                          entireFormDisabled ||
                          !appConfig.FEATURE_FLAGS.FAMILY
                            .REACT_APP_FEATURE_FLAG_EDIT_FAMILY_ENABLED
                        }
                        onFocus={handleFormFieldFocus}
                        onBlur={handleFormFieldBlur}
                        displayCharacterCount={activeField === 'familyName'}
                        characterLimit={30}
                        enforceCharacterLimit
                      />
                    }
                  />
                </FZSettingsCard>

                {/*   <FZSettingsCard
                  headline={t('editFamilyScreen-financial-settings-headline')}
                  subheadline={t(
                    'editFamilyScreen-financial-settings-subheadline',
                  )}
                  keyColor={colors.secondary}
                >
                  <FZSettingsCardItem
                    elementIsButton={false}
                    label={t(
                      'editFamilyScreen-payment-representative-selector-label',
                    )}
                    tooltip={t(
                      'editFamilyScreen-payment-representative-selector-tooltip',
                    )}
                    disabled={
                      entireFormDisabled ||
                      !appConfig.FEATURE_FLAGS.USER
                        .REACT_APP_FEATURE_FLAG_UPDATE_DEFAULT_ACTIVE_FAMILY_ENABLED
                    }
                    value={
                      <Field
                        className={classes.paymentRepresentativeSelector}
                        name="paymentRepresentativeUid"
                        component={FZSelectInputField}
                        color={colors.white}
                        disableUnderline
                        inputProps={{
                          id: 'paymentRepresentativeUid',
                        }}
                        displayEmpty={true}
                        placeholder={t(
                          'editFamilyScreen-paymentRepresentativeUid-not-set-yet',
                        )}
                        defaultValue={
                          currentlyActiveFamily.paymentRepresentative
                            ?.displayName || null
                        }
                        disabled={
                          entireFormDisabled ||
                          !appConfig.FEATURE_FLAGS.USER
                            .REACT_APP_FEATURE_FLAG_UPDATE_DEFAULT_ACTIVE_FAMILY_ENABLED
                        }
                        onFocus={handleFormFieldFocus}
                        onBlur={handleFormFieldBlur}
                      >
                        {familyMembers &&
                          familyMembers.length > 0 &&
                          familyMembers.map((member: UserProfile) => {
                            return (
                              <MenuItem
                                key={member.uid}
                                value={member.uid}
                                selected={
                                  currentlyActiveFamily.paymentRepresentative
                                    ?.uid === member.uid
                                }
                              >
                                <Typography variant="body2">
                                  {`${member.displayName} (${member.userName})`}
                                </Typography>
                              </MenuItem>
                            );
                          })}
                      </Field>
                    }
                  ></FZSettingsCardItem>

                  <FZSettingsCardItem
                    elementIsButton={true}
                    label={t(
                      'editFamilyScreen-payment-settings-subscriptions-label',
                    )}
                    tooltip={t(
                      'editFamilyScreen-payment-settings-subscriptions-tooltip',
                    )}
                    disabled={entireFormDisabled}
                    secondaryActionIcon={<NavigateNextRoundedIcon />}
                    onSecondaryAction={() => {
                      history.push('/family/edit/subscriptions');
                    }}
                  ></FZSettingsCardItem>
                </FZSettingsCard> */}
              </Form>
            );
          }}
        </Formik>
      </ContentPageTemplate>
    </FZPageContainer>
  );
}
