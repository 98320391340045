import { Card } from '@material-ui/core';
import { Formik } from 'formik';
import { CREATED, TOO_MANY_REQUESTS } from 'http-status-codes';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import fzColorfulBackgroundImage from '../../../assets/images/FZ_colorful_background.jpg';
import { CreateContactRequestDto } from '../../../common/redux/contactRequests/DTO/ContactRequestDto';
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../common/redux/ui/ui.actions';
import { FullWidthBackground } from '../../01_atoms/FullWidthBackground/FullWidthBackground';
import FZContactForm, {
  ContactFormValues,
} from '../../03_organisms/FZContactForm/FZContactForm';
import ContentPageTemplate from '../../04_templates/ContentPageTemplate/ContentPageTemplate';
import useConfig from '../../Hooks/useConfig';
import { useContactRequestService } from '../../Hooks/useContactRequestService';
import { useInputValidationService } from '../../Hooks/useInputValidation';

interface Props {}

export default function ContactScreen(props: Props): ReactElement {
  const { t } = useTranslation();
  const inputValidationService = useInputValidationService();
  const contactRequestService = useContactRequestService();
  const appConfig = useConfig();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <FullWidthBackground imageSource={fzColorfulBackgroundImage}>
      <ContentPageTemplate>
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 32,
          }}
        >
          <Formik
            initialValues={{
              name: '',
              nameForHmns: '',
              email: '',
              emailForHmns: '',
              subject: '',
              subjectForHmns: '',
              message: '',
              messageForHmns: '',
              consentToTermsOfServiceAndDataProtectionAgreementGiven: false,
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={values => {
              const errors: Partial<ContactFormValues> = {};

              if (!inputValidationService.validateEmail(values.emailForHmns)) {
                errors.emailForHmns = t('contactForm-error-invalid-email');
              }

              if (
                !inputValidationService.testIfStringHasMinimumLength(
                  values.subjectForHmns,
                  5,
                )
              ) {
                errors.subjectForHmns = t(
                  'contactForm-error-subject-too-short',
                );
              }

              if (
                !inputValidationService.testIfStringIsWithinMaximimumLenth(
                  values.subjectForHmns,
                  100,
                )
              ) {
                errors.subjectForHmns = t('contactForm-error-subject-too-long');
              }

              if (
                !inputValidationService.testIfStringHasMinimumLength(
                  values.messageForHmns,
                  5,
                )
              ) {
                errors.messageForHmns = t(
                  'contactForm-error-message-too-short',
                );
              }

              if (
                !inputValidationService.testIfStringIsWithinMaximimumLenth(
                  values.messageForHmns,
                  500,
                )
              ) {
                errors.messageForHmns = t('contactForm-error-message-too-long');
              }

              if (
                !values.consentToTermsOfServiceAndDataProtectionAgreementGiven
              ) {
                errors.consentToTermsOfServiceAndDataProtectionAgreementGiven = true;
              }

              return errors;
            }}
            onSubmit={async (values, helpers) => {
              const payload: Partial<CreateContactRequestDto> = {
                name: values.nameForHmns,
                nameHoneypot: values.name,

                email: values.emailForHmns,
                emailHoneypot: values.email,

                subject: values.subjectForHmns,
                subjectHoneypot: values.subject,

                message: values.subjectForHmns,
                messageHoneypot: values.subject,

                consentToDataProcessingAgreementGiven:
                  values.consentToTermsOfServiceAndDataProtectionAgreementGiven,
                dpaConsentIsForVersionOfDpa:
                  appConfig.LEGAL_CONFIG
                    .DATA_PROCESSING_AGREEMENT_DOCUMENT_VERSION,
                consentToTermsOfServiceGiven:
                  values.consentToTermsOfServiceAndDataProtectionAgreementGiven,
                tosConsentIsForVersionOfToS:
                  appConfig.LEGAL_CONFIG.TERMS_OF_SERVICE_DOCUMENT_VERSION,
              };

              helpers.setSubmitting(true);

              const response = await contactRequestService.postContactRequest(
                payload,
              );

              if (response.status === TOO_MANY_REQUESTS) {
                dispatch(
                  showErrorSnackbar(t('contactForm-too-many-requests'), true),
                );
              } else if (response.status === CREATED) {
                dispatch(
                  showSuccessSnackbar(t('contactForm-status-created'), true),
                );
              } else {
                dispatch(
                  showErrorSnackbar(t('contactForm-request-failed'), true),
                );
              }

              helpers.setSubmitting(false);
              helpers.resetForm();
            }}
          >
            {({
              submitForm,
              isSubmitting,
              errors,
              handleReset,
              setFieldValue,
            }) => (
              <FZContactForm
                nameFieldName={'nameForHmns'}
                nameHoneypotFieldName={'name'}
                nameFieldLabel={t('contactForm-name-field-label')}
                nameFieldPlaceholder={t('contactForm-name-field-placeholder')}
                emailFieldName={'emailForHmns'}
                emailHoneypotFieldName={'email'}
                emailFieldLabel={t('contactForm-email-field-label')}
                emailFieldPlaceholder={t('contactForm-email-field-placeholder')}
                subjectFieldName={'subjectForHmns'}
                subjectHoneypotFieldName={'subject'}
                subjectFieldLabel={t('contactForm-subject-field-label')}
                subjectFieldPlaceholder={t(
                  'contactForm-subject-field-placeholder',
                )}
                subjectFieldCharacterLimit={100}
                messageFieldName={'messageForHmns'}
                messageHoneypotFieldName={'message'}
                messageFieldLabel={t('contactForm-message-field-label')}
                messageFieldPlaceholder={t(
                  'contactForm-message-field-placeholder',
                )}
                messageFieldCharacterLimit={500}
                consentToTermsOfServiceAndDataProtectionAgreementFieldName={
                  'consentToTermsOfServiceAndDataProtectionAgreementGiven'
                }
                abortButtonLabel={t('contactForm-abortButton-label')}
                onAbort={() => {
                  history.goBack();
                }}
                sendButtonLabel={t('contactForm-sendButton-label')}
                errors={errors}
                onSubmit={() => {
                  submitForm();
                }}
              ></FZContactForm>
            )}
          </Formik>
        </Card>
      </ContentPageTemplate>
    </FullWidthBackground>
  );
}
