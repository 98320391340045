import { IconButton, makeStyles } from '@material-ui/core';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import React, { ReactElement, useState } from 'react';

interface Props {
  children: JSX.Element[];
  autoplay?: boolean;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 250, // 158 in design spec, but thats too small to contain all the texts without the element pushing content below it down by growing,
    padding: 32,
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  nextContentPieceIcon: {
    flexBasis: '10%',
  },
});

export default function FZGenericSlider({
  children,
  autoplay,
}: Props): ReactElement {
  const [activeSlide, setActiveSlide] = useState(0);
  const classes = useStyles();

  if (autoplay) {
    setTimeout(() => {
      setActiveSlide((activeSlide + 1) % children.length);
    }, 5000);
  }

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <IconButton
          aria-label="display previous tipp button"
          className={classes.nextContentPieceIcon}
          onClick={() => {
            setActiveSlide(
              activeSlide === 0 ? children.length - 1 : activeSlide - 1,
            );
          }}
          disabled={false}
        >
          <KeyboardArrowLeftRoundedIcon />
        </IconButton>

        {children[activeSlide]}

        <IconButton
          aria-label="display next tipp button"
          className={classes.nextContentPieceIcon}
          onClick={() => {
            setActiveSlide((activeSlide + 1) % children.length);
          }}
          disabled={false}
        >
          <KeyboardArrowRightRoundedIcon />
        </IconButton>
      </div>
    </div>
  );
}
