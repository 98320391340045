import { makeStyles, Theme } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../../00_Constants/colors';
import FZButton from '../../../01_atoms/FZButton/FZButton';
import FZFieldLabel from '../../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZFieldValue from '../../../01_atoms/FZFieldValue/FZFIeldValue';
import FZTextInputField from '../../../01_atoms/FZTextInputField/FZTextInputField';

const useStyles = makeStyles((theme: Theme) => {
  return {
    emailValue: {
      marginBottom: 50,
      marginTop: 22,
    },

    formInputField: {
      width: '100%',
    },

    submitButton: {
      marginTop: 46,
    },

    newEmailLabel: { marginBottom: 16 },
  };
});

export interface EditEmailFormValues {
  newEmail: string;
}

interface Props {
  currentUserEmail: string;
  onSubmit: (
    values: EditEmailFormValues,
    helpers: FormikHelpers<EditEmailFormValues>,
  ) => void;
}

export default function EditEmailForm({
  currentUserEmail,
  onSubmit,
}: Props): ReactElement {
  const { t } = useTranslation();
  //const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          newEmail: '',
        }}
        validate={values => {
          const errors: Partial<EditEmailFormValues> = {};

          if (!values.newEmail) {
            errors.newEmail = t('editEmailForm-email-required-message');
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.newEmail)
          ) {
            errors.newEmail = t(
              'editEmailForm-email-does-not-match-email-pattern-message',
            );
          }

          return errors;
        }}
        onSubmit={(values, helpers: FormikHelpers<EditEmailFormValues>) => {
          onSubmit(values, helpers);
        }}
      >
        {({ submitForm, isSubmitting, errors }) => (
          <Form>
            <FZFieldLabel
              label={t('editEmailScreen-your-current-email-is-headline')}
            ></FZFieldLabel>

            <FZFieldValue
              label={currentUserEmail}
              className={classes.emailValue}
            ></FZFieldValue>

            <FZFieldLabel
              label={t('editEmailScreen-your-new-email-is-headline')}
              className={classes.newEmailLabel}
            ></FZFieldLabel>

            <Field
              id="newEmail"
              name="newEmail"
              data-testid="newEmailField"
              component={FZTextInputField}
              className={classes.formInputField}
              placeholder={t('editEmailScreen-new-email-field-label')}
              color={colors.quartiary}
              backgroundColor={colors.white}
            />

            <FZButton
              className={classes.submitButton}
              label={t('editEmailScreen-finish-editing-button-label')}
              backgroundColor={colors.quartiary}
              variant="contained"
              isSubmitting={isSubmitting}
              type="submit"
            ></FZButton>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
