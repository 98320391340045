import { makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement } from 'react';

interface Props {
  children: JSX.Element[];
}

const usePricingTableStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      //backgroundColor: 'white',
      //minHeight: 300,
      //marginTop: 16,

      [theme.breakpoints.up('md')]: {
        //minHeight: 190,
        flexDirection: 'row',

        '& > :first-child': {
          minHeight: 400,
          //maxHeight: 550,
          // transform: 'perspective(150px) rotateY(-3deg)',
          // boxShadow: `3px 0px ${colors.accent}`,
        },

        '& > :nth-child(2)': {
          //marginTop: -20,
          minHeight: 400,
          zIndex: 5,
          marginLeft: 32,
          marginRight: 32,
          // boxShadow: `0px 1px ${colors.accent}`,
        },

        '& > :last-child': {
          minHeight: 400,
          // maxHeight: 550,
          //transform: 'perspective(150px) rotateY(3deg)',
          // boxShadow: `-3px 0px 0px  0px ${colors.accent}`,
        },
      },

      //padding: 8,
      //borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',

      justifyContent: 'space-between',
      width: '95%',
      flexWrap: 'wrap',
    },
  };
});

export default function FZPricingTable({ children }: Props): ReactElement {
  const classes = usePricingTableStyles();
  return <div className={classes.container}>{children}</div>;
}
