// src/Login.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import fzColorfulBackgroundImage from '../../../../../assets/images/FZ_colorful_background.jpg';
import { initiateForgotPasswordRequest } from '../../../../../common/redux/login/login.actions';
import {
  showErrorSnackbar,
  showInfoSnackbar,
} from '../../../../../common/redux/ui/ui.actions';
import { FullWidthBackground } from '../../../../01_atoms/FullWidthBackground/FullWidthBackground';
import useConfig from '../../../../Hooks/useConfig';
import InitiateResetPasswordForm, {
  InitiateResetPasswordFlowFormFormValues,
} from './InitiateResetPasswordFlowForm';

interface Props {}

const InitiateResetPasswordFlowScreen: React.FC<Props> = () => {
  const config = useConfig();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const resetPasswordEnabled: boolean =
    config.FEATURE_FLAGS.AUTH.REACT_APP_FEATURE_FLAG_RESET_PASSWORD_ENABLED;

  const history = useHistory();
  if (!resetPasswordEnabled) {
    dispatch(
      showInfoSnackbar(
        t('initiateResetPasswordScreen-403-signup-currently-closed'),
        false,
      ),
    );
  }

  const handleSubmitButtonClick = React.useCallback(
    (values: InitiateResetPasswordFlowFormFormValues, helpers) => {
      dispatch(
        initiateForgotPasswordRequest(
          { primaryUserEmail: values.username },
          () => {
            helpers.setSubmitting(false);
            history.push('/reset-password-email-sent');
          },
          () => {
            helpers.setSubmitting(false);
            dispatch(
              showErrorSnackbar(
                t(
                  'initiateResetPasswordFlowScreen-reset-password-email-could-not-be-sent-message',
                ),
                true,
              ),
            );
          },
        ),
      );
    },
    [dispatch, history, t],
  );

  return (
    <FullWidthBackground
      imageSource={fzColorfulBackgroundImage}
      position="absolute"
    >
      <InitiateResetPasswordForm
        onSubmit={handleSubmitButtonClick}
        enabled={resetPasswordEnabled}
      />
    </FullWidthBackground>
  );
};

export default InitiateResetPasswordFlowScreen;
