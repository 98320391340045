import { FormHelperText, Link, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { Field } from 'formik';
import { Checkbox } from 'formik-material-ui';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import colors from '../../00_Constants/colors';
import FZButton from '../../01_atoms/FZButton/FZButton';
import FZFieldLabel from '../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZTextInputField from '../../01_atoms/FZTextInputField/FZTextInputField';
import FZTypography from '../../01_atoms/FZTypography/FZTypography';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 16,
    },

    horizontalRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    formInputField: {
      width: '100%',
      marginBottom: 16,
    },

    nameLabel: {},

    messageLabel: {
      marginTop: 34,
    },

    subjectLabel: {
      //marginTop: 34,
    },

    emailLabel: {
      paddingLeft: 8,
    },

    emailField: {
      paddingLeft: 8,
      minHeight: 95, // Used to prevent that error messages cause reflow and the fields break out of alignment.
    },

    nameField: {
      minHeight: 95, // Used to prevent that error messages cause reflow and the fields break out of alignment.
    },

    verticalField: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    /**
     * Styles for honeypot fields that will only be filled by bots
     */
    ohnoHNY: {
      opcacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      zIndex: -1,
    },
  };
});

export interface ContactFormValues {
  nameForHmns: string;
  emailForHmns: string;
  subjectForHmns: string;
  messageForHmns: string;

  name: string;
  email: string;
  subject: string;
  message: string;

  consentToTermsOfServiceAndDataProtectionAgreementGiven: boolean;
}

interface Props {
  children?: ReactElement;

  // Form Props
  nameFieldName: string;
  emailFieldName: string;
  subjectFieldName: string;
  messageFieldName: string;
  consentToTermsOfServiceAndDataProtectionAgreementFieldName: string;
  errors: any;

  // Labels and Placeholders
  nameFieldLabel: string;
  nameFieldPlaceholder: string;
  nameHoneypotFieldName: string;
  emailFieldLabel: string;
  emailFieldPlaceholder: string;
  emailHoneypotFieldName: string;
  subjectFieldLabel: string;
  subjectFieldPlaceholder: string;
  subjectHoneypotFieldName: string;
  subjectFieldCharacterLimit: number;
  messageFieldLabel: string;
  messageFieldPlaceholder: string;
  messageHoneypotFieldName: string;
  messageFieldCharacterLimit: number;
  sendButtonLabel: string;
  abortButtonLabel: string;

  // Style Props
  nameLabelClassName?: string;
  nameFieldClassName?: string;
  emailLabelClassName?: string;
  emailFieldClassName?: string;
  subjectLabelClassName?: string;
  subjectFieldClassName?: string;
  messageLabelClassName?: string;
  messageFieldClassName?: string;

  onSubmit: any;
  onAbort: () => void;
}

export default function FZContactForm({
  // Form Props
  nameFieldName,
  emailFieldName,
  subjectFieldName,
  messageFieldName,
  consentToTermsOfServiceAndDataProtectionAgreementFieldName,
  errors,

  // Labels and Placeholders
  nameFieldLabel,
  nameFieldPlaceholder,
  nameHoneypotFieldName,
  emailFieldLabel,
  emailFieldPlaceholder,
  emailHoneypotFieldName,
  subjectFieldLabel,
  subjectFieldPlaceholder,
  subjectFieldCharacterLimit,
  subjectHoneypotFieldName,
  messageFieldLabel,
  messageFieldPlaceholder,
  messageHoneypotFieldName,
  messageFieldCharacterLimit,
  sendButtonLabel,
  abortButtonLabel,

  // Style Props
  nameLabelClassName,
  nameFieldClassName,
  emailLabelClassName,
  emailFieldClassName,
  subjectLabelClassName,
  subjectFieldClassName,
  messageLabelClassName,
  messageFieldClassName,
  onSubmit,
  onAbort,
}: Props): ReactElement {
  const { t } = useTranslation();
  //const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.horizontalRow}>
        <div className={classes.verticalField}>
          <FZFieldLabel
            label={nameFieldLabel}
            className={clsx(classes.nameLabel, nameFieldClassName)}
            isOptional
          ></FZFieldLabel>

          <Field
            id={nameFieldName}
            name={nameFieldName}
            data-testid={nameFieldName}
            component={FZTextInputField}
            className={clsx(
              classes.formInputField,
              classes.nameField,
              nameFieldClassName,
            )}
            placeholder={nameFieldPlaceholder}
            color={colors.accent}
            backgroundColor={colors.white}
            multiline={false}
          />
        </div>

        <div className={classes.verticalField}>
          <FZFieldLabel
            label={emailFieldLabel}
            className={clsx(classes.emailLabel, emailLabelClassName)}
          ></FZFieldLabel>

          <Field
            id={emailFieldName}
            name={emailFieldName}
            data-testid={emailFieldName}
            component={FZTextInputField}
            className={clsx(
              classes.formInputField,
              classes.emailField,
              emailFieldClassName,
            )}
            placeholder={emailFieldPlaceholder}
            color={colors.accent}
            backgroundColor={colors.white}
            multiline={false}
          />
        </div>
      </div>

      <FZFieldLabel
        label={subjectFieldLabel}
        className={clsx(classes.subjectLabel, subjectLabelClassName)}
      ></FZFieldLabel>

      <Field
        id={subjectFieldName}
        name={subjectFieldName}
        data-testid={subjectFieldName}
        component={FZTextInputField}
        className={clsx(classes.formInputField, subjectFieldClassName)}
        placeholder={subjectFieldPlaceholder}
        color={colors.accent}
        backgroundColor={colors.white}
        multiline
        displayCharacterCount
        characterLimit={subjectFieldCharacterLimit}
        enforceCharacterLimit
      />

      <FZFieldLabel
        label={messageFieldLabel}
        className={clsx(classes.subjectLabel, messageLabelClassName)}
      ></FZFieldLabel>

      <Field
        id={messageFieldName}
        name={messageFieldName}
        data-testid={messageFieldName}
        component={FZTextInputField}
        className={clsx(classes.formInputField, messageFieldClassName)}
        placeholder={messageFieldPlaceholder}
        color={colors.accent}
        backgroundColor={colors.white}
        multiline
        displayCharacterCount
        characterLimit={messageFieldCharacterLimit}
        enforceCharacterLimit
        rows={5}
        rowsMax={5}
      />

      <div className={classes.horizontalRow}>
        <Field
          component={Checkbox}
          name={consentToTermsOfServiceAndDataProtectionAgreementFieldName}
          variant="outlined"
          color="secondary"
          type="checkbox"
        />
        <FZTypography variant="body1">
          <Trans i18nKey="signupScreen-consentToTermsOfServiceAndDataProtectionAgreementGiven-field-label">
            <Link
              href="/data-processing-agreement"
              component="a"
              color="secondary"
            >
              {{
                DataProcessingAgreement: t(
                  'generic-vocabulary-DataProcessingAgreement',
                ),
              }}
            </Link>
            <Link href="/terms-of-service" component="a" color="secondary">
              {{
                TermsOfService: t('generic-vocabulary-TermsOfService'),
              }}
            </Link>
          </Trans>
        </FZTypography>
      </div>

      {errors.consentToTermsOfServiceAndDataProtectionAgreementGiven && (
        <div className={classes.horizontalRow}>
          <FormHelperText style={{ color: colors.danger }}>
            {t(
              'contactForm-consentToTermsOfServiceAndDataProtectionAgreementGiven-error',
            )}
          </FormHelperText>
        </div>
      )}

      <div className={classes.horizontalRow}>
        <FZButton
          label={abortButtonLabel}
          labelColor={colors.white}
          backgroundColor={colors.accent}
          onClick={() => {
            onAbort();
          }}
        ></FZButton>

        <FZButton
          label={sendButtonLabel}
          labelColor={colors.white}
          backgroundColor={colors.accent}
          onClick={onSubmit}
        ></FZButton>
      </div>

      <Field
        id={nameHoneypotFieldName}
        name={nameHoneypotFieldName}
        data-testid={nameHoneypotFieldName}
        component={FZTextInputField}
        className={clsx(classes.ohnoHNY)}
        placeholder={nameFieldPlaceholder}
        color={colors.accent}
        backgroundColor={colors.white}
        multiline={false}
      />

      <Field
        id={emailHoneypotFieldName}
        name={emailHoneypotFieldName}
        data-testid={emailHoneypotFieldName}
        component={FZTextInputField}
        className={clsx(classes.ohnoHNY)}
        placeholder={emailFieldPlaceholder}
        color={colors.accent}
        backgroundColor={colors.white}
        multiline={false}
      />

      <Field
        id={subjectHoneypotFieldName}
        name={subjectHoneypotFieldName}
        data-testid={subjectHoneypotFieldName}
        component={FZTextInputField}
        className={clsx(classes.ohnoHNY)}
        placeholder={subjectFieldPlaceholder}
        color={colors.accent}
        backgroundColor={colors.white}
      />

      <Field
        id={messageHoneypotFieldName}
        name={messageHoneypotFieldName}
        data-testid={messageHoneypotFieldName}
        component={FZTextInputField}
        className={clsx(classes.ohnoHNY)}
        placeholder={messageFieldPlaceholder}
        color={colors.accent}
        backgroundColor={colors.white}
        multiline
        rows={5}
        rowsMax={5}
      />
    </div>
  );
}
