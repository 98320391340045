import {
  Backdrop,
  Button,
  ButtonGroup,
  Container,
  Grid,
  isWidthDown,
  Tooltip,
  Typography,
  withWidth,
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import FilterCenterFocusRoundedIcon from '@material-ui/icons/FilterCenterFocusRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import ZoomInRoundedIcon from '@material-ui/icons/ZoomInRounded';
import ZoomOutRoundedIcon from '@material-ui/icons/ZoomOutRounded';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { StyleRules } from '@material-ui/styles/withStyles';
import { withOktaAuth } from '@okta/okta-react';
import memoizeOne from 'memoize-one';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Circle, Group, Layer, Line, Rect, Stage, Text } from 'react-konva';
import { connect, DispatchProp, ReactReduxContext } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, compose } from 'redux';
import { svgIconPathMap } from '../../../assets/icons/svg/svgIconPathMap';
import avatarPlaceholder4 from '../../../assets/images/avatars/avatar_placeholder_image4.png';
import { Family } from '../../../common/models/Family';
import {
  clearActiveFamily,
  editFamilyRequest,
  fetchFamilyRequest,
  leaveFamilyRequest,
  RemoveFamilyMemberActionPayload,
  removeFamilyMemberInvitationRequest,
  removeFamilyMemberRequest,
} from '../../../common/redux/families/family.actions';
import {
  selectCurrentlyActiveFamily,
  selectCurrentUserIsLastMemberInCurrentlyActiveFamily,
  selectFamilyMembersAndInvites,
  selectIfCurrentlyActiveFamilyIsDeleted,
  selectUserHasReachedFamilyLimit,
  selectUserIsAdminInCurrentlyActiveFamily,
} from '../../../common/redux/families/family.selectors';
import {
  EditFamilyDto,
  FamilyInvitation,
  FamilyInvitationType,
  isFamilyInvitation,
} from '../../../common/redux/families/types';
import {
  ActionCallbacks,
  ApplicationState,
} from '../../../common/redux/main/types';
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../common/redux/ui/ui.actions';
import { isUserProfile, UserProfile } from '../../../common/redux/users/types';
import { selectLoggedInUserUid } from '../../../common/redux/users/user.selectors';
import colors from '../../00_Constants/colors';
import FZButton from '../../01_atoms/FZButton/FZButton';
import FZCanvasButton from '../../01_atoms/FZCanvasButton/FZCanvasButton';
import FZInfoCard from '../../01_atoms/FZInfoCard/FZInfoCard';
import FZPageContainer from '../../01_atoms/FZPageContainer/FZPageContainer';
import FZTypography from '../../01_atoms/FZTypography/FZTypography';
import FZCanvasAvatar from '../../02_molecules/FZCanvasAvatar/FZCanvasAvatar';
import FZDialog from '../../02_molecules/FZConfirmDialog/FZDialog';
import FZAppBar from '../../03_organisms/FZAppBar/FZAppBar';
import FZFamilyMemberProfileCard from '../../03_organisms/FZFamilyMemberProfileCard/FZFamilyMemberProfileCard';
import FZInviteFamilyViaLinkDialog from '../../03_organisms/FZInviteFamilyViaLinkDialog/FZInviteFamilyViaLinkDialog';
import FZJoinFamilyWithCodeDialog from '../../03_organisms/FZJoinFamilyWithCodeDialog/FZJoinFamilyWithCodeDialog';
import { withHooks, WithHooksProps } from '../../HOCs/withHooks';
import { withServices, WithServicesProps } from '../../HOCs/withServices';
import './FamilyScreen.css';

const styles: (theme: Theme) => StyleRules<object> = theme =>
  createStyles({
    pageContainerWithoutAnActiveFamily: {},

    pageContainerWithAnActiveFamily: {
      position: 'absolute',
      height: '100%',
      overflow: 'hidden',
    },

    noFamilyActiveScreenContainer: {
      marginBottom: 72,
    },

    stageContainer: {
      overflow: 'hidden',
      height: '100%',
      backgroundColor: '#FFFA',
      opacity: 1,
      position: 'absolute',

      // backgroundImage:
      //   'radial-gradient(#1112 1.9000000000000001px, transparent 1.9000000000000001px), radial-gradient(#1112 1.9000000000000001px, #FFF 1.9000000000000001px)',
      // backgroundBlendMode: 'multiply',
      // backgroundSize: '76px 76px',
      // backgroundPosition: '0 0,38px 38px',

      /*  '& > div': {
        overflowY: 'hidden',
      }, */

      /*   '& .konvajs-content': {
        //background: 'yellow',
        width: '100% !important',
        height: 'auto',
        overflow: 'scroll',
        //paddingBottom: '50%', // height/width*100%;
      },

      '& canvas': {
        width: '100% !important',
        height: '100% !important',
      }, */
    },

    familyFlaggedForDeletionInfoCard: {
      marginTop: 16,
    },

    createFamilyFAB: {
      position: 'fixed',
      bottom: 88,
      right: 32,
    },

    editFamilySpeedDialClasses: {
      root: {},
    },

    editFamilySpeedDialFab: {
      backgroundColor: `${colors.secondary} !important`,
    },

    createFamilyFABTooltipWrapper: {
      position: 'fixed',
      bottom: 160,
      right: 32,
    },

    [theme.breakpoints.up('md')]: {
      createFamilyFAB: {
        bottom: 24,
      },

      createFamilyFABTooltipWrapper: {
        bottom: 124,
      },
    },

    createFamilyFabRoot: {
      backgroundColor: colors.secondary,
    },

    zoomButtonGroup: {
      position: 'fixed',
      top: 80,
      right: 16,
    },

    zoomButtonGroupRoot: {
      backgroundColor: '#FFFFFF',
      //color: colors.secondary,
    },

    zoomButtonGroupIcon: {
      //color: colors.secondary,
    },

    introParagraph: {
      paddingTop: 8,
      paddingBottom: 8,
    },

    speedDialTooltip: {
      whiteSpace: 'nowrap',
      paddingRight: 16,
    },
  });

export type FZCanvasCoordinates = {
  x: number;
  y: number;
};

type Props = DispatchProp<AnyAction> &
  WithHooksProps &
  RouteComponentProps<any> &
  WithTranslation &
  WithServicesProps & {
    auth: any;
    classes: any;
    history: any;

    // Redux Props
    familyMembersAndInvites: Array<UserProfile | FamilyInvitation>;
    userHasReachedFamilyLimit: boolean;
    currentlyActiveFamily: Family | null;
    currentlyActiveFamilyIsFlaggedForDeletion: boolean;
    currentUserIsAdminInCurrentlyActiveFamily: boolean;
    currentUserIsLastMemberInCurrentlyActiveFamily: boolean;
    currentlyActiveUserUid: string;

    // HoC Procs
    t?: any;

    // width of the viewport as provided by material UIs withWidth HoC
    width?: any;
  };

type State = {
  // Canvas State
  stageWidth: number;
  stageHeight: number;
  stagePositionX: number;
  stagePositionY: number;
  familyNameElementCenter: FZCanvasCoordinates;
  stageScaleX: number;
  stageScaleY: number;
  lastCenter: FZCanvasCoordinates;
  lastDistance: number;
  lastPointerPosition: FZCanvasCoordinates;
  presetAvatarPositions: FZCanvasCoordinates[];

  // UI Buttons and popovers
  inviteFamilyMemberButtonHovered: boolean;
  leaveFamilyConfirmationOpen: boolean;
  leaveAndDeleteFamilyConfirmationOpen: boolean;
  familyMemberDetailPopoverOpen: boolean;
  familyContextMenuOpen: boolean;
  speedDialOpen: boolean;
  familyInviteLinkSharingModalOpen: boolean;
  joinFamilyViaCodeModalOpen: boolean;
  currentlyViewedUserProfile: UserProfile | FamilyInvitation;

  wheelZoomEnabled: boolean;
};

class FamilyScreen extends Component<Props, State> {
  private stageRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      stageWidth: window.innerWidth,
      stageHeight: window.innerHeight,
      stagePositionX: 0,
      stagePositionY: 0,
      stageScaleX: 1,
      stageScaleY: 1,
      familyNameElementCenter: {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      },
      lastDistance: 0,
      lastCenter: { x: window.innerWidth / 2, y: window.innerHeight / 2 },
      lastPointerPosition: {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      },

      presetAvatarPositions: [],
      inviteFamilyMemberButtonHovered: false,
      leaveFamilyConfirmationOpen: false,
      leaveAndDeleteFamilyConfirmationOpen: false,
      familyMemberDetailPopoverOpen: false,
      currentlyViewedUserProfile: null,
      familyContextMenuOpen: false,
      speedDialOpen: false,

      familyInviteLinkSharingModalOpen: false,
      joinFamilyViaCodeModalOpen: false,
      wheelZoomEnabled: false,
    };
  }

  componentDidMount() {
    const {
      matomoAnalyticsTracker,
      dispatch,
      currentlyActiveFamily,
    } = this.props;

    matomoAnalyticsTracker.trackPageView({
      documentTitle: 'Family Screen', // optional
    });

    if (currentlyActiveFamily) {
      dispatch(fetchFamilyRequest(currentlyActiveFamily.uuid));

      window.addEventListener('resize', this.fitStageIntoParent, {
        passive: true,
      });
      window.addEventListener('resize', this.resetInitialAvatarPositions, {
        passive: true,
      });
      this.fitStageIntoParent();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.fitStageIntoParent);
    window.removeEventListener('resize', this.resetInitialAvatarPositions);
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.familyMembersAndInvites && prevState.presetAvatarPositions) {
      if (
        nextProps.familyMembersAndInvites?.length !==
        prevState.presetAvatarPositions.length
      ) {
        return {
          presetAvatarPositions: FamilyScreen.computeInitialAvatarPositions(
            nextProps,
            prevState,
          ),
        };
      }
    }

    // Return null to indicate no change to state.
    return null;
  }

  /**
   * Computes the center between to given cartesian coordinates in 2D space
   */
  private getCenter = (
    p1: FZCanvasCoordinates,
    p2: FZCanvasCoordinates,
  ): FZCanvasCoordinates => {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  };

  /**
   * Computes the distance between to given cartesian coordinates in 2D space
   */
  private getDistance = (
    p1: FZCanvasCoordinates,
    p2: FZCanvasCoordinates,
  ): number => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  };

  getClientPointerRelativeToStage = (
    clientX: number,
    clientY: number,
    stage: any,
  ) => {
    return {
      x: clientX - stage.getContent().offsetLeft,
      y: clientY - stage.getContent().offsetTop,
    };
  };

  /**
   * Handles dragging the entire stage on touch / mousedown.
   */
  private handleCanvasTouchMove = (event: any) => {
    event.evt.preventDefault();
    const touch1 = event.evt.touches[0];
    const touch2 = event.evt.touches[1];

    if (touch1 && touch2) {
      // if the stage was under Konva's drag&drop
      // we need to stop it, and implement our own pan logic with two pointers

      const p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      };
      const p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      };

      if (!this.state.lastCenter) {
        this.setState({ lastCenter: this.getCenter(p1, p2) });
      }
      const newCenter = this.getCenter(p1, p2);

      const dist = this.getDistance(p1, p2);

      if (!this.state.lastDistance) {
        this.setState({ lastDistance: dist });
      }

      // local coordinates of center point
      const pointTo = {
        x: (newCenter.x - this.state.stagePositionX) / this.state.stageScaleX,
        y: (newCenter.y - this.state.stagePositionY) / this.state.stageScaleX,
      };

      const scale = this.state.stageScaleX * (dist / this.state.lastDistance);

      // calculate new position of the stage
      const dx = newCenter.x - this.state.lastCenter.x;
      const dy = newCenter.y - this.state.lastCenter.y;

      const newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      };

      this.setState({
        stageScaleX: scale,
        stageScaleY: scale,
        stagePositionX: newPos.x,
        stagePositionY: newPos.y,
        lastDistance: dist,
        lastCenter: newCenter,
      });
    }
  };

  handleTouchMove = (event: any) => {
    const currentStageRef = this.stageRef.current;
    if (currentStageRef) {
      const stage = currentStageRef.getStage();
      const touch1 = event.evt.touches[0];
      const touch2 = event.evt.touches[1];

      if (touch1 && touch2) {
        const dist = this.getDistance(
          {
            x: touch1.clientX,
            y: touch1.clientY,
          },
          {
            x: touch2.clientX,
            y: touch2.clientY,
          },
        );

        const currX = (touch1.clientX + touch2.clientX) / 2;
        const currY = (touch1.clientY + touch2.clientY) / 2;
        const currViewPortPoint = { x: currX, y: currY };
        const pointer = this.getClientPointerRelativeToStage(
          currX,
          currY,
          stage,
        );

        if (!this.state.lastDistance) {
          this.setState({ lastDistance: dist });
        }
        if (!this.state.lastPointerPosition) {
          this.setState({ lastPointerPosition: { x: currX, y: currY } });
        }

        const lastPointerPosition = this.state.lastPointerPosition;
        const prevX = lastPointerPosition.x;
        const prevY = lastPointerPosition.y;
        const diffX = currX - prevX;
        const diffY = currY - prevY;

        const oldScale = stage.scaleX();
        const startPos = {
          x: (pointer.x - stage.x()) / oldScale,
          y: (pointer.y - stage.y()) / oldScale,
        };

        const newScale =
          (oldScale * dist) /
          (this.state.lastDistance ? this.state.lastDistance : dist);
        const newPosition = {
          x: ((pointer.x + diffX) / newScale - startPos.x) * newScale,
          y: ((pointer.y + diffY) / newScale - startPos.y) * newScale,
        };

        this.setState({
          stageScaleX: newScale,
          stageScaleY: newScale,
          stagePositionX: newPosition.x,
          stagePositionY: newPosition.y,
          lastDistance: dist,
          lastPointerPosition: currViewPortPoint,
        });
      }
    }
  };

  /**
   * Computes the initial positions of the family-member avatars on this screen.
   * Is used in getDerviedStateFromProps to be recomputed each time the number of members
   * changes.
   */
  static computeInitialAvatarPositions = (
    nextProps: Props,
    prevState: State,
  ) => {
    const { familyNameElementCenter, presetAvatarPositions } = prevState;
    const { familyMembersAndInvites } = nextProps;
    const circleOrigin = {
      x: familyNameElementCenter.x,
      y: familyNameElementCenter.y,
    };
    const baseCircleRadius = 200;
    const circleRadiusIncrement = 150;
    const avatarPositions = [...presetAvatarPositions];
    const MAX_IMAGES_THAT_FIT_NICELY_AROUND_CENTER = 10;
    const MAX_IMAGES_THAT_FIT_LEVEL_TWO_AROUND_CENTER = 30;
    let radiusIsIncremented = false;

    if (
      familyMembersAndInvites?.length <=
      MAX_IMAGES_THAT_FIT_NICELY_AROUND_CENTER
    ) {
      // Draw all images equidistant from the center
      familyMembersAndInvites.map((member, index) => {
        const avatarPosition = {
          x:
            baseCircleRadius *
              Math.cos(
                (-index * 2 * Math.PI) / familyMembersAndInvites.length,
              ) +
            circleOrigin.x,
          y:
            baseCircleRadius *
              Math.sin(
                (-index * 2 * Math.PI) / familyMembersAndInvites.length,
              ) +
            circleOrigin.y,
        };
        avatarPositions[index] = avatarPosition;
        radiusIsIncremented = !radiusIsIncremented;
        return avatarPosition;
      });
    } else if (
      familyMembersAndInvites?.length >
        MAX_IMAGES_THAT_FIT_NICELY_AROUND_CENTER &&
      familyMembersAndInvites?.length <=
        MAX_IMAGES_THAT_FIT_LEVEL_TWO_AROUND_CENTER
    ) {
      // Draw two levels of depth around the center, altering between level 1 and 2
      // on each image
      familyMembersAndInvites.map((member, index) => {
        // Hinder the last placed image to overlap with the first-placed.
        if (index === familyMembersAndInvites.length - 1) {
          radiusIsIncremented = true;
        }
        const drawnRadius = radiusIsIncremented
          ? baseCircleRadius + circleRadiusIncrement
          : baseCircleRadius;

        const avatarPosition = {
          x:
            drawnRadius *
              Math.cos(
                (-index * 2 * Math.PI) / familyMembersAndInvites.length,
              ) +
            circleOrigin.x,
          y:
            drawnRadius *
              Math.sin(
                (-index * 2 * Math.PI) / familyMembersAndInvites.length,
              ) +
            circleOrigin.y,
        };
        avatarPositions[index] = avatarPosition;
        radiusIsIncremented = !radiusIsIncremented;
        return avatarPosition;
      });
    } else if (
      familyMembersAndInvites?.length >
      MAX_IMAGES_THAT_FIT_LEVEL_TWO_AROUND_CENTER
    ) {
      // Draw three levels of depth around center, cycling through the three levels
      // on each image
      const circleRadiusIncrements = [300, 450, 600];

      familyMembersAndInvites.map((member, index) => {
        let drawnRadius =
          circleRadiusIncrements[index % circleRadiusIncrements.length];

        // We saw a possible overlap of the very last and the first family member
        // every time <lastMembersIndex % circleRadiusIncrements.length> is 0.
        if (index === familyMembersAndInvites.length - 1) {
          if (index % 3 === 0) {
            drawnRadius = circleRadiusIncrements[1];
          }
        }

        const avatarPosition = {
          x:
            drawnRadius *
              Math.cos(
                (-index * 2 * Math.PI) / familyMembersAndInvites.length,
              ) +
            circleOrigin.x,
          y:
            drawnRadius *
              Math.sin(
                (-index * 2 * Math.PI) / familyMembersAndInvites.length,
              ) +
            circleOrigin.y,
        };
        avatarPositions[index] = avatarPosition;
        radiusIsIncremented = !radiusIsIncremented;
        return avatarPosition;
      });
    }

    return avatarPositions;
  };

  /**
   * Restores the position of avatars drawn on the canvas
   * to their initial position around the "family circle"
   */
  resetInitialAvatarPositions = () => {
    const { familyNameElementCenter, presetAvatarPositions } = this.state;
    const { familyMembersAndInvites } = this.props;

    const circleOrigin = {
      x: familyNameElementCenter.x,
      y: familyNameElementCenter.y,
    };
    const baseCircleRadius = 200;
    const MAX_IMAGES_THAT_FIT_NICELY_AROUND_CENTER = 10;
    const MAX_IMAGES_THAT_FIT_LEVEL_TWO_AROUND_CENTER = 30;
    const circleRadiusIncrement = 150;
    let radiusIsIncremented = false;

    const avatarPositions = [...presetAvatarPositions];

    if (
      familyMembersAndInvites?.length <=
      MAX_IMAGES_THAT_FIT_NICELY_AROUND_CENTER
    ) {
      // Draw all images equidistant from the center
      familyMembersAndInvites.map((member, index) => {
        const avatarPosition = {
          x:
            baseCircleRadius *
              Math.cos(
                (-index * 2 * Math.PI) / familyMembersAndInvites.length,
              ) +
            circleOrigin.x,
          y:
            baseCircleRadius *
              Math.sin(
                (-index * 2 * Math.PI) / familyMembersAndInvites.length,
              ) +
            circleOrigin.y,
        };
        avatarPositions[index] = avatarPosition;
        radiusIsIncremented = !radiusIsIncremented;
        return avatarPosition;
      });
    } else if (
      familyMembersAndInvites?.length >
        MAX_IMAGES_THAT_FIT_NICELY_AROUND_CENTER &&
      familyMembersAndInvites?.length <=
        MAX_IMAGES_THAT_FIT_LEVEL_TWO_AROUND_CENTER
    ) {
      // Draw two levels of depth around the center, altering between level 1 and 2
      // on each image
      familyMembersAndInvites.map((member, index) => {
        // Hinder the last placed image to overlap with the first-placed.
        if (index === familyMembersAndInvites?.length - 1) {
          radiusIsIncremented = true;
        }

        const drawnRadius = radiusIsIncremented
          ? baseCircleRadius + circleRadiusIncrement
          : baseCircleRadius;

        const avatarPosition = {
          x:
            drawnRadius *
              Math.cos(
                (-index * 2 * Math.PI) / familyMembersAndInvites.length,
              ) +
            circleOrigin.x,
          y:
            drawnRadius *
              Math.sin(
                (-index * 2 * Math.PI) / familyMembersAndInvites.length,
              ) +
            circleOrigin.y,
        };
        avatarPositions[index] = avatarPosition;
        radiusIsIncremented = !radiusIsIncremented;
        return avatarPosition;
      });
    } else if (
      familyMembersAndInvites?.length >
      MAX_IMAGES_THAT_FIT_LEVEL_TWO_AROUND_CENTER
    ) {
      // Draw three levels of depth around center, cycling through the three levels
      // on each image
      const circleRadiusIncrements = [300, 450, 600];
      familyMembersAndInvites.map((member, index) => {
        let drawnRadius =
          circleRadiusIncrements[index % circleRadiusIncrements.length];

        // We saw a possible overlap of the very last and the first family member
        // every time <lastMembersIndex % circleRadiusIncrements.length> is 0.
        if (index === familyMembersAndInvites.length - 1) {
          if (index % 3 === 0) {
            drawnRadius = circleRadiusIncrements[1];
          }
        }

        const avatarPosition = {
          x:
            drawnRadius *
              Math.cos(
                (-index * 2 * Math.PI) / familyMembersAndInvites.length,
              ) +
            circleOrigin.x,
          y:
            drawnRadius *
              Math.sin(
                (-index * 2 * Math.PI) / familyMembersAndInvites.length,
              ) +
            circleOrigin.y,
        };
        avatarPositions[index] = avatarPosition;
        radiusIsIncremented = !radiusIsIncremented;
        return avatarPosition;
      });
    }

    this.setState({ presetAvatarPositions: avatarPositions });
  };

  /**∂
   * Handles repositioning the stage / entire family graph
   */
  updateStagePosition = (e: any) => {
    const pointerPosition = this.getRelativePointerPosition(this.stageRef);

    const newAvatarPosition = {
      x: pointerPosition.x,
      y: pointerPosition.y,
    };

    this.setState({ familyNameElementCenter: newAvatarPosition });

    this.resetInitialAvatarPositions();
  };

  /**
   * Handles updating a single avatars position
   */
  updateAvatarPosition = (index: number, e: any) => {
    const pointerPosition = this.getRelativePointerPosition(this.stageRef);
    const avatarPositions = [...this.state.presetAvatarPositions];
    const newAvatarPosition = {
      x: pointerPosition.x,
      y: pointerPosition.y,
    };
    avatarPositions[index] = newAvatarPosition;

    this.setState({ presetAvatarPositions: avatarPositions });
  };

  /**
   * Computes the relative position of the pointer (touch or mouse) to a given node on the canvas.
   */
  getRelativePointerPosition = (node: any) => {
    // the function will return pointer position relative to the passed node
    const transform = node.getAbsoluteTransform().copy();
    // to detect relative position we need to invert transform
    transform.invert();

    // get pointer (say mouse or touch) position
    const pos = node.getStage().getPointerPosition();

    // now we find relative point
    return transform.point(pos);
  };

  /**
   * Scales the entire stage to fit inside the parent container.
   */
  fitStageIntoParent = () => {
    // now you may want to make it visible even on small screens
    // we can just scale it
    const scale = Math.min(
      window.innerWidth / this.state.stageWidth,
      window.innerHeight / this.state.stageHeight,
    );

    this.setState({
      stageWidth: window.innerWidth,
      stageHeight: window.innerHeight,
      stageScaleX: scale,
      stageScaleY: scale,
    });
  };

  /**
   * Returns the coordinates of the screen center
   */
  getScreenCenter = (): FZCanvasCoordinates => {
    return {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };
  };

  /**
   * Recenters the graph into the center of the screen
   */
  recenterFamilyGraph = (resetZoom: boolean) => {
    if (resetZoom) {
      this.setState(
        {
          stageScaleX: 1,
          stageScaleY: 1,
          familyNameElementCenter: {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
          },
          stagePositionX: 0,
          stagePositionY: 0,
          stageWidth: window.innerWidth,
          stageHeight: window.innerHeight,
          lastDistance: 0,
          lastCenter: { x: window.innerWidth / 2, y: window.innerHeight / 2 },
          lastPointerPosition: {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
          },
        },
        () => {
          this.resetInitialAvatarPositions();
        },
      );
    } else {
      this.setState(
        {
          stageScaleX: 1,
          stageScaleY: 1,
          familyNameElementCenter: {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
          },
          stagePositionX: 0,
          stagePositionY: 0,
          stageWidth: window.innerWidth,
          stageHeight: window.innerHeight,
          lastDistance: 0,
          lastCenter: { x: window.innerWidth / 2, y: window.innerHeight / 2 },
          lastPointerPosition: {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
          },
        },
        () => {
          this.resetInitialAvatarPositions();
        },
      );
    }
  };

  /**
   * Handles a click onto the zoom-out button
   */
  handleZoomOutButtonClick = () => {
    const zoomStep = 0.2;

    this.setState((prevState: State) => {
      return {
        stageScaleX: prevState.stageScaleX - zoomStep,
        stageScaleY: prevState.stageScaleY - zoomStep,
      };
    });
  };

  /**
   * Handles a click onto the zoom-in button
   */
  handleZoomInButtonClick = () => {
    const zoomStep = 0.2;
    this.setState((prevState: State) => {
      return {
        stageScaleX: prevState.stageScaleX + zoomStep,
        stageScaleY: prevState.stageScaleY + zoomStep,
      };
    });
  };

  /**
   * Handles mousewheel interaction / zooming
   */
  handleWheel = (e: any) => {
    if (!this.state.wheelZoomEnabled) {
      return;
    }
    e.evt.preventDefault();

    const scaleBy = 1.2;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    /*   const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    }; */

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    this.setState({
      stageScaleX: newScale,
      stageScaleY: newScale,
      //stagePositionX:-(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      //stagePositionY:-(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    });
  };

  /**
   * Handles a click onto the invite-family-member button
   */
  handleInviteFamilyMemberButtonClick = () => {
    const { appConfig } = this.props;

    if (
      appConfig.FEATURE_FLAGS.FAMILY
        .REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_ENABLED
    ) {
      const { currentlyActiveFamily, history } = this.props;
      history.push(`/family/${currentlyActiveFamily.name}/inviteMember`);
    }
  };

  /**
   * Handles a click onto the leave-family-button
   */
  handleLeaveFamilyButtonClick = () => {
    this.setState({ leaveFamilyConfirmationOpen: true });
  };

  /**
   * Handles a click onto the leave and delete family button (from the confirmation dialog to confirm leaving a family as the last member)
   */
  handleLeaveAndDeleteFamilyButtonClick = () => {
    this.setState({ leaveAndDeleteFamilyConfirmationOpen: true });
  };

  /**
   * Handles a click onto the delete family invitation button
   */
  handleDeleteFamilyInvitationButtonClick = (
    invitationToRemove: FamilyInvitation,
  ) => {
    const { dispatch, currentlyActiveFamily, t } = this.props;
    dispatch(
      removeFamilyMemberInvitationRequest(
        currentlyActiveFamily.uuid,
        invitationToRemove,
        {
          successCallback: () => {
            dispatch(
              showSuccessSnackbar(
                t('familyScreen-remove-famiy-member-invitation-success'),
                true,
              ),
            );
          },
          errorCallback: () => {
            dispatch(
              showErrorSnackbar(
                t('familyScreen-remove-famiy-member-invitation-error'),
                true,
              ),
            );
          },
        },
      ),
    );

    this.setState({
      currentlyViewedUserProfile: null,
      familyMemberDetailPopoverOpen: false,
    });
  };

  /**
   * Handles a click onto the create family button.
   */
  handleCreateFamilyButtonClick = () => {
    const { history } = this.props;
    history.push(`/create-new-family`);
  };

  /**
   * Handles a click onto the re-center button
   */
  handleRecenterCanvasButtonClick = () => {
    this.recenterFamilyGraph(true);
  };

  /**
   * Handles the confirmation of the action to leave the currently active family.
   */
  handleLeaveFamilyConfirmed = () => {
    this.setState({
      leaveFamilyConfirmationOpen: false,
      familyMemberDetailPopoverOpen: false,
    });

    const { dispatch, currentlyActiveFamily, t } = this.props;

    const callbacks: ActionCallbacks = {
      successCallback: () => {
        dispatch(
          showSuccessSnackbar(
            t('familyScreen-leave-family-successful-message'),
            true,
          ),
        );
      },
      errorCallback: () => {
        dispatch(
          showErrorSnackbar(t('familyScreen-leave-family-error-message'), true),
        );
      },
    };

    if (currentlyActiveFamily) {
      dispatch(
        leaveFamilyRequest(
          { familyUuidOfFamilyToLeave: currentlyActiveFamily.uuid },
          callbacks,
        ),
      );
    }

    return;
  };

  /**
   * Handles the confirmation of the action to leave the currently active family as the last member, which
   * will cause the family to be flagged for deletion and deleted after X amount of days.
   */
  handleLeaveAndDeleteFamilyConfirmed = () => {
    const { dispatch, currentlyActiveFamily, t } = this.props;
    this.setState({ leaveAndDeleteFamilyConfirmationOpen: false });

    const callbacks: ActionCallbacks = {
      successCallback: () => {
        dispatch(
          showSuccessSnackbar(
            t('familyScreen-leave-and-delete-family-successful-message'),
            true,
          ),
        );

        dispatch(clearActiveFamily());
      },
      errorCallback: () => {
        dispatch(
          showErrorSnackbar(
            t('familyScreen-leave-and-delete-family-error-message'),
            true,
          ),
        );
      },
    };

    if (currentlyActiveFamily) {
      dispatch(
        leaveFamilyRequest(
          { familyUuidOfFamilyToLeave: currentlyActiveFamily.uuid },
          callbacks,
        ),
      );
    }

    return;
  };

  handleLeaveAndDeleteFamilyCanceled = () => {
    this.setState({ leaveAndDeleteFamilyConfirmationOpen: false });
  };

  /**
   * Handles the confirmation of the action to join a family using a code.
   */
  handleJoinFamilyConfirmed = () => {
    this.setState({ joinFamilyViaCodeModalOpen: false });
  };

  handleJoinFamilySuccess = () => {
    this.setState({ joinFamilyViaCodeModalOpen: false });
  };

  /**
   * Handles the cancelation of the action to join a family using a code.
   */
  handleJoinFamilyCanceled = () => {
    this.setState({ joinFamilyViaCodeModalOpen: false });
  };

  /**
   * Handles the cancelation of the action to leave the currently active family.
   */
  handleLeaveFamilyCanceled = () => {
    this.setState({ leaveFamilyConfirmationOpen: false });
    return;
  };

  /**
   * Handles opening the detail view of an individual family member
   */
  handleFamilyMemberDetailPopoverOpen = () => {
    this.setState({ familyMemberDetailPopoverOpen: true });
  };

  /**
   * Handles closing the detail view of an individual family member
   */
  handleFamilyMemberDetailPopoverClose = () => {
    this.setState({ familyMemberDetailPopoverOpen: false });
  };

  /**
   * Handles a click onto the "edit family" button
   */
  handleEditFamilyButtonClick = () => {
    const { history } = this.props;

    history.push('family/edit');
  };

  /**
   * Handles a click onto the button / element to create a share-link to join a family.
   */
  handleInviteViaLinkButtonClick = () => {
    this.setState({
      speedDialOpen: false,
      familyInviteLinkSharingModalOpen: true,
    });
  };

  handleInvitaFamilyMemberDialogClosed = () => {
    this.setState({ familyInviteLinkSharingModalOpen: false });
  };

  handleJoinFamilyButtonClick = () => {
    this.setState({ joinFamilyViaCodeModalOpen: true });
  };

  /**
   * Handles a click onto the button that shall remove a family member permanently from the family.
   */
  handleRemoveFamilyMemberButtonClicked = (
    familyUuid: string,
    memberToRemoveUid: string,
  ) => {
    const { dispatch, t } = this.props;

    const actionPayload: RemoveFamilyMemberActionPayload = {
      customerUid: memberToRemoveUid,
      familyUuid: familyUuid,
    };

    const actionCallbacks = {
      successCallback: () => {
        dispatch(
          showSuccessSnackbar(
            t('familyScreen-removeFamilyMember-success-message'),
            true,
          ),
        );
      },
      errorCallback: () => {
        dispatch(
          showErrorSnackbar(
            t('familyScreen-removeFamilyMember-error-message'),
            true,
          ),
        );
      },
    };

    dispatch(removeFamilyMemberRequest(actionPayload, actionCallbacks));
  };

  /**
   * Handles a click onto a button that shall stop the deletion of a family
   * that has previously been flagged for deletion.
   */
  handleStopFamilyDeletionButtonClick = () => {
    const { dispatch, t, currentlyActiveFamily } = this.props;

    const editedFamily: EditFamilyDto = {
      uuid: currentlyActiveFamily.uuid,
      isDeleted: false,
    };

    // Dispatch Update of family settings / subscriptions
    const actionCallbacks: ActionCallbacks = {
      successCallback: () => {
        dispatch(
          showSuccessSnackbar(
            t('editFamilyScreen-reactivating-family-successful-message'),
            true,
          ),
        );
      },
      errorCallback: () => {
        dispatch(
          showErrorSnackbar(
            t('editFamilyScreen-reactivating-family-error-message'),
            true,
          ),
        );
      },
    };

    dispatch(editFamilyRequest(editedFamily, actionCallbacks));

    return;
  };

  /**
   * Returns a different set of options that can be performed on individual family members, depending on wether
   * - its on oneself or another member or
   * - the user is a family admin
   *
   */
  getFamilyMemberProfileCardHeaderMenuItems = () => {
    const { currentlyViewedUserProfile } = this.state;
    const {
      t,
      currentUserIsAdminInCurrentlyActiveFamily,
      currentUserIsLastMemberInCurrentlyActiveFamily,
      currentlyActiveFamily,
      currentlyActiveUserUid,
    } = this.props;

    let deleteActionLabel = '';
    let deleteActionClickHandler = null;
    let deleteActionDisabled = false;

    if (isUserProfile(currentlyViewedUserProfile)) {
      deleteActionDisabled = currentUserIsLastMemberInCurrentlyActiveFamily;

      const viewedProfileIsOwnProfile =
        currentlyViewedUserProfile?.uid === currentlyActiveUserUid;

      if (viewedProfileIsOwnProfile) {
        deleteActionLabel = t(
          'familyScreen-familyMemberProfileCard-leaveFamily-label',
        );
        deleteActionClickHandler = this.handleLeaveFamilyButtonClick;

        if (currentUserIsLastMemberInCurrentlyActiveFamily) {
          deleteActionDisabled = false;
          deleteActionLabel = t(
            'familyScreen-familyMemberProfileCard-leaveAndDeleteFamily-label',
          );
          deleteActionClickHandler = this.handleLeaveAndDeleteFamilyButtonClick;
        }
      } else {
        if (currentUserIsAdminInCurrentlyActiveFamily) {
          deleteActionLabel = t(
            'familyScreen-familyMemberProfileCard-removeMemberButton-label',
          );
          deleteActionClickHandler = () => {
            this.handleRemoveFamilyMemberButtonClicked(
              currentlyActiveFamily.uuid,
              currentlyViewedUserProfile.uid,
            );
          };
        }
      }
    } else if (isFamilyInvitation(currentlyViewedUserProfile)) {
      deleteActionLabel = t(
        'familyScreen-familyMemberProfileCard-deleteInvitation-label',
      );
      deleteActionClickHandler = () => {
        this.handleDeleteFamilyInvitationButtonClick(
          currentlyViewedUserProfile,
        );
      };
    }

    const deleteAction = {
      label: deleteActionLabel,
      onClick: deleteActionClickHandler,
      disabled: deleteActionDisabled,
    };

    if (deleteActionLabel !== '') {
      return [deleteAction];
    } else {
      return [];
    }
  };

  render() {
    const {
      currentlyActiveFamily,
      userHasReachedFamilyLimit,
      currentlyActiveFamilyIsFlaggedForDeletion,
      familyMembersAndInvites,
      classes,
      t,
      appConfig,
      services,
      width,
    } = this.props;
    const {
      stageWidth,
      stageHeight,
      stagePositionX,
      stagePositionY,
      stageScaleX,
      stageScaleY,
      familyNameElementCenter,
      familyMemberDetailPopoverOpen,
      presetAvatarPositions,
    } = this.state;
    const {
      REACT_APP_FEATURE_FLAG_CREATE_FAMILY_ENABLED: createFamilyEnabled,
    } = appConfig.FEATURE_FLAGS.FAMILY;

    const memoizedViewPortIsMobile = memoizeOne(isWidthDown);
    const viewportIsMobile = memoizedViewPortIsMobile('xs', width, true);
    const familyMemberProfileCardHeaderMenuItems = this.getFamilyMemberProfileCardHeaderMenuItems();

    return (
      <FZPageContainer
        className={
          currentlyActiveFamily
            ? classes.pageContainerWithAnActiveFamily
            : classes.pageContainerWithoutAnActiveFamily
        }
      >
        <FZAppBar activeTab={2}></FZAppBar>

        {currentlyActiveFamilyIsFlaggedForDeletion && (
          <Container>
            <FZInfoCard
              keyColor={colors.danger}
              className={classes.familyFlaggedForDeletionInfoCard}
              actions={
                <FZButton
                  label={t(
                    'editFamilyScreen-general-settings-delete-family-secondary-action-reactive-family-button',
                  )}
                  backgroundColor={colors.secondary}
                  labelColor={colors.textMedium}
                  variant="contained"
                  onClick={this.handleStopFamilyDeletionButtonClick}
                ></FZButton>
              }
            >
              {t('editFamilyScreen-general-settings-delete-family-value', {
                familyWillBeDeletedAtDate: new Date(
                  currentlyActiveFamily.willBeDeletedAt,
                ).toLocaleString(),
              })}
            </FZInfoCard>
          </Container>
        )}

        {/* Screen when there is no family active  */}
        {!currentlyActiveFamily && (
          <Container className={classes.noFamilyActiveScreenContainer}>
            <Grid container>
              <Grid item xs={12} sm={12} md={8}>
                <FZTypography
                  variant="h2"
                  color={colors.secondary}
                  style={{ marginTop: 57 }}
                >
                  {t('familyScreen-no-family-state-headline')}
                </FZTypography>
                <Typography variant="body1" className={classes.introParagraph}>
                  {t('familyScreen-no-family-state-explanation-0')}
                </Typography>

                <Typography variant="body1" className={classes.introParagraph}>
                  {t('familyScreen-no-family-state-explanation-1')}
                </Typography>
                <Typography variant="body1" className={classes.introParagraph}>
                  {t('familyScreen-no-family-state-explanation-2')}
                </Typography>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <Tooltip
                    title={
                      createFamilyEnabled
                        ? t('familyScreen-createNewFamilyButton-tooltip')
                        : t(
                            'familyScreen-createNewFamilyButton-disabled-tooltip',
                          )
                    }
                  >
                    <FZButton
                      style={{ marginRight: 8 }}
                      label={t('familyScreen-createNewFamilyButton-label')}
                      backgroundColor={colors.secondary}
                      isSubmitting={false}
                      disabled={
                        !createFamilyEnabled || userHasReachedFamilyLimit
                      }
                      onClick={this.handleCreateFamilyButtonClick}
                      variant="contained"
                    ></FZButton>
                  </Tooltip>

                  <Tooltip
                    title={
                      createFamilyEnabled
                        ? t('familyScreen-joinFamilyButton-tooltip')
                        : t('familyScreen-joinFamilyButton-disabled-tooltip')
                    }
                  >
                    <FZButton
                      label={t('familyScreen-joinFamilyButton-label')}
                      backgroundColor={colors.white}
                      labelColor={colors.secondary}
                      isSubmitting={false}
                      disabled={!createFamilyEnabled}
                      onClick={this.handleJoinFamilyButtonClick}
                      variant="outlined"
                    ></FZButton>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Container>
        )}

        {!currentlyActiveFamilyIsFlaggedForDeletion && (
          <ReactReduxContext.Consumer>
            {({ store }) => {
              return (
                <div className={classes.stageContainer}>
                  {/* Family overview  */}
                  {currentlyActiveFamily && (
                    <Stage
                      onTouchMove={this.handleTouchMove}
                      onTouchEnd={e => {
                        /*   this.setState({
                        lastCenter: null,
                        lastDistance: 0,
                      }); */
                      }}
                      onDragEnd={e => {
                        this.setState({
                          stagePositionX: e.target.x(),
                          stagePositionY: e.target.y(),
                        });
                      }}
                      onWheel={this.handleWheel}
                      draggable={true}
                      width={stageWidth}
                      height={stageHeight}
                      scaleX={stageScaleX}
                      scaleY={stageScaleY}
                      x={stagePositionX}
                      y={stagePositionY}
                      ref={ref => {
                        this.stageRef = ref;
                      }}
                    >
                      <Layer>
                        {familyMembersAndInvites &&
                          familyMembersAndInvites.map(
                            (memberOrInvite, index) => {
                              if (isUserProfile(memberOrInvite)) {
                                return (
                                  <Group
                                    key={
                                      memberOrInvite.profileImage?.uuid ||
                                      memberOrInvite.uid
                                    }
                                  >
                                    <Line
                                      points={[
                                        presetAvatarPositions[index]?.x,
                                        presetAvatarPositions[index]?.y,
                                        familyNameElementCenter.x,
                                        familyNameElementCenter.y,
                                      ]}
                                      strokeEnabled={true}
                                      strokeWidth={2}
                                      stroke={colors.borderDark}
                                    ></Line>
                                    <FZCanvasAvatar
                                      draggable={false /** viewportIsMobile */}
                                      onDragEnd={e => {
                                        //this.updateAvatarPosition(index, e);
                                      }}
                                      onDragMove={e => {
                                        //this.updateAvatarPosition(index, e);
                                      }}
                                      store={store}
                                      profilePictureUuid={
                                        memberOrInvite.profileImage?.uuid
                                      }
                                      label={
                                        memberOrInvite?.displayName ||
                                        t(
                                          'familyScreen-no-displayname-given-placeholder',
                                        )
                                      }
                                      x={presetAvatarPositions[index]?.x}
                                      y={presetAvatarPositions[index]?.y}
                                      onClick={() => {
                                        this.setState({
                                          currentlyViewedUserProfile: memberOrInvite,
                                        });
                                        this.handleFamilyMemberDetailPopoverOpen();
                                      }}
                                    ></FZCanvasAvatar>
                                  </Group>
                                );
                              } else {
                                if (
                                  memberOrInvite.invitationType ===
                                  FamilyInvitationType.EMAIL_INVITATION
                                ) {
                                  // Its an invitation
                                  // We treat emailed- invitations and sharing links both
                                  // as invites, differentiated by their "invitationType" field.
                                  // We only want to render emailed invitation to the canvas.
                                  return (
                                    <Group key={memberOrInvite.uuid}>
                                      <Line
                                        points={[
                                          presetAvatarPositions[index]?.x,
                                          presetAvatarPositions[index]?.y,
                                          familyNameElementCenter.x,
                                          familyNameElementCenter.y,
                                        ]}
                                        strokeEnabled={true}
                                        strokeWidth={2}
                                        stroke={colors.borderDark}
                                      ></Line>
                                      <FZCanvasAvatar
                                        draggable={
                                          false /**!viewportIsMobile */
                                        }
                                        onDragEnd={e => {
                                          //this.updateAvatarPosition(index, e);
                                        }}
                                        onDragMove={e => {
                                          //this.updateAvatarPosition(index, e);
                                        }}
                                        store={store}
                                        imageSource={avatarPlaceholder4}
                                        label={
                                          memberOrInvite?.receiverEmailAdress ||
                                          ''
                                        }
                                        x={presetAvatarPositions[index]?.x}
                                        y={presetAvatarPositions[index]?.y}
                                        tooltipLabel={`${memberOrInvite?.receiverEmailAdress ||
                                          ''} - ${t(
                                          'familyScreen-member-invited-at-status-label',
                                        )} ${memberOrInvite?.createdAt &&
                                          services.dateTransformationService.getDateInCurrentBrowserLocale(
                                            new Date(memberOrInvite.createdAt),
                                          )}`}
                                        bottomRightBadge={
                                          <FZCanvasButton
                                            color={colors.white}
                                            disabled={true}
                                            outlined
                                            outlineColor={'#707070'}
                                            fillColor={colors.secondary}
                                            iconPath={
                                              svgIconPathMap.inviteLetterIcon
                                            }
                                            offsetX={-120}
                                            offsetY={-120}
                                            scaleX={0.5}
                                            scaleY={0.5}
                                          ></FZCanvasButton>
                                        }
                                        onClick={() => {
                                          this.setState({
                                            currentlyViewedUserProfile: memberOrInvite,
                                          });
                                          this.handleFamilyMemberDetailPopoverOpen();
                                        }}
                                      ></FZCanvasAvatar>
                                    </Group>
                                  );
                                }

                                // Sharing links are not rendered to the canvas.
                                return null;
                              }
                            },
                          )}

                        <Group
                          draggable={false}
                          // onDragMove={this.updateStagePosition}
                          // onDragEnd={this.updateStagePosition}
                          x={familyNameElementCenter.x}
                          y={familyNameElementCenter.y}
                        >
                          <Circle
                            fill={colors.secondary}
                            radius={70}
                            stroke={colors.secondary}
                            strokeEnabled={true}
                          ></Circle>

                          {/*********************************************
                           * Family name elementThis invisible rect is  *
                           * neccessary for React-Konva's "align"       *
                           * properties to work properly, which cause   *
                           * the family name to be centered in the      *
                           * circle no matter its length.
                           * ********************************************/}
                          <Rect
                            width={140}
                            height={140}
                            offsetX={70}
                            offsetY={70}
                          />
                          <Text
                            width={140}
                            height={140}
                            offsetX={70}
                            offsetY={70}
                            //wrap="word"
                            text={
                              currentlyActiveFamily?.name?.substr(0, 30) || ''
                            }
                            align="center"
                            verticalAlign="middle"
                            fontFamily={"'OpenSansBold'"}
                            fontSize={16}
                            fill="black"
                            fillEnabled
                          ></Text>

                          {/* Leave Family Button  */}

                          {/*  <FZCanvasButton
                    hoverable
                    onClick={this.handleLeaveFamilyButtonClick}
                    color={colors.danger}
                    disabled={familyMemberInvitesEnabled === false}
                    hasTooltip
                    tooltipWhenButtonEnabledText={t(
                      'familyScreen-leaveFamilyButton-tooltip',
                    )}
                    tooltipWhenButtonDisabledText={t(
                      'familyScreen-leaveFamilyButton-disabled-tooltip',
                    )}
                    outlined
                    outlineColor={'#707070'}
                    fillColor={colors.primary}
                    iconPath={svgIconPathMap.exitToAppIconPath}
                    offsetX={-72}
                    offsetY={-72}
                    scaleX={0.75}
                    scaleY={0.75}
                  ></FZCanvasButton> */}

                          {/*     <FZCanvasContextMenu
                    open={this.state.familyContextMenuOpen}
                  >
                    <FZCanvasContextMenuItem
                      text={'test'}
                    ></FZCanvasContextMenuItem>
                    <FZCanvasContextMenuItem
                      text={'test2'}
                    ></FZCanvasContextMenuItem>
                  </FZCanvasContextMenu> */}

                          {/* Family Member Invite Button */}
                          {/*     <FZCanvasButton
                    hoverable
                    onClick={this.handleInviteFamilyMemberButtonClick}
                    color={colors.secondary}
                    disabled={familyMemberInvitesEnabled === false}
                    hasTooltip
                    tooltipWhenButtonEnabledText={t(
                      'familyScreen-inviteFamilyMemberButton-tooltip',
                    )}
                    tooltipWhenButtonDisabledText={t(
                      'familyScreen-inviteFamilyMemberButton-disabled-tooltip',
                    )}
                    outlined
                    outlineColor={'#707070'}
                    fillColor={colors.primary}
                    iconPath={svgIconPathMap.addIconPath}
                    offsetX={-50}
                    offsetY={50}
                    scaleX={1}
                    scaleY={1}
                  ></FZCanvasButton> */}
                        </Group>
                      </Layer>
                    </Stage>
                  )}

                  {/* Zoom / Viewreset Buttons   */}
                  {currentlyActiveFamily && (
                    <>
                      <ButtonGroup
                        classes={{ root: classes.zoomButtonGroupRoot }}
                        className={classes.zoomButtonGroup}
                        aria-label="outlined primary button group"
                        orientation="horizontal"
                        variant="outlined"
                        size="large"
                      >
                        <Button onClick={this.handleRecenterCanvasButtonClick}>
                          <FilterCenterFocusRoundedIcon></FilterCenterFocusRoundedIcon>
                        </Button>
                        <Button onClick={this.handleZoomOutButtonClick}>
                          <ZoomOutRoundedIcon
                            className={classes.zoomButtonGroupIcon}
                          ></ZoomOutRoundedIcon>
                        </Button>
                        <Button onClick={this.handleZoomInButtonClick}>
                          <ZoomInRoundedIcon></ZoomInRoundedIcon>
                        </Button>
                      </ButtonGroup>
                    </>
                  )}

                  {/* Family Member Detail View (triggered when clicking an avatar)  */}
                  {currentlyActiveFamily && (
                    <>
                      <SpeedDial
                        ariaLabel="SpeedDial tooltip example"
                        icon={<SpeedDialIcon />}
                        FabProps={{
                          classes: {
                            root: classes.editFamilySpeedDialFab,
                          },
                        }}
                        onClose={() => {
                          this.setState({ speedDialOpen: false });
                        }}
                        onOpen={() => {
                          this.setState({ speedDialOpen: true });
                        }}
                        open={this.state.speedDialOpen}
                        className={classes.createFamilyFAB}
                        classes={{ root: classes.editFamilySpeedDialClasses }}
                      >
                        <SpeedDialAction
                          key={'editFamily'}
                          icon={<EditRoundedIcon />}
                          tooltipTitle={t(
                            'familyScreen-speedDial-editFamily-tooltip-label',
                          )}
                          classes={{
                            staticTooltipLabel: classes.speedDialTooltip,
                          }}
                          tooltipOpen={!viewportIsMobile}
                          onClick={this.handleEditFamilyButtonClick}
                          FabProps={{
                            disabled: !appConfig.FEATURE_FLAGS.FAMILY
                              .REACT_APP_FEATURE_FLAG_EDIT_FAMILY_ENABLED,
                          }}
                        />

                        <SpeedDialAction
                          key={'inviteViaLink'}
                          icon={<ShareRoundedIcon />}
                          tooltipTitle={t(
                            'familyScreen-speedDial-inviteViaLink-tooltip-label',
                          )}
                          tooltipOpen={!viewportIsMobile}
                          onClick={this.handleInviteViaLinkButtonClick}
                          classes={{
                            staticTooltipLabel: classes.speedDialTooltip,
                          }}
                          FabProps={{
                            disabled: !appConfig.FEATURE_FLAGS.FAMILY
                              .REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_VIA_LINK_ENABLED,
                          }}
                        />

                        <SpeedDialAction
                          key={'inviteViaEmail'}
                          icon={<EmailRoundedIcon />}
                          tooltipTitle={t(
                            'familyScreen-speedDial-inviteViaEmail-tooltip-label',
                          )}
                          tooltipOpen={!viewportIsMobile}
                          onClick={this.handleInviteFamilyMemberButtonClick}
                          classes={{
                            staticTooltipLabel: classes.speedDialTooltip,
                          }}
                          FabProps={{
                            disabled: !appConfig.FEATURE_FLAGS.FAMILY
                              .REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_ENABLED,
                          }}
                        />

                        {/*  <SpeedDialAction
            key={'leaveFamily'}
            icon={<ExitToAppRoundedIcon />}
            tooltipTitle={t(
              'familyScreen-speedDial-leaveFamily-tooltip-label',
            )}
            tooltipOpen
            onClick={this.handleLeaveFamilyButtonClick}
            classes={{ staticTooltipLabel: classes.speedDialTooltip }}
          /> */}
                      </SpeedDial>
                    </>
                  )}
                </div>
              );
            }}
          </ReactReduxContext.Consumer>
        )}

        <>
          {/* Leave Family Confirm-Dialog  */}
          <FZDialog
            onConfirm={this.handleLeaveFamilyConfirmed}
            onCancel={this.handleLeaveFamilyCanceled}
            open={this.state.leaveFamilyConfirmationOpen}
            title={t('familyScreen-leaveFamilyConfirmationDialog-title')}
            variant="confirm"
            color={colors.danger}
          >
            {t('familyScreen-leaveFamilyConfirmationDialog-message')}
          </FZDialog>

          {/** Leave Family and flag it for deletion  Confirm-Dialog     */}
          <FZDialog
            onConfirm={this.handleLeaveAndDeleteFamilyConfirmed}
            onCancel={this.handleLeaveAndDeleteFamilyCanceled}
            open={this.state.leaveAndDeleteFamilyConfirmationOpen}
            title={t(
              'familyScreen-leaveAndDeleteFamilyConfirmationDialog-title',
            )}
            variant="confirm"
            color={colors.danger}
          >
            {t('familyScreen-leaveAndDeleteFamilyConfirmationDialog-message')}
          </FZDialog>

          {/** Join Family Dialog     */}
          <FZJoinFamilyWithCodeDialog
            open={this.state.joinFamilyViaCodeModalOpen}
            color={colors.secondary}
            onClose={this.handleJoinFamilyCanceled}
            onJoinSuccessful={this.handleJoinFamilySuccess}
          ></FZJoinFamilyWithCodeDialog>

          {/* Invite Family via Link Dialog */}
          <FZInviteFamilyViaLinkDialog
            familyUuid={currentlyActiveFamily?.uuid}
            open={this.state.familyInviteLinkSharingModalOpen}
            color={colors.secondary}
            onClose={this.handleInvitaFamilyMemberDialogClosed}
          ></FZInviteFamilyViaLinkDialog>

          {currentlyActiveFamily && (
            <Backdrop
              style={{ zIndex: 2 }}
              className={classes.backdrop}
              open={familyMemberDetailPopoverOpen}
              /* onClick={this.handleFamilyMemberDetailPopoverClose} */
            >
              <FZFamilyMemberProfileCard
                open={familyMemberDetailPopoverOpen}
                onClose={this.handleFamilyMemberDetailPopoverClose}
                userProfile={this.state.currentlyViewedUserProfile}
                headerMenuItems={familyMemberProfileCardHeaderMenuItems}
              ></FZFamilyMemberProfileCard>
            </Backdrop>
          )}
        </>
      </FZPageContainer>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    currentlyActiveUserUid: selectLoggedInUserUid(state),
    currentlyActiveFamily: selectCurrentlyActiveFamily(state),
    currentlyActiveFamilyIsFlaggedForDeletion: selectIfCurrentlyActiveFamilyIsDeleted(
      state,
    ),
    userHasReachedFamilyLimit: selectUserHasReachedFamilyLimit(state),
    familyMembersAndInvites: selectFamilyMembersAndInvites(state), //selectFamilyMembersAndInvites(state), //,
    currentUserIsAdminInCurrentlyActiveFamily: selectUserIsAdminInCurrentlyActiveFamily(
      state,
    ),
    currentUserIsLastMemberInCurrentlyActiveFamily: selectCurrentUserIsLastMemberInCurrentlyActiveFamily(
      state,
    ),
  };
};

export default compose(
  connect(mapStateToProps),
  withWidth(),
  withOktaAuth,
  withRouter,
  withHooks,
  withTranslation(),
  withServices,
  withStyles(styles),
)(FamilyScreen);
