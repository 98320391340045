import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import ImageService from '../../services/ImageService';
import { selectAccessToken } from '../login/login.selectors';
import {
  fetchPresignedImageUrlFailure,
  fetchPresignedImageUrlSuccess,
  FetchPresignedUrlRequestAction,
  imageActionTypes,
} from './image.actions';

function* fetchPresignedImageUrlSaga(action: FetchPresignedUrlRequestAction) {
  try {
    // Get Service from redux-saga-context
    const imageService: ImageService = yield getContext('imageService');
    const accessToken: string = yield select(selectAccessToken);

    const {
      ownerFamilyUuid,
      publishedInNewspaperWithUuid,
      belongsToArticleWithUuid,
      imageUuid,
    } = action.payload;

    let response = null;

    // Call the endpoint that makes sure we are allowed to fetch the resource based on the newspaper it belonged to.
    if (publishedInNewspaperWithUuid !== null) {
      response = yield call(
        imageService.getTemporaryAccessUrlForProtectedImageInNewspaper,
        accessToken,
        ownerFamilyUuid,
        publishedInNewspaperWithUuid,
        belongsToArticleWithUuid,
        imageUuid,
      );

      // Call the endpoint that makes sure we are part of the family in which the uploader of the image is also member.
    } else if (publishedInNewspaperWithUuid === null && ownerFamilyUuid) {
      response = yield call(
        imageService.getTemporaryAccessUrlForFamilyResource,
        accessToken,
        ownerFamilyUuid,
        imageUuid,
      );

      // Call the endpoint on which we can fetch self owned resources.
    } else {
      response = yield call(
        imageService.getTemporaryAccessUrlForProtectedImage,
        accessToken,
        imageUuid,
      );
    }

    if (response) {
      yield put(
        fetchPresignedImageUrlSuccess(
          action.payload.imageUuid,
          response.url,
          response.createdAt,
        ),
      );
    }
  } catch (error) {
    yield put(fetchPresignedImageUrlFailure(error));
  }
}

function* watchFetchPresignedImageUrl() {
  yield takeEvery(
    imageActionTypes.FETCH_PRESIGNED_DOWNLOAD_URL_REQUEST,
    fetchPresignedImageUrlSaga,
  );
}

const imageSagas = {
  watchFetchPresignedImageUrl,
};

export default imageSagas;
