import { makeStyles, Theme } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../../00_Constants/colors';
import FZButton from '../../../01_atoms/FZButton/FZButton';
import FZFieldLabel from '../../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZFieldValue from '../../../01_atoms/FZFieldValue/FZFIeldValue';
import FZTextInputField from '../../../01_atoms/FZTextInputField/FZTextInputField';

const useStyles = makeStyles((theme: Theme) => {
  return {
    authorNameValue: {
      marginBottom: 50,
      marginTop: 22,
    },

    formInputField: {
      width: '100%',
    },

    submitButton: {
      marginTop: 46,
    },

    newAuthorNameLabel: { marginBottom: 16 },
  };
});

export interface EditAuthorNameValues {
  newAuthorName: string;
}

interface Props {
  currentAuthorName: string;
  onSubmit: (
    values: EditAuthorNameValues,
    helpers: FormikHelpers<EditAuthorNameValues>,
  ) => void;
}

export default function FZEditAuthorNameForm({
  currentAuthorName,
  onSubmit,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [activeField, setActiveField] = useState('');

  const handleFormFieldFocus = (e: any) => {
    setActiveField(e.target.name);
  };

  const handleFormFieldBlur = (e: any) => {
    setActiveField('');
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          newAuthorName: '',
        }}
        validate={values => {
          const errors: Partial<EditAuthorNameValues> = {};

          if (!values.newAuthorName) {
            errors.newAuthorName = t(
              'editAuthorNameScreen-authorName-required-message',
            );
          }

          return errors;
        }}
        onSubmit={(values, helpers) => {
          onSubmit(values, helpers);
        }}
      >
        {({ submitForm, isSubmitting, errors }) => (
          <Form>
            <FZFieldLabel
              label={t(
                'editAuthorNameScreen-your-current-authorName-is-headline',
              )}
            ></FZFieldLabel>

            <FZFieldValue
              label={currentAuthorName}
              className={classes.authorNameValue}
            ></FZFieldValue>

            <FZFieldLabel
              label={t('editAuthorNameScreen-your-new-authorName-is-headline')}
              className={classes.newAuthorNameLabel}
            ></FZFieldLabel>

            <Field
              id="newAuthorName"
              name="newAuthorName"
              data-testid="newAuthorNameField"
              component={FZTextInputField}
              className={classes.formInputField}
              placeholder={t('editAuthorNameScreen-new-authorName-field-label')}
              color={colors.quartiary}
              backgroundColor={colors.white}
              characterLimit={30}
              enforceCharacterLimit
              displayCharacterCount={activeField === 'newAuthorName'}
              onFocus={handleFormFieldFocus}
              onBlur={handleFormFieldBlur}
            />

            <FZButton
              variant="contained"
              className={classes.submitButton}
              label={t('editAuthorNameScreen-finish-editing-button-label')}
              backgroundColor={colors.quartiary}
              isSubmitting={isSubmitting}
              type="submit"
            ></FZButton>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
