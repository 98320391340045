// src/Home.js

import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import CropLandscapeRoundedIcon from '@material-ui/icons/CropLandscapeRounded';
import CropPortraitRoundedIcon from '@material-ui/icons/CropPortraitRounded';
import InsertPhotoRoundedIcon from '@material-ui/icons/InsertPhotoRounded';
import MyLocationRoundedIcon from '@material-ui/icons/MyLocationRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { StyleRules } from '@material-ui/styles/withStyles';
import React, { Component } from 'react';
import { default as Cropper, default as ReactCropper } from 'react-cropper';
import { withTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouterChildContext, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import i18n from '../../../common/internationalization/i18n_config';
import {
  Article,
  ArticleDto,
  ArticleType,
} from '../../../common/models/Article';
import { Family } from '../../../common/models/Family';
import { Image as CustomImageType } from '../../../common/models/Image';
import { setLoading } from '../../../common/redux/app/app.actions';
import {
  deleteArticleRequest,
  deleteImageRequest,
  postArticleImageCancelled,
  postImageRequest,
  updateArticleRequest,
} from '../../../common/redux/articles/article.actions';
import {
  selectCurrentlyActiveFamily,
  selectCurrentlyActiveFamilyHasReachedEndOfReleaseCycle,
  selectIfCurrentlyActiveFamilyIsDeleted,
} from '../../../common/redux/families/family.selectors';
import { selectAccessToken } from '../../../common/redux/login/login.selectors';
import { ApplicationState } from '../../../common/redux/main/types';
import {
  setNavbarOptions,
  setShouldBlockNavigation,
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../common/redux/ui/ui.actions';
import FormattingUtilities from '../../../common/util/FormattingUtilities';
import colors from '../../00_Constants/colors';
import {
  ARTICLES,
  DELETE_ARTICLE,
  PUBLISH_ARTICLE,
  SAVE_ARTICLE_AS_DRAFT,
} from '../../00_Constants/trackerConstants';
import FZButton from '../../01_atoms/FZButton/FZButton';
import FZNavigateBackButton from '../../01_atoms/FZNavigateBackButton/FZNavigateBackButton';
import FZProgress from '../../01_atoms/FZProgress/FZProgress';
import FZSpinner from '../../01_atoms/FZSpinner/FZSpinner';
import FZAppBar from '../../03_organisms/FZAppBar/FZAppBar';
import FZRouteLeavingGuard from '../../03_organisms/FZRouteLeavingGuard/FZRouteLeavingGuard';
import { withHooks, WithHooksProps } from '../../HOCs/withHooks';
import { withServices, WithServicesProps } from '../../HOCs/withServices';
//import 'cropperjs/dist/cropper.css';
import './AddArticleScreen.css';

type Props = RouterChildContext<any> &
  DispatchProp &
  WithServicesProps &
  WithHooksProps & {
    t?: any;
    history: any;
    classes?: any;
    isPostingImage: boolean;
    isCreatingDraft: boolean;
    isDeletingArticle: boolean;
    currentlyEditedArticle: Article;
    postingImageSuccessful: boolean;
    isUpdatingArticle: boolean;
    updatingArticleWasSuccessful: boolean;
    deletingArticleWasSuccessful: boolean;
    currentlyActiveFamily: Family | null;
    currentlyActiveFamilyHasReachedEndOfReleaseCycle: boolean;
    currentlyActiveFamilyShallBeDeleted: boolean;
    shouldBlockNavigation: boolean;
    accessToken: string;
  };

type State = {
  formIsInitialized: boolean;
  overallFormInputError: string;
  articleImageUrl: string | null;
  articleImageWasDeleted: boolean;
  imageInputValue: string | null;
  croppedImageInputValue: string | null;
  croppedImageBlob: Blob | null;
  imageInputError: string;
  imageUploadProgress: number;
  dateInputValue: string;
  locationInputValue: string;
  titleInputValue: string;
  titleInputError: string;
  contentInputValue: string;
  contentInputNumberOfCharacters: number;
  contentInputNumberOfLines: number;
  contentInputMaxNumberOfCharacters: number;
  contentInputMaxNumberOfLines: number;
  contentInputError: string;
  authorInputValue: string | null;
  imageUploadHovered: boolean;
  articleIsAnswerTo: Article | null;
  imageOrientationToggleValue: 'portrait' | 'landscape';
  imageOrientationPortraitSizes: any;
  imageOrientationLandscapeSizes: any;
};

const styles: (theme: Theme) => StyleRules<object> = theme =>
  createStyles({
    rootContainer: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 16,
      },

      marginTop: '16px',
      paddingLeft: '16px',
      paddingRight: '16px',
    },

    progressRoot: {
      width: '100%',
    },

    thatsNewContainer: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    addImagesIconStyle: {
      opacity: 1,
      color: 'rgba(0,0,0,0.42)',
      padding: 28,

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },

    addImageIconHoveredStyle: {
      opacity: 1,
      color: 'rgba(0,0,0,0.42)',
      padding: 28,

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },

    imageuploadPlaceholderStyle: {
      height: 201,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: 'rgba(0,0,0,0.42)',
      borderWidth: '1px',
      borderStyle: 'solid',
    },

    imageuploadPlaceholderHoveredStyle: {
      height: 201,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: '2px',
      borderStyle: 'solid',
      cursor: 'copy',
    },

    imagePreviewContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },

    imagePreviewImageContainer: {
      //backgroundColor: 'dimgrey',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    imagePreviewImageStyle: {
      width: '100%',
      maxWidth: '400px',
    },

    iconDisabledStyle: {
      color: 'rgba(0,0,0,0.42)',
    },

    iconEnabledStyle: {
      color: 'rgba(0,0,0,1)',
    },

    contentInputRoot: {},

    locationInputStyle: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      [theme.breakpoints.up('sm')]: {
        width: '90%',
        float: 'left',
      },
    },

    dateInputStyle: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      [theme.breakpoints.up('sm')]: {
        width: '90%',
        float: 'right',
      },
    },

    deleteButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
      },
    },

    inputSideLabelContainer: {
      display: 'flex',
      justifyContent: 'flex-end',

      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },
    },

    inputSideLabelStyle: {
      color: '#468C7F',
      letterSpacing: '3.13px',
      fontSize: 20,
      textAlign: 'right',
      paddingRight: 24,

      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
        paddingRight: 0,
        marginBottom: 16,
      },
    },

    rowContainer: {
      marginTop: 20,
    },

    /*  toggleButtonRoot: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
    },

    toggleButtonLabel: {
      '&:selected': {
        color: theme.palette.secondary.main,
      },
    },

    toggleButtonSelected: {
      backgroundColor: 'rgba(255,255,255,1)',
    }, */
  });

let cropperRef: ReactCropper | null;
let fileUploadRef: HTMLInputElement | null;

class AddArticleScreen extends Component<Props, State> {
  screenContainerRef: any = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      formIsInitialized: false,
      overallFormInputError: '',
      articleImageWasDeleted: false,
      articleImageUrl: null,
      imageInputValue: null,
      imageInputError: '',

      croppedImageInputValue: null,
      croppedImageBlob: null,
      imageUploadHovered: false,
      imageUploadProgress: 0,
      dateInputValue: FormattingUtilities.getFormattedDate(Date.now()),
      locationInputValue: '',
      titleInputValue: '',
      titleInputError: '',
      contentInputValue: '',
      contentInputNumberOfCharacters: 0,
      contentInputNumberOfLines: 0,
      contentInputError: '',
      authorInputValue: null,
      articleIsAnswerTo: null,
      imageOrientationToggleValue: 'portrait',
      imageOrientationPortraitSizes: {
        minWidth: 337,
        minHeight: 452, // === 338 pt, same size as the portrait-image height in print of the newspapers
      },
      imageOrientationLandscapeSizes: {
        minWidth: 337,
        minHeight: 252, // === 189 pt, same size as the landscape-image height in print of the newspapers
      },

      contentInputMaxNumberOfCharacters: props.currentlyEditedArticle
        ? props.currentlyEditedArticle.articleType === ArticleType.LONG_ARTICLE
          ? 1000
          : 300
        : 0,

      contentInputMaxNumberOfLines: props.currentlyEditedArticle
        ? props.currentlyEditedArticle.articleType === ArticleType.LONG_ARTICLE
          ? 20
          : 6
        : 0,
    };

    fileUploadRef = null;
    this.bindFunctions = this.bindFunctions.bind(this);
    this.bindFunctions();
  }

  /**
   * Binds all the methods in this class to a this-instance.
   */
  bindFunctions(): void {
    this.resetForm = this.resetForm.bind(this);
    this.renderImageUploadWidgetOrExistingImage = this.renderImageUploadWidgetOrExistingImage.bind(
      this,
    );
    this.renderImagePreview = this.renderImagePreview.bind(this);
    this.handleFileInputChange = this.handleFileInputChange.bind(this);

    this.handleAddImagesButtonPress = this.handleAddImagesButtonPress.bind(
      this,
    );

    this.getCroppedImageAsDataUrl = this.getCroppedImageAsDataUrl.bind(this);
    this.getCroppedImageAsBlob = this.getCroppedImageAsBlob.bind(this);

    this.handleImageCropperOrientationChange = this.handleImageCropperOrientationChange.bind(
      this,
    );

    this.handleDateInputChange = this.handleDateInputChange.bind(this);
    this.handleLocationInputChange = this.handleLocationInputChange.bind(this);
    this.handleTitleInputChange = this.handleTitleInputChange.bind(this);
    this.handleContentInputChange = this.handleContentInputChange.bind(this);
    this.handleAuthorInputChange = this.handleAuthorInputChange.bind(this);

    this.handlePublishArticleButtonPressed = this.handlePublishArticleButtonPressed.bind(
      this,
    );
    this.handleSaveAsDraftButtonPressed = this.handleSaveAsDraftButtonPressed.bind(
      this,
    );
    this.handleDeleteArtileButtonPressed = this.handleDeleteArtileButtonPressed.bind(
      this,
    );
    this.handleUploadImageCancelled = this.handleUploadImageCancelled.bind(
      this,
    );

    this.validateFormAsDraftSynchronously = this.validateFormAsDraftSynchronously.bind(
      this,
    );
    this.validateFormSynchronously = this.validateFormSynchronously.bind(this);

    this.uploadImage = this.uploadImage.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.updateArticle = this.updateArticle.bind(this);
  }

  /**
   * Resets all input values in this form, including a possibly uploaded image
   */
  resetForm(): void {
    const todaysDate = FormattingUtilities.getFormattedDate(Date.now());
    this.setState({
      imageInputValue: null,
      imageInputError: '',

      dateInputValue: todaysDate,
      locationInputValue: '',
      titleInputValue: '',
      titleInputError: '',
      contentInputValue: '',
      contentInputNumberOfCharacters: 0,
      contentInputNumberOfLines: 0,
      contentInputError: '',
      authorInputValue: '',
      imageUploadHovered: false,
    });
  }

  /**
   * Renders a widget that allows the user to upload an image, given that none has been uploaded so far.
   */
  renderImageUploadWidgetOrExistingImage(classes: any): any {
    if (
      this.state.imageInputValue === null &&
      this.state.croppedImageInputValue === null &&
      this.state.articleImageUrl === null
    ) {
      return (
        <div>
          <div
            className={
              this.state.imageUploadHovered
                ? classes.imageuploadPlaceholderHoveredStyle
                : classes.imageuploadPlaceholderStyle
            }
            onMouseEnter={() => {
              this.setState({ imageUploadHovered: true });
            }}
            onMouseLeave={() => {
              this.setState({ imageUploadHovered: false });
            }}
            onClick={this.handleAddImagesButtonPress}
          >
            <div
              className={
                this.state.imageUploadHovered
                  ? classes.addImageIconHoveredStyle
                  : classes.addImagesIconStyle
              }
            >
              <InsertPhotoRoundedIcon
                style={{ width: '100px', fontSize: 100 }}
              />
              <Typography style={{ textTransform: 'uppercase' }}>
                {i18n.t('addArticleScreen-addImageButton-label')}
              </Typography>

              <form>
                <input
                  id="fileUploader"
                  type="file"
                  ref={ref => (fileUploadRef = ref)}
                  style={{ position: 'fixed', top: '-100em' }}
                  onChange={this.handleFileInputChange}
                  accept="image/png, image/jpg, image/jpeg"
                />
              </form>
            </div>
          </div>
          {this.state.imageInputError && (
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="space-around"
            >
              <FormHelperText
                style={{ color: colors.danger }}
              >{`${this.state.imageInputError}`}</FormHelperText>
            </Box>
          )}
        </div>
      );
    }

    if (this.state.articleImageUrl && this.state.imageInputValue === null) {
      return (
        <div className={classes.imagePreviewContainer}>
          <div className={classes.imagePreviewImageContainer}>
            <img
              alt={i18n.t('addArticleScreen-uploadedImage-AltText')}
              src={this.state.articleImageUrl}
              className={classes.imagePreviewImageStyle}
            />
          </div>
          <Button
            style={{ width: '100%' }}
            onClick={() => {
              this.setState({
                articleImageUrl: null,
                imageInputValue: null,
                croppedImageInputValue: null,
                articleImageWasDeleted: true,
              });
            }}
          >
            {i18n.t('addArticleScreen-abort-cropping-button-label')}
          </Button>
        </div>
      );
    }
  }

  /**
   * Renders an image cropping widget that allows to crop an image before uploading it.
   */
  renderImageCropper(classes: any): any {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ToggleButtonGroup
          style={{ float: 'right', marginBottom: 4, alignSelf: 'flex-end' }}
          value={this.state.imageOrientationToggleValue}
          exclusive
          onChange={this.handleImageCropperOrientationChange}
          aria-label="image cropper orientation"
          size="small"
        >
          <ToggleButton
            value="portrait"
            aria-label="portrait"
            classes={{
              root: classes.toggleButtonRoot,
              label: classes.toggleButtonLabel,
              selected: classes.toggleButtonSelected, // class name, e.g. `classes-nesting-label-x`
            }}
          >
            <CropPortraitRoundedIcon />
          </ToggleButton>
          <ToggleButton
            value="landscape"
            aria-label="landscape"
            classes={{
              root: classes.toggleButtonRoot,
              label: classes.toggleButtonLabel,
              selected: classes.toggleButtonSelected, // class name, e.g. `classes-nesting-label-x`
            }}
          >
            <CropLandscapeRoundedIcon />
          </ToggleButton>
        </ToggleButtonGroup>

        <Cropper
          ref={ref => {
            cropperRef = ref;
          }}
          src={
            this.state.imageInputValue ? this.state.imageInputValue : undefined
          }
          style={{ maxHeight: '500px' }}
          aspectRatio={
            this.state.imageOrientationToggleValue === 'portrait'
              ? this.state.imageOrientationPortraitSizes.minWidth /
                this.state.imageOrientationPortraitSizes.minHeight
              : this.state.imageOrientationLandscapeSizes.minWidth /
                this.state.imageOrientationLandscapeSizes.minHeight
          }
          allowFullScreen={true}
          /*      minCropBoxHeight={
            this.state.imageOrientationToggleValue === 'portrait'
              ? this.state.imageOrientationPortraitSizes.minHeight
              : this.state.imageOrientationLandscapeSizes.minHeight
          } */
          minCropBoxWidth={
            this.state.imageOrientationToggleValue === 'portrait'
              ? this.state.imageOrientationPortraitSizes.minWidth
              : this.state.imageOrientationLandscapeSizes.minWidth
          }
          guides={false}
          /*   minCanvasHeight={
            this.state.imageOrientationToggleValue === 'portrait'
              ? this.state.imageOrientationPortraitSizes.minHeight
              : this.state.imageOrientationLandscapeSizes.minHeight
          } */
          minCanvasWidth={
            this.state.imageOrientationToggleValue === 'portrait'
              ? this.state.imageOrientationLandscapeSizes.minWidth
              : this.state.imageOrientationLandscapeSizes.minWidth
          }
          responsive={true}
          cropBoxResizable={true}
          dragMode={'move'}
          viewMode={1}
          zoomable={false}
        />

        {this.state.imageInputError && (
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-around"
          >
            <FormHelperText
              style={{ color: colors.danger }}
            >{`${this.state.imageInputError}`}</FormHelperText>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
        >
          <Button
            onClick={() => {
              this.setState({ imageInputError: '', imageInputValue: null });
            }}
          >
            {i18n.t('addArticleScreen-abort-cropping-button-label')}
          </Button>
          <Button
            style={{ marginTop: 5 }}
            variant="contained"
            color="secondary"
            onClick={() => {
              const { dispatch } = this.props;
              dispatch(setLoading(true, ''));

              const croppedImageAsBase64 = this.getCroppedImageAsDataUrl();

              const mimeType = CustomImageType.base64MimeType(
                croppedImageAsBase64!,
              );

              if (mimeType) {
                this.getCroppedImageAsBlob(mimeType);
                this.setState({
                  croppedImageInputValue: croppedImageAsBase64!,
                  imageInputValue: null,
                  imageInputError: '',
                });
                dispatch(setLoading(false, ''));
              }
            }}
          >
            {i18n.t('addArticleScreen-crop-button-label')}
          </Button>
        </Box>
      </div>
    );
  }

  /**
   * Renders the preview of an uploaded image for the article, given that one exists.
   */
  renderImagePreview(classes: any): any {
    return (
      <>
        {this.state.croppedImageInputValue && (
          <div className={classes.imagePreviewContainer}>
            <div className={classes.imagePreviewImageContainer}>
              <img
                alt={i18n.t('addArticleScreen-uploadedImage-AltText')}
                src={this.state.croppedImageInputValue}
                className={classes.imagePreviewImageStyle}
              />
            </div>
            <Button
              style={{ width: '100%' }}
              onClick={() => {
                this.setState({
                  imageInputValue: null,
                  croppedImageInputValue: null,
                });
              }}
            >
              {i18n.t('addArticleScreen-abort-cropping-button-label')}
            </Button>
          </div>
        )}
      </>
    );
  }

  /**
   * Renders an image that was previously uploaded to the article.
   */
  renderArticleImage(classes: any): any {
    return (
      <>
        {this.state.articleImageUrl && (
          <div className={classes.imagePreviewContainer}>
            <div className={classes.imagePreviewImageContainer}>
              <img
                alt={i18n.t('addArticleScreen-uploadedImage-AltText')}
                src={this.state.articleImageUrl}
                className={classes.imagePreviewImageStyle}
              />
            </div>
            <Button
              style={{ width: '100%' }}
              onClick={() => {
                this.setState({
                  articleImageUrl: null,
                });
              }}
            >
              {i18n.t('addArticleScreen-abort-cropping-button-label')}
            </Button>
          </div>
        )}
      </>
    );
  }

  /**
   * Reads the cropped image data and returns it as a data url (base64 encoded)
   */
  getCroppedImageAsDataUrl() {
    return cropperRef?.getCroppedCanvas().toDataURL();
  }

  /**
   * Reads the cropped image data and returns it as a blob for server uploading.
   */
  getCroppedImageAsBlob(imageContentType: string): void {
    cropperRef?.getCroppedCanvas().toBlob(blob => {
      this.setState({
        croppedImageBlob: blob,
      });
    }, imageContentType);
  }

  /**
   * Handles a change in the file input component
   */
  handleFileInputChange(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    const objectUrl = URL.createObjectURL(file);

    const img = new Image();
    img.src = objectUrl;

    URL.createObjectURL(file);

    const imageOnloadCallback = () => {
      const width = img.naturalWidth,
        height = img.naturalHeight;

      // check its dimensions
      if (width <= 337 || height <= 252) {
        // it doesn't fit, unset the value
        // post an error

        this.setState({
          imageInputValue: null,
          imageInputError: i18n.t(
            'addArticleScreen-imageTooSmall-error-message',
          ),
        });
        // unload it
        URL.revokeObjectURL(img.src);
      } else {
        this.setState({ imageInputValue: objectUrl, imageInputError: '' });
      }
    };

    imageOnloadCallback.bind(this);

    // as soon as the image has been loaded
    img.onload = imageOnloadCallback;
  }

  /**
   * Handles a press onto the add-images button in the placeholder on top of the page.
   * NOTE: This does not yet trigger the upload of an image, but rather opens the file explorer to pick an image.
   */
  handleAddImagesButtonPress(): void {
    fileUploadRef?.click();
  }

  /**
   * Handles a change in the toggle button group that sets image orientation.
   * @param inputEvent
   */
  handleImageCropperOrientationChange(
    inputEvent: any,
    orientation: 'landscape' | 'portrait',
  ): void {
    if (orientation !== null) {
      this.setState({
        imageOrientationToggleValue: orientation,
      });
    }
  }

  /**
   * Handles a change in the date input field
   */
  handleDateInputChange(inputEvent: any): void {
    this.setState({
      dateInputValue: inputEvent.target.value,
    });
  }

  /**
   * Handles a change in the location input field
   */
  handleLocationInputChange(inputEvent: any): void {
    this.setState({
      locationInputValue: inputEvent.target.value,
    });
  }

  /**
   * Handles a text change in the title input field.
   */
  handleTitleInputChange(inputEvent: any): void {
    this.setState({
      titleInputValue: inputEvent.target.value,
      titleInputError: '',
    });
  }

  /**
   * Handles a text change in the title input field.
   */
  handleContentInputChange(inputEvent: any): void {
    const newInputValue: string = inputEvent.target.value;
    const ARRAY_OF_LINES = inputEvent.target.value.split('\n');
    const NUMBER_OF_NEW_LINES_IN_INPUT = ARRAY_OF_LINES.length;
    const totalNumberOfCharacters = newInputValue.length;

    if (
      totalNumberOfCharacters <= this.state.contentInputMaxNumberOfCharacters &&
      NUMBER_OF_NEW_LINES_IN_INPUT <= this.state.contentInputMaxNumberOfLines
    ) {
      this.setState({
        contentInputValue: newInputValue,
        contentInputNumberOfLines: NUMBER_OF_NEW_LINES_IN_INPUT,
        contentInputNumberOfCharacters: totalNumberOfCharacters,
        contentInputError: '',
        overallFormInputError: '',
      });
    }
  }

  /**
   * Handles a text change in the author input field.
   */
  handleAuthorInputChange(inputEvent: any): void {
    this.setState({
      authorInputValue: inputEvent.target.value,
    });
  }

  /**
   * Handles a click onto the publish article button
   */
  handlePublishArticleButtonPressed(): void {
    const { matomoAnalyticsTracker } = this.props;
    const formValid = this.validateFormSynchronously();

    if (formValid) {
      matomoAnalyticsTracker.trackEvent({
        category: ARTICLES,
        action: PUBLISH_ARTICLE,
        name: 'Add Article Screen - Publish Article Button',
        href: '/write-article',
      });
      this.updateArticle(false);
    }
  }

  /**
   * Handles a click onto the "save-article-as-draft" button
   */
  handleSaveAsDraftButtonPressed(): void {
    const formValidForSavingAsDraft = this.validateFormAsDraftSynchronously();

    if (formValidForSavingAsDraft) {
      const { matomoAnalyticsTracker } = this.props;
      matomoAnalyticsTracker.trackEvent({
        category: ARTICLES,
        action: SAVE_ARTICLE_AS_DRAFT,
        name: 'Add Article Screen - Save as Draft Button',
        href: '/write-article',
      });
      this.updateArticle(true);
    }
  }

  /**
   * Handles a click onto the "delete-article" button
   */
  handleDeleteArtileButtonPressed(): void {
    const { matomoAnalyticsTracker, dispatch } = this.props;
    matomoAnalyticsTracker.trackEvent({
      category: ARTICLES,
      action: DELETE_ARTICLE,
      name: 'Add Article Screen - Delete Article Button',
      href: '/write-article',
    });
    dispatch(deleteArticleRequest(this.props.currentlyEditedArticle));
  }

  /**
   * Handles a cancellation on a running task to upload an image
   */
  handleUploadImageCancelled(): void {
    const { dispatch } = this.props;
    dispatch(postArticleImageCancelled());
  }

  /**
   * Submits a request to update an article using values from the input fields of this page
   * or pre-existing inputs.
   * @param {boolean} asDraft - specifies if the article shall be submitted as a draft (unpublished)
   * or published to the current newspaper of the currently active family.
   */
  updateArticle(asDraft: boolean) {
    // Read the values of input fields on this page.

    const { currentlyEditedArticle, dispatch, t } = this.props;

    const articleData: ArticleDto = {
      uuid: currentlyEditedArticle.uuid,
      isDraft: asDraft,
      freelyAssignedDate: this.state.dateInputValue,
      locationName: this.state.locationInputValue,
      title: this.state.titleInputValue,
      description: this.state.contentInputValue,
      // If the user set an author, use it. Else - use the one that the article was initialized with
      freelyAssignedAuthor: this.state.authorInputValue
        ? this.state.authorInputValue
        : currentlyEditedArticle.freelyAssignedAuthor,
      photos: currentlyEditedArticle.photos,
      answerTo: currentlyEditedArticle.answerTo,
    };

    const articleToPublish = new Article(articleData);

    // If the user did anything with the image uploader, this will be called after the image operation succeeds, and only then update the article.
    const imageOperationSuccessCallback = () => {
      // Trigger updating the article only
      dispatch(updateArticleRequest(articleToPublish));
    };

    const imageOperationErrorCallback = () => {
      dispatch(
        showErrorSnackbar(
          t('addArticleScreen-uploading-image-failed-message'),
          true,
        ),
      );
    };

    // The user deleted the old image and did not add a new one.
    // Send an empty photos array and rely on the backend to delete the photo.
    if (
      this.state.articleImageWasDeleted &&
      !this.state.croppedImageInputValue
    ) {
      this.deleteImage(imageOperationSuccessCallback);
      return;

      // The user did add a new image, upload it first, then update the article.
    } else if (this.state.croppedImageInputValue) {
      this.uploadImage(
        imageOperationSuccessCallback,
        imageOperationErrorCallback,
      );
      return;
    }

    const updateArticleSuccessCallback = () => {
      dispatch(
        showSuccessSnackbar(
          t('addArticleScreen-update-article-successful-message'),
          true,
        ),
      );
    };

    const updateArticleErrorCallback = (error: any) => {
      dispatch(
        showErrorSnackbar(
          t('addArticleScreen-update-article-error-message'),
          false,
        ),
      );
    };

    // Perform the update
    dispatch(
      updateArticleRequest(
        articleToPublish,
        updateArticleSuccessCallback,
        updateArticleErrorCallback,
      ),
    );
  }

  /**
   * Triggers deletion of an image that had been added to the article, but was now deleted.
   * @param successCallback
   */
  deleteImage(successCallback: () => void): void {
    this.props.dispatch(
      deleteImageRequest(
        this.props.currentlyEditedArticle!.uuid,
        this.props.currentlyEditedArticle.photos[0].uuid,
        successCallback,
      ),
    );
  }

  /**
   * Triggers uploading the image that has been added to the article.
   */
  uploadImage(
    successCallback: () => void,
    errorCallback: (error: any) => void,
  ): void {
    const mimeType = CustomImageType.base64MimeType(
      this.state.croppedImageInputValue,
    );
    const imageAsBlob = this.state.croppedImageBlob;

    const imageToUpload = new CustomImageType();
    imageToUpload.blob = imageAsBlob;
    imageToUpload.mimeType = mimeType!;

    const uploadImageProgressCallback = (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );

      this.setState({ imageUploadProgress: percentCompleted });
    };

    this.props.dispatch(
      postImageRequest(
        [imageToUpload],
        this.props.currentlyEditedArticle?.uuid!,
        uploadImageProgressCallback,
        {
          successCallback: successCallback,
          errorCallback: errorCallback,
        },
      ),
    );
  }

  /**
   * Validates the addArticleForm and sets error messages accordingly.
   * @returns {boolean} true if the form is valid. False otherwise.
   */
  validateFormSynchronously(): boolean {
    if (
      Boolean(this.state.imageInputValue) &&
      !Boolean(this.state.croppedImageInputValue)
    ) {
      this.setState({
        imageInputError: i18n.t('addArticleForm-image-upload-error-message'),
      });
      /*   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      document.body.scrollTo(0, 0);
      document.body.scrollTop = 0; */

      document.getElementById('root').scrollTo(0, 0);
      return false;
    }

    if (!this.state.titleInputValue) {
      this.setState({
        titleInputError: i18n.t('addArticleForm-title-empty-error-message'),
      });
      return false;
    }

    if (
      !this.state.contentInputValue &&
      !(
        Boolean(this.state.croppedImageInputValue) ||
        Boolean(this.state.articleImageUrl)
      )
    ) {
      this.setState({
        overallFormInputError: i18n.t(
          'addArticleForm-content-or-image-empty-error-message',
        ),
        contentInputError: ' ',
      });
      return false;
    }

    return true;
  }

  /**
   * Validates the addArticleForm by the rules of draft article, which is
   * not as strict as the rules for a published article.
   */
  validateFormAsDraftSynchronously(): boolean {
    if (
      Boolean(this.state.imageInputValue) &&
      !Boolean(this.state.croppedImageInputValue)
    ) {
      this.setState({
        imageInputError: i18n.t('addArticleForm-image-upload-error-message'),
      });
      document.getElementById('root').scrollTo(0, 0);

      return false;
    }

    return true;
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.currentlyEditedArticle && !state.formIsInitialized) {
      const todaysDate = FormattingUtilities.getFormattedDate(Date.now());

      return {
        titleInputValue: props.currentlyEditedArticle.title,
        contentInputValue: props.currentlyEditedArticle.description,
        contentInputNumberOfCharacters:
          props.currentlyEditedArticle.description.length,

        locationInputValue: props.currentlyEditedArticle.locationName || '',
        authorInputValue: props.currentlyEditedArticle.freelyAssignedAuthor,
        dateInputValue:
          props.currentlyEditedArticle.freelyAssignedDate || todaysDate,
        articleIsAnswerTo: props.currentlyEditedArticle.answerTo,
        formIsInitialized: true,
      };
    }

    return state;
  }

  async componentDidMount() {
    const {
      dispatch,
      matomoAnalyticsTracker,
      currentlyEditedArticle,
      services,
      accessToken,
    } = this.props;

    document.getElementById('root').scrollTo(0, 0);

    matomoAnalyticsTracker.trackPageView({
      documentTitle: 'Write Article Screen', // optional
    });

    dispatch(setShouldBlockNavigation(true));

    if (
      currentlyEditedArticle &&
      currentlyEditedArticle.photos &&
      currentlyEditedArticle.photos.length > 0 &&
      this.state.articleImageUrl === null
    ) {
      const protectedImage = currentlyEditedArticle.photos[0];

      const uploadedArticleImageUrl = await services.imageService.getTemporaryAccessUrlForProtectedImage(
        accessToken,
        protectedImage.uuid,
      );

      if (uploadedArticleImageUrl) {
        this.setState({ articleImageUrl: uploadedArticleImageUrl.url });
      }
    }
  }

  componentDidUpdate() {
    const {
      dispatch,
      history,
      updatingArticleWasSuccessful,
      deletingArticleWasSuccessful,
    } = this.props;

    const shouldRedirectBackToHome =
      updatingArticleWasSuccessful || deletingArticleWasSuccessful;

    if (shouldRedirectBackToHome) {
      dispatch(setShouldBlockNavigation(false));

      history.push({
        pathname: '/home',
        state: {
          shouldRefetchCurrentNewspaper: true,
          shouldRefetchOwnArticles: true,
        },
      });
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setShouldBlockNavigation(false));
    dispatch(setNavbarOptions({ displayBackIcon: false }));
  }

  renderAddArticleScreen(classes: any) {
    const inputsShouldBlock =
      this.props.isPostingImage ||
      this.props.isUpdatingArticle ||
      this.props.isDeletingArticle;

    const {
      currentlyActiveFamilyHasReachedEndOfReleaseCycle,
      currentlyActiveFamilyShallBeDeleted,
      currentlyActiveFamily,
      history,
    } = this.props;

    return (
      <React.Fragment>
        <FZRouteLeavingGuard
          confirmTitle={i18n.t(
            'addArticleScreen-are-you-sure-you-want-to-leave-the-page-prompt-title',
          )}
          confirmMessage={i18n.t(
            'addArticleScreen-are-you-sure-you-want-to-leave-the-page-prompt-message',
          )}
          onNavigationConfirmed={(targetLocation: string) => {
            const { isPostingImage } = this.props;

            if (isPostingImage) {
              this.handleUploadImageCancelled();
            }
          }}
          // When should shouldBlockNavigation be invoked,
          // simply passing a boolean
          // (same as "when" prop of Prompt of React-Router)
          when={this.props.shouldBlockNavigation}
          // Navigate function
          navigate={(path: string) => {
            history.push({
              pathname: '/home',

              state: {
                shouldRefetchCurrentNewspaper: false,
                shouldRefetchOwnArticles: true,
                shouldRefetchLatestPublishedNewspaper: false,
              },
            });
          }}
          // Use as "message" prop of Prompt of React-Router
          shouldBlockNavigation={location => {
            return this.props.shouldBlockNavigation;
          }}
        />

        <Grid xs={12} md={12} lg={12} className={classes.rootContainer} item>
          {/**
           *  Answer To - Row
           */}
          {this.state.articleIsAnswerTo && (
            <Grid container className={classes.rowContainer}>
              <Grid
                xs={12}
                md={2}
                lg={2}
                item
                className={classes.inputSideLabelContainer}
              >
                <Typography
                  variant="h5"
                  component="p"
                  className={classes.inputSideLabelStyle}
                >
                  {`${i18n.t('addArticleForm-answerTo-label')}`}
                </Typography>
              </Grid>

              <Grid xs={12} md={10} lg={10} item>
                <Typography
                  variant="h5"
                  component="p"
                  style={{ color: '#000000' }}
                >
                  {`${this.state.articleIsAnswerTo.title}`}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Box display="flex" flexDirection="column">
            {/**
             *  Image Row
             */}
            <Grid container className={classes.rowContainer}>
              <Grid
                item
                xs={12}
                md={2}
                lg={2}
                className={classes.inputSideLabelContainer}
              >
                <Typography
                  variant="h5"
                  component="p"
                  className={classes.inputSideLabelStyle}
                >
                  {i18n.t('addArticleScreen-image-input-row-label')}
                </Typography>
              </Grid>

              <Grid item xs={12} md={10} lg={10}>
                {this.props.isPostingImage ? (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <FZProgress
                      statusMessage={i18n.t(
                        'addArticleScreen-posting-article-image-loading-message',
                      )}
                      value={this.state.imageUploadProgress}
                    ></FZProgress>

                    <Button onClick={this.handleUploadImageCancelled}>
                      {i18n.t(
                        'addArticleScreen-cancel-image-upload-button-label',
                      )}
                    </Button>
                  </div>
                ) : (
                  <>
                    {this.renderImageUploadWidgetOrExistingImage(classes)}
                    {this.state.imageInputValue !== null &&
                      this.state.croppedImageInputValue === null &&
                      this.renderImageCropper(classes)}
                    {this.state.imageInputValue === null &&
                      this.state.croppedImageInputValue !== null &&
                      this.renderImagePreview(classes)}
                  </>
                )}
              </Grid>
              {/*  <Grid
                xs={12}
                md={2}
                lg={2}
                item
                className={classes.thatsNewContainer}
              >
                <Card style={{ marginLeft: '34px' }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      className={classes.inputSideLabelStyle}
                    >
                      {i18n.t('addArticleScreen-ThatsNewBox-Headline')}
                    </Typography>
                    <Typography variant="body2">
                      {i18n.t('addArticleScreen-ThatsNewBox-Content')}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
               
          
*/}
              <Grid
                container
                style={{ marginTop: '20px' }}
                className={classes.rowContainer}
              >
                {/**
                 * Meta (Location & Date) Row
                 */}
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    lg={2}
                    className={classes.inputSideLabelContainer}
                  >
                    <Typography
                      variant="h5"
                      component="p"
                      className={classes.inputSideLabelStyle}
                    >
                      {i18n.t(
                        'addArticleScreen-location-and-time-input-row-label',
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={10} lg={10}>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                          value={this.state.locationInputValue}
                          onChange={this.handleLocationInputChange}
                          className={classes.locationInputStyle}
                          color="secondary"
                          placeholder={i18n.t(
                            'addArticleForm-location-input-placeholder',
                          )}
                          // label={i18n.t("addArticleForm-location-input-label")}
                          id="locationInputTextField"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <MyLocationRoundedIcon
                                  className={
                                    this.state.locationInputValue.length === 0
                                      ? classes.iconDisabledStyle
                                      : classes.iconEnabledStyle
                                  }
                                />
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 20 }}
                          helperText={`${this.state.locationInputValue.length}/20`}
                          disabled={inputsShouldBlock}
                        />
                      </Grid>

                      <Grid xs={12} sm={6} md={6} lg={6} item>
                        <TextField
                          className={classes.dateInputStyle}
                          value={this.state.dateInputValue}
                          onChange={this.handleDateInputChange}
                          color="secondary"
                          placeholder={i18n.t(
                            'addArticleForm-date-input-placeholder',
                          )}
                          // label={i18n.t("addArticleForm-date-input-label")}
                          id="dateInputTextField"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <TodayRoundedIcon
                                  className={
                                    this.state.dateInputValue.length === 0
                                      ? classes.iconDisabledStyle
                                      : classes.iconEnabledStyle
                                  }
                                />
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 20 }}
                          helperText={`${this.state.dateInputValue.length}/20`}
                          disabled={inputsShouldBlock}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/**
                 * Title Row
                 */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ marginTop: 20 }}
                  className={classes.rowContainer}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      lg={2}
                      className={classes.inputSideLabelContainer}
                    >
                      <Typography
                        variant="h5"
                        component="p"
                        className={classes.inputSideLabelStyle}
                        style={{ paddingTop: '8px' }}
                      >
                        {i18n.t('addArticleScreen-title-input-row-label')}
                      </Typography>
                    </Grid>

                    <Grid xs={12} md={10} lg={10} item>
                      <TextField
                        value={this.state.titleInputValue}
                        onChange={this.handleTitleInputChange}
                        color="secondary"
                        placeholder={i18n.t(
                          'addArticleForm-title-input-placeholder',
                        )}
                        /*    label={i18n.t('addArticleForm-title-input-label')} */
                        style={{
                          width: '100%',
                          fontSize: '80px',
                          fontWeight: 'bold',
                        }}
                        inputProps={{
                          maxLength: 50,
                          style: { fontSize: '24px' },
                        }}
                        InputLabelProps={{ style: { fontSize: '24px' } }}
                        helperText={this.state.titleInputError}
                        error={this.state.titleInputError.length !== 0}
                        disabled={inputsShouldBlock}
                      />

                      <FormHelperText>{`${this.state.titleInputValue.length}/50`}</FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>

                {/**
                 * Content Row
                 */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ marginTop: 20 }}
                  className={classes.rowContainer}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      lg={2}
                      className={classes.inputSideLabelContainer}
                    >
                      <Typography
                        variant="h5"
                        component="p"
                        className={classes.inputSideLabelStyle}
                      >
                        {i18n.t('addArticleScreen-content-input-row-label')}
                      </Typography>
                    </Grid>

                    <Grid xs={12} md={10} lg={10} item>
                      <TextField
                        value={this.state.contentInputValue}
                        onChange={this.handleContentInputChange}
                        color="secondary"
                        placeholder={i18n.t(
                          'addArticleForm-content-input-placeholder',
                        )}
                        /* label={i18n.t('addArticleForm-content-input-label')} */
                        multiline
                        rows={this.state.contentInputMaxNumberOfLines}
                        rowsMax={this.state.contentInputMaxNumberOfLines}
                        fullWidth
                        style={{
                          overflowY: 'hidden',
                          overflowX: 'hidden',
                        }}
                        inputProps={{
                          maxLength: this.state
                            .contentInputMaxNumberOfCharacters,
                        }}
                        helperText={this.state.contentInputError}
                        error={this.state.contentInputError.length !== 0}
                        classes={{ root: classes.contentInputRoot }}
                        disabled={inputsShouldBlock}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <FormHelperText>{`${
                          this.state.contentInputNumberOfCharacters
                        }/${
                          this.state.contentInputMaxNumberOfCharacters
                        } ${i18n.t(
                          'addArticleScreen-contentInput-character-limitator-label',
                        )}`}</FormHelperText>
                        <FormHelperText>{`${
                          this.state.contentInputNumberOfLines
                        }/${this.state.contentInputMaxNumberOfLines} ${i18n.t(
                          'addArticleScreen-contentInput-line-limitator-label',
                        )}`}</FormHelperText>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/**
                 * Author Row
                 */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ marginTop: 20 }}
                  className={classes.rowContainer}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      lg={2}
                      className={classes.inputSideLabelContainer}
                    >
                      <Typography
                        variant="h5"
                        component="p"
                        className={classes.inputSideLabelStyle}
                      >
                        {i18n.t('addArticleScreen-author-input-row-label')}
                      </Typography>
                    </Grid>

                    <Grid xs={12} md={10} lg={10} item>
                      <Grid xs={4} item>
                        <TextField
                          value={this.state.authorInputValue}
                          onChange={this.handleAuthorInputChange}
                          color="secondary"
                          placeholder={i18n.t(
                            'addArticleForm-author-input-placeholder',
                          )}
                          /*  label={i18n.t('addArticleForm-author-input-label')} */
                          multiline={false}
                          rows={1}
                          rowsMax={1}
                          style={{ width: '100%' }}
                          inputProps={{ maxLength: 30 }}
                          helperText={`${this.state.authorInputValue?.length ||
                            0}/30`}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleRoundedIcon
                                  className={
                                    this.state.authorInputValue?.length === 0
                                      ? classes.iconDisabledStyle
                                      : classes.iconEnabledStyle
                                  }
                                />
                              </InputAdornment>
                            ),
                          }}
                          disabled={inputsShouldBlock}
                        />
                      </Grid>
                      <Grid xs={8} item />
                    </Grid>
                  </Grid>
                </Grid>

                {/**
  Error Row */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ marginTop: 20 }}
                  className={classes.rowContainer}
                >
                  <Grid container>
                    <Grid xs={2} item></Grid>
                    <Grid xs={8} item>
                      <FormHelperText color="helper" error>
                        {this.state.overallFormInputError}
                      </FormHelperText>
                    </Grid>
                    <Grid xs={2} item></Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ marginTop: 20 }}
                  className={classes.rowContainer}
                >
                  <Grid container>
                    <Grid xs={'auto'} md={2} item>
                      {' '}
                    </Grid>

                    <Grid xs={12} md={10} item>
                      <Grid container style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid item xs={10} sm={4} md={4}>
                          {this.props.isPostingImage ? (
                            <CircularProgress
                              value={this.state.imageUploadProgress}
                              color="secondary"
                            />
                          ) : (
                            <Tooltip
                              title={
                                currentlyActiveFamily
                                  ? i18n.t(
                                      'addArticleScreen-publishArticleButton-enabled-tooltip',
                                    )
                                  : i18n.t(
                                      'addArticleScreen-publishArticleButton-disabled-because-of-no-family-active-tooltip',
                                    )
                              }
                            >
                              <span>
                                <FZButton
                                  variant="contained"
                                  labelColor={colors.white}
                                  backgroundColor={colors.accent}
                                  style={{ marginTop: 20, marginBottom: 20 }}
                                  onClick={
                                    this.handlePublishArticleButtonPressed
                                  }
                                  disabled={
                                    !currentlyActiveFamily ||
                                    currentlyActiveFamilyShallBeDeleted ||
                                    currentlyActiveFamilyHasReachedEndOfReleaseCycle ||
                                    inputsShouldBlock
                                  }
                                  label={i18n.t(
                                    'addArticleForm-publish-article-button-label',
                                  )}
                                ></FZButton>
                              </span>
                            </Tooltip>
                          )}
                        </Grid>
                        <Grid item xs={10} sm={4} md={4}>
                          {!this.props.isPostingImage && (
                            <Button
                              variant="outlined"
                              color="secondary"
                              style={{
                                marginTop: 20,
                                marginBottom: 20,
                                color: colors.textMedium,
                              }}
                              onClick={this.handleSaveAsDraftButtonPressed}
                              disabled={inputsShouldBlock}
                            >
                              {i18n.t(
                                'addArticleForm-save-article-as-draft-button-label',
                              )}
                            </Button>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          sm={4}
                          md={4}
                          className={classes.deleteButtonContainer}
                        >
                          <Button
                            variant="outlined"
                            style={{
                              marginTop: 20,
                              marginBottom: 20,
                              color: colors.danger,
                              borderColor: colors.danger,
                            }}
                            onClick={this.handleDeleteArtileButtonPressed}
                            disabled={inputsShouldBlock}
                          >
                            {i18n.t(
                              'addArticleForm-delete-article-button-label',
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={'auto'} md={2}>
                      {' '}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    const { classes, isUpdatingArticle, isDeletingArticle, t } = this.props;
    const shouldRenderSpinner = isUpdatingArticle || isDeletingArticle;

    return (
      <React.Fragment>
        <FZAppBar
          hideTopNavigation
          navbarLeftContent={
            <FZNavigateBackButton
              backDestinationLabel={t(
                'addArticleScreen-back-destination-label',
              )}
            />
          }
        ></FZAppBar>

        {shouldRenderSpinner && (
          <FZSpinner
            modal={true}
            color={colors.accent}
            statusMessage={
              isUpdatingArticle
                ? i18n.t('addArticleForm-is-publishing-article-status')
                : i18n.t('addArticleForm-is-deleting-article-status')
            }
          />
        )}
        <Container
          style={{ padding: 0 }}
          ref={ref => (this.screenContainerRef = ref)}
        >
          {this.renderAddArticleScreen(classes)}
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    currentlyActiveFamily: selectCurrentlyActiveFamily(state),
    accessToken: selectAccessToken(state),
    isCreatingDraft: state.articles.isCreatingDraft,
    isPostingImage: state.articles.isPostingImage,
    isDeletingArticle: state.articles.isDeletingArticle,
    isUpdatingArticle: state.articles.isUpdatingArticle,
    postingImageSuccessful: state.articles.postingImageWasSuccessful,
    deletingArticleWasSuccessful: state.articles.deletingArticleWasSuccessful,
    updatingArticleWasSuccessful: state.articles.updatingArticleWasSuccessful,
    currentlyEditedArticle: state.articles.currentlyDraftedArticle,
    currentlyActiveFamilyHasReachedEndOfReleaseCycle: selectCurrentlyActiveFamilyHasReachedEndOfReleaseCycle(
      state,
    ),
    currentlyActiveFamilyShallBeDeleted: selectIfCurrentlyActiveFamilyIsDeleted(
      state,
    ),
    shouldBlockNavigation: state.ui.shouldBlockNavigation,
  };
};

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  withRouter,
  withHooks,
  withStyles(styles),
  withServices,
)(AddArticleScreen);
