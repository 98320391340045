import { Card, makeStyles } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Family } from '../../../../common/models/Family';
import { addFamilyMemberInvitationRequest } from '../../../../common/redux/families/family.actions';
import {
  selectCurrentlyActiveFamily,
  selectCurrentlyDraftedFamilyInvitation,
} from '../../../../common/redux/families/family.selectors';
import {
  CreateFamilyInvitationDto,
  FamilyInvitationType,
} from '../../../../common/redux/families/types';
import { showErrorSnackbar } from '../../../../common/redux/ui/ui.actions';
import colors from '../../../00_Constants/colors';
import FZButton from '../../../01_atoms/FZButton/FZButton';
import FZNavigateBackButton from '../../../01_atoms/FZNavigateBackButton/FZNavigateBackButton';
import FZPageContainer from '../../../01_atoms/FZPageContainer/FZPageContainer';
import FZStepDenominator from '../../../01_atoms/FZStepDenominator/FZStepDenominator';
import FZAppBar from '../../../03_organisms/FZAppBar/FZAppBar';
import FZFamilyInvitationPreview from '../../../03_organisms/FZFamilyInvitationPreview/FZFamilyInvitationPreview';
import FZInviteFamilyMemberWidget from '../../../03_organisms/FZInviteFamilyMemberWidget/FZInviteFamilyMemberWidget';
import ContentPageTemplate from '../../../04_templates/ContentPageTemplate/ContentPageTemplate';

const useStyles = makeStyles({
  /*  crossPatternBackground: {
    //backgroundColor: '#F2C53DCC',
    opactity: 1,
    height: '100%',
    /*    background:
      'radial-gradient(circle, transparent 20%, #FFF 20%, #FFF 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #FFF 20%, #FFF 80%, transparent 80%, transparent) 100px 100px, linear-gradient(#1112 8px, transparent 8px) 0 -4px, linear-gradient(90deg, #1112 8px, #FFF 8px) -4px 0',
    backgroundSize: '200px 200px, 200px 200px, 100px 100px, 100px 100px',
  }, */

  invitationPreview: {
    marginTop: 32,
  },
});

interface Props {}

/* const useStyles = makeStyles((theme: Theme) => {}); */

export default function InviteFamilyMemberScreen(props: Props): ReactElement {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);

  const currentlyActiveFamily: Family = useSelector(
    selectCurrentlyActiveFamily,
  );

  const currentlyDraftedInvitation: CreateFamilyInvitationDto = useSelector(
    selectCurrentlyDraftedFamilyInvitation,
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleFinishInviteButtonClick = React.useCallback(() => {
    dispatch(
      addFamilyMemberInvitationRequest(
        currentlyActiveFamily.uuid,
        {
          ...currentlyDraftedInvitation,
          invitationType: FamilyInvitationType.EMAIL_INVITATION,
        },
        {
          successCallback: () => {
            history.push('/family');
          },
          errorCallback: () => {
            dispatch(
              showErrorSnackbar(
                t(
                  'inviteFamilyMemberScreen-invite-family-member-error-message',
                ),
                true,
              ),
            );
          },
        },
      ),
    );
  }, [
    dispatch,
    history,
    currentlyActiveFamily.uuid,
    currentlyDraftedInvitation,
    t,
  ]);

  return (
    /*    <FullWidthBackground
      imageSource={fzColorfulBackgroundImage}
      position="absolute"
    > */
    <FZPageContainer>
      <FZAppBar
        navbarLeftContent={
          <FZNavigateBackButton
            backDestinationLabel={t(
              'inviteFamilyMemberScreen-backDestinationLabel',
            )}
          />
        }
      ></FZAppBar>

      <ContentPageTemplate>
        <FZStepDenominator
          stepLabel={t('FZStepDenominator-step-label')}
          stepOfLabel={t('FZStepDenominator-step-of-label')}
          currentStep={currentStep}
          totalSteps={2}
        ></FZStepDenominator>

        {currentStep === 0 && (
          <Card
            style={{
              width: '100%',
              padding: 32,
              margin: 16,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FZInviteFamilyMemberWidget
              onSubmit={() => {
                setCurrentStep(currentStep + 1);
              }}
            />
          </Card>
        )}

        {currentStep === 1 && (
          <Card
            style={{
              width: '100%',
              padding: 32,
              margin: 16,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FZFamilyInvitationPreview
              className={classes.invitationPreview}
              headline={t(
                'inviteFamilyMemberScreen-invitationPreview-headline',
              )}
              includeReceiverList
              subject={currentlyDraftedInvitation.invitationSubject}
              message={currentlyDraftedInvitation.personalMessage}
              receivers={currentlyDraftedInvitation.receiverEmailAdresses}
            />

            <FZButton
              label={t('inviteFamilyMemberScreen-send-invites-button-label')}
              onClick={handleFinishInviteButtonClick}
              backgroundColor={colors.secondary}
              labelColor={colors.textMedium}
              style={{ alignSelf: 'flex-end' }}
            ></FZButton>
          </Card>
        )}
      </ContentPageTemplate>
    </FZPageContainer>
    /*     </FullWidthBackground> */
  );
}
