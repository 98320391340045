import { Link } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import './FZLink.css';

interface Props {
  href: string;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export default function FZLink({
  href,
  children,
  className,
  style,
}: Props): ReactElement {
  return (
    <Link
      href={href}
      className={clsx('link link--elara', className)}
      style={style}
    >
      <span>{children}</span>
    </Link>
  );
}
