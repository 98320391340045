import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlogPost } from '../../../common/models/FeedContent';
import { MuiGridWidth } from '../../../types';
import colors from '../../00_Constants/colors';

const useStyles = makeStyles((theme: Theme) => {
  return {
    cardContainer: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '4px',
      borderBottomColor: colors.tertiary,

      '&:hover': {
        cursor: 'pointer',
      },
    },

    cardMedia: {
      height: '461px',

      [theme.breakpoints.down('sm')]: {
        maxHeight: 300,
      },
    },

    cardMediaBackground: {},
  };
});

type Props = {
  blogPostSlug: string;
  blogPostToRender: BlogPost;
  columns: MuiGridWidth;
  onReadMoreButtonClicked: (
    blogPostToReadMoreAbout: BlogPost,
    slug: string,
  ) => void;
};

const FZBlogPostPreview = (props: Props) => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const {
    blogPostToRender,
    columns,
    onReadMoreButtonClicked,
    blogPostSlug,
  } = props;

  return (
    <Grid item xs={12} sm={12} md={columns} lg={columns} zeroMinWidth>
      <Card
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        className={classes.cardContainer}
        onClick={() => {
          onReadMoreButtonClicked(blogPostToRender, blogPostSlug);
        }}
      >
        {blogPostToRender.teaser_image && (
          <CardMedia
            className={classes.cardMedia}
            image={blogPostToRender.teaser_image.url}
            classes={{ root: classes.cardMediaBackground }}
            title={blogPostToRender.title[0].text}
          />
        )}
        <CardHeader title={blogPostToRender.title[0].text}></CardHeader>
        <CardContent>{blogPostToRender.description}</CardContent>
        <CardActions>
          <Button
            variant="text"
            style={{ color: colors.tertiary }}
            onClick={() => {
              onReadMoreButtonClicked(blogPostToRender, blogPostSlug);
            }}
          >
            {t('blogPostPreviewComponent-readMore-button-label')}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default FZBlogPostPreview;
