import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import { MatomoInstance } from '@datapunt/matomo-tracker-react/lib/types';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import axios from 'axios';
import { UNAUTHORIZED } from 'http-status-codes';
import React from 'react';
/* import CookieBot from 'react-cookiebot'; */
import ReactDOM from 'react-dom';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import AppContainer from './App';
import applicationConfig from './common/config/application_config';
import i18next from './common/internationalization/i18n_config';
import { logoutRequest } from './common/redux/login/login.actions';
import { persistor, store } from './common/redux/main/store_config';
import { showInfoSnackbar } from './common/redux/ui/ui.actions';
import { InputValidationService } from './common/services';
import AccountService from './common/services/AccountService';
import ContactRequestService from './common/services/ContactRequestService';
import { DateTransformationService } from './common/services/DateTransformationService';
import ImageService from './common/services/ImageService';
import UserService from './common/services/UserService';
import {
  ServicesProps,
  ServicesProvider,
} from './components/Contexts/ServicesContext';
import history from './history';
import './index.css';

// eslint-disable-next-line
require('dotenv').config();

// Configure moment-JS to use the current locale, so that
// date, time and datetime strings are localized
// when the browserlocale changes.
// moment.locale(i18next.language.substr(0, 2));

const isProd = process.env.NODE_ENV === 'production';

const disableReactDevTools = (): void => {
  const noop = (): void => undefined;
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
    }
  }
};

const enableSentryErrorLogging = (): void => {
  const sentryKey = process.env.REACT_APP_SENTRY_DSN;

  // https://5a5938a3038a4c6b8a53a201571a79c9@o679537.ingest.sentry.io/5769927

  // Initialize Sentry - the error and performance monitoring solution used in this project
  Sentry.init({
    dsn: sentryKey,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
  });
};

if (isProd) {
  disableReactDevTools();
  enableSentryErrorLogging();
} else {
  console.log(i18next);
}

const services: ServicesProps = {
  imageService: new ImageService(),
  inputValidationService: new InputValidationService(),
  accountService: new AccountService(),
  userService: new UserService(),
  dateTransformationService: new DateTransformationService(),
  contactRequestService: new ContactRequestService(),
};

let matomoInstance: MatomoInstance = null;

matomoInstance = createInstance({
  urlBase: applicationConfig.TRACKING_CONFIG.MATOMO_BASE_URL,
  siteId: applicationConfig.TRACKING_CONFIG.TRACKED_SITE_MATOMO_ID,
  // optional, default value: `1`tart
  //trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
  //srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`

  configurations: {
    disableCookies: true,
  },
});
/* 
const cookieBotDomainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAINGROUPID; */

const configureApiClientInterceptors = () => {
  axios.interceptors.response.use(
    function(response) {
      // Do something with response data
      return response;
    },
    function(error) {
      if (error.response && error.response.status === UNAUTHORIZED) {
        if (error.response.data.errorCode) {
          // Do nothing here, since 401s are allowed and should not cause a logout.
        } else {
          store.dispatch(
            logoutRequest({
              successCallback: () => {
                history.push('/login');
                store.dispatch(
                  showInfoSnackbar(
                    'Du wurdest automatisch abgemeldet, bitte melde dich erneut an.',
                    true,
                  ),
                );
              },
              errorCallback: () => {
                console.log('An error occured when attempting to log out.');
              },
            }),
          );
        }
      }
      // Do something with response error
      return Promise.reject(error);
    },
  );
};

configureApiClientInterceptors();

const appRoot = document.getElementById('root');
appRoot.setAttribute('notranslate', 'true');

ReactDOM.render(
  <ReduxStoreProvider store={store}>
    {/* isProd && <CookieBot domainGroupId={cookieBotDomainGroupId} /> */}
    <PersistGate loading={<h1>Lädt</h1>} persistor={persistor}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MatomoProvider value={matomoInstance}>
          <ServicesProvider value={services}>
            <React.StrictMode>
              {/* StrictMode causes a deprecation error in the used version of material ui. See https://github.com/mui-org/material-ui-pickers/issues/1786 It will be re-enabled once this is fixed. */}
              <AppContainer />
            </React.StrictMode>
          </ServicesProvider>
        </MatomoProvider>
      </MuiPickersUtilsProvider>
    </PersistGate>
  </ReduxStoreProvider>,
  document.getElementById('root'),
);
