import { ApplicationConfig } from './types';

const developmentEnvConfig = {
  API_CONFIG: {
    SERVICE_GATEWAY_URL: process.env.REACT_APP_DEVELOPMENT_SERVICE_GATEWAY_URL,
  },

  AUTH_CONFIG: {
    OKTA_CLIENT_ID: process.env.REACT_APP_DEVELOPMENT_OKTA_CLIENT_ID,
    OKTA_ISSUER: process.env.REACT_APP_DEVELOPMENT_OKTA_ISSUER,
    OKTA_REDIRECT_URI: process.env.REACT_APP_DEVELOPMENT_OKTA_REDIRECT_URI,
  },

  MONITORING_CONFIG: {
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  },

  TRACKING_CONFIG: {
    MATOMO_BASE_URL: process.env.REACT_APP_DEVELOPMENT_MATOMO_BASE_URL,
    TRACKED_SITE_MATOMO_ID: Number(
      process.env.REACT_APP_DEVELOPMENT_TRACKED_SITE_MATOMO_ID,
    ),
  },

  FEATURE_FLAGS: {
    USER: {
      REACT_APP_FEATRUE_FLAG_CHANGE_SETTINGS_ENABLED:
        process.env.REACT_APP_FEATRUE_FLAG_CHANGE_SETTINGS_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_EDIT_DISPLAY_NAME_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_EDIT_DISPLAY_NAME_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_EDIT_ADRESS_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_EDIT_ADRESS_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_UPDATE_PASSWORD_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_UPDATE_PASSWORD_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_UPDATE_PRIMARY_EMAIL_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_UPDATE_PRIMARY_EMAIL_ENABLED ===
        'true',

      REACT_APP_FEATURE_FLAG_UPDATE_DEFAULT_ACTIVE_FAMILY_ENABLED:
        process.env
          .REACT_APP_FEATURE_FLAG_UPDATE_DEFAULT_ACTIVE_FAMILY_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_UPDATE_SUBSCRIPTION_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_UPDATE_SUBSCRIPTION_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_UPDATE_PAYMENT_METHOD_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_UPDATE_PAYMENT_METHOD_ENABLED ===
        'true',
    },

    AUTH: {
      REACT_APP_FEATURE_FLAG_REGISTRATION_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_REGISTRATION_ENABLED === 'true',

      REACT_APP_FEATURE_FLAG_RESET_PASSWORD_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_RESET_PASSWORD_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_LOGIN_HELPDESK_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_LOGIN_HELPDESK_ENABLED === 'true',

      REACT_APP_FEATURE_FLAG_LOGIN_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_LOGIN_ENABLED === 'true',
    },
    FAMILY: {
      REACT_APP_FEATURE_FLAG_CREATE_FAMILY_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_CREATE_FAMILY_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_VIA_LINK_ENABLED:
        process.env
          .REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_VIA_LINK_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_JOIN_FAMILY_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_JOIN_FAMILY_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_EDIT_FAMILY_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_EDIT_FAMILY_ENABLED === 'true',
    },

    ARTICLES: {
      REACT_APP_FEATURE_FLAG_CREATE_NEW_ARTICLES_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_CREATE_NEW_ARTICLES_ENABLED ===
        'true',
    },
  },

  LEGAL_CONFIG: {
    TERMS_OF_SERVICE_DOCUMENT_VERSION:
      process.env.REACT_APP_TERMS_OF_SERVICE_DOCUMENT_VERSION,
    DATA_PROCESSING_AGREEMENT_DOCUMENT_VERSION:
      process.env.REACT_APP_DATA_PROCESSING_AGREEMENT_DOCUMENT_VERSION,
    COOKIEBOT_DOMAINGROUPID: process.env.REACT_APP_COOKIEBOT_DOMAINGROUPID,
  },

  LINK_CONFIG: {
    FAMILY_INVITATIONS: {
      REACT_APP_FAMILY_INVITATIONS_BASE_URL:
        process.env.REACT_APP_DEVELOPMENT_FAMILY_INVITATIONS_BASE_URL,
    },
  },
};

const testEnvConfig = {
  API_CONFIG: {
    SERVICE_GATEWAY_URL: process.env.REACT_APP_TEST_SERVICE_GATEWAY_URL,
  },

  MONITORING_CONFIG: {
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  },

  AUTH_CONFIG: {
    OKTA_CLIENT_ID: process.env.REACT_APP_TEST_OKTA_CLIENT_ID,
    OKTA_ISSUER: process.env.REACT_APP_TEST_OKTA_ISSUER,
    OKTA_REDIRECT_URI: process.env.REACT_APP_TEST_OKTA_REDIRECT_URI,
  },

  TRACKING_CONFIG: {
    MATOMO_BASE_URL: process.env.REACT_APP_TEST_MATOMO_BASE_URL,
    TRACKED_SITE_MATOMO_ID: Number(
      process.env.REACT_APP_TEST_TRACKED_SITE_MATOMO_ID,
    ),
  },

  FEATURE_FLAGS: {
    USER: {
      REACT_APP_FEATRUE_FLAG_CHANGE_SETTINGS_ENABLED:
        process.env.REACT_APP_FEATRUE_FLAG_CHANGE_SETTINGS_ENABLED === 'true',

      REACT_APP_FEATURE_FLAG_EDIT_DISPLAY_NAME_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_EDIT_DISPLAY_NAME_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_EDIT_ADRESS_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_EDIT_ADRESS_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_UPDATE_PASSWORD_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_UPDATE_PASSWORD_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_UPDATE_PRIMARY_EMAIL_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_UPDATE_PRIMARY_EMAIL_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_UPDATE_DEFAULT_ACTIVE_FAMILY_ENABLED:
        process.env
          .REACT_APP_FEATURE_FLAG_UPDATE_DEFAULT_ACTIVE_FAMILY_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_UPDATE_SUBSCRIPTION_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_UPDATE_SUBSCRIPTION_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_UPDATE_PAYMENT_METHOD_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_UPDATE_PAYMENT_METHOD_ENABLED ===
        'true',
    },
    AUTH: {
      REACT_APP_FEATURE_FLAG_REGISTRATION_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_REGISTRATION_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_RESET_PASSWORD_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_RESET_PASSWORD_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_LOGIN_HELPDESK_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_LOGIN_HELPDESK_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_LOGIN_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_LOGIN_ENABLED === 'true',
    },
    FAMILY: {
      REACT_APP_FEATURE_FLAG_CREATE_FAMILY_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_CREATE_FAMILY_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_VIA_LINK_ENABLED:
        process.env
          .REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_VIA_LINK_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_JOIN_FAMILY_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_JOIN_FAMILY_ENABLED === 'true',

      REACT_APP_FEATURE_FLAG_EDIT_FAMILY_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_EDIT_FAMILY_ENABLED === 'true',
    },
    ARTICLES: {
      REACT_APP_FEATURE_FLAG_CREATE_NEW_ARTICLES_ENABLED:
        process.env.REACT_APP_FEATURE_FLAG_CREATE_NEW_ARTICLES_ENABLED ===
        'true',
    },
  },

  LEGAL_CONFIG: {
    TERMS_OF_SERVICE_DOCUMENT_VERSION:
      process.env.REACT_APP_TERMS_OF_SERVICE_DOCUMENT_VERSION,
    DATA_PROCESSING_AGREEMENT_DOCUMENT_VERSION:
      process.env.REACT_APP_DATA_PROCESSING_AGREEMENT_DOCUMENT_VERSION,
    COOKIEBOT_DOMAINGROUPID: process.env.REACT_APP_COOKIEBOT_DOMAINGROUPID,
  },

  LINK_CONFIG: {
    FAMILY_INVITATIONS: {
      REACT_APP_FAMILY_INVITATIONS_BASE_URL:
        process.env.REACT_APP_TEST_FAMILY_INVITATIONS_BASE_URL,
    },
  },
};

const stagingEnvConfig = {
  API_CONFIG: {
    SERVICE_GATEWAY_URL: process.env.REACT_APP_STAGING_SERVICE_GATEWAY_URL,
  },
  AUTH_CONFIG: {
    OKTA_CLIENT_ID: process.env.REACT_APP_STAGING_OKTA_CLIENT_ID,
    OKTA_ISSUER: process.env.REACT_APP_STAGING_OKTA_ISSUER,
    OKTA_REDIRECT_URI: process.env.REACT_APP_STAGING_OKTA_REDIRECT_URI,
  },

  MONITORING_CONFIG: {
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  },

  TRACKING_CONFIG: {
    MATOMO_BASE_URL: process.env.REACT_APP_STAGING_MATOMO_BASE_URL,
    TRACKED_SITE_MATOMO_ID: Number(
      process.env.REACT_APP_STAGING_TRACKED_SITE_MATOMO_ID,
    ),
  },

  FEATURE_FLAGS: {
    USER: {
      REACT_APP_FEATRUE_FLAG_CHANGE_SETTINGS_ENABLED:
        process.env.REACT_APP_STAGING_FEATRUE_FLAG_CHANGE_SETTINGS_ENABLED ===
        'true',

      REACT_APP_FEATURE_FLAG_EDIT_DISPLAY_NAME_ENABLED:
        process.env.REACT_APP_STAGING_FEATURE_FLAG_EDIT_DISPLAY_NAME_ENABLED ===
        'true',

      REACT_APP_FEATURE_FLAG_EDIT_ADRESS_ENABLED:
        process.env.REACT_APP_STAGING_FEATURE_FLAG_EDIT_ADRESS_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_UPDATE_PASSWORD_ENABLED:
        process.env.REACT_APP_STAGING_FEATURE_FLAG_UPDATE_PASSWORD_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_UPDATE_PRIMARY_EMAIL_ENABLED:
        process.env
          .REACT_APP_STAGING_FEATURE_FLAG_UPDATE_PRIMARY_EMAIL_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_UPDATE_DEFAULT_ACTIVE_FAMILY_ENABLED:
        process.env
          .REACT_APP_STAGING_FEATURE_FLAG_UPDATE_DEFAULT_ACTIVE_FAMILY_ENABLED ===
        'true',

      REACT_APP_FEATURE_FLAG_UPDATE_SUBSCRIPTION_ENABLED:
        process.env
          .REACT_APP_STAGING_FEATURE_FLAG_UPDATE_SUBSCRIPTION_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_UPDATE_PAYMENT_METHOD_ENABLED:
        process.env
          .REACT_APP_STAGING_FEATURE_FLAG_UPDATE_PAYMENT_METHOD_ENABLED ===
        'true',
    },
    AUTH: {
      REACT_APP_FEATURE_FLAG_REGISTRATION_ENABLED:
        process.env.REACT_APP_STAGING_FEATURE_FLAG_REGISTRATION_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_RESET_PASSWORD_ENABLED:
        process.env.REACT_APP_STAGING_FEATURE_FLAG_RESET_PASSWORD_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_LOGIN_HELPDESK_ENABLED:
        process.env.REACT_APP_STAGING_FEATURE_FLAG_LOGIN_HELPDESK_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_LOGIN_ENABLED:
        process.env.REACT_APP_STAGING_FEATURE_FLAG_LOGIN_ENABLED === 'true',
    },
    FAMILY: {
      REACT_APP_FEATURE_FLAG_CREATE_FAMILY_ENABLED:
        process.env.REACT_APP_STAGING_FEATURE_FLAG_CREATE_FAMILY_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_ENABLED:
        process.env
          .REACT_APP_STAGING_FEATURE_FLAG_INVITE_FAMILY_MEMBER_ENABLED ===
        'true',

      REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_VIA_LINK_ENABLED:
        process.env
          .REACT_APP_STAGING_FEATURE_FLAG_INVITE_FAMILY_MEMBER_VIA_LINK_ENABLED ===
        'true',

      REACT_APP_FEATURE_FLAG_JOIN_FAMILY_ENABLED:
        process.env.REACT_APP_STAGING_FEATURE_FLAG_JOIN_FAMILY_ENABLED ===
        'true',

      REACT_APP_FEATURE_FLAG_EDIT_FAMILY_ENABLED:
        process.env.REACT_APP_STAGING_FEATURE_FLAG_EDIT_FAMILY_ENABLED ===
        'true',
    },
    ARTICLES: {
      REACT_APP_FEATURE_FLAG_CREATE_NEW_ARTICLES_ENABLED:
        process.env
          .REACT_APP_STAGING_FEATURE_FLAG_CREATE_NEW_ARTICLES_ENABLED ===
        'true',
    },
  },

  LEGAL_CONFIG: {
    TERMS_OF_SERVICE_DOCUMENT_VERSION:
      process.env.REACT_APP_TERMS_OF_SERVICE_DOCUMENT_VERSION,
    DATA_PROCESSING_AGREEMENT_DOCUMENT_VERSION:
      process.env.REACT_APP_DATA_PROCESSING_AGREEMENT_DOCUMENT_VERSION,
    COOKIEBOT_DOMAINGROUPID: process.env.REACT_APP_COOKIEBOT_DOMAINGROUPID,
  },

  LINK_CONFIG: {
    FAMILY_INVITATIONS: {
      REACT_APP_FAMILY_INVITATIONS_BASE_URL:
        process.env.REACT_APP_STAGING_FAMILY_INVITATIONS_BASE_URL,
    },
  },
};

const productionEnvConfig = {
  API_CONFIG: {
    SERVICE_GATEWAY_URL: process.env.REACT_APP_PRODUCTION_SERVICE_GATEWAY_URL,
  },

  MONITORING_CONFIG: {
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  },

  AUTH_CONFIG: {
    OKTA_CLIENT_ID: process.env.REACT_APP_PRODUCTION_OKTA_CLIENT_ID,
    OKTA_ISSUER: process.env.REACT_APP_PRODUCTION_OKTA_ISSUER,
    OKTA_REDIRECT_URI: process.env.REACT_APP_PRODUCTION_OKTA_REDIRECT_URI,
  },

  TRACKING_CONFIG: {
    MATOMO_BASE_URL: process.env.REACT_APP_PRODUCTION_MATOMO_BASE_URL,
    TRACKED_SITE_MATOMO_ID: Number(
      process.env.REACT_APP_PRODUCTION_TRACKED_SITE_MATOMO_ID,
    ),
  },

  FEATURE_FLAGS: {
    USER: {
      REACT_APP_FEATRUE_FLAG_CHANGE_SETTINGS_ENABLED:
        process.env
          .REACT_APP_PRODUCTION_FEATRUE_FLAG_CHANGE_SETTINGS_ENABLED === 'true',

      REACT_APP_FEATURE_FLAG_EDIT_DISPLAY_NAME_ENABLED:
        process.env
          .REACT_APP_PRODUCTION_FEATURE_FLAG_EDIT_DISPLAY_NAME_ENABLED ===
        'true',

      REACT_APP_FEATURE_FLAG_EDIT_ADRESS_ENABLED:
        process.env.REACT_APP_PRODUCTION_FEATURE_FLAG_EDIT_ADRESS_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_UPDATE_PASSWORD_ENABLED:
        process.env
          .REACT_APP_PRODUCTION_FEATURE_FLAG_UPDATE_PASSWORD_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_UPDATE_PRIMARY_EMAIL_ENABLED:
        process.env
          .REACT_APP_PRODUCTION_FEATURE_FLAG_UPDATE_PRIMARY_EMAIL_ENABLED ===
        'true',

      REACT_APP_FEATURE_FLAG_UPDATE_DEFAULT_ACTIVE_FAMILY_ENABLED:
        process.env
          .REACT_APP_PRODUCTION_FEATURE_FLAG_UPDATE_DEFAULT_ACTIVE_FAMILY_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_UPDATE_SUBSCRIPTION_ENABLED:
        process.env
          .REACT_APP_PRODUCTION_FEATURE_FLAG_UPDATE_SUBSCRIPTION_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_UPDATE_PAYMENT_METHOD_ENABLED:
        process.env
          .REACT_APP_PRODUCTION_FEATURE_FLAG_UPDATE_PAYMENT_METHOD_ENABLED ===
        'true',
    },
    AUTH: {
      REACT_APP_FEATURE_FLAG_LOGIN_ENABLED:
        process.env.REACT_APP_PRODUCTION_FEATURE_FLAG_LOGIN_ENABLED === 'true',
      REACT_APP_FEATURE_FLAG_REGISTRATION_ENABLED:
        process.env.REACT_APP_PRODUCTION_FEATURE_FLAG_REGISTRATION_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_RESET_PASSWORD_ENABLED:
        process.env.REACT_APP_PRODUCTION_FEATURE_FLAG_RESET_PASSWORD_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_LOGIN_HELPDESK_ENABLED:
        process.env.REACT_APP_PRODUCTION_FEATURE_FLAG_LOGIN_HELPDESK_ENABLED ===
        'true',
    },
    FAMILY: {
      REACT_APP_FEATURE_FLAG_CREATE_FAMILY_ENABLED:
        process.env.REACT_APP_PRODUCTION_FEATURE_FLAG_CREATE_FAMILY_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_ENABLED:
        process.env
          .REACT_APP_PRODUCTION_FEATURE_FLAG_INVITE_FAMILY_MEMBER_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_INVITE_FAMILY_MEMBER_VIA_LINK_ENABLED:
        process.env
          .REACT_APP_PRODUCTION_FEATURE_FLAG_INVITE_FAMILY_MEMBER_VIA_LINK_ENABLED ===
        'true',
      REACT_APP_FEATURE_FLAG_JOIN_FAMILY_ENABLED:
        process.env.REACT_APP_PRODUCTION_FEATURE_FLAG_JOIN_FAMILY_ENABLED ===
        'true',

      REACT_APP_FEATURE_FLAG_EDIT_FAMILY_ENABLED:
        process.env.REACT_APP_PRODUCTION_FEATURE_FLAG_EDIT_FAMILY_ENABLED ===
        'true',
    },
    ARTICLES: {
      REACT_APP_FEATURE_FLAG_CREATE_NEW_ARTICLES_ENABLED:
        process.env
          .REACT_APP_PRODUCTION_FEATURE_FLAG_CREATE_NEW_ARTICLES_ENABLED ===
        'true',
    },
  },

  LEGAL_CONFIG: {
    TERMS_OF_SERVICE_DOCUMENT_VERSION:
      process.env.REACT_APP_TERMS_OF_SERVICE_DOCUMENT_VERSION,
    DATA_PROCESSING_AGREEMENT_DOCUMENT_VERSION:
      process.env.REACT_APP_DATA_PROCESSING_AGREEMENT_DOCUMENT_VERSION,
    COOKIEBOT_DOMAINGROUPID: process.env.REACT_APP_COOKIEBOT_DOMAINGROUPID,
  },

  LINK_CONFIG: {
    FAMILY_INVITATIONS: {
      REACT_APP_FAMILY_INVITATIONS_BASE_URL:
        process.env.REACT_APP_PRODUCTION_FAMILY_INVITATIONS_BASE_URL,
    },
  },
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case 'development':
    console.log('Activating Development Environment');
    config = developmentEnvConfig;
    break;
  case 'test':
    console.log('Activating Test Environment');
    config = testEnvConfig;
    break;
  case 'staging':
    console.log('Activating Staging Environment');
    config = stagingEnvConfig;
    break;
  case 'production':
    config = productionEnvConfig;
    break;

  default:
    throw new Error(
      'No Envrionment is set. Please set a value for the REACT_APP_STAGE variable.',
    );
}

const applicationConfig: ApplicationConfig = {
  FEATURE_CONFIG: {
    authentication: {
      persistLoginStateOnClose: true,
      persistLoginStatePeriodically: false,
      persistLoginStatePeriodicallyDurationInMilliseconds: 30000,
    },

    imageHandling: {
      imagePresignedUrlsValidityDurationInSeconds: 60 * 60 * 24,
    },
  },

  // Add common config values here
  ...config,
};

export default applicationConfig;
