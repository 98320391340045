import { StepConnector, StepIconProps } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import colors from '../../00_Constants/colors';
import FZTypography from '../../01_atoms/FZTypography/FZTypography';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    whiteSpace: 'pre-line',
    backgroundColor: 'rgba(0,0,0,0)',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export interface Props {
  steps: string[];
  stepContent: string[];
  id?: string;
}

const FZConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '&$line': {
      borderColor: colors.tertiary,
    },
  },
  completed: {
    '&$line': {
      borderColor: colors.tertiary,
    },
  },
  line: {
    marginLeft: '50%',
    borderColor: colors.tertiary,
    borderTopWidth: 3,
    borderRadius: 1,
    borderWidth: 3,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    //backgroundColor: '#ccc',
    zIndex: 1,
    color: colors.tertiary,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    /*  backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)', */
  },
  completed: {
    /*   backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)', */
  },

  iconLabel: {
    color: colors.tertiary,
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: (
      <FZTypography variant="h5" className={classes.iconLabel}>
        1
      </FZTypography>
    ),
    2: (
      <FZTypography variant="h5" className={classes.iconLabel}>
        2
      </FZTypography>
    ),
    3: (
      <FZTypography variant="h5" className={classes.iconLabel}>
        3
      </FZTypography>
    ),
    4: (
      <FZTypography variant="h5" className={classes.iconLabel}>
        4
      </FZTypography>
    ),
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

export default function FZStepper(props: Props) {
  const classes = useStyles();

  const steps = props.steps;
  const stepContent = props.stepContent;
  const [activeStep] = React.useState(steps.length - 1);

  return (
    <div className={classes.root} id={props.id}>
      <Stepper
        style={{ backgroundColor: 'transparent' }}
        activeStep={activeStep}
        orientation="vertical"
        connector={<FZConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            <StepContent>
              <FZTypography variant="body1">{stepContent[index]}</FZTypography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
