import { AnyAction } from 'redux';
import { BlogPost, FeedContent } from '../../models/FeedContent';
import { authenticationActionTypes } from '../login/login.actions';
import { feedActionTypes } from './feed.actions';

export type FeedReducerState = {
  isFetchingFeed: boolean;
  feedContent: FeedContent | null;
  currentlyViewedPost: BlogPost | null;
  numberOfUnseenFeedItems: number;
};

const initialState: FeedReducerState = {
  isFetchingFeed: false,
  currentlyViewedPost: null,
  feedContent: null,
  numberOfUnseenFeedItems: 0,
};

const Feed = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case feedActionTypes.FETCH_PERSONALIZED_FEED_REQUEST: {
      return {
        ...state,
        isFetchingFeed: true,
      };
    }

    case feedActionTypes.FETCH_PERSONALIZED_FEED_SUCCESS: {
      return {
        ...state,
        feedContent: action.payload,
        isFetchingFeed: false,
      };
    }

    case feedActionTypes.FETCH_PERSONALIZED_FEED_FAILURE: {
      return {
        ...state,
        isFetchingFeed: false,
      };
    }

    case feedActionTypes.FETCH_PERSONALIZED_FEED_FULFILL: {
      return {
        ...state,
        isFetchingFeed: false,
      };
    }

    case feedActionTypes.SET_CURRENTLY_VIEWED_POST: {
      return {
        ...state,
        currentlyViewedPost: action.payload.currentlyViewedPost,
      };
    }

    case feedActionTypes.SET_UNSEEN_FEED_ITEMS: {
      return {
        ...state,
        numberOfUnseenFeedItems: action.payload.numberOfUnseenFeedItems,
      };
    }

    case authenticationActionTypes.LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default Feed;
