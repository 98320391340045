/**
 * File to load the configuration of i18next module, which is used for translations
 */
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import commonDe from './locales/de/common.json';
import commonEn from './locales/en/common.json';
import { initReactI18next } from 'react-i18next';

const options = {
  interpolation: {
    escapeValue: false, // not needed for react, since interpolation is done by react itself
  },
  debug: process.env.NODE_ENV !== 'production',
  // Load translation files
  resources: {
    de: {
      translation: commonDe,
    },
    en: {
      translation: commonEn,
    },
  },
  keySeperator: false,
  //lng: 'de',
  // Fallback in case the default user language could not be found
  fallbackLng: 'de',

  // Namespace to be loaded
  //ns: ['common'],
  //namespaces: ['common'],
  //defaultNS: 'common',
  //fallbackNS: 'common',

  react: {
    wait: true,
    withRef: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(options, () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('I18n Initialized.');
    }
  });

export default i18n;
