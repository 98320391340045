import { AxiosResponse } from 'axios';
import { API_CONFIG, apiClient } from '../api';
import { Newspaper } from '../models/Newspaper';

class NewspaperService {
  /**
   * Fetches the current newspaper of a given family, filtering only the articles that the currently logged in user has authored.
   */
  async getUserContributionsInCurrentNewspaperOfFamily(
    familyUuid: string,
    accessToken: string,
  ): Promise<AxiosResponse<Newspaper | undefined>> {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const uriString = API_CONFIG.NEWSPAPER_SERVICE.GET_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_URL(
      familyUuid,
    );

    return apiClient.get(uriString, { headers });
  }

  /**
   * Fetches the latest family newspaper of a family with a given Uuid,
   * given that the requester is a member of this family.
   * @param familyUuid - unique identifer for the family that the latest newspaper shall be fetched of.
   */
  async fetchLastFamilyNewspaperOfFamily(
    familyUuid: string,
    accessToken: string,
  ) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return apiClient.get(
      API_CONFIG.ARTICLE_SERVICE.GET_LAST_NEWSPAPER_FOR_FAMILY_URL(familyUuid),
      { headers },
    );
  }
}
export default NewspaperService;
