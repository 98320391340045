import { combineReducers, Reducer } from 'redux';
import { all } from 'redux-saga/effects';
import app from '../app/app.reducer';
import api from '../api/api.reducer';
import articles from '../articles/article.reducer';
import articleSagas from '../articles/article.sagas';
import families from '../families/family.reducer';
import familySagas from '../families/family.sagas';
import feed from '../feed/feed.reducer';
import feedSagas from '../feed/feed.sagas';
import login from '../login/login.reducer';
import loginSagas from '../login/login.sagas';
import { ApplicationState } from './types';
import ui from '../ui/ui.reducer';
import user from '../users/user.reducer';
import userSagas from '../users/user.sagas';
import image from '../images/image.reducer';
import imageSagas from '../images/image.sagas';
import apiSagas from '../api/api.sagas';

// Compose the Application State by combining all individual reducers
const rootReducer: Reducer<ApplicationState> = combineReducers<
  ApplicationState
>({
  app,
  api,
  login,
  user,
  articles,
  families,
  feed,
  ui,
  image,
});

// Start all the individual sagas
const rootSaga = function* rootSaga() {
  yield all([
    apiSagas.watchAllToCatchApiErrors(),

    loginSagas.watchLoginRequest(),
    loginSagas.watchSignoutRequest(),
    loginSagas.watchSignupRequestSaga(),
    loginSagas.watchActivateAccount(),
    loginSagas.watchSignInSuccess(),
    loginSagas.watchUpdatePrimaryEmail(),
    loginSagas.watchUpdatePassword(),
    loginSagas.watchSignOutSuccess(),
    loginSagas.watchInitiateForgotPasswordFlow(),
    loginSagas.watchResetPasswordWithRecoveryToken(),

    userSagas.watchSigninSuccess(),
    userSagas.watchGetOwnProfile(),
    userSagas.watchUpdateOwnProfile(),
    userSagas.watchGetUserSettings(),
    userSagas.watchUpdateUserSettings(),
    userSagas.watchUpdateProfileImage(),

    familySagas.watchFetchOwnFamilies(),
    familySagas.watchFetchFamily(),
    familySagas.watchCreateFamily(),
    familySagas.watchEditFamily(),
    familySagas.watchJoinFamilyWithInviteCode(),
    familySagas.watchAddFamilyInvitation(),
    familySagas.watchRemoveFamilyInvitation(),
    familySagas.watchfetchUserContributionsInCurrentNewspaperOfFamily(),
    familySagas.watchFetchLastNewspaperForFamily(),
    familySagas.watchSwitchActiveFamily(),
    familySagas.watchLeaveFamily(),
    familySagas.watchEndOthersMembershipInFamily(),

    articleSagas.watchGetArticles(),
    articleSagas.watchPostArticle(),
    articleSagas.watchPostArticleDraft(),
    articleSagas.watchDeleteArticle(),
    articleSagas.watchDeleteImageFromArticle(),
    articleSagas.watchPostArticleImage(),
    articleSagas.watchUpdateArticle(),
    articleSagas.watchGetOwnArticles(),

    feedSagas.watchGetPersonalizedFeed(),

    imageSagas.watchFetchPresignedImageUrl(),
  ]);
};

export { rootReducer, rootSaga };
