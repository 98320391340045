import { makeStyles, Theme } from '@material-ui/core';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import React, { ReactElement } from 'react';

const usePricingTableAdvantageListItemStyles = makeStyles((theme: Theme) => {
  return {
    advantageListItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },

    checkMarkIcon: {
      //color: colors.success,
      paddingRight: 4,
      marginTop: 0,
      marginBottom: 4,
      color: '#FFF',
    },
  };
});

interface FZPricingTableAdvantageListItemProps {
  children: JSX.Element | JSX.Element[];
  key: string;
}

export default function FZPricingTableAdvantageListItem({
  children,
}: FZPricingTableAdvantageListItemProps): ReactElement {
  const classes = usePricingTableAdvantageListItemStyles();

  return (
    <li className={classes.advantageListItem}>
      <DoneRoundedIcon className={classes.checkMarkIcon} />
      {children}
    </li>
  );
}
