import { makeStyles } from '@material-ui/core';
import MuiSelect from '@material-ui/core/Select';
import { fieldToSelect, SelectProps } from 'formik-material-ui';
import React from 'react';

const useStyles = makeStyles({
  select: {
    '& .MuiInput-underline::before': {
      border: 'none',
      borderBottomColor: '#FFFFFF',
    },
  },
  root: {
    textAlign: 'right',

    '& label.Mui-focused': {
      color: (props: Props) => props.color,
    },

    '& .MuiInput-underline::before': {
      border: 'none',
      borderBottomColor: '#FFFFFF',
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: (props: Props) => props.color,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: (props: Props) => props.color,
      },
      '&:hover fieldset': {
        borderColor: (props: Props) => props.color,
      },
      '&.Mui-focused fieldset': {
        borderColor: (props: Props) => props.color,
      },
    },
  },
});

type Props = SelectProps & {
  color: string;
  backgroundColor: string;
};

export default function FZSelectInputField(props: Props) {
  const { color, children, ...others } = props;
  const classes = useStyles({ color });

  return (
    <MuiSelect
      classes={{ root: classes.root, select: classes.select }}
      MenuProps={{ disableScrollLock: true }}
      {...fieldToSelect(others)}
    >
      {children}
    </MuiSelect>
  );
}
