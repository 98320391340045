import React, { ReactElement } from 'react';

import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { useHistory } from 'react-router-dom';
import { Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
  backDestinationLabel?: string;
}

export default function FZNavigateBackButton({
  backDestinationLabel,
}: Props): ReactElement {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Tooltip title={t('navigateBackButton-back-tooltip')}>
          <ArrowBackRoundedIcon
            style={{ marginRight: 16 }}
            onClick={() => {
              history.goBack();
            }}
          ></ArrowBackRoundedIcon>
        </Tooltip>

        {backDestinationLabel && (
          <Typography variant="h6">{backDestinationLabel} </Typography>
        )}
      </span>
    </React.Fragment>
  );
}
