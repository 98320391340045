import { Button, Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import React, { Component } from 'react';

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 16,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      border: 'none',
    },
  });

type Props = WithStyles<typeof styles> & {
  open: boolean;
  onModalClosed: (callback?: () => void) => void;
  body?: any;
  onModalConfirmed: (callback?: () => void) => void;
  type: 'confirm' | 'generic';
  confirmTitle?: string;
  confirmMessage?: string;
};

type State = {};

class FZModal extends Component<Props, State> {
  interval: any;

  render() {
    const {
      classes,
      open,
      onModalClosed,
      onModalConfirmed,
      body,
      confirmTitle,
      confirmMessage,
      type,
    } = this.props;

    let modalBody;
    if (type === 'confirm') {
      modalBody = (
        <div className={classes.paper}>
          <h2 id="transition-modal-title">{confirmTitle}</h2>
          <p id="transition-modal-description">{confirmMessage}</p>
          <span style={{ float: 'right' }}>
            <Button
              style={{ marginRight: 8 }}
              //variant="outlined"
              color="secondary"
              onClick={() => {
                onModalClosed();
              }}
            >
              Abbrechen
            </Button>

            <Button
              //variant="outlined"
              color="secondary"
              onClick={() => {
                onModalConfirmed();
              }}
            >
              OK
            </Button>
          </span>
        </div>
      );
    } else {
      modalBody = body;
    }

    return (
      <Modal
        className={classes.modal}
        open={open}
        onClose={onModalClosed}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        //disableAutoFocus={true}
        //disableEnforceFocus={true}
      >
        {modalBody}
      </Modal>
    );
  }
}

export default withStyles(styles)(FZModal);
