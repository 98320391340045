import { Family } from '../../models/Family';
import { Newspaper } from '../../models/Newspaper';
import { UserProfile } from '../users/types';

/**
 * Represents the two ways of inviting family members - via email or via link
 */
export enum FamilyInvitationType {
  EMAIL_INVITATION = 'EMAIL_INVITATION',
  SHARING_LINK = 'SHARING_LINK',
  INVITATION_SKIPPED = 'INVITATION_SKIPPED',
}

export interface FamilyInvitation {
  uuid?: string;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deliveryStatus?: string;
  lastSuccesfulDeliveryAt?: string;
  invitationSubject?: string;
  personalMessage?: string;
  personalSalutation?: string;
  personalSignature?: string;
  receiverEmailAdress?: string;
  invitationType?: FamilyInvitationType;
  invitationLink?: string;
  invitationCode?: string;
  isForFamily?: Family;
  sentByCustomer?: UserProfile;
}

export const isFamilyInvitation = (p: any): p is FamilyInvitation =>
  p && p.invitationType;

export interface CreateFamilyState {
  familyName: string;
  meansOfInvitation: FamilyInvitationType;
  multiReceiverInvitation: CreateFamilyInvitationDto;
}

/**
 * A data transfer object to create a family
 */
export interface CreateFamilyDto {
  name: string;
  invitations: CreateFamilyInvitationDto[];
}

/**
 * A data transfer object to update a family
 */
export interface EditFamilyDto {
  uuid: string;
  name?: string;
  paymentRepresentativeUid?: string;
  isDeleted?: boolean;
  willBeDeletedAt?: Date | string | null;
}

export interface CreateFamilyInvitationDto {
  uuid?: string;
  receiverEmailAdresses?: string[];
  invitationSubject?: string;
  personalSalutation?: string;
  personalMessage?: string;
  personalSignature?: string;
  invitationType?: FamilyInvitationType;
}

export type FamilyReducerState = {
  /**
   * State for the families that a user is part of or invited to.
   */
  isFetchingOwnFamilies: boolean;
  ownFamilies: FamilyDictionary | null;

  /**
   * State for managing the family in whose context the user is currently active.
   */
  currentlyActiveFamily: Family | null;
  isFetchingFamily: false;
  currentlyActiveFamilyHasReachedEndOfReleaseCycle: boolean;
  endOfReleaseCycleReminderHasFlashed: boolean;

  /**
   * State for the newspapers of family
   */
  isFetchingLastPublishedNewspaper: boolean;
  lastPublishedNewspaper: Newspaper | null;

  isFetchingCurrentNewspaper: boolean;
  currentNewspaper: Newspaper | null;

  /**
   * State for creating a new family and inviting member to it.
   */
  currentlyCreatedFamily: CreateFamilyState;
};

export type FamilyDictionary = {
  [key: string]: Family;
};
