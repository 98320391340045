import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { logoutRequest } from '../../../common/redux/login/login.actions';

/**
 * Checks that the user's access token is not expired.
 * @param {number} tokenExpiry - The expiry of the token as a unix-epoch-timestamp (number telling elapsed seconds since 01.01.1970)
 * @returns {boolean} wether the tokenExpiry has expired or not.
 */
const assertTokenNotExpired = (tokenExpiry: number | null): boolean => {
  const now = Date.now().valueOf() / 1000;
  return typeof tokenExpiry !== 'undefined' && tokenExpiry < now;
};

const ProtectedRoute = ({ component, authState, ...rest }: any) => {
  const [tokenExpired, setTokenExpired] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      // Check validity of the access token from authstate every 5 seconds
      setTokenExpired(assertTokenNotExpired(authState.tokenExpiry));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [authState]);

  if (tokenExpired) {
    dispatch(logoutRequest());
  }

  const routeComponent = (props: any) =>
    authState.isLoggedIn && authState.accessToken && tokenExpired === false ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    );
  return <Route {...rest} render={routeComponent} />;
};

export default ProtectedRoute;
