import { createSelector } from 'reselect';
import { ApplicationState } from '../main/types';
import { LoginReducerState } from './types';

export const selectAccessToken = (state: any) => state.login.accessToken;

export const selectFamilyInvitationToken = createSelector(
  (state: ApplicationState) => state.login,
  (loginState: LoginReducerState) => loginState.joinedWithInvitationToken,
);
export const selectFamilyInvitationTokenIsPersonalized = createSelector(
  (state: ApplicationState) => state.login,
  (loginState: LoginReducerState) => loginState.invitationTokenIsPersonalized,
);
export const selectPersonalizedFamilyInvitationTokenReceiverEmail = createSelector(
  (state: ApplicationState) => state.login,
  (loginState: LoginReducerState) =>
    loginState.invitationTokenIsForReceiverEmail,
);
