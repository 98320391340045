import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import FZFieldLabel from '../../01_atoms/FZFieldLabel/FZFieldLabel';

const useStyles = makeStyles(theme => {
  return {
    accordionContentItemContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      flexWrap: 'wrap',

      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
      },
    },

    accordionContentItemLabel: {
      [theme.breakpoints.up('sm')]: {
        width: '35%',
      },

      width: '100%',
      marginRight: 16,
    },

    accordionContentItemValue: {
      whiteSpace: 'nowrap',
    },
  };
});

export type FZSettingsCardItemProps = {
  label?: string;
  value?: string | JSX.Element;
  children?: JSX.Element | JSX.Element[];
  secondaryActionElement?: JSX.Element;
  secondaryActionIcon?: JSX.Element;
  onSecondaryAction?: () => void;
  elementIsButton?: any;
  disabled?: boolean;
  tooltip?: string;
};

export default function FZSettingsCardItem({
  label,
  value,
  children,
  secondaryActionIcon,
  secondaryActionElement,
  onSecondaryAction,
  elementIsButton,
  disabled,
  tooltip,
}: FZSettingsCardItemProps): ReactElement {
  const classes = useStyles({});

  return (
    <ListItem
      button={elementIsButton}
      disabled={disabled}
      onClick={onSecondaryAction}
    >
      <span className={classes.accordionContentItemContainer}>
        <FZFieldLabel
          label={label}
          className={classes.accordionContentItemLabel}
          disabled={disabled}
          tooltip={tooltip}
        ></FZFieldLabel>
        {typeof value === 'string' && (
          <Typography
            variant={'body2'}
            className={classes.accordionContentItemValue}
          >
            {value}
          </Typography>
        )}

        {typeof value !== 'string' && value}

        {children}

        {secondaryActionElement && (
          <ListItemSecondaryAction
            onClick={disabled ? null : onSecondaryAction}
          >
            {secondaryActionElement}
          </ListItemSecondaryAction>
        )}
      </span>

      {secondaryActionIcon && (
        <ListItemSecondaryAction onClick={disabled ? null : onSecondaryAction}>
          <IconButton edge="end" disabled={disabled}>
            {secondaryActionIcon}
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
