import { action, Action, PayloadAction } from 'typesafe-actions';
import { Image } from '../../models/Image';
import { UserSettings } from '../../models/UserSettings';
import { ActionCallbacks } from '../main/types';
import {
  UpdateUserProfileDto,
  UpdateUserSettingsDto,
  UserProfile,
} from './types';

/**
 * Constants used as action types in ../_actions/user.actions.js
 */
export enum userActionTypes {
  FETCH_OWN_PROFILE_REQUEST = 'FETCH_OWN_PROFILE_REQUEST',
  FETCH_OWN_PROFILE_SUCCESS = 'FETCH_OWN_PROFILE_SUCCESS',
  FETCH_OWN_PROFILE_FAILURE = 'FETCH_OWN_PROFILE_FAILURE',
  FETCH_OWN_PROFILE_FULFILL = 'FETCH_OWN_PROFILE_FULFILL',

  FETCH_USER_SETTINGS_REQUEST = 'FETCH_USER_SETTINGS_REQUEST',
  FETCH_USER_SETTINGS_SUCCESS = 'FETCH_USER_SETTINGS_SUCCESS',
  FETCH_USER_SETTINGS_FAILURE = 'FETCH_USER_SETTINGS_FAILURE',
  FETCH_USER_SETTINGS_FULFILL = 'FETCH_USER_SETTINGS_FULFILL',

  UPDATE_USER_SETTINGS_REQUEST = 'UPDATE_USER_SETTINGS_REQUEST',
  UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS',
  UPDATE_USER_SETTINGS_FAILURE = 'UPDATE_USER_SETTINGS_FAILURE',
  UPDATE_USER_SETTINGS_FULFILL = 'UPDATE_USER_PROFILE_FULFILL',

  UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST',
  UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE',
  UPDATE_USER_PROFILE_FULFILL = 'UPDATE_USER_PROFILE_FULFILL',

  PUT_PROFILE_IMAGE_REQUEST = 'PUT_PROFILE_IMAGE_REQUEST',
  PUT_PROFILE_IMAGE_SUCCESS = 'PUT_PROFILE_IMAGE_SUCCESS',
  PUT_PROFILE_IMAGE_FAILURE = 'PUT_PROFILE_IMAGE_FAILURE',
  PUT_PROFILE_IMAGE_FULFILL = 'PUT_PROFILE_IMAGE_FULFILL',
}

//#region Fetch Own Profile Action Creators
export const fetchOwnProfileRequest = () =>
  action(userActionTypes.FETCH_OWN_PROFILE_REQUEST);

export const fetchOwnProfileSuccess = (userProfile: UserProfile) =>
  action(userActionTypes.FETCH_OWN_PROFILE_SUCCESS, {
    userProfile: userProfile,
  });

export const fetchOwnProfileFailure = (error: Error) => {
  return action(userActionTypes.FETCH_OWN_PROFILE_FAILURE, { error: error });
};

export const fetchOwnProfileFulfill = () => {
  return action(userActionTypes.FETCH_OWN_PROFILE_FULFILL);
};
//#endregion

//#region Fetch User Settings Action Creators

export const fetchUserSettingsRequest = () => {
  return action(userActionTypes.FETCH_USER_SETTINGS_REQUEST);
};

export const fetchUserSettingsSuccess = (newSettings: UserSettings) => {
  return action(userActionTypes.FETCH_USER_SETTINGS_SUCCESS, {
    newSettings,
  });
};

//#endregion

//#region Update User Settings Action Creators

export type UpdateUserSettingsAction = Action & {
  type: userActionTypes.UPDATE_USER_SETTINGS_REQUEST;
  payload: {
    newSettings: UpdateUserSettingsDto;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
};

export const updateUserSettingsRequest = (
  newSettings: UpdateUserSettingsDto,
  successCallback?: () => void,
  errorCallback?: () => void,
): UpdateUserSettingsAction =>
  action(userActionTypes.UPDATE_USER_SETTINGS_REQUEST, {
    newSettings: newSettings,
    successCallback: successCallback,
    errorCallback: errorCallback,
  });

export const updateUserSettingsSuccess = (updatedSettings: UserSettings) =>
  action(userActionTypes.UPDATE_USER_SETTINGS_SUCCESS, {
    updatedSettings: updatedSettings,
  });

export const updateUserSettingsFailure = (error: Error) => {
  return action(userActionTypes.UPDATE_USER_SETTINGS_FAILURE, { error: error });
};

export const updateUserSettingsFulfill = () => {
  return action(userActionTypes.UPDATE_USER_SETTINGS_FULFILL);
};
//#endregion

//#region Update Customer Profile Action Creators
export type UpdateUserProfileAction = Action & {
  type: userActionTypes.UPDATE_USER_PROFILE_REQUEST;
  payload: {
    newProfile: UpdateUserProfileDto;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
};

export const updateUserProfileRequest = (
  newProfile: UpdateUserProfileDto,
  successCallback?: () => void,
  errorCallback?: () => void,
): UpdateUserProfileAction =>
  action(userActionTypes.UPDATE_USER_PROFILE_REQUEST, {
    newProfile: newProfile,
    successCallback: successCallback,
    errorCallback: errorCallback,
  });

export type UpdateUserProfileSuccessAction = PayloadAction<
  userActionTypes.UPDATE_USER_PROFILE_SUCCESS,
  UpdateUserProfileDto
>;

export const updateUserProfileSuccess = (
  updatedProfile: UpdateUserProfileDto,
): UpdateUserProfileSuccessAction =>
  action(userActionTypes.UPDATE_USER_PROFILE_SUCCESS, updatedProfile);

export const updateUserProfileFailure = (error: Error) => {
  return action(userActionTypes.UPDATE_USER_PROFILE_FAILURE, { error: error });
};

export const updateUserProfileFulfill = () => {
  return action(userActionTypes.UPDATE_USER_PROFILE_FULFILL);
};

//#region Update Profile Image Action Creators

export type putProfileImageAction = Action & {
  type: userActionTypes.PUT_PROFILE_IMAGE_REQUEST;
  payload: {
    image: Image;
    uploadProgressCallback: (progressEvent: any) => void;
    uploadImageSuccessCallback: () => void;
  };
};

export const putProfileImageRequest = (
  data: Image,
  uploadProgressCallback: (progressEvent: any) => void,
  uploadImageSuccessCallback: () => void,
  actionCallbacks: ActionCallbacks,
): putProfileImageAction =>
  action(
    userActionTypes.PUT_PROFILE_IMAGE_REQUEST,
    {
      image: data,
      uploadProgressCallback: uploadProgressCallback,
      uploadImageSuccessCallback: uploadImageSuccessCallback,
    },
    actionCallbacks,
  );
export const putProfileImageSuccess = (data: Image) =>
  action(userActionTypes.PUT_PROFILE_IMAGE_SUCCESS, data);
export const putProfileImageFailure = (error: any) =>
  action(userActionTypes.PUT_PROFILE_IMAGE_FAILURE, error);
export const putProfileImageFulfill = () =>
  action(userActionTypes.PUT_PROFILE_IMAGE_FULFILL);

//#endregion
