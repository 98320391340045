import { Newspaper } from '../../models/Newspaper';
import { authenticationActionTypes } from '../login/login.actions';
import {
  AddFamilyMemberInvitationSuccessAction,
  AddReceiverToMultiReceiverInvitationAction,
  CreateFamilySuccessAction,
  EditFamilySuccessAction,
  familyActionTypes,
  JoinFamilySuccessAction,
  LeaveFamilySuccessAction,
  RemoveFamilyMemberInvitationSuccessAction,
  RemoveReceiverFromMultiReceiverInvitationAction,
  SetFamilyNameAction,
  SetMeansOfFamilyMemberInvitationAction,
  SetMultiReceiverInvitationMessageAction,
  SetMultiReceiverInvitationSubjectAction,
} from './family.actions';
import {
  FamilyDictionary,
  FamilyInvitationType,
  FamilyReducerState,
} from './types';

const initialState: FamilyReducerState = {
  isFetchingOwnFamilies: false,
  ownFamilies: {},
  currentlyActiveFamily: null,
  isFetchingFamily: false,
  currentlyActiveFamilyHasReachedEndOfReleaseCycle: false,
  endOfReleaseCycleReminderHasFlashed: false,

  isFetchingLastPublishedNewspaper: false,
  lastPublishedNewspaper: null,

  isFetchingCurrentNewspaper: false,
  currentNewspaper: null,

  currentlyCreatedFamily: {
    familyName: '',
    meansOfInvitation: FamilyInvitationType.SHARING_LINK,

    multiReceiverInvitation: {
      personalMessage: '',
      invitationSubject: '',
      receiverEmailAdresses: [],
      uuid: '',
    },
  },
};

const family = (state = initialState, action: any): FamilyReducerState => {
  switch (action.type) {
    //#region Fetching single Families

    case familyActionTypes.FETCH_FAMILY_REQUEST: {
      return {
        isFetchingFamily: true,
        ...state,
      };
    }

    case familyActionTypes.FETCH_FAMILY_SUCCESS: {
      if (
        action.payload.fetchedFamily.uuid === state.currentlyActiveFamily?.uuid
      ) {
        return {
          ...state,
          currentlyActiveFamily: action.payload.fetchedFamily,
          isFetchingFamily: false,
        };
      }

      return {
        isFetchingFamily: false,
        ...state,
      };
    }

    //#endregion

    //#region Fetching Families the user is a member in

    case familyActionTypes.FETCH_OWN_FAMILIES_REQUEST: {
      return {
        ...state,
        isFetchingOwnFamilies: true,
      };
    }

    case familyActionTypes.FETCH_OWN_FAMILIES_SUCCESS: {
      const ownFamiliesAsObjectWithKeysBeingFamilyUuidsAndValuesBeingFamilyObjects: FamilyDictionary = {};

      const now = new Date();
      for (let i = 0; i < action.payload.ownFamilies.length; i++) {
        action.payload.ownFamilies[i].hasReachedEndOfReleaseCycle =
          action.payload.ownFamilies[i].nextReleaseDate < now;
        ownFamiliesAsObjectWithKeysBeingFamilyUuidsAndValuesBeingFamilyObjects[
          action.payload.ownFamilies[i].uuid
        ] = action.payload.ownFamilies[i];
      }

      return {
        ...state,
        isFetchingOwnFamilies: false,
        ownFamilies: ownFamiliesAsObjectWithKeysBeingFamilyUuidsAndValuesBeingFamilyObjects,
      };
    }

    case familyActionTypes.FETCH_OWN_FAMILIES_FAILURE: {
      return {
        ...state,
        isFetchingOwnFamilies: false,
      };
    }
    //#endregion

    //#region Switching between Families

    case familyActionTypes.SWITCH_ACTIVE_FAMILY: {
      return {
        ...state,
        currentlyActiveFamily: action.payload.familyToSwitchTo,
        endOfReleaseCycleReminderHasFlashed: false,
        currentNewspaper: null,
        lastPublishedNewspaper: null,
      };
    }
    //#endregion

    //#region Fetching newspapers of a family

    case familyActionTypes.FETCH_LAST_NEWSPAPER_FOR_FAMILY_REQUEST: {
      return {
        ...state,
        isFetchingLastPublishedNewspaper: true,
      };
    }

    case familyActionTypes.FETCH_LAST_NEWSPAPER_FOR_FAMILY_SUCCESS: {
      return {
        ...state,
        lastPublishedNewspaper: new Newspaper(
          action.payload.lastPublishedNewspaper,
        ),
        isFetchingLastPublishedNewspaper: false,
      };
    }

    case familyActionTypes.FETCH_LAST_NEWSPAPER_FOR_FAMILY_FAILURE: {
      return { ...state, isFetchingLastPublishedNewspaper: false };
    }

    case familyActionTypes.FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_REQUEST: {
      return {
        ...state,
        isFetchingCurrentNewspaper: true,
      };
    }

    case familyActionTypes.FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_SUCCESS: {
      return {
        ...state,
        isFetchingCurrentNewspaper: false,
        currentNewspaper: new Newspaper(
          action.payload.newspaperWithOnlyArticlesOfCurrentUser,
        ),
      };
    }

    case familyActionTypes.FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_FAILURE: {
      return {
        ...state,
        isFetchingCurrentNewspaper: false,
      };
    }

    case familyActionTypes.FETCH_USER_CONTRIBUTIONS_IN_CURRENT_NEWSPAPER_OF_FAMILY_FULFILL: {
      return state;
    }

    //#endregion

    //#region Composing (emailed) Family invitations

    case familyActionTypes.SET_FAMILY_NAME: {
      const typedAction: SetFamilyNameAction = action;

      return {
        ...state,
        currentlyCreatedFamily: {
          ...state.currentlyCreatedFamily,
          familyName: typedAction.payload.familyNameToSet,
        },
      };
    }

    case familyActionTypes.ADD_RECEIVER_TO_MULTI_RECEIVER_INVITATION: {
      const typedAction: AddReceiverToMultiReceiverInvitationAction = action;

      const newReceivers =
        state.currentlyCreatedFamily.multiReceiverInvitation
          .receiverEmailAdresses;

      newReceivers.push(typedAction.payload.receiverToAdd);

      return {
        ...state,
        currentlyCreatedFamily: {
          ...state.currentlyCreatedFamily,
          multiReceiverInvitation: {
            ...state.currentlyCreatedFamily.multiReceiverInvitation,
            receiverEmailAdresses: newReceivers,
          },
        },
      };
    }

    case familyActionTypes.REMOVE_RECEIVER_FROM_MULTI_RECEIVER_INVITATION: {
      const typedAction: RemoveReceiverFromMultiReceiverInvitationAction = action;

      const newReceivers = state.currentlyCreatedFamily.multiReceiverInvitation.receiverEmailAdresses.filter(
        (receiverAdress: string) => {
          return receiverAdress !== typedAction.payload.receiverToRemove;
        },
      );

      return {
        ...state,
        currentlyCreatedFamily: {
          ...state.currentlyCreatedFamily,
          multiReceiverInvitation: {
            ...state.currentlyCreatedFamily.multiReceiverInvitation,
            receiverEmailAdresses: newReceivers,
          },
        },
      };
    }

    case familyActionTypes.SET_MULTI_RECEIVER_INVITATION_SUBJECT: {
      const typedAction: SetMultiReceiverInvitationSubjectAction = action;
      return {
        ...state,
        currentlyCreatedFamily: {
          ...state.currentlyCreatedFamily,
          multiReceiverInvitation: {
            ...state.currentlyCreatedFamily.multiReceiverInvitation,
            invitationSubject: typedAction.payload.invitationSubject,
          },
        },
      };
    }

    case familyActionTypes.SET_MEANS_OF_FAMILY_MEMBER_INVITATION: {
      const typedAction: SetMeansOfFamilyMemberInvitationAction = action;
      return {
        ...state,
        currentlyCreatedFamily: {
          ...state.currentlyCreatedFamily,
          meansOfInvitation: typedAction.payload.meansOfInvitation,
        },
      };
    }

    case familyActionTypes.SET_MULTI_RECEIVER_INVITATION_MESSAGE: {
      const typedAction: SetMultiReceiverInvitationMessageAction = action;
      return {
        ...state,
        currentlyCreatedFamily: {
          ...state.currentlyCreatedFamily,
          multiReceiverInvitation: {
            ...state.currentlyCreatedFamily.multiReceiverInvitation,
            personalMessage: typedAction.payload.invitationMessage,
          },
        },
      };
    }

    //#endregion

    //#region Creating invites to a family
    case familyActionTypes.ADD_FAMILY_MEMBER_INVITATION_SUCCESS: {
      const typedAction = action as AddFamilyMemberInvitationSuccessAction;

      const invitationsToCurrentlyActiveFamilyCopy = [
        ...state.currentlyActiveFamily?.invitations,
      ];

      invitationsToCurrentlyActiveFamilyCopy.push(
        typedAction.payload.createdInvitation,
      );

      return {
        ...state,
        currentlyActiveFamily: {
          ...state.currentlyActiveFamily,
          invitations: invitationsToCurrentlyActiveFamilyCopy,
        },
      };
    }

    //#region Deleting invites to a family
    case familyActionTypes.REMOVE_FAMILY_MEMBER_INVITATION_SUCCESS: {
      const typedAction = action as RemoveFamilyMemberInvitationSuccessAction;

      let invitationsToCurrentlyActiveFamilyCopy = [
        ...state.currentlyActiveFamily?.invitations,
      ];

      invitationsToCurrentlyActiveFamilyCopy = invitationsToCurrentlyActiveFamilyCopy.filter(
        element => {
          return element.uuid !== typedAction.payload.deletedInvitationUuid;
        },
      );

      state.currentlyActiveFamily.invitations = invitationsToCurrentlyActiveFamilyCopy;

      return { ...state, currentlyActiveFamily: state.currentlyActiveFamily };
    }

    //#region Accepting Invites to a family
    case familyActionTypes.JOIN_FAMILY_SUCCESS: {
      const typedAction: JoinFamilySuccessAction = action;
      return {
        ...state,
        currentlyActiveFamily: typedAction.payload.joinedFamily,
      };
    }

    //#endregion

    //#region State to flash a reminder when a families releaseCycle has ended

    case familyActionTypes.SET_FAMILY_HAS_REACHED_END_OF_RELEASE_CYCLE: {
      const ownFamilies = state.ownFamilies;

      if (ownFamilies[action.payload.family.uuid]) {
        ownFamilies[action.payload.family.uuid].hasReachedEndOfReleaseCycle =
          action.payload.hasReachedEndOfReleaseCycle;
      }

      return {
        ...state,
        ownFamilies: ownFamilies,
      };
    }

    case familyActionTypes.SET_END_OF_RELEASE_REMINDER_HAS_FLASHED: {
      return {
        ...state,
        endOfReleaseCycleReminderHasFlashed: action.payload.hasFlashed,
      };
    }
    //#endregion

    //#region Creating a family

    case familyActionTypes.CREATE_FAMILY_SUCCESS: {
      const typedAction = action as CreateFamilySuccessAction;
      const newlyCreatedFamily = typedAction.payload.createdFamily;

      state.ownFamilies[newlyCreatedFamily.uuid] = newlyCreatedFamily;

      return {
        ...state,
        currentlyActiveFamily: typedAction.payload.createdFamily,
        currentlyCreatedFamily: initialState.currentlyCreatedFamily,
      };
    }

    //#endregion

    //#region

    case familyActionTypes.EDIT_FAMILY_SUCCESS: {
      const typedAction = action as EditFamilySuccessAction;
      const updatedFamily = typedAction.payload.editedFamily;

      state.ownFamilies[updatedFamily.uuid] = updatedFamily;

      /**
       *  currentlyActiveFamily: {
          ...state.currentlyActiveFamily,
          name: updatedFamily.name,
          paymentRepresentative: updatedFamily.paymentRepresentative,
        },
       */

      return {
        ...state,
        currentlyActiveFamily: updatedFamily,
      };
    }

    //#endregion

    //#region Leaving a family
    case familyActionTypes.LEAVE_FAMILY_SUCCESS: {
      const typedAction = action as LeaveFamilySuccessAction;

      // Delete the family that was left from the dictionary of ownFamilies
      const ownFamiliesCopy = { ...state.ownFamilies };
      delete ownFamiliesCopy[typedAction.payload.familyUuidOfFamilyToLeave];

      return {
        ...state,
        ownFamilies: ownFamiliesCopy,
        currentlyActiveFamily: null,
      };
    }

    case familyActionTypes.CLEAR_ACTIVE_FAMILY: {
      return {
        ...state,
        currentlyActiveFamily: null,
      };
    }

    //#endregion

    case authenticationActionTypes.LOGOUT_SUCCESS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

/*  This method can be used to react to an updated article and patch it into the current Newspaper in redux state. 
This is only neccessary once the  currentnewspaper is no longer re-fetched anyway due to a page change.  
const patchNewArticleIntoCurrentNewspaper = (
  state: FamilyReducerState,
  updatedArticle: Article,
): FamilyReducerState => {
  // Put the updated article into ownArticles Array
  const indexOfUpdatedArticle = state.currentNewspaper.articles.findIndex(
    (article: Article) => {
      return article.uuid === updatedArticle.uuid;
    },
  );
  const currentNewspaperArticlesCopy = [...state.currentNewspaper.articles];
  currentNewspaperArticlesCopy[indexOfUpdatedArticle] = updatedArticle;

  const currentNewspaperUpdated = {
    ...state.currentNewspaper,
    articles: currentNewspaperArticlesCopy,
  };

  return {
    ...state,
    currentNewspaper: currentNewspaperUpdated,
  };
}; */

export default family;
