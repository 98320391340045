import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFamilyMemberInvitationRequest,
  removeFamilyMemberInvitationRequest,
} from '../../../common/redux/families/family.actions';
import {
  selectCurrentlyActiveFamily,
  selectFamilyInvitationLinks,
} from '../../../common/redux/families/family.selectors';
import {
  FamilyInvitation,
  FamilyInvitationType,
} from '../../../common/redux/families/types';
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../common/redux/ui/ui.actions';
import colors from '../../00_Constants/colors';
import FZButton from '../../01_atoms/FZButton/FZButton';
import FZFieldLabel from '../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZSpinner from '../../01_atoms/FZSpinner';
import FZTypography from '../../01_atoms/FZTypography/FZTypography';
import FZDialog from '../../02_molecules/FZConfirmDialog/FZDialog';
import useConfig from '../../Hooks/useConfig';

interface Props {
  open: boolean;
  onClose: () => void;
  color?: string;
  familyUuid: string;
}

export default function FZInviteFamilyViaLinkDialog({
  open,
  familyUuid,
  color,
  onClose,
}: Props): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentlyActiveFamily = useSelector(selectCurrentlyActiveFamily);
  const familyInvitationLinks = useSelector(selectFamilyInvitationLinks);
  const [loading, setLoading] = useState<boolean>(false);
  const appConfig = useConfig();

  const deleteFamilyInvitationLink = (invitationToDelete: FamilyInvitation) => {
    setLoading(true);
    dispatch(
      removeFamilyMemberInvitationRequest(
        currentlyActiveFamily.uuid,
        invitationToDelete,
        {
          successCallback: () => {
            setLoading(false);

            dispatch(
              showSuccessSnackbar(
                t(
                  'FZinviteFamilyMemberViaLinkDialog-delete-invitation-link-success-message',
                ),
                true,
              ),
            );
          },
          errorCallback: () => {
            setLoading(false);
            dispatch(
              showErrorSnackbar(
                t(
                  'FZinviteFamilyMemberViaLinkDialog-delete-invitation-link-error-message',
                ),
                true,
              ),
            );
          },
        },
      ),
    );
  };

  const createFamilyInvitation = useCallback(() => {
    setLoading(true);
    dispatch(
      addFamilyMemberInvitationRequest(
        familyUuid,
        { invitationType: FamilyInvitationType.SHARING_LINK },
        {
          successCallback: () => {
            setLoading(false);

            dispatch(
              showSuccessSnackbar(
                t(
                  'FZinviteFamilyMemberViaLinkDialog-create-invitation-link-success-message',
                ),
                true,
              ),
            );
          },
          errorCallback: () => {
            setLoading(false);

            dispatch(
              showErrorSnackbar(
                t(
                  'FZinviteFamilyMemberViaLinkDialog-create-invitation-link-error-message',
                ),
                true,
              ),
            );
          },
        },
      ),
    );
  }, [dispatch, t, familyUuid]);

  const copyValueToClipboard = async (valueToCopyToClipboard: string) => {
    try {
      await navigator.clipboard.writeText(valueToCopyToClipboard);
      dispatch(
        showSuccessSnackbar(
          t(
            'FZInviteFamilyViaLinkDialog-copyInvitationToClipboard-success-message',
          ),
          true,
        ),
      );
    } catch (err) {
      dispatch(
        showErrorSnackbar(
          t(
            'FZInviteFamilyViaLinkDialog-copyInvitationToClipboard-failed-error',
          ),
          true,
        ),
      );
    }
  };

  return (
    <div>
      <FZDialog
        title={t('FZinviteToFamilyViaLinkDialog-title')}
        variant="generic"
        open={open}
        onClose={onClose}
        displayCloseIcon
      >
        <FZTypography variant="body1">
          {t(
            'FZinviteFamilyMemberViaLinkDialog-invitations-explanation-message',
          )}
        </FZTypography>

        <FZFieldLabel
          style={{ marginTop: 32 }}
          label={t('FZinviteFamilyMemberViaLinkDialog-activeInvitations-label')}
        ></FZFieldLabel>

        <List dense={false}>
          {!familyInvitationLinks ||
            (familyInvitationLinks.length === 0 && (
              <ListItem>
                <ListItemText
                  primary={
                    <span>
                      {t('FZinviteFamilyViaLinkDialog-no-active-links-label')}
                    </span>
                  }
                  secondary={''}
                />
              </ListItem>
            ))}
          {familyInvitationLinks?.map((element: FamilyInvitation) => {
            const invitationLink = `${appConfig.LINK_CONFIG.FAMILY_INVITATIONS.REACT_APP_FAMILY_INVITATIONS_BASE_URL}/${element.invitationCode}`;
            return (
              <ListItem key={element.updatedAt}>
                {/** Query if copying to clipboard is supported in the current browser, and only then display the copy to clipboard button*/
                document.queryCommandSupported('copy') && (
                  <ListItemAvatar>
                    <Avatar
                      onClick={() => {
                        copyValueToClipboard(invitationLink);
                      }}
                    >
                      <Tooltip
                        title={t(
                          'FZInviteFamilyViaLinkDialog-copy-to-clipboard-button-tooltip',
                        )}
                      >
                        <LinkRoundedIcon color={'action'} />
                      </Tooltip>
                    </Avatar>
                  </ListItemAvatar>
                )}

                <ListItemText
                  primary={<span>{invitationLink || ''}</span>}
                  secondary={''}
                />

                <ListItemSecondaryAction>
                  <Tooltip
                    title={t(
                      'FZInviteFamilyViaLinkDialog-delete-link-button-tooltip',
                    )}
                  >
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        deleteFamilyInvitationLink(element);
                      }}
                    >
                      <DeleteRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>

        {loading ? (
          <FZSpinner color={colors.secondary}></FZSpinner>
        ) : (
          <FZButton
            disabled={
              !familyInvitationLinks || familyInvitationLinks.length > 0
            }
            variant="contained"
            style={{ float: 'right' }}
            backgroundColor={color}
            label={
              familyInvitationLinks?.length > 0
                ? t(
                    'FZinviteFamilyMemberViaLinkDialog-invitation-link-already-active-label',
                  )
                : t(
                    'FZinviteFamilyMemberViaLinkDialog-create-new-invitation-link-button-label',
                  )
            }
            onClick={createFamilyInvitation}
          ></FZButton>
        )}
      </FZDialog>
    </div>
  );
}
