import { Button } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import failureIllustration from '../../../../assets/images/failure_illustration.png';
import fzColorfulBackgroundImage from '../../../../assets/images/FZ_colorful_background.jpg';
import successIllustration from '../../../../assets/images/success_illustration.png';
import { accountActivationRequest } from '../../../../common/redux/login/login.actions';
import colors from '../../../00_Constants/colors';
import { FullWidthBackground } from '../../../01_atoms/FullWidthBackground/FullWidthBackground';
import FZSpinner from '../../../01_atoms/FZSpinner';
import FZTypography from '../../../01_atoms/FZTypography/FZTypography';
import FZStatusPageTemplate from '../../../04_templates/StatusPageTemplate/FZStatusPage/FZStatusPageTemplate';
import { useQueryString } from '../../../Hooks/useQueryString';

interface Props {}

export default function VerifyEmailAdressScreen(props: Props): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const queryString = useQueryString();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    requestFinishedSuccessFully,
    setRequestFinishedSuccessFully,
  ] = useState(false);

  const queryStringUserParameter = queryString.get('user');
  const queryStringActivationTokenParameter = queryString.get('activation');

  useEffect(() => {
    setIsSubmitting(true);
    // code to run on component mount
    dispatch(
      accountActivationRequest(
        {
          customerUid: queryStringUserParameter,
          activationToken: queryStringActivationTokenParameter,
        },
        {
          successCallback: () => {
            // Success
            setIsSubmitting(false);
            setRequestFinishedSuccessFully(true);
          },
          errorCallback: () => {
            // Error
            setIsSubmitting(false);
          },
        },
      ),
    );
  }, [dispatch, queryStringUserParameter, queryStringActivationTokenParameter]);

  const verifyEmailPageIllustration = isSubmitting
    ? null
    : requestFinishedSuccessFully
    ? successIllustration
    : failureIllustration;

  const verifyEmailPageBody: JSX.Element = (
    <span>
      {isSubmitting && (
        <FZSpinner
          color={colors.quartiary}
          statusMessage={t('verifyEmailScreen-is-submitting-message')}
        ></FZSpinner>
      )}

      {isSubmitting ? null : requestFinishedSuccessFully ? (
        <>
          <FZTypography variant={'h5'}>
            {t('verifyEmailScreen-success-headline')}
          </FZTypography>
          <FZTypography variant="body1">
            {t('verifyEmailScreen-success-message')}
          </FZTypography>
        </>
      ) : (
        <>
          <FZTypography variant={'h5'}>
            {t('verifyEmailScreen-error-headline')}
          </FZTypography>
          <FZTypography variant="body1">
            {t('verifyEmailScreen-error-message')}
          </FZTypography>
        </>
      )}
    </span>
  );

  const verifyEmailPageActions: JSX.Element = (
    <Button
      variant="outlined"
      onClick={() => {
        history.push('/login');
      }}
    >
      {t('resetPasswordEmailSentScreen-back-to-signin-button-label')}
    </Button>
  );

  return (
    <FullWidthBackground
      imageSource={fzColorfulBackgroundImage}
      position="absolute"
    >
      <FZStatusPageTemplate
        imageSource={verifyEmailPageIllustration}
        body={verifyEmailPageBody}
        actions={verifyEmailPageActions}
      ></FZStatusPageTemplate>
    </FullWidthBackground>
  );
}
