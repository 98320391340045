import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import fzColorfulBackgroundImage from '../../../assets/images/FZ_colorful_background.jpg';
import logo from '../../../assets/images/FZ_Logo.jpg';
import colors from '../../00_Constants/colors';
import FZLink from '../FZLink/FZLink';
import FZTypography from '../FZTypography/FZTypography';

const useStyles = makeStyles((theme: Theme) => {
  return {
    footerContainer: {
      position: 'relative',
    },

    footerBackground: {
      position: 'absolute',
      backgroundColor: colors.textStrong,
      width: '100vw',
      backgroundImage: `url(${fzColorfulBackgroundImage})`,
      backgroundSize: 'cover',
    },

    footerBase: {
      paddingTop: 32,
      paddingBottom: 32,
    },

    footerSectionContainer: {
      padding: 16,

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
    },

    footerListContainer: {
      listStyle: 'none',
      paddingLeft: 0,
    },

    footerListContainerItem: {
      marginTop: 16,
      marginBottom: 16,
      color: 'white',
    },

    footerListContainerLink: {
      marginTop: 16,
      marginBottom: 16,
      textTransform: 'uppercase',
      color: colors.white,
    },

    footerCardHeader: {
      color: colors.white,
      textAlign: 'center',
    },
  };
});

interface Props {}

export default function FZFooter(props: Props): ReactElement {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <div className={classes.footerContainer}>
      <div className={classes.footerBackground}>
        <Container>
          <footer className={classes.footerBase}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                className={classes.footerSectionContainer}
              >
                <Card
                  style={{ backgroundColor: 'rgba(0,0,0,0)' }}
                  elevation={3}
                >
                  <CardHeader
                    title={t('footer-payment-methods-headline')}
                    className={classes.footerCardHeader}
                  ></CardHeader>

                  <CardContent></CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                className={classes.footerSectionContainer}
              >
                <Card
                  style={{ backgroundColor: 'rgba(0,0,0,0)' }}
                  elevation={3}
                >
                  <CardHeader
                    title={t('footer-delivery-partners-headline')}
                    className={classes.footerCardHeader}
                  ></CardHeader>

                  <CardContent></CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                className={classes.footerSectionContainer}
              >
                <div
                  style={{
                    /* width: '100%',
              height: 50, */

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img
                    alt="logo"
                    src={logo}
                    style={{
                      borderRadius: 8,
                      width: 50,
                      height: 50,
                      marginRight: 8,
                    }}
                  ></img>

                  <FZTypography
                    variant="h5"
                    style={{ marginTop: 8 }}
                    color={colors.white}
                  >
                    {t('footer-serviceName-label')}
                  </FZTypography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                className={classes.footerSectionContainer}
              >
                <FZTypography variant="h6" color={colors.white}>
                  {t('footer-companySection-headline')}
                </FZTypography>
                <ul className={classes.footerListContainer}>
                  <li className={clsx(classes.footerListContainerLink)}>
                    <FZLink href="/conept">
                      {t('footer-concept-link-label')}
                    </FZLink>
                  </li>

                  <li className={classes.footerListContainerLink}>
                    <FZLink href="/about-us">
                      {t('footer-about-us-link-label')}
                    </FZLink>
                  </li>

                  <li className={classes.footerListContainerLink}>
                    <FZLink href="/FAQ">{t('footer-faq-link-label')}</FZLink>
                  </li>
                </ul>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                className={classes.footerSectionContainer}
              >
                <FZTypography variant="h6" color={colors.white}>
                  {t('footer-legalSection-headline')}
                </FZTypography>
                <ul className={classes.footerListContainer}>
                  <li className={classes.footerListContainerLink}>
                    <FZLink href="/termsOfService">
                      {t('footer-TermsOfService-link-label')}
                    </FZLink>
                  </li>
                  <li className={classes.footerListContainerLink}>
                    <FZLink href="/dataProcessingAgreement">
                      {t('footer-DataProtectionAgreement-link-label')}
                    </FZLink>{' '}
                  </li>
                  <li className={classes.footerListContainerLink}>
                    <FZLink
                      className={classes.footerListContainerLink}
                      href="/imprint"
                    >
                      {t('footer-Imprint-link-label')}
                    </FZLink>
                  </li>
                </ul>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                className={classes.footerSectionContainer}
              >
                <FZTypography variant="h6" color={colors.white}>
                  {t('footer-stayInTouchSection-headline')}
                </FZTypography>
                <ul className={classes.footerListContainer}>
                  <FZLink
                    className={classes.footerListContainerLink}
                    href="/contact"
                  >
                    {t('footer-contact-us-via-form-label')}
                  </FZLink>

                  <li className={classes.footerListContainerItem}>
                    {t('footer-contact-us-via-phone-label')}
                  </li>

                  <li className={classes.footerListContainerItem}>
                    {t('footer-contact-us-via-email-label')}
                  </li>
                </ul>

                <FZTypography variant="h6" color={colors.white}>
                  {t('footer-adress-headline')}
                </FZTypography>
                <ul className={classes.footerListContainer}>
                  <li className={classes.footerListContainerItem}>
                    {t('footer-adress-entityName-label')}
                  </li>
                  <li className={classes.footerListContainerItem}>
                    {t('footer-adress-steetName-label')}
                  </li>
                  <li className={classes.footerListContainerItem}>
                    {t('footer-adress-postCodeAndCity-label')}
                  </li>
                </ul>
              </Grid>

              <Grid item xs={12}>
                <span
                  style={{
                    color: 'white',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingTop: 4,
                    paddingBottom: 4,
                  }}
                >
                  {t('footer-createdWithLoveForFamilies-label')}
                </span>
              </Grid>
            </Grid>
          </footer>
        </Container>
      </div>
    </div>
  );
}

/* interface FooterSectionProps {}

export function FZFooterSection({}: FooterSectionProps) {} */
