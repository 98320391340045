import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((props: ImageGridProps) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: 'rgba(0,0,0,0)',
    },
    gridList: {
      width: (props: ImageGridProps) => {
        return '100%'; //props.gridWidth;
      }, // 642
      height: (props: ImageGridProps) => {
        return props.gridHeight;
      }, // 400
      overflow: 'hidden',
    },
  }),
);

export interface ImageGridItem {
  image: any;
  title: string;
  author: string;
  cols: number;
  rows: number;
}

export interface ImageGridProps {
  columns: number;
  data: ImageGridItem[];
  gridHeight: number;
}

export default function FZImageGrid(props: ImageGridProps) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <GridList
        cellHeight={80}
        className={classes.gridList}
        cols={props.columns}
      >
        {props.data.map((tile: ImageGridItem) => (
          <GridListTile key={tile.title} cols={tile.cols || 1} rows={tile.rows}>
            <img src={tile.image} alt={tile.title} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
