import { useState } from 'react';
import { InputValidationService } from '../../common/services';

export function useInputValidationService(): InputValidationService {
  const [inputValidationService, setInputValidationService] = useState<
    InputValidationService
  >(null);

  if (!inputValidationService) {
    const inputValidationService = new InputValidationService();
    setInputValidationService(inputValidationService);
  }

  return inputValidationService;
}
