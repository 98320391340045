import { API_CONFIG, apiClient } from '../api';

class FeedService {
  /**
   * Fetches the articles that a speciic user has authored from the API
   */
  async getPersonalizedFeed(userUid: string, authToken: string) {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const uriString = API_CONFIG.FEED_SERVICE.GET_PERSONALIZED_FEED_URL(
      userUid,
    );

    const response = apiClient.get(uriString, { headers });

    return response;
  }
}
export default FeedService;
