import { AnyAction } from 'redux';
import { authenticationActionTypes } from './login.actions';
import { LoginReducerState } from './types';

const initialState: LoginReducerState = {
  isLoggedIn: false,
  isLoggingIn: false,
  loginErrors: null,
  idToken: null,
  accessToken: null,
  tokenExpiry: null,
  loggedInUser: null,

  joinedWithInvitationToken: null,
  invitationTokenIsPersonalized: false,
  invitationTokenIsForReceiverEmail: null,
};

const login = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case authenticationActionTypes.REGISTRATION_REQUEST: {
      return state;
    }

    case authenticationActionTypes.REGISTRATION_SUCCESS: {
      return state;
    }

    case authenticationActionTypes.REGISTRATION_FAILURE: {
      return state;
    }

    case authenticationActionTypes.LOGIN_REQUEST: {
      return {
        ...state,
        isLoggingIn: true,
      };
    }
    case authenticationActionTypes.LOGIN_SUCCESS: {
      const { accessToken, loggedInUser, tokenExpiry } = action.payload;

      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        accessToken: accessToken,
        tokenExpiry: tokenExpiry,
        loggedInUser: loggedInUser,
      };
    }

    case authenticationActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        isLoggingIn: false,
        loginErrors: action.payload.errors,
      };
    }

    case authenticationActionTypes.LOGOUT_REQUEST: {
      return {
        ...state,
        isloggingOut: true,
      };
    }

    case authenticationActionTypes.SET_FAMILY_INVITATION_TOKEN: {
      return {
        ...state,
        joinedWithInvitationToken: action.payload.invitationToken,
        invitationTokenIsPersonalized:
          action.payload.invitationTokenIsPersonalized,
        invitationTokenIsForReceiverEmail:
          action.payload.invitationTokenIsForReceiverEmail,
      };
    }

    case authenticationActionTypes.LOGOUT_SUCCESS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default login;
