export interface ImageDto {
  title?: string;
  uuid?: string;
  url?: string;
  description?: string;
}

export class Image {
  // User generated title for the image
  title?: string;

  // Unique identifier for the file
  uuid?: string;

  // Weburl that the file can be retrieved from
  url?: string;

  // User generated description of the image
  description?: string;

  // Path to retrieve the file from the local filesystem
  localFilePath?: string;

  // MIME type of the image
  mimeType?: string;

  // Width in Pixels
  width?: number;

  // Height in Pixels
  height?: number;

  // Size in Bytes
  size?: number;

  // A base64 encoded
  base64Representation?: string;

  // A blob representation
  blobRepresentation?: Blob;

  constructor(imageDto?: ImageDto) {
    if (imageDto) {
      this.title = imageDto.title;
      this.description = imageDto.description;
      this.url = imageDto.url || '';
      this.uuid = imageDto.uuid || '';
    }
  }

  set blob(blobRepresentation: Blob) {
    this.blobRepresentation = blobRepresentation;
  }

  get blob(): Blob | null {
    if (this.blobRepresentation) {
      return this.blobRepresentation;
    }
    return null;
  }

  get fileExtension() {
    // Convert the mime Type to the correct file Extension
    return this.mimeType?.substr(6, this.mimeType?.length);
  }

  static base64MimeType(base64DataUri: string): string | null {
    let result = null;

    if (typeof base64DataUri !== 'string') {
      return result;
    }

    const mime = base64DataUri.match(
      /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
    );

    if (mime && mime.length) {
      result = mime[1];
    }

    return result;
  }
}
