import { makeStyles, Theme, Typography } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    labelContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      width: '100%',
      paddingTop: 8,
      paddingBottom: 8,
    },

    enabledIcon: {
      color: theme.palette.success.main,
      paddingRight: 8,
    },

    disabledIcon: {
      color: theme.palette.error.main,
      paddingRight: 8,
    },
  };
});

interface Props {
  toggleEnabled: boolean;
  message: string;
}

export default function FZToggleLabel({
  toggleEnabled,
  message,
}: Props): ReactElement {
  const classes = useStyles({});
  return (
    <span className={classes.labelContainer}>
      {toggleEnabled ? (
        <HighlightOffRoundedIcon className={classes.disabledIcon} />
      ) : (
        <CheckCircleRoundedIcon className={classes.enabledIcon} />
      )}
      <Typography variant="body1">{message}</Typography>
    </span>
  );
}
