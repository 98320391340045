import { Card, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
//import { makeStyles } from '@material-ui/core';
import fzColorfulBackgroundImage from '../../../../assets/images/FZ_colorful_background.jpg';
import {
  JoinFamilyActionPayload,
  joinFamilyRequest,
} from '../../../../common/redux/families/family.actions';
import { setInvitationToken } from '../../../../common/redux/login/login.actions';
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../../common/redux/ui/ui.actions';
import { selectAuthState } from '../../../../common/redux/users/user.selectors';
import colors from '../../../00_Constants/colors';
import { FullWidthBackground } from '../../../01_atoms/FullWidthBackground/FullWidthBackground';
import FZButton from '../../../01_atoms/FZButton/FZButton';
import FZSpinner from '../../../01_atoms/FZSpinner';
import FZTypography from '../../../01_atoms/FZTypography/FZTypography';
import ContentPageTemplate from '../../../04_templates/ContentPageTemplate/ContentPageTemplate';

const useStyles = makeStyles({
  cardContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props {}

interface UrlParameters {
  invitationCode: string;
}

export default function AcceptInvitationScreen(props: Props): ReactElement {
  const [joiningFailed, setJoiningFailed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { invitationCode } = useParams<UrlParameters>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { isLoggingIn, isLoggedIn } = useSelector(selectAuthState);

  // The user is logged in.
  if (!isLoggingIn && !isLoggedIn) {
    history.push('/signup');
  }

  useEffect(() => {
    if (joiningFailed) {
      return;
    }

    if (invitationCode) {
      dispatch(setInvitationToken(invitationCode, false));
    }

    if (!isLoggingIn && !isLoggedIn) {
      return;
    }

    const joinFamilyRequestBody: JoinFamilyActionPayload = {
      inviteCode: invitationCode,
      inviteCodeIsGeneralAccess: true,
    };

    const joinFamilyCallbacks = {
      successCallback: () => {
        setTimeout(() => {
          dispatch(
            showSuccessSnackbar(
              t('acceptInvitationScreen-joining-family-successful'),
              true,
            ),
          );
          setIsLoading(false);

          history.push('/family');
        }, 1000);
      },
      errorCallback: () => {
        setIsLoading(false);
        setJoiningFailed(true);
        dispatch(
          showErrorSnackbar(
            t('acceptInvitationScreen-joining-family-failed-error'),
            true,
          ),
        );
      },
    };

    setIsLoading(true);

    // Join a family using the code
    dispatch(joinFamilyRequest(joinFamilyRequestBody, joinFamilyCallbacks));
  }, [
    t,
    dispatch,
    history,
    invitationCode,
    isLoggingIn,
    isLoggedIn,
    joiningFailed,
  ]);

  return (
    <FullWidthBackground imageSource={fzColorfulBackgroundImage}>
      <ContentPageTemplate>
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <CardHeader
            title={t('acceptInvitationScreen-headline')}
            style={{ textAlign: 'center' }}
          ></CardHeader>
          <CardContent className={classes.cardContentContainer}>
            {!joiningFailed && (
              <FZTypography variant="body1">
                {t('acceptInvitationScreen-message')}
              </FZTypography>
            )}

            {isLoading && (
              <FZSpinner
                color={colors.secondary}
                statusMessage={t('acceptInvitationScreen-loading-message')}
              ></FZSpinner>
            )}

            {joiningFailed && (
              <>
                <FZTypography variant="body1" style={{ maxWidth: '50%' }}>
                  {t('acceptInvitationScreen-joining-family-failed-error')}
                </FZTypography>

                <FZButton
                  backgroundColor={colors.quartiary}
                  variant="contained"
                  label={t(
                    'acceptInvitationScreen-joining-family-failed-go-back-to-login-link-label',
                  )}
                  onClick={() => {
                    history.push('/login');
                  }}
                  style={{ marginTop: 16 }}
                ></FZButton>
              </>
            )}
          </CardContent>
        </Card>
      </ContentPageTemplate>
    </FullWidthBackground>
  );
}
