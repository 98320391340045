import { makeStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import passwordIllustration from '../../../../assets/images/password_illustration.png';
import { updatePasswordRequest } from '../../../../common/redux/login/login.actions';
import { UpdatePasswordDto } from '../../../../common/redux/login/types';
import { ActionCallbacks } from '../../../../common/redux/main/types';
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../../common/redux/ui/ui.actions';
import FZEditSettingPageTemplate from '../../../04_templates/EditSettingPageTemplate/FZEditSettingPageTemplate';
import useConfig from '../../../Hooks/useConfig';
import EditPasswordForm, { EditPasswordFormValues } from './EditPasswordForm';

interface Props {}

const useStyles = makeStyles({
  imageClassName: {
    paddingLeft: 60,
    paddingTop: 30,
    width: '70%',
    height: 'auto',
    //transform: 'scaleX(-1)',
    float: 'right',
  },
});

export default function EditPasswordScreen(): ReactElement {
  const { t } = useTranslation();
  const editPasswordEnabled: boolean = useConfig().FEATURE_FLAGS.USER
    .REACT_APP_FEATURE_FLAG_UPDATE_PASSWORD_ENABLED;
  const history = useHistory();
  const dispatch = useDispatch();
  const handleFinishEditingButtonClick = React.useCallback(
    (
      values: EditPasswordFormValues,
      helpers: FormikHelpers<EditPasswordFormValues>,
    ) => {
      const updatePasswordRequestBody: UpdatePasswordDto = {
        oldPassword: values.oldPassword,
        newPassword: values.repeatedNewPassword,
      };

      const actionCallbacks: ActionCallbacks = {
        successCallback: () => {
          helpers.setSubmitting(false);
          dispatch(
            showSuccessSnackbar(
              t('editPasswordScreen-edit-password-success-message'),
              true,
            ),
          );
          history.push('/profile');
        },
        errorCallback: () => {
          helpers.setSubmitting(false);
          dispatch(
            showErrorSnackbar(
              t('editPasswordScreen-edit-password-error-message'),
              true,
            ),
          );
        },
      };

      helpers.setSubmitting(true);
      dispatch(
        updatePasswordRequest(updatePasswordRequestBody, actionCallbacks),
      );
    },
    [history, dispatch, t],
  );
  const classes = useStyles();
  return (
    <FZEditSettingPageTemplate
      backgroundImageSource={passwordIllustration}
      headline={t('editPasswordScreen-headline')}
      subheadline={t('editPasswordScreen-subheadline')}
      backDestinationLabel={t('editPasswordScreen-backdestination-label')}
      imageClassName={classes.imageClassName}
    >
      <EditPasswordForm
        onSubmit={handleFinishEditingButtonClick}
        formEnabled={editPasswordEnabled}
      ></EditPasswordForm>
    </FZEditSettingPageTemplate>
  );
}
