import { CREATED, OK, UNAUTHORIZED } from 'http-status-codes';
import jwtDecode from 'jwt-decode';
import {
  call,
  getContext,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { User, UserData } from '../../models/User';
import AccountService from '../../services/AccountService';
import ApiUtilities from '../../api/ApiUtilities';
import { setFirstLoadFinished } from '../app/app.actions';
import { fetchOwnFamiliesRequest } from '../families/family.actions';
import { ActionCallbacks } from '../main/types';
import {
  fetchUserSettingsRequest,
  userActionTypes,
} from '../users/user.actions';
import { selectLoggedInAccountUid } from '../users/user.selectors';
import {
  AccountActivationRequestAction,
  authenticationActionTypes,
  InitiateForgotPasswordAction,
  initiateForgotPasswordError,
  initiateForgotPasswordFulfill,
  initiateForgotPasswordSuccess,
  loginError,
  loginSuccess,
  logoutFailure,
  logoutSuccess,
  RegisterUserAccountRequestAction,
  registrationFailure,
  ResetPasswordWithRecoveryTokenAction,
  resetPasswordWithRecoveryTokenError,
  resetPasswordWithRecoveryTokenFulfill,
  resetPasswordWithRecoveryTokenSuccess,
  SignInSuccessAction,
  UpdatePasswordAction,
  updatePasswordError,
  updatePasswordFulfill,
  updatePasswordSuccess,
  updatePrimaryEmailError,
  UpdatePrimaryEmailRequestAction,
  updatePrimaryEmailSuccess,
} from './login.actions';
import { selectAccessToken } from './login.selectors';
import { ActivateAccountDto, JwtPayload, LoginDto } from './types';

//#region Login
function* loginSaga(action: any): Generator<any, any, any> {
  const loginDto: LoginDto = action.payload;
  const callbacks: ActionCallbacks = action.meta;

  try {
    // Get Service from redux-saga-context
    const acountService: AccountService = yield getContext('accountService');

    // Perform async side effect
    const response = yield call(
      acountService.login,
      loginDto.username,
      loginDto.password,
    );

    // Check if the response yields a successful status
    if (response && response.status === CREATED) {
      const userinfo: JwtPayload = jwtDecode(response.data.accessToken);
      const userData: UserData = {
        email: userinfo.username,
        accountUid: userinfo.sub,
        firstName: userinfo.firstname,
        customerUid: userinfo.customerUid,
      };

      const currentlyLoggedInUser = new User(userData);

      yield put(
        loginSuccess(
          response.data.accessToken,
          userinfo.exp,
          null,
          currentlyLoggedInUser,
        ),
      );

      if (callbacks.successCallback) {
        yield call(callbacks.successCallback);
      }
    } else {
      yield put(loginError(response.data));

      if (callbacks.errorCallback) {
        yield call(callbacks.errorCallback);
      }
    }
  } catch (error) {
    yield put(loginError(error.response.data));

    if (callbacks.errorCallback) {
      yield call(callbacks.errorCallback);
    }
  }
}

function* watchLoginRequest() {
  yield takeLatest(authenticationActionTypes.LOGIN_REQUEST, loginSaga);
}

//#endregion

//#region Misc

function* fetchFamiliesAndSwitchToDefaultActiveFamilySaga() {
  yield put(fetchOwnFamiliesRequest());
}

//#endregion

//#region  Initiate forgot password

function* initiateForgotPasswordFlowSaga(action: InitiateForgotPasswordAction) {
  try {
    // Get Service from redux-saga-context
    const acountService: AccountService = yield getContext('accountService');
    // Perform async side effect

    const response = yield call(
      acountService.initiateForgotPasswordFlow,
      action.payload.requestBody.primaryUserEmail,
    );

    // Check if the response yields a successful status
    if (response && response.status === CREATED) {
      yield put(initiateForgotPasswordSuccess(response.data));
      if (action.payload.successCallback) {
        yield call(action.payload.successCallback);
      }
    } else {
      yield put(initiateForgotPasswordError(response.data));

      if (action.payload.errorCallback) {
        yield call(action.payload.errorCallback);
      }
    }
  } catch (error) {
    yield put(initiateForgotPasswordError(error.response.data));

    if (action.payload.errorCallback) {
      yield call(action.payload.errorCallback);
    }
  } finally {
    yield put(initiateForgotPasswordFulfill());
  }
}

function* watchInitiateForgotPasswordFlow() {
  yield takeLatest(
    authenticationActionTypes.INITIATE_FORGOT_PASSWORD_REQUEST,
    initiateForgotPasswordFlowSaga,
  );
}

//#endregion

//#region Reset Password with Recovery token

function* resetPasswordWithRecoveryTokenSaga(
  action: ResetPasswordWithRecoveryTokenAction,
): Generator<any, any, any> {
  try {
    // Get Service from redux-saga-context
    const acountService: AccountService = yield getContext('accountService');
    // Perform async side effect

    const response = yield call(
      acountService.resetPasswordWithRecoveryToken,
      action.payload.requestBody,
    );

    // Check if the response yields a successful status
    if (response && response.status === CREATED) {
      yield put(resetPasswordWithRecoveryTokenSuccess(response.data));
      if (action.payload.successCallback) {
        yield call(action.payload.successCallback);
      }
    } else {
      yield put(resetPasswordWithRecoveryTokenError(response.data));

      if (action.payload.errorCallback) {
        yield call(action.payload.errorCallback);
      }
    }
  } catch (error) {
    yield put(resetPasswordWithRecoveryTokenError(error.response.data));

    if (action.payload.errorCallback) {
      yield call(action.payload.errorCallback);
    }
  } finally {
    yield put(resetPasswordWithRecoveryTokenFulfill());
  }
}

function* watchResetPasswordWithRecoveryToken() {
  yield takeLatest(
    authenticationActionTypes.RESET_PASSWORD_WITH_RECOVERY_TOKEN_REQUEST,
    resetPasswordWithRecoveryTokenSaga,
  );
}

//#endregion

//#region Update Password with active user login

function* updatePasswordSaga(
  action: UpdatePasswordAction,
): Generator<any, any, any> {
  try {
    // Get Service from redux-saga-context
    const acountService: AccountService = yield getContext('accountService');
    // Perform async side effect
    console.log(action);
    const accessToken = yield select(selectAccessToken);
    const accountUid = yield select(selectLoggedInAccountUid);
    const response = yield call(
      acountService.updateUserPassword,
      accountUid,
      action.payload.oldPassword,
      action.payload.newPassword,
      accessToken,
    );

    // Check if the response yields a successful status
    if (response && response.status === OK) {
      yield put(updatePasswordSuccess(response.data));
      yield call(ApiUtilities.callSuccessCallbackIfDefined, action);
    } else {
      yield call(ApiUtilities.callErrorCallbackIfDefined, action);
    }
  } catch (error) {
    const handledError = ApiUtilities.handleApiError(error);
    yield put(updatePasswordError(handledError));
    yield call(ApiUtilities.callErrorCallbackIfDefined, action, handledError);
  } finally {
    yield put(updatePasswordFulfill());
  }
}

function* watchUpdatePassword() {
  yield takeLatest(
    authenticationActionTypes.UPDATE_PASSWORD_REQUEST,
    updatePasswordSaga,
  );
}

//#endregion

//#region Update Primary Email with veriication token

function* updatePrimaryEmail(action: UpdatePrimaryEmailRequestAction) {
  try {
    // Get Service from redux-saga-context
    const acountService: AccountService = yield getContext('accountService');

    // Perform async side effect
    const response = yield call(
      acountService.updateUserPrimaryEmail,
      action.payload.verificationToken,
    );

    // Check if the response yields a successful status
    if (response && response.status === OK) {
      yield put(updatePrimaryEmailSuccess());
      if (action.meta.successCallback) {
        yield call(action.meta.successCallback);
      }
    }
  } catch (error) {
    yield put(updatePrimaryEmailError(error));

    if (action.meta.errorCallback) {
      yield call(action.meta.errorCallback);
    }
  }
}

function* watchUpdatePrimaryEmail() {
  yield takeLatest(
    authenticationActionTypes.UPDATE_PRIMARY_EMAIL_WITH_VERIFICATION_TOKEN_REQUEST,
    updatePrimaryEmail,
  );
}

//#endregion

//#region  Register / Signup

function* signUpSaga(action: RegisterUserAccountRequestAction) {
  console.log('calling with action body');
  console.log(action);
  let response;
  try {
    const accountService: AccountService = yield getContext('accountService');

    response = yield call(accountService.postAccount, action.payload);

    if (response && response.status === CREATED) {
      action.meta.successCallback();
      yield put({
        type: authenticationActionTypes.REGISTRATION_SUCCESS,
      });
    }
  } catch (error) {
    const handledError = ApiUtilities.handleApiError(error);

    yield call(
      action.meta.errorCallback,
      handledError?.statusCode,
      handledError?.errorCode,
      handledError?.errorSummary,
    );

    yield put(registrationFailure(handledError));
  }
}

function* watchSignupRequestSaga() {
  yield takeLatest(authenticationActionTypes.REGISTRATION_REQUEST, signUpSaga);
}

//#endregion

//#region Activate Account

function* activateAccountSaga(action: AccountActivationRequestAction) {
  try {
    const accountService: AccountService = yield getContext('accountService');
    const activateAccountPayload: ActivateAccountDto = action.payload;

    const response = yield call(
      accountService.activateAccount,
      activateAccountPayload.customerUid,
      activateAccountPayload.activationToken,
    );

    if (response && response.status === CREATED) {
      action.meta.successCallback();
      yield put({
        type: authenticationActionTypes.ACTIVATE_ACCOUNT_SUCCESS,
      });
    }
  } catch (error) {
    action.meta.errorCallback();
    yield put({
      type: authenticationActionTypes.ACTIVATE_ACCOUNT_FAILURE,
      payload: {
        error,
      },
    });
  }
}
function* watchActivateAccount() {
  yield takeEvery(
    authenticationActionTypes.ACTIVATE_ACCOUNT_REQUEST,
    activateAccountSaga,
  );
}

//#endregion

//#region Login / Logout

function* signInSuccessSaga(action: SignInSuccessAction) {
  yield put(fetchUserSettingsRequest());

  while (yield take(userActionTypes.FETCH_USER_SETTINGS_SUCCESS)) {
    yield call(fetchFamiliesAndSwitchToDefaultActiveFamilySaga);
  }

  if (action.payload.successCallback) {
    yield call(action.payload.successCallback);
  }
  yield put(setFirstLoadFinished(true));
}

function* watchSignInSuccess() {
  yield takeLatest(authenticationActionTypes.LOGIN_SUCCESS, signInSuccessSaga);
}

function* signoutSaga(action: any): Generator<any, any, any> {
  try {
    // Get Service from redux-saga-context
    const acountService: AccountService = yield getContext('accountService');

    const accessToken = yield select(selectAccessToken);
    // Perform async side effect
    const response = yield call(acountService.logout, accessToken);

    // Check if the response yields a successful status
    if ((response && response.status === OK) || response.status === CREATED) {
      yield put(logoutSuccess());
      if (action.meta?.successCallback) {
        yield call(action.meta.successCallback);
      }
    } else {
      yield put(logoutFailure(response.data));

      if (action.meta?.errorCallback) {
        yield call(action.meta.errorCallback);
      }
    }
  } catch (error) {
    const handledError = ApiUtilities.handleApiError(error);

    if (handledError.status === UNAUTHORIZED) {
      yield put(logoutSuccess());
      if (action.meta?.successCallback) {
        yield call(action.meta.successCallback);
      }
    } else {
      yield put(logoutFailure(handledError));

      if (action.meta?.errorCallback) {
        yield call(action.meta.errorCallback);
      }
    }
  }
}

function* watchSignoutRequest() {
  yield takeLatest(authenticationActionTypes.LOGOUT_REQUEST, signoutSaga);
}

function* signoutSuccessSaga() {
  //yield put(setShouldBlockNavigation(false));
}

function* watchSignOutSuccess() {
  yield takeLatest(
    authenticationActionTypes.LOGOUT_SUCCESS,
    signoutSuccessSaga,
  );
}

//#endregion

const loginSagas = {
  watchLoginRequest,
  watchSignoutRequest,
  watchSignupRequestSaga,
  watchSignInSuccess,
  watchSignOutSuccess,
  watchUpdatePrimaryEmail,
  watchInitiateForgotPasswordFlow,
  watchResetPasswordWithRecoveryToken,
  watchUpdatePassword,
  watchActivateAccount,
};

export default loginSagas;
