import { AnyAction } from 'redux';
import {
  FetchPresignedUrlRequestSuccessAction,
  imageActionTypes,
} from './image.actions';
import { ImageReducerState } from './types';
import { authenticationActionTypes } from '../login/login.actions';

const initialState: ImageReducerState = {
  cachedUrls: {},
};

const image = (state = initialState, action: AnyAction): ImageReducerState => {
  switch (action.type) {
    case imageActionTypes.FETCH_PRESIGNED_DOWNLOAD_URL_REQUEST: {
      return {
        ...state,
      };
    }

    case imageActionTypes.FETCH_PRESIGNED_DOWNLOAD_URL_SUCCESS: {
      const typedAction = action as FetchPresignedUrlRequestSuccessAction;
      // Write the new image url to the dictionary
      state.cachedUrls[typedAction.payload.imageUuid] =
        typedAction.payload.presignedDownloadUrl;

      return { ...state };
    }

    case imageActionTypes.FETCH_PRESIGNED_DOWNLOAD_URL_FAILURE: {
      return {
        ...state,
      };
    }

    case authenticationActionTypes.LOGOUT_SUCCESS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default image;
