import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import FeaturedVideoRoundedIcon from '@material-ui/icons/FeaturedVideoRounded';
import ViewColumnRoundedIcon from '@material-ui/icons/ViewColumnRounded';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { StyleRules } from '@material-ui/styles/withStyles';
import memoizeOne from 'memoize-one';
import React, { Component, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import TrackVisibility from 'react-on-screen';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, compose } from 'redux';
import { Article, ArticleType } from '../../../common/models/Article';
import { Family } from '../../../common/models/Family';
import { Newspaper } from '../../../common/models/Newspaper';
import { selectFirstLoadFinished } from '../../../common/redux/app/app.selectors';
import {
  fetchOwnArticlesRequest,
  postArticleDraftRequest,
  setCurrentlyEditedArticle,
} from '../../../common/redux/articles/article.actions';
import {
  selectOwnArticleDrafts,
  selectOwnArticles,
} from '../../../common/redux/articles/article.selectors';
import {
  fetchFamilyRequest,
  fetchLastPublishedNewspaperForFamilyRequest,
  fetchUserContributionsInNewspaperOfFamilyRequest,
  setEndOfReleaseCycleReminderHasFlashed,
  setFamilyHasReachedEndOfReleaseCycle,
} from '../../../common/redux/families/family.actions';
import {
  selectCurrentlyActiveFamily,
  selectCurrentNewspaperOfCurrentlyActiveFamily,
  selectIfCurrentlyActiveFamilyIsDeleted,
  selectLastPublishedNewspaperOfCurrentlyActiveFamily,
} from '../../../common/redux/families/family.selectors';
import { ApplicationState } from '../../../common/redux/main/types';
import { showInfoSnackbar } from '../../../common/redux/ui/ui.actions';
import { UserProfile } from '../../../common/redux/users/types';
import {
  selectDefaultAuthorPseudonymForActiveUser,
  selectLoggedInUser,
  selectUserProfile,
} from '../../../common/redux/users/user.selectors';
import colors from '../../00_Constants/colors';
import {
  ARTICLES,
  CREATE_NEW_ARTICLE,
  START_EDITING_ARTICLE,
} from '../../00_Constants/trackerConstants';
import FZEmptyListPlaceholder from '../../01_atoms/FZEmptyListPlaceholder/FZEmptyLIstPlaceholder';
import FZPageContainer from '../../01_atoms/FZPageContainer/FZPageContainer';
import FZSpinner from '../../01_atoms/FZSpinner/FZSpinner';
import FZCountdown from '../../02_molecules/FZCountdown/FZCountdown';
import FZAppBar from '../../03_organisms/FZAppBar/FZAppBar';
import FZArticleListItem from '../../03_organisms/FZArticleListItem/FZArticleListItem';
import { withConfig } from '../../HOCs/withConfig';
import { withHooks, WithHooksProps } from '../../HOCs/withHooks';
import { withServices } from '../../HOCs/withServices';
import './HomeScreen.css';

type RouterProps = {
  shouldRefetchCurrentNewspaper: boolean;
  shouldRefetchOwnArticles: boolean;
};

type Props = DispatchProp<AnyAction> &
  WithHooksProps &
  RouteComponentProps<{}, any, RouterProps | any> & {
    firstAppLoadFinished: boolean;
    auth: any;
    history: any;
    classes?: any;
    isFetchingArticles: boolean;
    loggedInUserFirstName: string;
    ownArticles: Article[];
    ownArticleDrafts: Article[];

    isFetchingOwnArticles: boolean;
    isCreatingDraft: boolean;
    creatingDraftFulfilledSuccessfully: boolean;

    isFetchingArticlesOfLastEdition: boolean;
    lastEditionArticles: Article[];

    defaultAuthornameForUser: string;

    currentNewspaerOfCurrentlyActiveFamily: Newspaper;
    lastPublishedNewspaperOfCurrentlyActiveFamily: Newspaper;

    currentlyActiveFamily: Family | null;
    currentlyActiveFamilyShallBeDeleted: boolean;
    endOfReleaseCycleReminderHasFlashed: boolean;

    userProfile: UserProfile;

    // width of the viewport as provided by material UIs withWidth HoC
    width?: any;
    // HoC Procs
    t?: any;
  };

type State = {
  selectedArticleUuid: string | null;
  selectedArticleContextMenuEntry: string;
  articleContextMenuAnchor: any;
  activeBottomNavTab: number;
  addArticleButtonPressRegistered: boolean;
  addArticleSpeedDialOpen: boolean;
};

const styles: (theme: Theme) => StyleRules<object> = theme =>
  createStyles({
    pageContainer: {},

    createArticleSpeedDial: {
      position: 'fixed',
      bottom: 88,
      right: 16,

      [theme.breakpoints.up('md')]: {
        bottom: 24,
      },
    },

    createArticleFAB: {},

    createArticleFabOpen: {
      /*  backgroundColor: colors.disabled,
      color: colors.white, */
    },

    listContainer: {
      //borderBottomWidth: 2,
      //borderBottomColor: 'black',
      //borderBottomStyle: 'solid',
      //maxWidth: '96%',
      //marginLeft: '16px',
      marginTop: '15px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
    },

    articleListContainer: {
      paddingLeft: 16,
      paddingRight: 16,
      //marginTop: 16,
      marginBottom: 32,
    },

    horizontalScrollArticleListContainer: {
      paddingLeft: 16,
      paddingRight: 16,
      //marginTop: 16,
      marginBottom: 48,

      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      overflowX: 'auto',

      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        height: 0,
        width: 0,
      },
    },

    sectionContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      overflowX: 'hidden',
    },

    speedDialRoot: {
      alignItems: 'flex-end',
    },

    speedDialAction: {
      padding: 8,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      textTransform: 'capitalize',
    },

    speedDialActionFab: {},

    speedDialTooltip: {
      '&:hover': {
        backgroundColor: colors.backgroundDark,
      },
    },
  });

class HomeScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedArticleUuid: null,
      articleContextMenuAnchor: null,
      selectedArticleContextMenuEntry: '',
      activeBottomNavTab: 1,
      addArticleButtonPressRegistered: false,
      addArticleSpeedDialOpen: false,
    };

    this.bindFunctions = this.bindFunctions.bind(this);
    this.bindFunctions();
  }

  bindFunctions(): void {
    this.handleAddArticleButtonClick = this.handleAddArticleButtonClick.bind(
      this,
    );

    this.handleEditArticleButtonClick = this.handleEditArticleButtonClick.bind(
      this,
    );

    this.renderFab = this.renderFab.bind(this);

    this.handleCountdownFinished = this.handleCountdownFinished.bind(this);
  }

  componentDidMount(): void {
    const {
      dispatch,
      currentlyActiveFamily,
      endOfReleaseCycleReminderHasFlashed,
      matomoAnalyticsTracker,
      location,
      t,
    } = this.props;

    matomoAnalyticsTracker.trackPageView({
      documentTitle: 'Article Screen', // optional
    });

    if (location?.state?.shouldRefetchOwnArticles === true) {
      dispatch(fetchOwnArticlesRequest());
    }

    if (currentlyActiveFamily) {
      dispatch(fetchFamilyRequest(currentlyActiveFamily.uuid));

      if (currentlyActiveFamily.nextReleaseDate < new Date()) {
        if (!endOfReleaseCycleReminderHasFlashed) {
          dispatch(
            showInfoSnackbar(t('release-cycle-reached-info-message'), false),
          );
          dispatch(
            setEndOfReleaseCycleReminderHasFlashed(currentlyActiveFamily, true),
          );
        }
      } else {
        dispatch(
          setFamilyHasReachedEndOfReleaseCycle(currentlyActiveFamily, false),
        );
      }

      // If the page that navigates here defines which parts shall be refetched, do it accordingly
      if (
        location?.state?.shouldRefetchCurrentNewspaper === true &&
        currentlyActiveFamily?.uuid
      ) {
        dispatch(fetchUserContributionsInNewspaperOfFamilyRequest());
      }

      if (
        location?.state?.shouldRefetchLatestPublishedNewspaper === true &&
        currentlyActiveFamily?.uuid
      ) {
        dispatch(fetchLastPublishedNewspaperForFamilyRequest());
      }

      if (!location?.state && currentlyActiveFamily?.uuid) {
        dispatch(fetchLastPublishedNewspaperForFamilyRequest());
        dispatch(fetchUserContributionsInNewspaperOfFamilyRequest());
        dispatch(fetchOwnArticlesRequest());
      }
    }
  }

  componentDidUpdate(oldProps: Props) {
    // If the page that navigates here defines which parts shall be refetched, do it accordingly
    if (
      oldProps.location?.state?.shouldRefetchCurrentNewspaper === true &&
      oldProps.currentlyActiveFamily?.uuid !==
        this.props.currentlyActiveFamily?.uuid
    ) {
      this.props.dispatch(fetchUserContributionsInNewspaperOfFamilyRequest());
    }

    if (
      oldProps.location?.state?.shouldRefetchLatestPublishedNewspaper ===
        true &&
      oldProps.currentlyActiveFamily?.uuid !==
        this.props.currentlyActiveFamily?.uuid
    ) {
      this.props.dispatch(fetchLastPublishedNewspaperForFamilyRequest());
    }
  }

  /**
   * Handles a click onto the edit-article button
   * @param {Article} articleThatShallbeEdited - The article that shall be edited.
   */
  handleEditArticleButtonClick(articleThatShallbeEdited: Article): void {
    const { dispatch, history, matomoAnalyticsTracker } = this.props;

    matomoAnalyticsTracker.trackEvent({
      category: ARTICLES,
      action: START_EDITING_ARTICLE,
      href: '/home',
      name: 'EDIT_ARTICLE_BUTTON_ON_ARTICLE_PREVIEW',
    });
    dispatch(setCurrentlyEditedArticle(articleThatShallbeEdited));
    history.push('/write-article');
  }

  /**
   * Handles a click onto the "add article" button, which creates a new
   * article.
   */
  handleAddArticleButtonClick(addArticleParams: {
    answerTo: Article | null;
    articleType: ArticleType;
  }): void {
    const {
      dispatch,
      matomoAnalyticsTracker,
      defaultAuthornameForUser,
    } = this.props;

    const emptyDraftArticleToPost: Article = new Article({
      title: '',
      description: '',
      freelyAssignedAuthor: defaultAuthornameForUser || '',
      answerTo: addArticleParams.answerTo || null,
      articleType: addArticleParams.articleType,
    });

    this.setState({
      addArticleButtonPressRegistered: true,
    });

    dispatch(postArticleDraftRequest(emptyDraftArticleToPost));
    matomoAnalyticsTracker.trackEvent({
      category: ARTICLES,
      action: CREATE_NEW_ARTICLE,
      href: '/home',
    });
  }

  /**
   * Handles the selection of a menu item in the context menu attached to each article preview
   */
  handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    menuItemKey: string,
  ): void => {
    this.setState({
      articleContextMenuAnchor: null,
      selectedArticleContextMenuEntry: menuItemKey,
    });
  };

  /**
   * Handles closing the context menu attached to each article preview
   */
  handleArticleContextMenuClose = (): void => {
    this.setState({ articleContextMenuAnchor: null });
  };

  /**
   * Handles opening the context menu attached to each article preview
   */
  handleArticleContextMenuOpened = (
    event: React.MouseEvent<HTMLElement>,
    articleUuid: string,
  ): void => {
    return; /* 
    if (event.type === 'contextmenu') {
      event.preventDefault();
      this.setState({ articleContextMenuAnchor: event.currentTarget });
    } */
  };

  /**
   * Handles the event that a release countdown of a family has finished.
   */
  handleCountdownFinished(): void {
    this.props.dispatch(
      setFamilyHasReachedEndOfReleaseCycle(
        this.props.currentlyActiveFamily,
        true,
      ),
    );
  }

  /**
   * Renders the Floating Action Button to add a new article.
   */
  renderFab(): ReactNode {
    const { classes, t } = this.props;

    return (
      <SpeedDial
        /*  hidden={!currentlyActiveFamily} */
        className={classes.createArticleSpeedDial}
        ariaLabel="write an article"
        icon={
          <>
            <SpeedDialIcon />
            {t('homeScreen-addArticleButton-label')}
          </>
        }
        FabProps={{
          color: 'secondary',
          variant: 'extended',
        }}
        onClose={() => {
          this.setState({ addArticleSpeedDialOpen: false });
        }}
        onOpen={() => {
          this.setState({ addArticleSpeedDialOpen: true });
        }}
        open={this.state.addArticleSpeedDialOpen}
        classes={{
          root: classes.speedDialRoot,
          fab: this.state.addArticleSpeedDialOpen
            ? classes.createArticleFabOpen
            : classes.createArticleFAB,
        }}
      >
        <SpeedDialAction
          key={'addLongArticle'}
          icon={
            <span className={classes.speedDialAction}>
              {t('homescreen-speedDial-add-long-article-button-label')}
              <ViewColumnRoundedIcon style={{ marginLeft: 8 }} />
            </span>
          }
          classes={{
            fab: classes.speedDialActionFab,
            staticTooltipLabel: classes.speedDialTooltip,
          }}
          onClick={() => {
            this.handleAddArticleButtonClick({
              answerTo: null,
              articleType: ArticleType.LONG_ARTICLE,
            });
          }}
          tooltipTitle={t(
            'homescreen-speedDial-add-long-article-button-tooltip',
          )}
          FabProps={{
            disabled: false,
            variant: 'extended',
          }}
        />

        <SpeedDialAction
          key={'addShortArticle'}
          icon={
            <span className={classes.speedDialAction}>
              {t('homescreen-speedDial-add-short-article-button-label')}
              <FeaturedVideoRoundedIcon style={{ marginLeft: 8 }} />
            </span>
          }
          classes={{
            staticTooltipLabel: classes.speedDialTooltip,
          }}
          onClick={() => {
            this.handleAddArticleButtonClick({
              answerTo: null,
              articleType: ArticleType.SHORT_ARTICLE,
            });
          }}
          tooltipTitle={t(
            'homescreen-speedDial-add-short-article-button-tooltip',
          )}
          FabProps={{
            disabled: false,

            variant: 'extended',
          }}
        />
      </SpeedDial>
    );
  }

  render(): ReactNode {
    const {
      classes,
      history,
      currentlyActiveFamily,
      currentlyActiveFamilyShallBeDeleted,
      isFetchingArticlesOfLastEdition,
      lastPublishedNewspaperOfCurrentlyActiveFamily,
      currentNewspaerOfCurrentlyActiveFamily,
      creatingDraftFulfilledSuccessfully,
      isFetchingOwnArticles,
      ownArticles,
      isCreatingDraft,
      width,
      t,
    } = this.props;

    if (
      this.state.addArticleButtonPressRegistered &&
      creatingDraftFulfilledSuccessfully
    ) {
      history.push('/write-article');
    }

    const memoizedViewPortIsMobile = memoizeOne(isWidthDown);
    const viewportIsMobile = memoizedViewPortIsMobile('sm', width, true);

    return (
      <FZPageContainer>
        <FZAppBar activeTab={1}></FZAppBar>

        <Container style={{ padding: 0 }}>
          {isCreatingDraft && (
            <FZSpinner
              modal={true}
              color={colors.accent}
              statusMessage={t('homeScreen-create-draft-article-status')}
            />
          )}

          <Box display="flex" flexDirection="column">
            {currentlyActiveFamily && !currentlyActiveFamilyShallBeDeleted && (
              <Box alignSelf="center">
                <FZCountdown
                  date={currentlyActiveFamily.nextReleaseDate.toString()}
                  countdownFinishedCallback={this.handleCountdownFinished}
                />
              </Box>
            )}

            {/********************
             * Last Edition List
             *********************/}

            <TrackVisibility partialVisibility>
              {({ isVisible }) => {
                const lastEditionHeadline: JSX.Element = (
                  <Box width="40vw" className={classes.listContainer}>
                    <Typography variant="h6" style={{}}>
                      {t('homeScreen-currentEdition-headline')}
                    </Typography>
                  </Box>
                );

                const lastEditionMissingPlaceholder: JSX.Element = (!currentlyActiveFamily ||
                  !lastPublishedNewspaperOfCurrentlyActiveFamily ||
                  !lastPublishedNewspaperOfCurrentlyActiveFamily.articles ||
                  lastPublishedNewspaperOfCurrentlyActiveFamily?.articles
                    ?.length === 0) && (
                  <Grid item xs={10} md={6} lg={6}>
                    <FZEmptyListPlaceholder
                      title={t(
                        'homeScreen-lastEditionArticlesList-emptyListPlacerholder-title',
                      )}
                      content={t(
                        'homeScreen-lastEditionArticlesList-emptyListPlacerholder-content',
                      )}
                      cardStyle={{
                        minHeight: 212,
                        marginTop: 16,
                        marginBottom: 16,
                      }}
                    />
                  </Grid>
                );

                const lastEditionArticles: JSX.Element[] =
                  currentlyActiveFamily &&
                  lastPublishedNewspaperOfCurrentlyActiveFamily?.articles
                    ?.length > 0 &&
                  lastPublishedNewspaperOfCurrentlyActiveFamily?.articles?.map(
                    (article: Article, index: number) => {
                      return (
                        <FZArticleListItem
                          isVisible={isVisible}
                          ownerFamilyUuid={currentlyActiveFamily?.uuid}
                          publishedInNewspaperWithUuid={
                            lastPublishedNewspaperOfCurrentlyActiveFamily?.uuid
                          }
                          onClickItem={this.handleArticleContextMenuOpened}
                          id={index}
                          selected={false}
                          onLongPressItem={() => {
                            return;
                          }}
                          articleToRender={article}
                          onEditTriggered={null}
                          editable={false}
                          answerable={true}
                          onAnswerTriggered={() => {
                            this.handleAddArticleButtonClick({
                              answerTo: article,
                              articleType: ArticleType.SHORT_ARTICLE,
                            });
                          }}
                          key={
                            article.updatedAt! +
                            (article.photos && article.photos.length > 0
                              ? article.photos[0].uuid
                              : '')
                          }
                        />
                      );
                    },
                  );

                const lastEditionLoadingIndicator: JSX.Element = isFetchingArticlesOfLastEdition && (
                  <>
                    <CircularProgress color="secondary" />
                  </>
                );

                return (
                  <Box className={classes.sectionContainer}>
                    {lastEditionHeadline}
                    {lastEditionLoadingIndicator}
                    {lastEditionMissingPlaceholder}

                    {viewportIsMobile && (
                      <Grid
                        container
                        className={classes.horizontalScrollArticleListContainer}
                      >
                        {lastEditionArticles}
                      </Grid>
                    )}

                    {!viewportIsMobile && (
                      <Grid
                        container
                        justify="flex-start"
                        spacing={2}
                        className={classes.articleListContainer}
                      >
                        {lastEditionArticles}
                      </Grid>
                    )}
                  </Box>
                );
              }}
            </TrackVisibility>

            <Box alignSelf="center" style={{ width: '80%' }}>
              <Divider />
            </Box>

            {/** ******************
             * Next Edition List
             ********************* */}

            <TrackVisibility partialVisibility>
              {({ isVisible }) => {
                const nextEditionHeadline: JSX.Element = (
                  <Box width="40vw" className={classes.listContainer}>
                    <Typography variant="h6" style={{}}>
                      {t('homeScreen-nextEdition-headline')}
                    </Typography>
                  </Box>
                );

                const nextEditionMissingPlaceholder: JSX.Element = (!currentNewspaerOfCurrentlyActiveFamily ||
                  (currentNewspaerOfCurrentlyActiveFamily &&
                    currentNewspaerOfCurrentlyActiveFamily.articles &&
                    currentNewspaerOfCurrentlyActiveFamily.articles.length ===
                      0)) && (
                  <Grid item xs={10} md={6} lg={6}>
                    <FZEmptyListPlaceholder
                      title={t(
                        'homeScreen-nextEditionArticlesList-emptyListPlacerholder-title',
                      )}
                      content={t(
                        'homeScreen-nextEditionArticlesList-emptyListPlacerholder-content',
                      )}
                      cardStyle={{
                        minHeight: 212,
                        marginTop: 16,
                        marginBottom: 16,
                      }}
                    />
                  </Grid>
                );

                const nextEditionArticles: JSX.Element[] =
                  currentlyActiveFamily &&
                  currentNewspaerOfCurrentlyActiveFamily?.articles?.map(
                    (article: Article, index: number) => {
                      return (
                        <FZArticleListItem
                          isVisible={isVisible}
                          ownerFamilyUuid={currentlyActiveFamily?.uuid}
                          publishedInNewspaperWithUuid={
                            currentNewspaerOfCurrentlyActiveFamily?.uuid
                          }
                          onClickItem={this.handleArticleContextMenuOpened}
                          id={index}
                          selected={false}
                          onLongPressItem={() => {
                            return;
                          }}
                          articleToRender={article}
                          editable
                          onEditTriggered={this.handleEditArticleButtonClick}
                          answerable={false}
                          onAnswerTriggered={() => {
                            return;
                          }}
                          key={
                            article.updatedAt! +
                            (article.photos && article.photos.length > 0
                              ? article.photos[0].uuid
                              : '')
                          }
                        />
                      );
                    },
                  );

                const nextEditionLoadingIndicator: JSX.Element = (
                  <>
                    <CircularProgress color="secondary" />
                  </>
                );

                return (
                  <Box className={classes.sectionContainer}>
                    {nextEditionHeadline}
                    {isFetchingOwnArticles && nextEditionLoadingIndicator}
                    {nextEditionMissingPlaceholder}

                    {viewportIsMobile && (
                      /*          <ScrollContainer horizontal> */
                      <Grid
                        container
                        className={classes.horizontalScrollArticleListContainer}
                      >
                        {nextEditionArticles}
                      </Grid>
                      /*     </ScrollContainer> */
                    )}

                    {!viewportIsMobile && (
                      <Grid
                        container
                        justify="flex-start"
                        spacing={2}
                        className={classes.articleListContainer}
                      >
                        {nextEditionArticles}
                      </Grid>
                    )}
                  </Box>
                );
              }}
            </TrackVisibility>

            <Box alignSelf="center" style={{ width: '80%' }}>
              <Divider />
            </Box>
            {/** ******************
             * Draft List
             ********************* */}

            <TrackVisibility partialVisibility>
              {({ isVisible }) => {
                const draftListHeadline = (
                  <Box width="40vw" className={classes.listContainer}>
                    <Typography variant="h6">
                      {t('homeScreen-draftList-headline')}
                    </Typography>
                  </Box>
                );

                const draftListEmptyPlaceholder =
                  !ownArticles ||
                  (ownArticles?.length === 0 && (
                    <Grid item xs={10} md={6} lg={6}>
                      <FZEmptyListPlaceholder
                        title={t(
                          'homeScreen-draftArticlesList-emptyListPlacerholder-title',
                        )}
                        content={t(
                          'homeScreen-draftArticlesList-emptyListPlacerholder-content',
                        )}
                        cardStyle={{
                          minHeight: 212,
                          marginTop: 16,
                          marginBottom: 16,
                        }}
                      />
                    </Grid>
                  ));

                const draftListLoadingIndicator = (
                  <>
                    <CircularProgress color="secondary" />
                  </>
                );

                const draftArticleList = ownArticles.map(
                  (article: Article, index: number) => {
                    if (article.isDraft) {
                      return (
                        <FZArticleListItem
                          isVisible={isVisible}
                          ownerFamilyUuid={null}
                          publishedInNewspaperWithUuid={null}
                          onClickItem={this.handleArticleContextMenuOpened}
                          id={index}
                          selected={false}
                          onLongPressItem={() => {
                            return;
                          }}
                          articleToRender={article}
                          editable
                          onEditTriggered={this.handleEditArticleButtonClick}
                          answerable={false}
                          onAnswerTriggered={() => {
                            return;
                          }}
                          key={
                            article.updatedAt! +
                            (article.photos && article.photos.length > 0
                              ? article.photos[0].uuid
                              : '')
                          }
                        />
                      );
                    }
                    return null;
                  },
                );

                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginBottom: '24px', paddingBottom: '60px' }}
                  >
                    {draftListHeadline}
                    {draftListEmptyPlaceholder}
                    {isFetchingOwnArticles ? (
                      draftListLoadingIndicator
                    ) : (
                      <>
                        {viewportIsMobile && (
                          <Grid
                            container
                            className={
                              classes.horizontalScrollArticleListContainer
                            }
                            style={{ marginBottom: 42 }}
                          >
                            {draftArticleList}
                          </Grid>
                        )}

                        {!viewportIsMobile && (
                          <Grid
                            container
                            justify="flex-start"
                            spacing={2}
                            className={classes.articleListContainer}
                          >
                            {draftArticleList}
                          </Grid>
                        )}
                      </>
                    )}
                  </Box>
                );
              }}
            </TrackVisibility>

            {!isCreatingDraft && this.renderFab()}
          </Box>
        </Container>
      </FZPageContainer>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    firstAppLoadFinished: selectFirstLoadFinished(state),
    currentlyActiveFamilyShallBeDeleted: selectIfCurrentlyActiveFamilyIsDeleted(
      state,
    ),
    userProfile: selectUserProfile(state),
    isFetchingArticles: state.articles.isFetchingOwnArticles,
    ownArticles: selectOwnArticles(state),
    ownArticleDrafts: selectOwnArticleDrafts(state),
    currentlyActiveFamily: selectCurrentlyActiveFamily(state),
    loggedInUserFirstName: selectLoggedInUser(state)?.displayName,
    isCreatingDraft: state.articles.isCreatingDraft,
    creatingDraftFulfilledSuccessfully:
      state.articles.creatingArticleWasSuccessful,
    endOfReleaseCycleReminderHasFlashed:
      state.families.endOfReleaseCycleReminderHasFlashed,
    defaultAuthornameForUser: selectDefaultAuthorPseudonymForActiveUser(state),
    currentNewspaerOfCurrentlyActiveFamily: selectCurrentNewspaperOfCurrentlyActiveFamily(
      state,
    ),
    lastPublishedNewspaperOfCurrentlyActiveFamily: selectLastPublishedNewspaperOfCurrentlyActiveFamily(
      state,
    ),
  };
};

/* export default compose(
  connect(mapStateToProps),
  withWidth(),
  withTranslation,
  withRouter,
  withHooks,
  withStyles(styles),
)(HomeScreen); */

const composedComponent = compose(
  connect(mapStateToProps),
  withWidth(),
  withConfig,
  withServices,
  withRouter,
  withHooks,
  withTranslation(),
  withStyles(styles),
)(HomeScreen);

export default composedComponent as React.ComponentType<any>;
