import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import FZFieldLabel from '../FZFieldLabel/FZFieldLabel';

const useStyles = makeStyles({
  stepDenominatorContainer: {
    justifyContent: 'center',
  },

  activeSlideLabel: {
    marginTop: 8,
  },
});

interface Props {
  stepLabel: string;
  stepOfLabel: string;
  currentStep: number;
  totalSteps: number;
  style?: React.CSSProperties;
  className?: any;
}

export default function FZStepDenominator({
  stepLabel,
  stepOfLabel,
  currentStep,
  totalSteps,
  style,
  className,
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <FZFieldLabel
      label={`${stepLabel} ${currentStep + 1} ${stepOfLabel} ${totalSteps}`}
      className={clsx(classes.activeSlideLabel, className)}
      classes={{ container: classes.stepDenominatorContainer }}
      style={style}
    ></FZFieldLabel>
  );
}
