import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

interface Props {
  children: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
  className?: string;
}

const useStyles = makeStyles({
  pageContainer: {
    position: 'relative',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',

    height: '100%',
    overflowX: 'hidden',
    width: '100%',

    '&::-webkit-scrollbar': {
      height: 0,
      width: 0,
    },
  },
});

export default function FZPageContainer({
  children,
  style,
  className,
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <div className={clsx(classes.pageContainer, className)} style={style}>
      {children}
    </div>
  );
}
