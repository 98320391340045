import React from 'react';

import newMessageIllustration from '../../../../assets/images/new_message.png'; // Tell webpack this JS file uses this image
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import FZStatusPageTemplate from '../../../04_templates/StatusPageTemplate/FZStatusPage/FZStatusPageTemplate';
import fzColorfulBackgroundImage from '../../../../assets/images/FZ_colorful_background.jpg';
import { FullWidthBackground } from '../../../01_atoms/FullWidthBackground/FullWidthBackground';

interface Props {}

export const EditEmailConfirmationSentScreen = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const statusPageBody: JSX.Element = (
    <span>
      <Typography variant={'h5'}>
        {t('editEmailConfirmationSentScreen-email-sent-headline')}
      </Typography>
      <Typography variant={'body1'}>
        {t('editEmailConfirmationSentScreen-email-sent-message')}
      </Typography>
    </span>
  );

  const statusPageActions: JSX.Element = (
    <Button
      variant="outlined"
      onClick={() => {
        history.push('/profile');
      }}
    >
      {t('editEmailConfirmationSentScreen-back-to-profile-button-label')}
    </Button>
  );

  return (
    <FullWidthBackground
      imageSource={fzColorfulBackgroundImage}
      position="absolute"
    >
      <FZStatusPageTemplate
        imageSource={newMessageIllustration}
        body={statusPageBody}
        actions={statusPageActions}
      ></FZStatusPageTemplate>
    </FullWidthBackground>
  );
};
