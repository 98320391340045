export class DateTransformationService {
  browserLocales: string[];
  currentLocale: string;

  constructor() {
    this.browserLocales = this.getBrowserLocales();
    this.currentLocale = this.browserLocales[0];
  }

  getDateInCurrentBrowserLocale(date: Date) {
    return new Intl.DateTimeFormat(this.currentLocale, {}).format(date);
  }

  // from https://phrase.com/blog/posts/detecting-a-users-locale/
  getBrowserLocales(options = {}) {
    const defaultOptions = {
      languageCodeOnly: false,
    };

    const opt = {
      ...defaultOptions,
      ...options,
    };

    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;

    if (!browserLocales) {
      return undefined;
    }

    return browserLocales.map(locale => {
      const trimmedLocale = locale.trim();

      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
  }
}
