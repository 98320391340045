import { Container, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { ReactElement } from 'react';
import colors from '../../00_Constants/colors';
import FZNavigateBackButton from '../../01_atoms/FZNavigateBackButton/FZNavigateBackButton';
import FZAppBar from '../../03_organisms/FZAppBar/FZAppBar';

interface Props {
  backgroundImageSource: string;
  children: JSX.Element | JSX.Element[];
  headline?: string;
  subheadline?: string;
  backDestinationLabel?: string;
  imageClassName?: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    screenContainer: {
      marginTop: 70,
      [theme.breakpoints.down('md')]: {
        marginTop: 60,
      },

      marginBottom: 70,
    },

    headline: { color: colors.quartiary },
    subheadline: { marginBottom: 51, marginTop: 2 },

    hiddenOnMobile: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  };
});

export default function FZEditSettingPageTemplate({
  children,
  backgroundImageSource,
  headline,
  subheadline,
  backDestinationLabel,
  imageClassName,
}: Props): ReactElement {
  const classes = useStyles({});

  return (
    <React.Fragment>
      <FZAppBar
        navbarLeftContent={
          <FZNavigateBackButton backDestinationLabel={backDestinationLabel} />
        }
      ></FZAppBar>
      <Container>
        <Grid container className={classes.screenContainer} spacing={2}>
          <Grid xs={1} item></Grid>
          <Grid xs={10} item>
            <Grid container>
              <Grid xs={12} md={6} item>
                {headline && (
                  <Typography variant="h5" className={classes.headline}>
                    {headline}
                  </Typography>
                )}
                {subheadline && (
                  <Typography variant="body1" className={classes.subheadline}>
                    {subheadline}
                  </Typography>
                )}
                {children}
              </Grid>

              <Grid item xs={12} md={6} className={classes.hiddenOnMobile}>
                <img
                  alt=""
                  className={imageClassName}
                  style={{
                    opacity: 0.67,
                  }}
                  src={backgroundImageSource}
                ></img>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={1} item></Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
