import { Article, ArticleDto } from './Article';

export type NewspaperDto = {
  id?: string;
  uuid?: string;
  createdAt?: string;
  articles?: ArticleDto[];
};

export class Newspaper {
  id?: string;

  uuid?: string;

  createdAt?: string;

  articles?: Article[];

  /**
   * @param {NewspaperDto} newspaperDto - Object representing a newspaper for a family.
   */
  constructor(newspaperDto: NewspaperDto) {
    this.id = newspaperDto.id ? newspaperDto.id : undefined;
    this.uuid = newspaperDto.uuid ? newspaperDto.uuid : undefined;
    this.createdAt = newspaperDto.createdAt
      ? newspaperDto.createdAt
      : undefined;
    this.articles =
      newspaperDto.articles &&
      newspaperDto.articles.map((articleDto: ArticleDto) => {
        const newArticle = new Article(articleDto);

        return newArticle;
      });
  }

  // /**
  //  * Gets a localized string that describes how much time has elapsed since this article was created
  //  */
  // getTimeSinceCreated(): string {
  //   return FormattingUtilities.getTimeSinceCreated(this.createdAt);
  // }

  // /**
  //  * Converts an article into a JSON-representation
  //  */
  // toJson() {
  //   return JSON.stringify(this);
  // }

  // /**
  //  * Converts an article into back into an NewspaperDto Object
  //  */
  // toDto(): NewspaperDto {
  //   const newspaperDto: NewspaperDto = {
  //     id: this.id,
  //     uuid: this.uuid,

  //     createdAt: this.createdAt,
  //   };

  //   return newspaperDto;
  // }

  // /**
  //  * Gets a universally unique identifier (UUID) for an article that has been generated by the backend.
  //  */
  // getUniqueIdentifier() {
  //   return this.uuid;
  // } */
}
