import { AxiosResponse } from 'axios';
import { apiClient, API_CONFIG } from '../api';
import { CreateContactRequestDto } from '../redux/contactRequests/DTO/ContactRequestDto';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import ApiUtilities from '../api/ApiUtilities';
class ContactRequestService {
  /**
   * Creates a new contact-request.
   */
  async postContactRequest(
    payload: Partial<CreateContactRequestDto>,
  ): Promise<AxiosResponse<CreateContactRequestDto | undefined>> {
    try {
      const postContactRequestUri = API_CONFIG.CONTACT_SERVICE.POST_NEW_CONTACT_REQUEST_URI();

      const query = RequestQueryBuilder.create({
        fields: [],
        search: {},
        join: [],
        sort: [],
        page: 1,
        limit: 25,
        resetCache: true,
      }).query();

      const requestPayload: CreateContactRequestDto = {
        email: payload.email,
        emailHoneypot: payload.emailHoneypot,
        name: payload.name,
        nameHoneypot: payload.nameHoneypot,
        subject: payload.subject,
        subjectHoneypot: payload.subjectHoneypot,
        message: payload.message,
        messageHoneypot: payload.messageHoneypot,

        consentToDataProcessingAgreementGiven:
          payload.consentToDataProcessingAgreementGiven,
        dpaConsentTimestamp: new Date().toString(),
        dpaConsentIsForVersionOfDpa: payload.dpaConsentIsForVersionOfDpa,
        consentToTermsOfServiceGiven: payload.consentToTermsOfServiceGiven,
        tosConsentTimestamp: new Date().toString(),
        tosConsentIsForVersionOfToS: payload.tosConsentIsForVersionOfToS,
      };

      const postAccountResponse = await apiClient.post(
        postContactRequestUri,
        requestPayload,
        { params: { queryString: query } },
      );

      return postAccountResponse;
    } catch (err) {
      return ApiUtilities.handleApiError(err);
    }
  }
}
export default ContactRequestService;
