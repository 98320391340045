// src/Home.js
import {
  Container,
  Divider,
  Grid,
  isWidthDown,
  ListItem,
  ListItemText,
  Typography,
  withWidth,
  WithWidthProps,
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Component, default as React } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  animateScroll as scroll,
  Element as ScrollAnchor,
  Link,
} from 'react-scroll';
import { AnyAction, compose } from 'redux';
import fzColorfulBackgroundImage from '../../../../assets/images/FZ_colorful_background.jpg';
import landingpageBlurredBackgroundRaw from '../../../../assets/images/FZ_landingpage_background.png';
import Logo from '../../../../assets/images/FZ_Logo.jpg';
/* import testimonialImage0 from '../../../../assets/images/FZ_testimonial_image_0.jpg';
import testimonialImage1 from '../../../../assets/images/FZ_testimonial_image_1.jpg'; */

import productGridImage0 from '../../../../assets/images/cc0_landingpage_images/alexander-dummer-UH-xs-FizTk-unsplash.jpg';
/* import productGridImage1 from '../../../../assets/images/cc0_landingpage_images/ann-danilina-c_rnPbSYVFM-unsplash.jpg'; */
import productGridImage2 from '../../../../assets/images/cc0_landingpage_images/annie-spratt-c6mdgjKg_hE-unsplash.jpg';
import productGridImage3 from '../../../../assets/images/cc0_landingpage_images/carly-rae-hobbins-zNHOIzjJiyA-unsplash.jpg';
import productGridImage4 from '../../../../assets/images/cc0_landingpage_images/johnny-cohen-OxOxqLAWvE0-unsplash.jpg';
import productGridImage5 from '../../../../assets/images/cc0_landingpage_images/katarzyna-grabowska-sRAWQyoUiVQ-unsplash.jpg';
import productGridImage6 from '../../../../assets/images/cc0_landingpage_images/krakenimages-7BpuzmcxlHU-unsplash.jpg';
import productGridImage7 from '../../../../assets/images/cc0_landingpage_images/larry-crayton-cf1cN3ofKUM-unsplash.jpg';
import productGridImage8 from '../../../../assets/images/cc0_landingpage_images/national-cancer-institute-JvPmIB4Hnx4-unsplash(1).jpg';
/* import productGridImage9 from '../../../../assets/images/cc0_landingpage_images/yogendra-singh-TpYrMmrdP5A-unsplash.jpg';
import productGridImage10 from '../../../../assets/images/cc0_landingpage_images/zach-reiner-K4XHVhJk6kw-unsplash.jpg'; */
import productGridImage11 from '../../../../assets/images/cc0_landingpage_images/amy-humphries-R_a3-W5ODvA-unsplash(1).jpg';

import { ApplicationState } from '../../../../common/redux/main/types';
import { selectAuthState } from '../../../../common/redux/users/user.selectors';
import colors from '../../../00_Constants/colors';
import fonts from '../../../00_Constants/fonts';
import {
  AUTHENTICATION,
  LOGIN_REQUEST,
} from '../../../00_Constants/trackerConstants';
import { FullWidthBackground } from '../../../01_atoms/FullWidthBackground/FullWidthBackground';
import FZButton from '../../../01_atoms/FZButton/FZButton';
import FZFooter from '../../../01_atoms/FZFooter/FZFooter';
import FZLink from '../../../01_atoms/FZLink/FZLink';
import FZTypography from '../../../01_atoms/FZTypography/FZTypography';
import FZGenericSlider from '../../../02_molecules/FZGenericSlider/FZGenericSlider';
import FZImageGrid from '../../../02_molecules/FZImageGrid/FZImageGrid';
import FZStepper from '../../../02_molecules/FZStepper/FZStepper';
import FZDrawerAppBar from '../../../03_organisms/FZDrawerAppBar/FZDrawerAppBar';
import FZPricingTable from '../../../03_organisms/FZPricingTable/FZPricingTable';
import FZPricingTableAdvantageListItem from '../../../03_organisms/FZPricingTable/FZPricingTableAdvantageListItem';
import FZPricingTableItem from '../../../03_organisms/FZPricingTable/FZPricingTableItem';
import FZStackedAccordion from '../../../03_organisms/FZStackedAccordions/FZStackedAccordion';
import FZTestimonialQuote from '../../../03_organisms/FZTestimonial/FZTestimonialQuote';
import { withConfig } from '../../../HOCs/withConfig';
import { withHooks, WithHooksProps } from '../../../HOCs/withHooks';
import { withServices, WithServicesProps } from '../../../HOCs/withServices';
import './LandingScreen.css';
import FZLanguageSwitcher from '../../../02_molecules/FZLanguageSwitcher/FZLanguageSwitcher';
import memoizeOne from 'memoize-one';

const styles = (theme: Theme) =>
  createStyles({
    pageContainer: {
      position: 'relative',
    },

    blurredBackgroundContainer: {
      backgroundImage: `url(${landingpageBlurredBackgroundRaw})`,
      backgroundPosition: '29% 34%',
      position: 'absolute',
      width: '100%',
      height: 540,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '200% 200%',

      [theme.breakpoints.up('lg')]: {
        backgroundSize: '250% 250%',
        backgroundPosition: '28% 34%',
      },
    },

    backgroundPatternContainer: {
      position: 'sticky',
      right: 0,
      //overflowX: 'auto',
      [theme.breakpoints.down('sm')]: {
        // display: 'none',
      },
      //alignSelf: 'flex-end',
      //minHeight: '1500px',
      //minWidth: '50%',
    },

    serviceName: {
      paddingTop: 7,
      '&:hover': {
        //cursor: 'pointer',
      },
    },

    appbar: {
      //backgroundColor: 'rgba(0,0,0,0)',
    },

    logo: {
      borderRadius: 8,
      width: 50,
      height: 50,
      marginRight: 8,
    },

    section: {
      minHeight: 300,
      paddingTop: 16,
    },

    sectionContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 32,
      width: '100%',
      paddingBottom: 48,
      paddingRight: 32,
      paddingLeft: 32,
    },

    sectionHeader: {
      marginTop: 0,
      //marginBottom: 23,
      color: 'white',
      marginBottom: 16,
    },

    sectionParagraph: {
      marginTop: 0,
      marginBottom: 0,
      color: 'white',
    },

    introSection: {
      minHeight: '540px',

      [theme.breakpoints.down('sm')]: {
        // paddingTop: 26,
      },
    },

    introSectionContentContainer: {
      zIndex: 2,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    testimonialSection: {
      /*   marginTop: 32,
      marginBottom: 32, */
    },

    pricingSection: {
      // marginTop: 32,
      //marginBottom: 32,
    },

    contributionSection: {
      marginTop: 32,
      marginBottom: 32,
    },

    aboutUsTextBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 16,

      [theme.breakpoints.down('md')]: {
        marginTop: 32,
      },
    },

    navLink: {
      marginLeft: 32,
      marginRight: 64,
      fontFamily: fonts.FONT_FAMILY_HEADLINE_BOLD,
      fontSize: '1.3rem',
      letterSpacing: '3.13px',
    },

    contactUsLink: {
      display: 'inline',
      marginRight: 8,

      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },

    drawerNavLink: {
      width: '100%',
      height: '100%',
    },

    drawerItem: {
      textAlign: 'center',
    },

    drawerItem1: {
      color: colors.tertiary,
    },

    drawerItem2: {
      color: colors.accent,
    },

    drawerItem3: {
      color: colors.secondary,
    },

    drawerItem4: {
      color: colors.textMedium,
    },

    drawerItem5: {
      color: colors.textMedium,
    },

    contactFormDialog: {
      //minWidth: '500px',
      padding: 32,
    },
  });

interface Props
  extends DispatchProp<AnyAction>,
    WithTranslation,
    WithWidthProps,
    RouteComponentProps<any>,
    WithHooksProps,
    WithServicesProps {
  authState: any;
  authService: any;
  history: any;
  classes?: any;

  // HoC Procs
  t?: any;
}

interface State {
  navigationDrawerOpen: boolean;
  contactUsModalOpen: boolean;
  introSectionEnabled: boolean;
  productSectionEnabled: boolean;
  aboutUsSectionEnabled: boolean;
  testimonialSectionEnabled: boolean;
  pricingSectionEnabled: boolean;
  ecoContributionSectionEnabled: boolean;
  faqSectionEnabled: boolean;
  footerEnabled: boolean;
}

class LandingScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      navigationDrawerOpen: false,
      contactUsModalOpen: false,

      introSectionEnabled: true,
      productSectionEnabled: true,
      aboutUsSectionEnabled: false,
      testimonialSectionEnabled: false,
      pricingSectionEnabled: false,
      ecoContributionSectionEnabled: false,
      faqSectionEnabled: false,
      footerEnabled: false,
    };
    this.handleLoginButtonPressed = this.handleLoginButtonPressed.bind(this);
    this.handleNavLinkClicked = this.handleNavLinkClicked.bind(this);
    this.handleNavigationDrawerToggle = this.handleNavigationDrawerToggle.bind(
      this,
    );
    this.toggleContactUsModal = this.toggleContactUsModal.bind(this);
  }

  async handleSignupButtonPressed() {
    const { history } = this.props;

    history.push('/signup');
  }

  /**
   * Handles a click onto the "login" button
   */
  async handleLoginButtonPressed(triggerSource: string) {
    const { matomoAnalyticsTracker, authState, history } = this.props;

    if (authState.isLoggedIn) {
      history.push('/home');
    }

    matomoAnalyticsTracker.trackEvent({
      category: AUTHENTICATION,
      action: LOGIN_REQUEST,
      name: triggerSource,
      href: '/',
    });

    history.push('/login');
  }

  /**
   * Toggles the modal that contains the contact form
   */
  toggleContactUsModal(): void {
    this.setState({ contactUsModalOpen: !this.state.contactUsModalOpen });
  }

  /**
   * Handles a click onto a navigation link for the main page navigation.
   */
  handleNavLinkClicked(): void {
    this.handleNavigationDrawerToggle();
  }

  /**
   * Toggles the main navigation drawer
   */
  handleNavigationDrawerToggle(): void {
    this.setState({ navigationDrawerOpen: !this.state.navigationDrawerOpen });
  }

  /**
   * Creates the items in the main navigation based on which sections of the page have been enabled in the components state.
   */
  createNavLinksBasedOnActiveSectionState(): JSX.Element[] {
    const navLinks = [];
    const { classes, t } = this.props;

    if (this.state.productSectionEnabled) {
      /* Only disabled until landingpage is ready to launch fully. 
      navLinks.push(
        <Link
          containerId="root"
          key="productLink"
          className={classes.navLink}
          activeClass="active"
          to="product"
          spy={false}
          smooth={'easeInOutQuad'}
          hashSpy={false}
          offset={-120}
          duration={500}
          delay={0}
          isDynamic={true}
          ignoreCancelEvents={false}
        >
          <FZLink href="#" style={{ color: colors.tertiary }}>
            {t('landingScreen-navlink-product-link-label')}
          </FZLink>
        </Link>,
      ); */
    }

    if (this.state.pricingSectionEnabled) {
      navLinks.push(
        <Link
          containerId="root"
          key="pricingLink"
          className={classes.navLink}
          activeClass="active"
          to="pricing"
          spy={false}
          smooth={'easeInOutQuad'}
          hashSpy={false}
          offset={-120}
          duration={500}
          delay={0}
          isDynamic={true}
          /*    onSetActive={this.handleSetActive}
onSetInactive={this.handleSetInactive} */
          ignoreCancelEvents={false}
        >
          <FZLink href="#" style={{ color: colors.accent }}>
            {t('landingScreen-navlink-pricing-link-label')}
          </FZLink>
        </Link>,
      );
    }

    if (this.state.faqSectionEnabled) {
      navLinks.push(
        <Link
          containerId="root"
          key="faqLink"
          className={classes.navLink}
          style={{ color: colors.secondary }}
          activeClass="active"
          to="FAQ"
          spy={false}
          smooth={'easeInOutQuad'}
          hashSpy={false}
          offset={-120}
          duration={500}
          delay={0}
          isDynamic={true}
          ignoreCancelEvents={false}
        >
          <FZLink href="#" style={{ color: colors.secondary }}>
            {t('landingScreen-navlink-FAQ-link-label')}
          </FZLink>
        </Link>,
      );
    }

    if (this.state.aboutUsSectionEnabled) {
      navLinks.push(
        <Link
          containerId="root"
          key="aboutLink"
          style={{ marginRight: 22 }}
          className={classes.navLink}
          activeClass="active"
          to="about"
          spy={true}
          smooth={'easeInOutQuad'}
          hashSpy={true}
          offset={-120}
          duration={500}
          delay={0}
          isDynamic={true}
          /*   onSetInactive={this.handleSetInactive}  */
          ignoreCancelEvents={true}
        >
          <FZLink href="#" style={{ color: colors.quartiary }}>
            {t('landingScreen-navlink-about-link-label')}
          </FZLink>
        </Link>,
      );
    }

    navLinks.push(<FZLanguageSwitcher style={{ marginRight: 20 }} />);

    navLinks.push(
      <FZButton
        key="signInButton"
        variant="text"
        labelColor={colors.textMedium}
        backgroundColor={colors.transparent}
        onClick={() => {
          this.handleLoginButtonPressed('LANDING_PAGE_APP_BAR_LOGIN_BUTTON');
        }}
        label={t('loginScreen-signinButton-label')}
      ></FZButton>,
    );

    return navLinks;
  }

  /**
   * Creates the items in the mobile navigation drawer based on which sections of the page have been enabled in the components state.
   */
  createDrawerItemsBasedOnActiveSectionState(): JSX.Element[] {
    const navLinks = [];
    const { classes, t } = this.props;

    if (this.state.productSectionEnabled) {
      /*  Only disabled until landingpage is ready to launch fully.  
       navLinks.push(
        <ListItem button key={'productLink'}>
          <Link
            containerId="root"
            style={{ color: colors.tertiary }}
            className={classes.drawerNavLink}
            activeClass="active"
            to="product"
            spy={true}
            smooth={'easeInOutQuad'}
            hashSpy={true}
            offset={-150}
            duration={500}
            delay={0}
            isDynamic={true}
            onClick={this.handleNavLinkClicked}
            onSetInactive={this.handleSetInactive} 
            ignoreCancelEvents={true}
          >
            <FZTypography variant="h6" color={colors.tertiary}>
              {t('landingScreen-navlink-product-link-label')}
            </FZTypography>
          </Link>
        </ListItem>,
      ); */
    }

    if (this.state.pricingSectionEnabled) {
      navLinks.push(
        <ListItem button key={'pricingLink'}>
          <Link
            containerId="root"
            style={{ color: colors.accent }}
            className={classes.drawerNavLink}
            activeClass="active"
            to="pricing"
            spy={true}
            smooth={'easeInOutQuad'}
            hashSpy={true}
            offset={-120}
            duration={500}
            delay={0}
            isDynamic={true}
            onClick={this.handleNavLinkClicked}
            /*  onSetInactive={this.handleSetInactive} */
            ignoreCancelEvents={true}
          >
            <FZTypography variant="h6" color={colors.accent}>
              {t('landingScreen-navlink-pricing-link-label')}
            </FZTypography>
          </Link>
        </ListItem>,
      );
    }

    if (this.state.faqSectionEnabled) {
      navLinks.push(
        <ListItem button key={'faqLink'}>
          <Link
            containerId="root"
            style={{ color: colors.secondary }}
            className={classes.drawerNavLink}
            activeClass="active"
            to="FAQ"
            spy={true}
            smooth={'easeInOutQuad'}
            hashSpy={true}
            offset={-120}
            duration={500}
            delay={0}
            isDynamic={true}
            onClick={this.handleNavLinkClicked}
            /*   onSetInactive={this.handleSetInactive}  */
            ignoreCancelEvents={true}
          >
            <FZTypography variant="h6" color={colors.secondary}>
              {t('landingScreen-navlink-FAQ-link-label')}
            </FZTypography>
          </Link>
        </ListItem>,
      );
    }

    if (this.state.aboutUsSectionEnabled) {
      navLinks.push(
        <ListItem button key={'aboutLink'}>
          <Link
            containerId="root"
            style={{ color: colors.quartiary }}
            className={classes.drawerNavLink}
            activeClass="active"
            to="about"
            spy={true}
            smooth={'easeInOutQuad'}
            hashSpy={true}
            offset={-120}
            duration={500}
            delay={0}
            isDynamic={true}
            onClick={this.handleNavLinkClicked}
            /*   onSetInactive={this.handleSetInactive}  */
            ignoreCancelEvents={true}
          >
            <FZTypography variant="h6" color={colors.quartiary}>
              {t('landingScreen-navlink-about-link-label')}
            </FZTypography>
          </Link>
        </ListItem>,
      );
    }

    if (
      this.state.productSectionEnabled ||
      this.state.pricingSectionEnabled ||
      this.state.faqSectionEnabled ||
      this.state.aboutUsSectionEnabled
    ) {
      navLinks.push(<Divider key="linkdivider"></Divider>);
    }

    navLinks.push(<FZLanguageSwitcher style={{}} />);

    navLinks.push(
      <ListItem button key={'signInLink'} className={classes.drawerItem}>
        <ListItemText
          primary={t('loginScreen-signinButton-label')}
          className={clsx(classes.drawerItem4, classes.drawerItem)}
          onClick={() => {
            this.handleLoginButtonPressed('LANDING_PAGE_CTA_LOGIN_BUTTON');
          }}
        />
      </ListItem>,
    );

    return navLinks;
  }

  componentDidMount() {
    this.props.matomoAnalyticsTracker.trackPageView({
      documentTitle: 'Landing Page', // optional
    });

    const { authState, history } = this.props;

    if (!authState.isLoggingIn && authState.isLoggedIn) {
      history.push('/home');
    }
  }

  render() {
    const { classes, t, authState, history, width } = this.props;

    const memoizedViewPortIsMobile = memoizeOne(isWidthDown);
    const viewportIsMobile = memoizedViewPortIsMobile('xs', width, true);

    if (!authState.isLoggingIn && authState.isLoggedIn) {
      history.push('/home');
    }

    return (
      <div style={{ height: '100%' }}>
        <FZDrawerAppBar
          open={this.state.navigationDrawerOpen}
          onOpen={this.handleNavigationDrawerToggle}
          onClose={this.handleNavigationDrawerToggle}
          containerRef={document.getElementById('drawerContainer')}
          navbarLeftContent={[
            <img
              src={Logo}
              className={classes.logo}
              alt={'logo'}
              key="appbar_logo"
            />,
            <Typography
              variant="h1"
              className={classes.serviceName}
              onClick={() => {
                window.scrollTo(0, 0);
                scroll.scrollToTop();
              }}
              key="appbar_serviceName"
            >
              {t('loginScreen-serviceName-label')}
            </Typography>,
          ]}
          drawerItems={this.createDrawerItemsBasedOnActiveSectionState()}
          navItems={this.createNavLinksBasedOnActiveSectionState()}
        />

        <div id="pageContainer" className={classes.pageContainer}>
          {this.state.introSectionEnabled && (
            <React.Fragment>
              <div className={classes.blurredBackgroundContainer}></div>
              <Container>
                <section
                  className={clsx(classes.section, classes.introSection)}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={12}
                      style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'space-around',
                      }}
                    >
                      {/*             <span className={classes.backgroundPatternContainer}>
                    <SvgIcon
                      component={landingpageBlurredBackground}
                      viewBox="0 -650 2000 2000"
                      transform="scale(100)"
                      style={{ float: 'right', zIndex: 0 }}
                    />
                  </span> */}
                      <span
                        className={classes.introSectionContentContainer}
                        style={{
                          paddingTop: '120px',
                          whiteSpace: 'break-spaces',
                        }}
                      >
                        <Typography variant="h2" component="h2">
                          {t('loginScreen-greeting-title')}
                        </Typography>
                        <Typography variant="h5" display="block" component="h5">
                          <p>{t('loginScreen-greeting-subtitle')}</p>
                        </Typography>

                        <FZButton
                          variant="outlined"
                          backgroundColor={colors.white}
                          labelColor={colors.tertiary}
                          style={{ marginTop: 40, marginBottom: 20 }}
                          onClick={() => {
                            this.handleSignupButtonPressed();
                          }}
                          label={t('loginScreen-cta-label')}
                        ></FZButton>
                      </span>
                    </Grid>
                  </Grid>
                </section>
              </Container>{' '}
            </React.Fragment>
          )}

          {this.state.productSectionEnabled && (
            <Container>
              <section
                className={clsx(classes.section, classes.howItWorksSection)}
              >
                <div className={classes.sectionContent}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <ScrollAnchor name="product" />
                      <FZImageGrid
                        columns={viewportIsMobile ? 2 : 4}
                        gridHeight={viewportIsMobile ? 550 : 400}
                        data={[
                          {
                            image: productGridImage0,
                            author: '',
                            cols: viewportIsMobile ? 1 : 3,
                            title: 'bild1',
                            rows: viewportIsMobile ? 1 : 2,
                          },

                          {
                            image: productGridImage11,
                            author: '',
                            cols: 1,
                            title: 'bild2',
                            rows: viewportIsMobile ? 1 : 2,
                          },
                          {
                            image: productGridImage2,
                            author: '',
                            cols: viewportIsMobile ? 2 : 1,
                            title: 'bild3',
                            rows: viewportIsMobile ? 2 : 1,
                          },
                          {
                            image: productGridImage3,
                            author: '',
                            cols: viewportIsMobile ? 2 : 1,
                            title: 'bild4',
                            rows: viewportIsMobile ? 2 : 1,
                          },

                          {
                            image: productGridImage4,
                            author: '',
                            cols: viewportIsMobile ? 2 : 1,
                            title: 'bild5',
                            rows: viewportIsMobile ? 2 : 1,
                          },
                          {
                            image: productGridImage5,
                            author: '',
                            cols: viewportIsMobile ? 2 : 1,
                            title: 'bild6',
                            rows: viewportIsMobile ? 2 : 1,
                          },

                          {
                            image: productGridImage6,
                            author: '',
                            cols: 1.5,
                            title: 'bild7',
                            rows: 2,
                          },
                          {
                            image: productGridImage7,
                            author: '',
                            cols: 1.5,
                            title: 'bild8',
                            rows: 2,
                          },

                          {
                            image: productGridImage8,
                            author: '',
                            cols: 1,
                            title: 'bild9',
                            rows: 2,
                          },
                        ]}
                      ></FZImageGrid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <FZStepper
                        id="product"
                        steps={[
                          t('landingScreen-howItWorksSection-step-0'),
                          t('landingScreen-howItWorksSection-step-1'),
                          t('landingScreen-howItWorksSection-step-2'),
                          t('landingScreen-howItWorksSection-step-3'),
                        ]}
                        stepContent={[]}
                      ></FZStepper>
                    </Grid>
                  </Grid>
                </div>
              </section>
            </Container>
          )}

          {this.state.pricingSectionEnabled && (
            <section className={clsx(classes.section, classes.pricingSection)}>
              <FullWidthBackground imageSource={fzColorfulBackgroundImage}>
                <div className={classes.sectionContent}>
                  <ScrollAnchor name="pricing" />
                  <Container>
                    <Grid container>
                      <Grid item xs={12} lg={2}></Grid>
                      <Grid
                        item
                        xs={12}
                        lg={8}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <FZTypography
                          variant="h4"
                          component="h4"
                          className={classes.sectionHeader}
                        >
                          {t('landingPage-pricingSection-headline')}
                        </FZTypography>

                        {/*            <FZTypography
                      variant="body1"
                      className={classes.sectionParagraph}
                      component="p"
                      style={{ width: '50%', marginBottom: 16 }}
                    >
                      {t(
                        'landingPage-pricingSection-howPricingWorks-paragraph',
                      )}
                    </FZTypography> */}

                        <FZPricingTable>
                          <FZPricingTableItem
                            pricingItemName={t(
                              'landingPage-pricingTable-option-0-title',
                            )}
                            pricingItemDescription={t(
                              'landingPage-pricingTable-option-0-description',
                            )}
                            pricingItemImage={''}
                            includeCtaButton={false}
                            ctaButtonLabel={t(
                              'landingPage-pricingTable-option-0-ctaButton-label',
                            )}
                            pricingItemIncludedAdvantages={[
                              <FZPricingTableAdvantageListItem
                                key={
                                  'landingPage-pricingTable-option-0-includedAdvantages-1'
                                }
                              >
                                {t(
                                  'landingPage-pricingTable-option-0-includedAdvantages-1',
                                )}
                              </FZPricingTableAdvantageListItem>,

                              <FZPricingTableAdvantageListItem
                                key={
                                  'landingPage-pricingTable-option-0-includedAdvantages-2'
                                }
                              >
                                {t(
                                  'landingPage-pricingTable-option-0-includedAdvantages-2',
                                )}
                              </FZPricingTableAdvantageListItem>,

                              <FZPricingTableAdvantageListItem
                                key={
                                  'landingPage-pricingTable-option-0-includedAdvantages-3'
                                }
                              >
                                {t(
                                  'landingPage-pricingTable-option-0-includedAdvantages-3',
                                )}
                              </FZPricingTableAdvantageListItem>,
                            ]}
                            pricingItemPrice={t(
                              'landingPage-pricingTable-option-0-price',
                            )}
                          ></FZPricingTableItem>

                          <FZPricingTableItem
                            pricingItemName={t(
                              'landingPage-pricingTable-option-1-title',
                            )}
                            pricingItemDescription={t(
                              'landingPage-pricingTable-option-1-description',
                            )}
                            pricingItemImage={''}
                            pricingItemIncludedAdvantages={[
                              <FZPricingTableAdvantageListItem
                                key={
                                  'landingPage-pricingTable-option-1-includedAdvantages-0'
                                }
                              >
                                {t(
                                  'landingPage-pricingTable-option-1-includedAdvantages-0',
                                )}
                              </FZPricingTableAdvantageListItem>,

                              <FZPricingTableAdvantageListItem
                                key={
                                  'landingPage-pricingTable-option-1-includedAdvantages-1'
                                }
                              >
                                {t(
                                  'landingPage-pricingTable-option-1-includedAdvantages-1',
                                )}
                              </FZPricingTableAdvantageListItem>,
                            ]}
                            pricingItemPrice={t(
                              'landingPage-pricingTable-option-1-price',
                            )}
                            includeCtaButton={false}
                            ctaButtonLabel={t(
                              'landingPage-pricingTable-option-1-ctaButton-label',
                            )}
                          ></FZPricingTableItem>

                          <FZPricingTableItem
                            pricingItemName={t(
                              'landingPage-pricingTable-option-2-title',
                            )}
                            pricingItemDescription={t(
                              'landingPage-pricingTable-option-2-description',
                            )}
                            pricingItemImage={''}
                            pricingItemIncludedAdvantages={[
                              <FZPricingTableAdvantageListItem
                                key={
                                  'landingPage-pricingTable-option-2-includedAdvantages-0'
                                }
                              >
                                {t(
                                  'landingPage-pricingTable-option-2-includedAdvantages-0',
                                )}
                              </FZPricingTableAdvantageListItem>,

                              <FZPricingTableAdvantageListItem
                                key={
                                  'landingPage-pricingTable-option-2-includedAdvantages-1'
                                }
                              >
                                {t(
                                  'landingPage-pricingTable-option-2-includedAdvantages-1',
                                )}
                              </FZPricingTableAdvantageListItem>,
                            ]}
                            pricingItemPrice={t(
                              'landingPage-pricingTable-option-2-price',
                            )}
                            includeCtaButton={false}
                            ctaButtonLabel={t(
                              'landingPage-pricingTable-option-2-ctaButton-label',
                            )}
                          ></FZPricingTableItem>
                        </FZPricingTable>
                      </Grid>
                      <Grid item xs={12} lg={2}></Grid>
                    </Grid>
                  </Container>
                </div>
              </FullWidthBackground>
            </section>
          )}

          {this.state.aboutUsSectionEnabled && (
            <Container>
              <section className={clsx(classes.section, classes.aboutSection)}>
                <ScrollAnchor name="about" />

                <Grid container>
                  <Grid item xs={12} md={4} className={classes.aboutUsTextBox}>
                    <FZTypography variant="h6" color={colors.quartiary}>
                      {t('landingScreen-aboutSection-headline-0')}
                    </FZTypography>

                    <FZTypography variant="body1">
                      {t('landingScreen-aboutSection-message-0')}
                    </FZTypography>
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.aboutUsTextBox}>
                    <FZTypography variant="h6" color={colors.quartiary}>
                      {t('landingScreen-aboutSection-headline-1')}
                    </FZTypography>

                    <FZTypography variant="body1">
                      {t('landingScreen-aboutSection-message-1')}
                    </FZTypography>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.aboutUsTextBox}>
                    <FZTypography variant="h6" color={colors.quartiary}>
                      {t('landingScreen-aboutSection-headline-2')}
                    </FZTypography>

                    <FZTypography variant="body1">
                      {t('landingScreen-aboutSection-message-2')}
                    </FZTypography>
                  </Grid>
                </Grid>
              </section>
            </Container>
          )}

          {this.state.testimonialSectionEnabled && (
            <Container>
              <section
                className={clsx(classes.section, classes.testimonialSection)}
              >
                <div className={classes.sectionContent}>
                  <ScrollAnchor name="testimonials" />
                  <FZTypography
                    variant="h4"
                    component="h4"
                    className={classes.sectionHeader}
                    id="testimonials"
                    style={{ color: 'black' }}
                  >
                    {t('landingPage-testimonialsSection-headline')}
                  </FZTypography>

                  <Grid container>
                    <Grid item lg={2}></Grid>
                    <Grid item xs={12} lg={8}>
                      <FZGenericSlider>
                        <FZTestimonialQuote
                          backgroundColor={colors.primary}
                          testimonial={{
                            creator: 'Familie Jenda',
                            creatorMeta: 'Dabei seit 5 Jahren',
                            content:
                              'Die Familienzeitung hat uns alle näher zusammen gebracht, jeden zweiten Samstag freuen wir uns auf ihre Ankunft.',
                          }}
                        />
                        <FZTestimonialQuote
                          backgroundColor={colors.primary}
                          testimonial={{
                            creator: 'Familie Peters',
                            creatorMeta: 'Dabei seit 3 Jahren',
                            content:
                              'Seit wir die Familienzeitung verwenden ist sie bei jedem Familientreffen ein Gesprächsthema. Endlich ein Fotobuch, an dem alle teilhaben!',
                          }}
                        />
                      </FZGenericSlider>
                    </Grid>
                    <Grid item lg={2}></Grid>
                  </Grid>
                </div>
              </section>
            </Container>
          )}

          {this.state.ecoContributionSectionEnabled && (
            <Container>
              <section
                className={clsx(classes.section, classes.contributionSection)}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <ScrollAnchor name="contribution" />
                    <FZTypography
                      variant="h4"
                      component="h4"
                      className={classes.sectionHeader}
                      style={{ color: colors.textStrong }}
                      id="contribution"
                    >
                      {t('landingPage-ecoSection-headline')}
                    </FZTypography>
                  </Grid>
                </Grid>
              </section>
            </Container>
          )}

          {this.state.faqSectionEnabled && (
            <Container>
              <section className={clsx(classes.section, classes.faqSection)}>
                <div className={classes.sectionContent}>
                  <ScrollAnchor name="FAQ" />
                  <FZTypography
                    variant="h4"
                    component="h4"
                    className={classes.sectionHeader}
                    style={{ color: colors.textStrong }}
                    id="FAQ"
                  >
                    {t('landingPage-faqSection-headline')}
                  </FZTypography>
                  <Grid container>
                    <Grid item xs={12} lg={2}></Grid>
                    <Grid item xs={12} lg={8}>
                      <FZStackedAccordion
                        data={[
                          {
                            key: t(
                              'landingPage-faqSection-question-1-question',
                            ),
                            summary: t(
                              'landingPage-faqSection-question-1-question',
                            ),
                            details: t(
                              'landingPage-faqSection-question-1-answer',
                            ),
                          },
                          {
                            key: t(
                              'landingPage-faqSection-question-2-question',
                            ),
                            summary: t(
                              'landingPage-faqSection-question-2-question',
                            ),
                            details: t(
                              'landingPage-faqSection-question-2-answer',
                            ),
                          },
                          {
                            key: t(
                              'landingPage-faqSection-question-3-question',
                            ),
                            summary: t(
                              'landingPage-faqSection-question-3-question',
                            ),
                            details: t(
                              'landingPage-faqSection-question-3-answer',
                            ),
                          },
                          {
                            key: t(
                              'landingPage-faqSection-question-4-question',
                            ),
                            summary: t(
                              'landingPage-faqSection-question-4-question',
                            ),
                            details: t(
                              'landingPage-faqSection-question-4-answer',
                            ),
                          },
                        ]}
                      ></FZStackedAccordion>

                      <span
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          marginTop: 8,
                        }}
                      >
                        <FZTypography
                          variant={'body2'}
                          style={{ display: 'inline', marginRight: 8 }}
                        >
                          {t(
                            'landingPage-faqSection-if-your-question-was-not-answered-contact-us-label-0',
                          )}
                        </FZTypography>

                        <FZTypography
                          variant="body2"
                          color={colors.accent}
                          onClick={() => {
                            this.props.history.push('/contact');
                          }}
                          className={classes.contactUsLink}
                        >
                          {t(
                            'landingPage-faqSection-if-your-question-was-not-answered-contact-us-label-1',
                          )}
                        </FZTypography>
                      </span>
                    </Grid>
                    <Grid item xs={12} lg={2}></Grid>
                  </Grid>
                </div>
              </section>
            </Container>
          )}

          {this.state.footerEnabled && <FZFooter></FZFooter>}
        </div>

        <div id="drawerContainer"></div>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    authState: selectAuthState(state),
  };
};

const composedComponent = compose(
  connect(mapStateToProps),
  withWidth(),
  withConfig,
  withServices,
  withRouter,
  withHooks,
  withTranslation(),
  withStyles(styles),
)(LandingScreen);

export default composedComponent as React.ComponentType<any>;
