import { CardMedia, Container, Grid, Typography } from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import { Elements, RichText } from 'prismic-reactjs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { BlogPost } from '../../../common/models/FeedContent';
import { ApplicationState } from '../../../common/redux/main/types';
import { MuiGridWidth } from '../../../types';
import { WithHooksProps, withHooks } from '../../HOCs/withHooks';
import FZAppBar from '../../03_organisms/FZAppBar/FZAppBar';
import fonts from '../../00_Constants/fonts';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import FZNavigateBackButton from '../../01_atoms/FZNavigateBackButton/FZNavigateBackButton';

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    cardMedia: {
      height: '480px',
    },

    cardMediaBackground: {
      backgroundSize: 'cover',
    },
  });

type Props = WithStyles<typeof styles> &
  WithHooksProps & {
    blogPostSlug: string;
    blogPostToRender: BlogPost;
    columns: MuiGridWidth;
    onReadMoreButtonClicked: (slug: string) => void;
    t: i18next.TFunction;
  };

type State = {};

class NewsDetailScreen extends Component<Props, State> {
  static lastRenderedPrismicHtmlType: any;

  constructor(props: Props) {
    super(props);

    this.state = {};

    this.renderBlogPostBody = this.renderBlogPostBody.bind(this);
    this.serializePrismicHtml = this.serializePrismicHtml.bind(this);
  }

  componentDidMount() {
    const { matomoAnalyticsTracker, blogPostToRender } = this.props;
    matomoAnalyticsTracker.trackPageView({
      documentTitle: `${blogPostToRender.title} - News - DetailScreen`, // optional
    });
  }

  serializePrismicHtml(
    type: any,
    element: any,
    content: any,
    children: any,
    key: any,
  ): any {
    /* const elementWidthInNumberOfColumns =
      NewsDetailScreen.lastRenderedPrismicHtmlType === Elements.image ? 6 : 12; */

    switch (type) {
      // Add a class to paragraph elements

      case Elements.heading1: {
        NewsDetailScreen.lastRenderedPrismicHtmlType = type;
        return <Typography variant={'h4'}>{children}</Typography>;
      }

      case Elements.heading2: {
        NewsDetailScreen.lastRenderedPrismicHtmlType = type;
        return <Typography variant={'h5'}>{children}</Typography>;
      }

      case Elements.heading3: {
        NewsDetailScreen.lastRenderedPrismicHtmlType = type;
        return <Typography variant={'h5'}>{children}</Typography>;
      }

      case Elements.heading4: {
        NewsDetailScreen.lastRenderedPrismicHtmlType = type;
        return <Typography variant={'h5'}>{children}</Typography>;
      }

      case Elements.heading5: {
        NewsDetailScreen.lastRenderedPrismicHtmlType = type;
        return <Typography variant={'h6'}>{children}</Typography>;
      }

      case Elements.paragraph: {
        NewsDetailScreen.lastRenderedPrismicHtmlType = type;
        return (
          <p>
            <Typography component="span" variant={'body1'}>
              {children}
            </Typography>
          </p>
        );
      }

      case Elements.strong: {
        return (
          <Typography
            component="span"
            variant={'body1'}
            style={{
              fontWeight: 'bold',
              fontFamily: fonts.FONT_FAMILY_BOLD,
              wordWrap: 'break-word',
            }}
          >
            {content}
          </Typography>
        );
      }

      /*  case Elements.span: {
        return <span key={key}> {content}</span>;
      } */

      case Elements.image:
        NewsDetailScreen.lastRenderedPrismicHtmlType = type;
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            key={key}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '16px',
              marginBottom: '16px',
            }}
          >
            <img
              style={{ maxWidth: '100%' }}
              src={element.url}
              alt={element.alt || ''}
            />
          </Grid>
        );

      case Elements.hyperlink:
        /*  const targetAttr = element.data.target
          ? { target: element.data.target }
          : {};

        const relAttr = element.data.target ? { rel: 'noopener' } : {};

        props = Object.assign(
          {
            className: 'link-class',

            href: element.data.url || linkResolver(element.data),
          },
          targetAttr,
          relAttr,
        );

        return React.createElement(
          'a',
          propsWithUniqueKey(props, key),
          children,
        ); */
        return null;

      default:
        return null;
    }
  }

  renderBlogPostBody(blogPost: BlogPost) {
    return blogPost.body.map((element: any, index: number) => {
      if (element.slice_type === 'text') {
        return (
          <Grid item xs={12} sm={12} md={12} key={index}>
            <RichText
              render={element.primary.text}
              htmlSerializer={this.serializePrismicHtml}
            />
          </Grid>
        );
      } else if (element.slice_type === 'image') {
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '16px',
              marginBottom: '16px',
            }}
          >
            <img
              src={element.primary.image.url}
              alt={element.primary.image.alt}
              style={{ height: '300', maxWidth: '100%' }}
            />
          </Grid>
        );
      } else if (element.slice_type === 'quote') {
        return null;
      } else if (element.slice_type === 'code') {
        return null;
      }
      return null;
    });
  }

  render() {
    const { classes, blogPostToRender, t } = this.props;

    if (!blogPostToRender) {
      return null;
    }

    return (
      <React.Fragment>
        <FZAppBar
          hideTopNavigation
          navbarLeftContent={
            <FZNavigateBackButton
              backDestinationLabel={t(
                'newsDetailsScreen-backDestination-label',
              )}
            />
          }
        ></FZAppBar>

        <Container>
          <Grid
            container
            spacing={2}
            style={{
              marginBottom: '84px',
            }}
          >
            <Grid item xs={12}>
              {blogPostToRender.teaser_image && (
                <CardMedia
                  className={classes.cardMedia}
                  image={blogPostToRender.teaser_image.url}
                  title={blogPostToRender.title[0].text}
                  classes={{ media: classes.cardMedia }}
                />
              )}

              <Typography
                variant="h4"
                style={{
                  marginTop: '16px',
                  paddingRight: '8px',
                  paddingLeft: '8px',
                }}
              >
                {blogPostToRender.title[0].text}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                paddingRight: '16px',
                paddingLeft: '16px',
              }}
            >
              {this.renderBlogPostBody(blogPostToRender)}
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    blogPostToRender: state.feed.currentlyViewedPost,
  };
};

const composedComponent = compose(
  connect(mapStateToProps),
  withHooks,
  withRouter,
  withTranslation(),
  withStyles(styles),
)(NewsDetailScreen);

export default composedComponent;
