import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import memoizeOne from 'memoize-one';
import React, { ReactElement } from 'react';
import FZFieldLabel from '../../01_atoms/FZFieldLabel/FZFieldLabel';

const useStyles = makeStyles({
  emptyRoot: {
    width: '100%',
    minHeight: 121,
  },
  root: { width: '100%', minHeight: 121 },
  chip: {
    margin: 8,
  },

  receiverAdressListContainerVertical: {
    width: '100%',
    minHeight: 121,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  receiverAdressListVerticalChip: {
    marginTop: 16,
    marginLeft: 24,
  },

  chipListHeadlineContainer: {
    justifyContent: 'flex-start',
  },
});

export interface FZChip<T> {
  key: string;
  label?: string;
  icon?: any;
  data?: T;
}

interface Props<T> {
  chipData: string[];
  itemsDeletable: boolean;
  onDeleteItem?: (deletedChipData: T) => void;
  containerClassName?: string;
  chipClassName?: string;
  chipListHeadline?: string;
  chipListEmptyLabel?: string;
  chipIcon?: JSX.Element;
  transformChipData?: (data: string[]) => Array<FZChip<string>>;
  variant: 'vertical' | 'horizontal';
}

const transformEmailAdressListToFZChipData = (
  invitedEmails: string[],
  icon: JSX.Element,
): FZChip<string>[] => {
  return invitedEmails.map(
    (element: string): FZChip<string> => {
      return {
        key: element,
        label: element,
        icon: icon,
        data: element,
      };
    },
  );
};

export default function FZChipList(props: Props<any>): ReactElement {
  const {
    chipListHeadline,

    itemsDeletable,
    onDeleteItem,
    containerClassName,
    chipClassName,
    chipListEmptyLabel,
    variant,
  } = props;
  const classes = useStyles({});

  let memoizedTransform;
  if (!props.transformChipData) {
    memoizedTransform = memoizeOne(transformEmailAdressListToFZChipData);
  } else {
    memoizedTransform = memoizeOne(props.transformChipData);
  }

  const transformedDataToRender = memoizedTransform(
    props.chipData,
    props.chipIcon,
  );

  return (
    <React.Fragment>
      {chipListHeadline && (
        <FZFieldLabel
          label={chipListHeadline}
          style={{ paddingBottom: 16 }}
          classes={{ container: classes.chipListHeadlineContainer }}
        ></FZFieldLabel>
      )}
      <div
        className={clsx(
          transformedDataToRender.length === 0
            ? classes.emptyRoot
            : classes.root,
          containerClassName,
          variant === 'vertical'
            ? classes.receiverAdressListContainerVertical
            : undefined,
        )}
        /*  elevation={2} */
      >
        {transformedDataToRender.length === 0 && chipListEmptyLabel && (
          <Chip variant="outlined" label={chipListEmptyLabel || ''}></Chip>
        )}
        {transformedDataToRender.map((element: FZChip<any>) => {
          return (
            <Chip
              key={element.key}
              variant="outlined"
              icon={element.icon || null}
              label={element.label || ''}
              onDelete={
                itemsDeletable
                  ? () => {
                      onDeleteItem(element.key);
                    }
                  : undefined
              }
              className={clsx(
                classes.chip,
                chipClassName,
                variant === 'vertical'
                  ? classes.receiverAdressListVerticalChip
                  : undefined,
              )}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}
