import { CREATED, NO_CONTENT, OK } from 'http-status-codes';
import {
  call,
  cancel,
  delay,
  fork,
  getContext,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Article } from '../../models/Article';
import { SortingClause } from '../../models/enums/SortingOrders';
import { Image } from '../../models/Image';
import { User } from '../../models/User';
import { ArticleService } from '../../services';
import { PostPreuploadedImagesRequest } from '../../services/ArticleService';
import { selectCurrentlyActiveFamily } from '../families/family.selectors';
import { selectAccessToken } from '../login/login.selectors';
import { selectLoggedInUser } from '../users/user.selectors';
import {
  articleActionTypes,
  deleteArticleError,
  deleteArticleFulfill,
  deleteArticleSuccess,
  deleteImageFaiure,
  deleteImageFulFill,
  deleteImageSuccess,
  fetchOwnArticlesFailure,
  fetchOwnArticlesFulfill,
  fetchOwnArticlesSuccess,
  PostImageAction,
  postImageFailure,
  postImageFulfill,
  postImageSuccess,
  updateArticleFailure,
  updateArticleFulfill,
  updateArticleSuccess,
} from './article.actions';
import { selectCurrentlyCreatedArticle } from './article.selectors';

function* getArticleSaga(action: any) {
  try {
    const articleService = yield getContext('articleService');
    const response = yield call(articleService.getArticle(action.payload));

    if (response && response.status === OK) {
      yield put({
        type: articleActionTypes.FETCH_ARTICLE_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: articleActionTypes.FETCH_ARTICLE_FAILURE,
      payload: {
        error,
      },
    });
  }
}

function* watchGetArticle() {
  yield takeEvery(articleActionTypes.FETCH_ARTICLES_REQUEST, getArticleSaga);
}

//--

function* getArticlesSaga(action: any) {
  try {
    // Get Service from redux-saga-context
    const articleService = yield getContext('articleService');
    const sortingClause: SortingClause = action.payload;
    const response = yield call(articleService.getArticles, sortingClause);

    if (response && response.status === OK) {
      yield put({
        type: articleActionTypes.FETCH_ARTICLES_SUCCESS,
        payload: { articles: response.data },
      });
    }
  } catch (error) {
    yield put({
      type: articleActionTypes.FETCH_ARTICLES_FAILURE,
      payload: {
        error,
      },
    });
  }
}

function* watchGetArticles() {
  yield takeLatest(articleActionTypes.FETCH_ARTICLES_REQUEST, getArticlesSaga);
}

// --

function* postArticleSaga(action: any) {
  try {
    // Get Service from redux-saga-context
    const articleService = yield getContext('articleService');
    const currentlyActiveFamily = yield select(selectCurrentlyActiveFamily);
    const token = yield select(selectAccessToken);

    // Perform async side effect
    const response = yield call(
      articleService.postArticle,
      currentlyActiveFamily.uuid,
      action.payload,
      token,
    );

    /*  const { response, timeout } = yield race({
      response: call(
        articleService.postArticle,
        currentlyActiveFamily.uuid,
        action.payload,
        token,
      ),
      timeout: delay(20000),
    });

    if (timeout) {
      yield put({
        type: articleActionTypes.POST_ARTICLE_FAILURE,
        payload: {
          error: 'TIMEOUT',
        },
      });
    } */

    // Check if the response yields a successful status
    if (response.status === CREATED) {
      yield put({
        type: articleActionTypes.POST_ARTICLE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: articleActionTypes.POST_ARTICLE_FAILURE,
      payload: {
        error,
      },
    });
  } finally {
    yield put({
      type: articleActionTypes.POST_ARTICLE_FULFILL,
    });
  }
}

function* watchPostArticle() {
  yield takeEvery(articleActionTypes.POST_ARTICLE_REQUEST, postArticleSaga);
}
// --

function* postArticleDraftSaga(action: any): Generator<any, any, any> {
  try {
    // Get Service from redux-saga-context
    const articleService = yield getContext('articleService');
    // Perform async side effect
    const currentlyActiveFamily = yield select(selectCurrentlyActiveFamily);
    const token: string = yield select(selectAccessToken);
    // Perform async side effect
    const { postArticleResponse, timeout } = yield race({
      postArticleResponse: call(
        articleService.postArticle,
        currentlyActiveFamily?.uuid || 'NONE',
        action.payload,
        token,
      ),
      timeout: delay(6000),
    });

    if (timeout) {
      yield put({
        type: articleActionTypes.POST_ARTICLE_DRAFT_FAILURE,
        payload: {
          error: 'TIMEOUT',
        },
      });
    }

    // Check if the response yields a successful status
    if (postArticleResponse) {
      yield put({
        type: articleActionTypes.POST_ARTICLE_DRAFT_SUCCESS,
        payload: postArticleResponse.data,
      });
    }
  } catch (error) {
    yield put({
      type: articleActionTypes.POST_ARTICLE_DRAFT_FAILURE,
      payload: {
        error,
      },
    });
  } finally {
    yield put({
      type: articleActionTypes.POST_ARTICLE_DRAFT_FULFILL,
    });
  }
}

function* watchPostArticleDraft() {
  yield takeEvery(
    articleActionTypes.POST_ARTICLE_DRAFT_REQUEST,
    postArticleDraftSaga,
  );
}

// --

function* deleteArticleImageSaga(action: any) {
  try {
    const accessToken = yield select(selectAccessToken);
    const articleService = yield getContext('articleService');

    const deleteImageResponse = yield call(
      articleService.deleteArticleImage,
      action.payload.articleUuid,
      action.payload.imageUuid,
      accessToken,
    );

    if (deleteImageResponse && deleteImageResponse.status === OK) {
      yield put(deleteImageSuccess(action.imageUuid));
      yield call(action.payload.deleteImageSuccessCallback);
    }
  } catch (error) {
    yield put(deleteImageFaiure(error));
  } finally {
    yield put(deleteImageFulFill());
  }
}

function* watchDeleteImageFromArticle() {
  yield takeLatest(
    articleActionTypes.DELETE_IMAGE_FROM_ARTICLE_REQUEST,
    deleteArticleImageSaga,
  );
}

function* deleteArticleSaga(action: any) {
  try {
    // Get Service from redux-saga-context
    const articleService = yield getContext('articleService');
    const accessToken = yield select(selectAccessToken);
    const articleToDelete: Article = action.payload;

    // Perform async side effect
    const { response, timeout } = yield race({
      response: call(
        articleService.deleteArticle,
        articleToDelete.uuid,
        accessToken,
      ),
      timeout: delay(6000),
    });

    if (timeout) {
      yield put(postImageFailure(new Error('TIMEOUT')));
    }

    // Check if the response yields a successful status
    if (response.status === OK || response.status === NO_CONTENT) {
      yield put(deleteArticleSuccess());
    }
  } catch (error) {
    yield put(deleteArticleError(error));
  } finally {
    yield put(deleteArticleFulfill());
  }
}

function* watchDeleteArticle() {
  yield takeEvery(articleActionTypes.DELETE_ARTICLE_REQUEST, deleteArticleSaga);
}

// --

function* postArticleImagesSaga(
  action: PostImageAction,
): Generator<any, any, any> {
  try {
    // Get Service from redux-saga-context
    const articleService = yield getContext('articleService');
    const imageService = yield getContext('imageService');
    const accessToken: string = yield select(selectAccessToken);
    const imageToUpload: Image = action.payload.images[0];
    const currentlyDraftedArticle = yield select(selectCurrentlyCreatedArticle);

    /** Step 1 - Generate a presigned-upload url that allows us to
     *  upload a resource directly to a designated storage provider.
     */

    const presignedImageUploadUrlResponse = yield call(
      imageService.getPresignedImageUploadUrl,
      accessToken,
      imageToUpload.mimeType,
    );

    if (
      presignedImageUploadUrlResponse &&
      presignedImageUploadUrlResponse.status === OK
    ) {
      /**
       * Step 2 - One the upload url could be obtained,
       * perform the upload request.
       */
      const imageToUpload: Image = action.payload.images[0];
      const { presignedUploadUrl } = presignedImageUploadUrlResponse.data;

      const { imageUploadResponse, imageUploadTimeout } = yield race({
        imageUploadResponse: yield call(
          imageService.uploadImageToDirectlyToStorageProvider,
          presignedUploadUrl,
          imageToUpload.blobRepresentation,
          imageToUpload.mimeType,
          action.payload.uploadProgressCallback,
        ),
        imageUploadTimeout: delay(30000), // 30 seconds
      });

      if (imageUploadTimeout) {
        action.meta.errorCallback && action.meta.errorCallback();
        yield put(postImageFailure(new Error('TIMEOUT')));
      }

      if (imageUploadResponse && imageUploadResponse.status === OK) {
        /* Step 3 - Update the backend with the information
         * that there is a new image that should be linked to an article.
         */

        const linkImagesToArticleRequestBody: PostPreuploadedImagesRequest = {
          preuploadedImages: [
            {
              url: presignedUploadUrl.split('?')[0],
              contentType: imageToUpload.mimeType,
            },
          ],
        };

        // Perform async side effect
        const { response, timeout } = yield race({
          response: call(
            articleService.postArticleImagesAsUrls,
            linkImagesToArticleRequestBody,
            currentlyDraftedArticle.uuid,
            accessToken,
          ),
          timeout: delay(6000),
        });

        if (timeout) {
          action.meta.errorCallback && action.meta.errorCallback();
          yield put(postImageFailure(new Error('TIMEOUT')));
        }

        // Check if the response yields a successful status, then the article has successfully been enriched with an image.
        if (response.status === CREATED || response.status === OK) {
          yield put(postImageSuccess(response.data));

          action.meta.successCallback && action.meta.successCallback();
        }
      }
    } else {
      action.meta.errorCallback && action.meta.errorCallback();
      yield put(
        postImageFailure(new Error('FAILED TO FETCH PRESIGNED IMAGE URL')),
      );
    }
  } catch (error) {
    action.meta.errorCallback && action.meta.errorCallback();
    yield put(postImageFailure(error));
  } finally {
    yield put(postImageFulfill());
  }
}

function* requestPostArticleImage(
  action: PostImageAction,
): Generator<any, any, any> {
  const postArticleimageTask = yield fork(postArticleImagesSaga, action);
  yield take(articleActionTypes.POST_ARTICLE_IMAGE_CANCELLED);
  yield cancel(postArticleimageTask);
}

function* watchPostArticleImage() {
  yield takeEvery(
    articleActionTypes.POST_ARTICLE_IMAGE_REQUEST,
    requestPostArticleImage,
  );
}

// --

function* updateArticleSaga(action: any) {
  try {
    // Get Service from redux-saga-context
    const articleService: ArticleService = yield getContext('articleService');
    // Perform async side effect
    const accessToken = yield select(selectAccessToken);
    const currentlyActiveFamily = yield select(selectCurrentlyActiveFamily);

    const response = yield call(
      articleService.updateArticle,
      currentlyActiveFamily?.uuid || 'NONE',
      action.payload.article,
      accessToken,
    );

    // Check if the response yields a successful status
    if (response && response.status === OK) {
      yield put(updateArticleSuccess(response.data));
      if (action.payload.successCallback) {
        yield call(action.payload.successCallback);
      }
    }
  } catch (error) {
    yield put(updateArticleFailure(error.response.data));

    if (action.payload.errorCallback) {
      yield call(action.payload.errorCallback, error);
    }
  } finally {
    yield put(updateArticleFulfill());
  }
}

function* watchUpdateArticle() {
  yield takeLatest(
    articleActionTypes.UPDATE_ARTICLE_REQUEST,
    updateArticleSaga,
  );
}

// --

function* getOwnArticlesSaga(action: any) {
  try {
    // Get Service from redux-saga-context
    const articleService = yield getContext('articleService');
    const sortingClause: SortingClause = action.payload;
    const loggedInUser: User = yield select(selectLoggedInUser);
    const accessToken = yield select(selectAccessToken);

    if (!loggedInUser) {
      yield put({
        type: articleActionTypes.FETCH_OWN_ARTICLES_FAILURE,
        payload: {
          error: { message: 'No User Logged in!' },
        },
      });
      return;
    }

    const response = yield call(
      articleService.getArticlesForUser,
      loggedInUser.customerUid,
      accessToken,
      sortingClause,
    );

    if (response && response.status === OK) {
      yield put(fetchOwnArticlesSuccess(response.data));
    } else {
      yield put(
        fetchOwnArticlesFailure(
          new Error(`Fetch articles completed with response${response}`),
        ),
      );
    }
  } catch (error) {
    yield put(fetchOwnArticlesFailure(error));
  } finally {
    yield put(fetchOwnArticlesFulfill());
  }
}

function* watchGetOwnArticles() {
  yield takeLatest(
    articleActionTypes.FETCH_OWN_ARTICLES_REQUEST,
    getOwnArticlesSaga,
  );
}

// --

const articleSagas = {
  watchGetArticles,
  watchGetArticle,
  watchPostArticle,
  watchPostArticleDraft,
  watchDeleteArticle,
  watchDeleteImageFromArticle,
  watchPostArticleImage,
  watchUpdateArticle,
  watchGetOwnArticles,
};

export default articleSagas;
