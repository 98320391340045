/**
 * A component able to display a profile Image or any given imagesource
 * on a canvas.
 * You can pass it either the Uuid of a profileImage, and it will try to resolve it by
 * generating a presigned-download url.
 * Alternatively, you can pass an already resolved image-source to the component with the <imagesource> Prop.
 */

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Group, Image as KonvaImage, Label, Text } from 'react-konva';
import { connect } from 'react-redux';
import { Store } from 'redux';
import { Family } from '../../../common/models/Family';
import { selectCurrentlyActiveFamily } from '../../../common/redux/families/family.selectors';
import { fetchPresignedImageUrlRequest } from '../../../common/redux/images/image.actions';
import { selectPresignedUrlForImage } from '../../../common/redux/images/image.selectors';
import { ApplicationState } from '../../../common/redux/main/types';
import colors from '../../00_Constants/colors';
import FZCanvasTooltip from '../FZCanvasTooltip/FZCanvasTooltip';

const styles = (theme: Theme) =>
  createStyles({
    profileImageStyle: {
      border: '2px solid black',
      borderRadius: '100px',
    },
  });

type State = {
  imageToDisplay: HTMLImageElement | null;
  imageToDisplayWidth: number;
  imageToDisplayHeight: number;
  imageToDisplayAspectRatio: number;
  imageToDisplayScaleX: number;
  imageToDisplayScaleY: number;
  imageContainerWidth: number;
  imageContainerHeight: number;
  hovered: boolean;
};

type Props = WithStyles &
  WithTranslation & {
    //
    profilePictureUuid?: string;
    imageSource?: string;
    label: string;
    dispatch?: any;
    store?: Store;
    x: number;
    y: number;
    classes?: any;

    connectTo?: any;
    connectorColor?: string;
    draggable?: boolean;
    onDragEnd?: (event: any) => void;
    onDragMove?: (event: any) => void;
    onClick?: (event: any) => void;

    // Redux Props
    presignedProfileImageDownloadUrl?: string;
    currentlyActiveFamily?: Family | null;
    drawConnector?: () => JSX.Element;

    statusLabel?: string;
    tooltipLabel?: string;

    bottomRightBadge?: JSX.Element;
  };

class FZCanvasAvatar extends Component<Props, State> {
  image: HTMLImageElement | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      imageToDisplay: null,
      imageToDisplayWidth: 0,
      imageToDisplayHeight: 0,
      imageToDisplayScaleX: 1,
      imageToDisplayScaleY: 1,
      imageToDisplayAspectRatio: 0,
      imageContainerWidth: 100, //57,
      imageContainerHeight: 100, //57,
      hovered: false,
    };
  }

  /**
   * Clips an image to a round shape
   * @param ctx - HTML5 canvas context
   * @param {number} x - horizontal Position of the image
   * @param {number} y - vertical posiiton of the image
   * @param {number} width - width of the image
   * @param {number} height - height of the image
   * @param {number} radius - radius of the cropped circle
   */
  clipImage = (
    ctx: any,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
  ) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  };

  componentDidMount() {
    const {
      dispatch,
      presignedProfileImageDownloadUrl,
      profilePictureUuid,
      currentlyActiveFamily,
      imageSource,
    } = this.props;

    // If a profilePictureUuid was passed and we could not yet select a cached download url from Redux for it, fetch such an url for that image.
    if (profilePictureUuid && !presignedProfileImageDownloadUrl) {
      dispatch(
        fetchPresignedImageUrlRequest(
          profilePictureUuid,
          null,
          null,
          currentlyActiveFamily?.uuid || null,
        ),
      );
    }

    // If there is a download url, but not yet an image to display, load it.
    if (presignedProfileImageDownloadUrl && !this.state.imageToDisplay) {
      this.loadImage(presignedProfileImageDownloadUrl);
    }

    if (imageSource) {
      this.loadImage(imageSource);
    }
  }

  componentDidUpdate(oldProps: Props) {
    if (oldProps.profilePictureUuid !== this.props.profilePictureUuid) {
      this.loadImage(this.props.presignedProfileImageDownloadUrl);
    }

    if (
      !this.state.imageToDisplay &&
      this.props.presignedProfileImageDownloadUrl
    ) {
      this.loadImage(this.props.presignedProfileImageDownloadUrl);
    }
  }

  /*   static getDerivedStateFromProps(props: Props, state: State): any {
       if (props.profilePictureUuid && !props.presignedProfileImageDownloadUrl) {
      props.dispatch(
        fetchPresignedImageUrlRequest(
          props.profilePictureUuid,
          null,
          null,
          props.currentlyActiveFamily?.uuid || null,
        ),
      ); 
    }

    // Return null if the state hasn't changed
    return null;
  } */

  /**
   * Registers an onloaad callback on the image that shall be displayed
   */
  loadImage = (imageSource: string) => {
    const { classes } = this.props;
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.className = classes.profileImageStyle;
    this.image.src = imageSource;
    this.image.addEventListener('load', this.handleLoad);
  };

  /**
   * Handles the onload event of the given image "props.imageToDisplay".
   */
  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    /* const imageToDisplayAspectRatio =
      this.image.naturalHeight / this.image.naturalWidth;
    const imageContainerAspectRatio =
      this.state.imageContainerHeight / this.state.imageContainerHeight; */
    const imageToDisplayScaleX = 1;
    const imageToDisplayScaleY = 1;

    /* if (imageContainerAspectRatio > imageToDisplayAspectRatio) {
      imageToDisplayScaleX =
        this.state.imageContainerWidth / this.image.naturalWidth;
      imageToDisplayScaleY = imageToDisplayScaleX;
    } else if (imageToDisplayAspectRatio > imageContainerAspectRatio) {
      imageToDisplayScaleY =
        this.state.imageContainerHeight / this.image.naturalHeight;
      imageToDisplayScaleX = imageToDisplayScaleY;
    } */

    this.setState({
      imageToDisplay: this.image,
      imageToDisplayHeight: this.image.naturalHeight,
      imageToDisplayWidth: this.image.naturalWidth,
      imageToDisplayScaleX: imageToDisplayScaleX,
      imageToDisplayScaleY: imageToDisplayScaleY,
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };

  handleMouseEnter = () => {
    this.setState({ hovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ hovered: false });
  };

  /**
   * A function that restricts the drag of each element to a circle.
   */
  restrictDragToCircle = (pos: any): any => {
    const x = 1000 / 2;
    const y = 70;
    const radius = 50;
    const scale =
      radius / Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
    if (scale < 1)
      return {
        y: Math.round((pos.y - y) * scale + y),
        x: Math.round((pos.x - x) * scale + x),
      };
    else return pos;
  };

  render() {
    const {
      imageToDisplay,
      imageContainerWidth,
      imageContainerHeight,
      imageToDisplayScaleX,
      imageToDisplayScaleY,
      hovered,
    } = this.state;
    const {
      x,
      y,
      label,
      draggable,
      onDragMove,
      onDragEnd,
      onClick,

      tooltipLabel,
      bottomRightBadge,
    } = this.props;
    return (
      <Group
        draggable={draggable}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        x={x}
        y={y}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={onClick}
        offsetX={25}
        offsetY={25}
      >
        <Group
          clipFunc={(ctx: any) =>
            this.clipImage(
              ctx,
              -25,
              -25,
              imageContainerWidth,
              imageContainerHeight,
              imageContainerWidth / 2 + 4,
            )
          }
        >
          <KonvaImage
            image={imageToDisplay}
            zIn
            width={imageContainerWidth}
            height={imageContainerHeight}
            scaleX={imageToDisplayScaleX}
            scaleY={imageToDisplayScaleY}
            offsetX={25}
            offsetY={25}
            strokeWidth={3}
            stroke={'black'}
            strokeEnabled={false}
            fill={colors.secondary}
            key={imageToDisplay?.src}
          ></KonvaImage>
        </Group>

        {bottomRightBadge && bottomRightBadge}

        <Label
          offsetX={imageContainerWidth / 2}
          offsetY={-imageContainerHeight / 2 - imageContainerHeight / 4}
        >
          {/*  <Tag fill={'#FFFFFF'} cornerRadius={8}></Tag> */}
          <Text
            fill={hovered ? 'gray' : 'black'}
            wrap="word"
            align="center"
            /*   padding={8} */
            width={150}
            height={30}
            text={label}
            fontSize={14}
            fontFamily={
              'OpenSansRegular'
              // 'OpenSansRegular,Open Sans,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
            }
          ></Text>
        </Label>

        {hovered && tooltipLabel && (
          <FZCanvasTooltip
            text={tooltipLabel}
            offsetX={imageContainerWidth / 2 + 45}
            offsetY={60}
            fillColor={'#707070'}
          ></FZCanvasTooltip>
        )}
      </Group>
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: Props) => {
  const profileImageUuid = ownProps.profilePictureUuid;

  return {
    currentlyActiveFamily: selectCurrentlyActiveFamily(state),
    presignedProfileImageDownloadUrl: selectPresignedUrlForImage(
      state,
      profileImageUuid,
    ),
  };
};

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps)(FZCanvasAvatar)),
);
