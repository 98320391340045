import { OK } from 'http-status-codes';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { FeedContent } from '../../models/FeedContent';
import FeedService from '../../services/FeedService';
import { selectAccessToken } from '../login/login.selectors';
import { selectLoggedInUserUid } from '../users/user.selectors';
import {
  feedActionTypes,
  fetchPersonalizedFeedFailure,
  fetchPersonalizedFeedFulfill,
  fetchPersonalizedFeedSuccess,
} from './feed.actions';

function* getPersonalizedFeedSaga(action: any) {
  try {
    // Get Service from redux-saga-context
    const feedService: FeedService = yield getContext('feedService');
    const authToken: string = yield select(selectAccessToken);
    const userUid: string = yield select(selectLoggedInUserUid);

    if (!userUid) {
      yield put(fetchPersonalizedFeedFailure(new Error('No User logged in')));
      return;
    }

    const response = yield call(
      feedService.getPersonalizedFeed,
      userUid,
      authToken,
    );

    if (response && response.status === OK) {
      const feedContent: FeedContent = {
        resources: response.data.blogPosts,
      };

      yield put(fetchPersonalizedFeedSuccess(feedContent));
    }
  } catch (error) {
    yield put(fetchPersonalizedFeedFailure(error));
  } finally {
    yield put(fetchPersonalizedFeedFulfill());
  }
}

function* watchGetPersonalizedFeed() {
  yield takeLatest(
    feedActionTypes.FETCH_PERSONALIZED_FEED_REQUEST,
    getPersonalizedFeedSaga,
  );
}

// --

const feedSagas = {
  watchGetPersonalizedFeed,
};

export default feedSagas;
