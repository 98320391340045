import { makeStyles, Theme } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../../00_Constants/colors';
import FZButton from '../../../01_atoms/FZButton/FZButton';
import FZFieldLabel from '../../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZFieldValue from '../../../01_atoms/FZFieldValue/FZFIeldValue';
import FZTextInputField from '../../../01_atoms/FZTextInputField/FZTextInputField';

const useStyles = makeStyles((theme: Theme) => {
  return {
    displayNameValue: {
      marginBottom: 50,
      marginTop: 22,
    },

    formInputField: {
      width: '100%',
    },

    submitButton: {},

    submitButtonContainer: {
      marginTop: 46,
    },

    newDisplayNameLabel: { marginBottom: 16 },
  };
});

export interface EditDisplayNameValues {
  newDisplayName: string;
}

interface Props {
  currentDisplayName: string;
  onSubmit: (
    values: EditDisplayNameValues,
    helpers: FormikHelpers<EditDisplayNameValues>,
  ) => void;
}

export default function FZEditDisplayNameForm({
  currentDisplayName,
  onSubmit,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [activeField, setActiveField] = useState('');

  const handleFormFieldFocus = (e: any) => {
    setActiveField(e.target.name);
  };

  const handleFormFieldBlur = (e: any) => {
    setActiveField('');
  };
  const classes = useStyles();

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          newDisplayName: '',
        }}
        validate={values => {
          const errors: Partial<EditDisplayNameValues> = {};

          if (!values.newDisplayName) {
            errors.newDisplayName = t(
              'editDisplayNameScreen-displayName-required-message',
            );
          }

          return errors;
        }}
        onSubmit={(values, helpers) => {
          onSubmit(values, helpers);
        }}
      >
        {({ submitForm, isSubmitting, errors }) => (
          <Form>
            <FZFieldLabel
              label={t(
                'editDisplayNameScreen-your-current-displayName-is-headline',
              )}
            ></FZFieldLabel>

            <FZFieldValue
              label={currentDisplayName}
              className={classes.displayNameValue}
            ></FZFieldValue>

            <FZFieldLabel
              label={t(
                'editDisplayNameScreen-your-new-displayName-is-headline',
              )}
              className={classes.newDisplayNameLabel}
            ></FZFieldLabel>

            <Field
              id="newDisplayName"
              name="newDisplayName"
              data-testid="newDisplayNameField"
              component={FZTextInputField}
              className={classes.formInputField}
              placeholder={t(
                'editDisplayNameScreen-new-displayName-field-label',
              )}
              color={colors.quartiary}
              backgroundColor={colors.white}
              characterLimit={50}
              enforceCharacterLimit
              displayCharacterCount={activeField === 'newDisplayName'}
              onFocus={handleFormFieldFocus}
              onBlur={handleFormFieldBlur}
            />

            <span className={classes.submitButtonContainer}>
              <FZButton
                className={classes.submitButton}
                label={t('editDisplayNameScreen-finish-editing-button-label')}
                backgroundColor={colors.quartiary}
                isSubmitting={isSubmitting}
                type="submit"
                variant="contained"
              ></FZButton>
            </span>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
