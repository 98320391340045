import { Grid, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

interface Props {
  children: JSX.Element | JSX.Element[];
  containerClassName?: string;
}

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 48,
  },
});

export default function ContentPageTemplate({
  children,
  containerClassName,
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <Grid container className={containerClassName}>
      <Grid item xs={1} md={2}></Grid>
      <Grid item xs={10} md={8} className={classes.contentContainer}>
        {children}
      </Grid>
      <Grid item xs={1} md={2}></Grid>
    </Grid>
  );
}
