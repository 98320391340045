import { OK } from 'http-status-codes';
import { apiClient, API_CONFIG } from '../api';
import applicationConfig from '../config/application_config';

interface Dictionary<T> {
  [Key: string]: T;
}

interface PresignedFetchUrl {
  url: string;
  createdAt: number; // The time that this Url was created in UTC Milliseconds
}

class ImageService {
  constructor() {
    return this;
  }

  /**
   * A dictionary of image urls that can be accessed.
   * This is maintained since the backend service hosts images on a protected server,
   * and clients need to request pre-signed urls that are valid for a given period of time
   * before they expire and need to be refetched. In order not to generate a new API Request
   * each time an image shall be displayed, we cache the already resolved urls here.
   */
  resolvedImageUrls: Dictionary<PresignedFetchUrl> = {};

  getImageUrlCacheEntryForKey(imageUuid: string) {
    if (!imageUuid) {
      return null;
    }

    return this.resolvedImageUrls[imageUuid];
  }

  setImageUrlCacheEntry(imageUuid: string, entry: PresignedFetchUrl) {
    return (this.resolvedImageUrls[imageUuid] = entry);
  }

  async getTemporaryAccessUrlForProtectedImageInNewspaper(
    accessToken: string,
    familyUuidOfFamilyThatOwnsNewspaper: string,
    newspaperUuidOfNewspaperInWhichArticleIsPublished: string,
    articleUuidOfArticleToWhichTheImageBelongs: string,
    imageUuidOfImageThatShallBeAccessed: string,
  ) {
    // Else, refetch it and save it to the dictionary / cache
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const presignedUrlResponse = await apiClient.get(
        API_CONFIG.IMAGE_SERVICE.GET_PRESIGNED_URL_FOR_PROTECTED_NEWSPAPER_ARTICLE_IMAGE(
          familyUuidOfFamilyThatOwnsNewspaper,
          newspaperUuidOfNewspaperInWhichArticleIsPublished,
          articleUuidOfArticleToWhichTheImageBelongs,
          imageUuidOfImageThatShallBeAccessed,
        ),
        { headers },
      );

      if (presignedUrlResponse && presignedUrlResponse.status === OK) {
        const presignedUrlToSave: PresignedFetchUrl = {
          url: presignedUrlResponse.data,
          createdAt: Date.now(),
        };

        return presignedUrlToSave;
      }
    } catch (error) {
      return null;
    }

    return null;
  }

  /**
   * Takes a url of a protected resource hosted externally, requests a presigned url to fetch it
   * and returns the url under which the resource can temporarily be accessed.
   */
  async getTemporaryAccessUrlForProtectedImage(
    accessToken: string,
    imageUuid: string,
  ): Promise<PresignedFetchUrl | null> {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const presignedUrlResponse = await apiClient.get(
        API_CONFIG.IMAGE_SERVICE.GET_PRESIGNED_URL_FOR_PROTECTED_IMAGE_URI(
          imageUuid,
        ),
        { headers },
      );

      if (presignedUrlResponse && presignedUrlResponse.status === OK) {
        const presignedUrlToSave: PresignedFetchUrl = {
          url: presignedUrlResponse.data,
          createdAt: Date.now(),
        };
        return presignedUrlToSave;
      }
    } catch (error) {
      return null;
    }

    return null;
  }

  /**
   * Allows to fetch a download url for a resource that only family members have access to.
   * @param {string} accessToken - personal access token that allows to call the api
   * @param {string} familyUuid - Universally unique identifier for the family that owns the resource
   * @param {string} imageUuid  - Universally unique identifier for the image / resource that shall be downloaded.
   */
  async getTemporaryAccessUrlForFamilyResource(
    accessToken: string,
    familyUuid: string,
    imageUuid: string,
  ): Promise<PresignedFetchUrl | null> {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const presignedUrlResponse = await apiClient.get(
        API_CONFIG.IMAGE_SERVICE.GET_PRESIGNED_URL_FOR_PROTECTED_FAMILY_RESOURCE_URL(
          familyUuid,
          imageUuid,
        ),
        { headers },
      );

      if (presignedUrlResponse && presignedUrlResponse.status === OK) {
        const presignedUrlToSave: PresignedFetchUrl = {
          url: presignedUrlResponse.data,
          createdAt: Date.now(),
        };
        return presignedUrlToSave;
      }
    } catch (error) {
      return null;
    }
    return null;
  }

  private presignedUrlStillValid(url: PresignedFetchUrl): boolean {
    return (
      url.createdAt +
        applicationConfig.FEATURE_CONFIG.imageHandling
          .imagePresignedUrlsValidityDurationInSeconds -
        Date.now() <
      0
    );
  }

  /**
   * Fetches a url from the server that allows to upload images directly to the designated image storage provider
   * rather than proxying over our backend.
   * @param accessToken
   * @param fileToUploadContentType
   */
  async getPresignedImageUploadUrl(
    accessToken: string,
    fileToUploadContentType: string,
  ) {
    return apiClient.get(
      API_CONFIG.IMAGE_SERVICE.GET_PRESIGNED_UPLOAD_FILE_UPLOAD_URL(),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          fileToUploadContentType,
        },
      },
    );
  }

  /**
   * Uploads a base64 image file directly to a designated file storage provider
   * using a previously generated "presigned url", which allows a "PUT" Operation onto a resource
   * that would otherwise be protected.
   * @param presignedUploadUrl - The presigned put-url to which the file can be uploaded
   * @param imageToUploadBlob - The image to upload in blob representation.
   */
  async uploadImageToDirectlyToStorageProvider(
    presignedUploadUrl: string,
    imageToUploadBlob: Blob,
    imageToUploadContentType: string,
    uploadProgressCallback: (progressEvent: any) => void,
  ) {
    return apiClient.put(presignedUploadUrl, imageToUploadBlob, {
      headers: {
        'x-amz-acl': 'private',
        'Content-Type': imageToUploadContentType,
      },
      onUploadProgress: uploadProgressCallback,
    });
  }
}
export default ImageService;
