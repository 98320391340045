import { Card, Grid, makeStyles, Popper, Theme } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../../00_Constants/colors';
import FZButton from '../../../01_atoms/FZButton/FZButton';
import FZFieldLabel from '../../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZSpinner from '../../../01_atoms/FZSpinner';
import FZTextInputField from '../../../01_atoms/FZTextInputField/FZTextInputField';
import FZToggleLabel from '../../../01_atoms/FZToggleLabel/FZToggleLabel';
import { useInputValidationService } from '../../../Hooks/useInputValidation';

const useStyles = makeStyles((theme: Theme) => {
  return {
    passwordValue: {
      marginBottom: 50,
      marginTop: 22,
    },

    formInputField: {
      width: '100%',
      //marginTop: 16,
      //marginBottom: 16,
    },

    submitButton: {
      marginTop: 46,
    },

    newPasswordValue: { marginBottom: 16 },
  };
});

export interface EditPasswordFormValues {
  oldPassword: string;
  newPassword: string;
  repeatedNewPassword: string;
}

interface Props {
  onSubmit: (
    values: EditPasswordFormValues,
    helpers: FormikHelpers<EditPasswordFormValues>,
  ) => void;
  formEnabled: boolean;
}

export default function EditPasswordForm({
  onSubmit,
  formEnabled,
}: Props): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();
  const inputValidationService = useInputValidationService();
  const [passwordFieldFocused, setpasswordFieldFocused] = useState<boolean>(
    false,
  );
  const passwordFieldRef = useRef();
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          repeatedNewPassword: '',
        }}
        validate={values => {
          const errors: Partial<EditPasswordFormValues> = {};

          const passwordIsStrongEnough =
            inputValidationService.testIfPasswordHasEightCharacters(
              values.newPassword,
            ) &&
            inputValidationService.testIfPasswordHasLowercaseLetter(
              values.newPassword,
            ) &&
            inputValidationService.testIfPasswordHasUpperCaseLetter(
              values.newPassword,
            ) &&
            inputValidationService.testIfPasswordHasNumber(
              values.newPassword,
            ) &&
            inputValidationService.testIfPasswordHasSpecialCharacter(
              values.newPassword,
            );

          // Regex for at least 8 digits, one uppercase, one lowercase letter, one digit
          if (!passwordIsStrongEnough) {
            errors.newPassword = t('signupScreen-password-too-weak-message');
          }

          if (!values.repeatedNewPassword) {
            errors.repeatedNewPassword = t(
              'signupScreen-repeated-password-required-message',
            );
          }

          if (values.newPassword !== values.repeatedNewPassword) {
            errors.repeatedNewPassword = t(
              'signupScreen-passwords-dont-match-message',
            );
          }

          return errors;
        }}
        onSubmit={(values, helpers) => {
          onSubmit(values, helpers);
        }}
      >
        {({
          values,
          submitForm,
          isSubmitting,
          errors,
          handleReset,
          setFieldValue,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginBottom: 32 }}>
                <FZFieldLabel
                  label={t('editPasswordScreen-oldPassword-field-label')}
                ></FZFieldLabel>
                <Field
                  id="oldPassword"
                  name="oldPassword"
                  data-testid="oldPasswordField"
                  component={FZTextInputField}
                  className={classes.formInputField}
                  label={''}
                  placeholder={''}
                  color={colors.quartiary}
                  backgroundColor={colors.white}
                  disabled={formEnabled === false}
                  type="password"
                />
              </Grid>

              <Grid item xs={12}>
                <FZFieldLabel
                  label={t('editPasswordScreen-newPassword-field-label')}
                ></FZFieldLabel>
                <Field
                  id="newPassword"
                  name="newPassword"
                  data-testid="newPasswordField"
                  component={FZTextInputField}
                  className={classes.formInputField}
                  label={''}
                  placeholder={''}
                  color={colors.quartiary}
                  backgroundColor={colors.white}
                  disabled={formEnabled === false}
                  onFocus={() => {
                    setpasswordFieldFocused(true);
                  }}
                  onBlur={(event: any) => {
                    setpasswordFieldFocused(false);
                  }}
                  inputRef={passwordFieldRef}
                  autoComplete={'new-password'}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <FZFieldLabel
                  label={t(
                    'editPasswordScreen-repeatedNewPassword-field-label',
                  )}
                ></FZFieldLabel>
                <Field
                  id="repeatedNewPassword"
                  name="repeatedNewPassword"
                  data-testid="repeatedNewPasswordField"
                  component={FZTextInputField}
                  className={classes.formInputField}
                  label={''}
                  placeholder={''}
                  color={colors.quartiary}
                  backgroundColor={colors.white}
                  disabled={formEnabled === false}
                  type="password"
                />
              </Grid>

              <Grid item xs={12}>
                {isSubmitting ? (
                  <FZSpinner
                    color={colors.quartiary}
                    statusMessage={t(
                      'editPasswordScreen-isSubmitting-spinner-message',
                    )}
                  ></FZSpinner>
                ) : (
                  <FZButton
                    variant="contained"
                    className={classes.submitButton}
                    label={t('editPasswordScreen-finish-editing-button-label')}
                    backgroundColor={colors.quartiary}
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={formEnabled === false}
                  ></FZButton>
                )}
              </Grid>
            </Grid>

            <Popper
              disablePortal
              open={passwordFieldFocused}
              anchorEl={passwordFieldRef.current}
              placement={'top-start'}
              modifiers={{
                flip: {
                  enabled: false,
                },
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'scrollParent',
                },
                arrow: {
                  enabled: false,
                  element: passwordFieldRef.current,
                },
              }}
              style={{ zIndex: 5, marginBottom: 8 }}
              children={
                <Card style={{ padding: 16 }}>
                  <FZFieldLabel
                    label={t(
                      'resetPasswordScreen-passwordRequirements-headline',
                    )}
                  ></FZFieldLabel>
                  <FZToggleLabel
                    toggleEnabled={
                      !inputValidationService.testIfPasswordHasEightCharacters(
                        values.newPassword,
                      )
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-eight-characters-message',
                    )}
                  ></FZToggleLabel>
                  <FZToggleLabel
                    toggleEnabled={
                      !inputValidationService.testIfPasswordHasLowercaseLetter(
                        values.newPassword,
                      )
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-one-lowercase-letter-message',
                    )}
                  ></FZToggleLabel>
                  <FZToggleLabel
                    toggleEnabled={
                      !inputValidationService.testIfPasswordHasUpperCaseLetter(
                        values.newPassword,
                      )
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-one-uppercase-letter-message',
                    )}
                  ></FZToggleLabel>
                  <FZToggleLabel
                    toggleEnabled={
                      !inputValidationService.testIfPasswordHasNumber(
                        values.newPassword,
                      )
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-one-digit-message',
                    )}
                  ></FZToggleLabel>

                  <FZToggleLabel
                    toggleEnabled={
                      !inputValidationService.testIfPasswordHasSpecialCharacter(
                        values.newPassword,
                      )
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-one-special-letter-message',
                    )}
                  ></FZToggleLabel>
                </Card>
              }
              transition
            ></Popper>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
