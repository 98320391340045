import { useState } from 'react';
import ContactRequestService from '../../common/services/ContactRequestService';

export function useContactRequestService(): ContactRequestService {
  const [contactRequestService, setContactRequestService] = useState<
    ContactRequestService
  >(null);

  if (!contactRequestService) {
    const contactRequestService = new ContactRequestService();
    setContactRequestService(contactRequestService);
  }

  return contactRequestService;
}
