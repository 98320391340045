import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';
import React, { CSSProperties, ReactElement } from 'react';
import FZButton from '../../01_atoms/FZButton/FZButton';
import FZTextInputField from '../../01_atoms/FZTextInputField/FZTextInputField';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      marginBottom: 8,
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },

    submitButton: {
      marginLeft: 16,

      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },

    inputField: {
      flexBasis: '80%',
    },
  };
});

export interface ListEntererValues {
  currentListEntryValue: string;
}

interface Props {
  style?: CSSProperties;
  keyColor: string;

  /**
   * TextField props
   */
  textFieldPlaceholder: string;
  textFieldEnabled: boolean;
  textFieldLabel: string;

  /**
   * Submit Button props
   */
  buttonLabel: string;
  buttonLabelColor: string;
  buttonBackgroundColor: string;

  /**
   * Validator for the inout
   */
  validate: (values: ListEntererValues) => Partial<ListEntererValues>;

  containerClassName?: string;
  inputFieldClassName?: string;
  buttonClassName?: string;

  onElementSubmitted: (element: string) => void;
}

export default function FZListEnterer(props: Props): ReactElement {
  const {
    keyColor,
    textFieldEnabled,
    textFieldPlaceholder,
    textFieldLabel,
    buttonLabel,
    buttonLabelColor,
    buttonBackgroundColor,
    onElementSubmitted,
    containerClassName,
    inputFieldClassName,
    buttonClassName,
    validate,
  } = props;
  const classes = useStyles({});

  return (
    <Formik
      initialValues={{
        currentListEntryValue: '',
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validate}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        resetForm();
        onElementSubmitted(values.currentListEntryValue);
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form
          className={clsx(classes.container, containerClassName)}
          style={props.style}
        >
          <Field
            id="currentListEntryValue"
            name="currentListEntryValue"
            data-testid="currentListEntryValue"
            component={FZTextInputField}
            label={textFieldLabel}
            placeholder={textFieldPlaceholder}
            color={keyColor}
            disabled={textFieldEnabled === false}
            className={clsx(classes.inputField, inputFieldClassName)}
          />

          <FZButton
            className={clsx(classes.submitButton, buttonClassName)}
            backgroundColor={buttonBackgroundColor}
            label={buttonLabel}
            labelColor={buttonLabelColor}
            onClick={() => {
              submitForm();
            }}
          ></FZButton>
        </Form>
      )}
    </Formik>
  );
}
