import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../00_Constants/colors';
import FZButton from '../../01_atoms/FZButton/FZButton';

const useStyles = makeStyles((theme: Theme) => {
  return {
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  };
});

interface Props {
  dialogClassName?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClick?: () => void;
  open: boolean;
  title: string;
  children: JSX.Element | JSX.Element[];
  variant: 'confirm' | 'generic';
  color?: string;
  onClose?: () => void;
  dialogProps?: DialogProps;
  displayCloseIcon?: boolean;
}

export default function FZDialog(props: Props): ReactElement {
  const {
    dialogClassName,
    onCancel,
    open,
    title,
    variant,
    children,
    color,
    onConfirm,
    onClose,
    displayCloseIcon,
    dialogProps,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={onClose}
      className={dialogClassName}
      maxWidth={'lg'}
      scroll={'paper'}
      {...dialogProps}
    >
      <DialogTitle id="confirmation-dialog-title">
        {title}

        {displayCloseIcon && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseRoundedIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>

      {variant === 'confirm' && (
        <DialogActions>
          <FZButton
            onClick={onCancel}
            label={t('FZDialog-confirm-variant-cancel-button-label')}
            /* labelColor={colors.textMedium || 'black'} */
            variant="outlined"
          ></FZButton>

          <FZButton
            onClick={onConfirm}
            label={t('FZDialog-confirm-variant-confirm-button-label')}
            backgroundColor={color || colors.primary}
            variant="contained"
          ></FZButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
