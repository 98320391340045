import { CircularProgress, CssBaseline } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import './App.css';
import config from './common/auth/auth_config';
import { selectAuthState } from './common/redux/users/user.selectors';
import theme from './common/themes/fz_theme';
import FZStatusFlash from './components/02_molecules/FZStatusFlash/FZStatusFlash';
import ProtectedRoute from './components/03_organisms/ProtectedRoute/ProtectedRoute';
import AddArticleScreen from './components/05_pages/addArticle/AddArticleScreen';
import AcceptInvitationScreen from './components/05_pages/auth/acceptInvitation/AcceptInvitationScreen';
import AcceptPersonalizedInvitationScreen from './components/05_pages/auth/acceptPersonalizedInvitation/AcceptPersonalizedInvitationScreen';
import LandingScreen from './components/05_pages/auth/login/LandingScreenRedesigned';
import LoginScreen from './components/05_pages/auth/login/LoginScreen';
import InitiateResetPasswordFlowScreen from './components/05_pages/auth/login/resetPassword/InitiateResetPasswordScreen';
import { ResetPasswordEmailSentScreen } from './components/05_pages/auth/login/resetPassword/ResetPasswordEmailSentScreen';
import ResetPasswordScreen from './components/05_pages/auth/login/resetPassword/ResetPasswordScreen';
import SignoutSuccessfulScreen from './components/05_pages/auth/login/SignoutSuccessfulScreen';
import UpdateEmailScreen from './components/05_pages/auth/login/updatePrimaryEmailScreen/UpdateEmailScreen';
import { SignupEmailSentScreen } from './components/05_pages/auth/signup/SignupEmailSentScreen';
import SignupScreen from './components/05_pages/auth/signup/SignupScreen';
import VerifyEmailAdressScreen from './components/05_pages/auth/signup/VerifyEmailAdressScreen';
import ContactScreen from './components/05_pages/contact/ContactScreen';
import DataProtectionAgreementScreen from './components/05_pages/dataProtectionAgreementPage/DataProtectionAgreementScreen';
import { CreateFamilyScreen } from './components/05_pages/family/createFamilyPage/CreateFamilyScreen';
import EditFamilyScreen from './components/05_pages/family/editFamilyPage/EditFamilyScreen';
import EditSubscriptionsScreen from './components/05_pages/family/editSubscriptionsPage/EditSubscriptionsScreen';
import FamilyScreen from './components/05_pages/family/FamilyScreen';
import InviteFamilyMemberScreen from './components/05_pages/family/inviteFamilyMemberPage/InviteFamilyMemberScreen';
import HelpScreen from './components/05_pages/help/HelpScreen';
import HomeScreen from './components/05_pages/home/HomeScreen';
import ImprintScreen from './components/05_pages/imprintPage/ImprintScreen';
import NewsDetailScreen from './components/05_pages/news/NewsDetailScreen';
import NewsScreen from './components/05_pages/news/NewsScreen';
import ProfileScreen from './components/05_pages/profile/ProfileScreen';
import EditAdressScreen from './components/05_pages/settings/editAdress/EditAdressScreen';
import EditAuthorNameScreen from './components/05_pages/settings/editAuthorName/EditAuthorNameScreen';
import EditDisplayNameScreen from './components/05_pages/settings/editDisplayName/FZEditDisplayNameScreen';
import { EditEmailConfirmationSentScreen } from './components/05_pages/settings/editEmail/EditEmailConfirmationSentScreen';
import EditEmailScreen from './components/05_pages/settings/editEmail/EditEmailScreen';
import EditPasswordScreen from './components/05_pages/settings/editPassword/EditPasswordScreen';
import TermsOfServiceScreen from './components/05_pages/termsOfServicePage/TermsOfServiceScreen';
import FZErrorBoundary from './components/HOCs/FZErrorBoundary';
import history from './history';

const styles: (theme: Theme) => StyleRules<object> = theme =>
  createStyles({
    /*    '@global': {
      // MUI typography elements use REMs, so you can scale the global
      // font size by setting the font-size on the <html> element.
      html: {
        fontSize: 16,
        [theme.breakpoints.up('sm')]: {
          fontSize: 18,
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 20,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 24,
        },
      },
    }, */
  });

const AppWithRouterAccessContainer: React.FC = (props: any) => {
  const authState = useSelector(selectAuthState);

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<CircularProgress />}>
          <FZErrorBoundary>
            <FZStatusFlash />
            <Switch>
              <Route exact path="/" component={LandingScreen} />
              {/* Authentication - Login -  related pages */}
              <Route
                exact
                path="/login"
                render={() => <LoginScreen issuer={config.issuer} />}
              />

              {/* form to kick off the forgot-password flow using only ones account name */}
              <Route
                path="/forgot-password"
                component={InitiateResetPasswordFlowScreen}
              />

              <Route
                path="/reset-password-email-sent"
                component={ResetPasswordEmailSentScreen}
              />

              <Route path="/reset-password" component={ResetPasswordScreen} />

              <Route path="/update-email" component={UpdateEmailScreen} />

              <Route
                path="/accept-invite/:invitationCode"
                component={AcceptInvitationScreen}
                exact
              />

              <Route
                path="/accept-personalized-invite"
                exact
                component={AcceptPersonalizedInvitationScreen}
              />

              <Route
                exact
                path="/sign-out-successful"
                component={SignoutSuccessfulScreen}
              />
              {/* Authentication - signup -  related pages */}
              <Route exact path="/signup" render={() => <SignupScreen />} />
              <Route
                exact
                path="/signup/activation-email-sent"
                component={SignupEmailSentScreen}
              />

              <Route
                exact
                path="/activate-account"
                component={VerifyEmailAdressScreen}
              />

              {/* Account / User related pages */}
              <ProtectedRoute
                exact
                path="/profile"
                component={ProfileScreen}
                authState={authState}
              />

              <ProtectedRoute
                exact
                path="/profile/settings/edit-email"
                component={EditEmailScreen}
                authState={authState}
              />

              <ProtectedRoute
                exact
                path="/profile/settings/edit-displayName"
                component={EditDisplayNameScreen}
                authState={authState}
              />

              <ProtectedRoute
                exact
                path="/profile/settings/edit-email-confirmation-sent"
                component={EditEmailConfirmationSentScreen}
                authState={authState}
              />

              <ProtectedRoute
                exact
                path="/profile/settings/edit-password"
                component={EditPasswordScreen}
                authState={authState}
              />
              <ProtectedRoute
                exact
                path="/profile/settings/edit-adress"
                component={EditAdressScreen}
                authState={authState}
              />

              <ProtectedRoute
                exact
                path="/profile/settings/edit-authorname"
                component={EditAuthorNameScreen}
                authState={authState}
              />

              {/* Newspaper related pages */}
              <ProtectedRoute
                exact
                path="/home"
                component={HomeScreen}
                authState={authState}
              />
              <ProtectedRoute
                exact
                path="/write-article"
                component={AddArticleScreen}
                authState={authState}
              />
              {/* Family related pages */}
              <ProtectedRoute
                exact
                path="/family"
                component={FamilyScreen}
                authState={authState}
              />
              <ProtectedRoute
                exact
                path="/family/:name/inviteMember"
                component={InviteFamilyMemberScreen}
                authState={authState}
              />

              <ProtectedRoute
                exact
                path="/family/edit"
                component={EditFamilyScreen}
                authState={authState}
              />

              <ProtectedRoute
                exact
                path="/family/edit/subscriptions"
                component={EditSubscriptionsScreen}
                authState={authState}
              />

              <ProtectedRoute
                path="/create-new-family"
                component={CreateFamilyScreen}
                authState={authState}
              />

              {/* Newsfeed related pages */}
              <ProtectedRoute
                exact
                path="/news"
                component={NewsScreen}
                authState={authState}
              />
              <ProtectedRoute
                path="/news/:slug"
                component={NewsDetailScreen}
                authState={authState}
              />

              {/* Legal Pages  */}
              <Route
                exact
                path="/data-processing-agreement"
                component={DataProtectionAgreementScreen}
              ></Route>
              <ProtectedRoute
                exact
                path="/imprint"
                component={ImprintScreen}
                authState={authState}
              ></ProtectedRoute>
              <Route
                exact
                path="/terms-of-service"
                component={TermsOfServiceScreen}
              ></Route>
              {/* Misc Pages */}
              <Route exact path="/contact" component={ContactScreen}></Route>
              <Route exact path="/help" component={HelpScreen}></Route>
            </Switch>
          </FZErrorBoundary>
        </Suspense>
      </ThemeProvider>
    </Router>
  );
};

const AppWithRouterAccess = withStyles(styles)(AppWithRouterAccessContainer);
export default AppWithRouterAccess;
