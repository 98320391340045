import { action } from 'typesafe-actions';

export enum imageActionTypes {
  FETCH_PRESIGNED_DOWNLOAD_URL_REQUEST = 'FETCH_PRESIGNED_DOWNLOAD_URL_REQUEST',
  FETCH_PRESIGNED_DOWNLOAD_URL_SUCCESS = 'FETCH_PRESIGNED_DOWNLOAD_URL_SUCCESS',
  FETCH_PRESIGNED_DOWNLOAD_URL_FAILURE = 'FETCH_PRESIGNED_DOWNLOAD_URL_FAILURE',
  FETCH_PRESIGNED_DOWNLOAD_URL_FULFILL = 'FETCH_PRESIGNED_DOWNLOAD_URL_FULFILL',
}

//#region Fetch Presigned Image Download Url Action Creators
export type FetchPresignedUrlRequestAction = {
  type: imageActionTypes.FETCH_PRESIGNED_DOWNLOAD_URL_REQUEST;
  payload: {
    imageUuid: string;
    belongsToArticleWithUuid: string;
    publishedInNewspaperWithUuid: string | null;
    ownerFamilyUuid: string | null;
  };
};

export const fetchPresignedImageUrlRequest = (
  imageUuid: string,
  belongsToArticleWithUuid: string,
  publishedInNewspaper: string | null,
  ownerFamilyUuid: string | null,
): FetchPresignedUrlRequestAction =>
  action(imageActionTypes.FETCH_PRESIGNED_DOWNLOAD_URL_REQUEST, {
    imageUuid: imageUuid,
    belongsToArticleWithUuid: belongsToArticleWithUuid,
    publishedInNewspaperWithUuid: publishedInNewspaper,
    ownerFamilyUuid: ownerFamilyUuid,
  });

export type FetchPresignedUrlRequestSuccessAction = {
  type: imageActionTypes.FETCH_PRESIGNED_DOWNLOAD_URL_SUCCESS;
  payload: {
    imageUuid: string;
    presignedDownloadUrl: string;
    createdAt: number;
  };
};
export const fetchPresignedImageUrlSuccess = (
  imageUuid: string,
  presignedDownloadUrl: string,
  createdAt: number,
): FetchPresignedUrlRequestSuccessAction =>
  action(imageActionTypes.FETCH_PRESIGNED_DOWNLOAD_URL_SUCCESS, {
    imageUuid: imageUuid,
    presignedDownloadUrl: presignedDownloadUrl,
    createdAt: createdAt,
  });

export const fetchPresignedImageUrlFailure = (error: Error) => {
  return action(imageActionTypes.FETCH_PRESIGNED_DOWNLOAD_URL_FAILURE, error);
};

export const fetchPresignedImageUrlFulfill = () => {
  return action(imageActionTypes.FETCH_PRESIGNED_DOWNLOAD_URL_FULFILL);
};
//#endregion
