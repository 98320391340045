import { AnyAction, Reducer } from 'redux';
import { Article, ArticleDto } from '../../models/Article';
import { authenticationActionTypes } from '../login/login.actions';
import { articleActionTypes } from './article.actions';
import { ArticleReducerState } from './types';

const initialState: ArticleReducerState = {
  items: [],
  isLoading: false,
  error: null,

  isCreatingDraft: false,
  currentlyDraftedArticle: null,
  currentlyDraftedArticleImage: null,
  creatingArticleWasSuccessful: false,

  isUpdatingArticle: false,
  updatingArticleWasSuccessful: false,

  isPostingImage: false,
  postingImageWasSuccessful: false,

  ownArticles: [],
  isFetchingOwnArticles: false,

  isDeletingArticle: false,
  deletingArticleWasSuccessful: false,
};

const articleReducer: Reducer<ArticleReducerState> = (
  state = initialState,
  action: AnyAction,
) => {
  // redux calls reducers with an "undefined" state, when they are called for the first time
  // this allows to set the initial state
  switch (action.type) {
    case articleActionTypes.FETCH_OWN_ARTICLES_REQUEST:
      return { ...state, isFetchingOwnArticles: true, error: null };

    case articleActionTypes.FETCH_OWN_ARTICLES_SUCCESS:
      const ownArticles = action.payload.articles.reduce(
        (filteredDraftsArray: Article[], articleDto: ArticleDto) => {
          if (articleDto.isDraft) {
            filteredDraftsArray.push(new Article(articleDto));
          }
          return filteredDraftsArray;
        },
        [],
      );

      return {
        ...state,
        isFetchingOwnArticles: false,
        ownArticles,
      };

    case articleActionTypes.FETCH_OWN_ARTICLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case articleActionTypes.FETCH_ARTICLES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case articleActionTypes.FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload.articles.map((article: Article) => {
          return new Article(article);
        }),
      };

    case articleActionTypes.FETCH_ARTICLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case articleActionTypes.POST_ARTICLE_DRAFT_REQUEST:
      return {
        ...state,
        isCreatingDraft: true,
        creatingArticleWasSuccessful: false,
        currentlyDraftedArticle: null,
        currentlyDraftedArticleImage: null,
      };

    case articleActionTypes.POST_ARTICLE_DRAFT_SUCCESS:
      return {
        ...state,
        isCreatingDraft: false,
        currentlyDraftedArticle: new Article(action.payload),
        creatingArticleWasSuccessful: true,
      };

    case articleActionTypes.POST_ARTICLE_DRAFT_FAILURE:
      return {
        ...state,
        isCreatingDraft: false,
        creatingArticleWasSuccessful: false,
        error: action.payload,
      };

    case articleActionTypes.POST_ARTICLE_DRAFT_FULFILL:
      return {
        ...state,
        creatingArticleWasSuccessful: false,
      };

    case articleActionTypes.POST_ARTICLE_IMAGE_REQUEST:
      return { ...state, isPostingImage: true };

    case articleActionTypes.POST_ARTICLE_IMAGE_FAILURE:
      return { ...state, isPostingImage: false };

    case articleActionTypes.POST_ARTICLE_IMAGE_CANCELLED:
      return { ...state, isPostingImage: false };

    case articleActionTypes.POST_ARTICLE_IMAGE_SUCCESS: {
      return {
        ...state,
        isPostingImage: false,
        postingImageWasSuccessful: true,
      };
    }

    case articleActionTypes.POST_ARTICLE_IMAGE_FULFILL:
      return {
        ...state,
        postingImageWasSuccessful: false,
        currentlyDraftedArticleImage: null,
      };

    case articleActionTypes.UPDATE_ARTICLE_REQUEST:
      return { ...state, isUpdatingArticle: true };

    case articleActionTypes.UPDATE_ARTICLE_SUCCESS: {
      return {
        ...state,
        isUpdatingArticle: false,
        updatingArticleWasSuccessful: true,
      };
    }

    case articleActionTypes.UPDATE_ARTICLE_FAILURE:
      return {
        ...state,
        isUpdatingArticle: false,
        updatingArticleWasSuccessful: false,
        error: action.payload,
      };

    case articleActionTypes.UPDATE_ARTICLE_FULFILL:
      return {
        ...state,
        isUpdatingArticle: false,
        updatingArticleWasSuccessful: false,
      };

    case articleActionTypes.DELETE_ARTICLE_REQUEST:
      return { ...state, isDeletingArticle: true };

    case articleActionTypes.DELETE_ARTICLE_SUCESS:
      return {
        ...state,
        currentlyDraftedArticle: null,
        isDeletingArticle: false,
        deletingArticleWasSuccessful: true,
      };

    case articleActionTypes.DELETE_ARTICLE_FAILURE:
      return {
        ...state,
        isDeletingArticle: false,
        error: action.payload,
      };

    case articleActionTypes.DELETE_ARTICLE_FULFILL:
      return { ...state, deletingArticleWasSuccessful: false };

    case articleActionTypes.SET_CURRENTLY_EDITED_ARTICLE: {
      return { ...state, currentlyDraftedArticle: action.payload.article };
    }

    case articleActionTypes.RESET_CURRENTLY_EDITED_ARTICLE:
      return { ...state, currentlyDraftedArticle: null };

    case authenticationActionTypes.LOGIN_REQUEST: {
      return initialState;
    }

    case authenticationActionTypes.LOGIN_SUCCESS: {
      return initialState;
    }

    case authenticationActionTypes.LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      // ALWAYS have a default case in a reducer which returns the previous state
      // in case an unknown action is triggered
      return state;
  }
};

export default articleReducer;
