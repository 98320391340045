// src/ResetPasswordForm.jsx

import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import colors from '../../../../00_Constants/colors';
import FZSpinner from '../../../../01_atoms/FZSpinner';
import clsx from 'clsx';
import FZToggleLabel from '../../../../01_atoms/FZToggleLabel/FZToggleLabel';
import FZFieldLabel from '../../../../01_atoms/FZFieldLabel/FZFieldLabel';

const useStyles = makeStyles(theme => {
  return {
    formContainer: {
      position: 'relative',
      top: 0,
    },

    form: {
      paddingTop: 32,

      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
        width: '90%',
      },

      [theme.breakpoints.down('md')]: {
        margin: '0 auto',
        width: '80%',
      },

      [theme.breakpoints.up('lg')]: {
        margin: '0 auto',
        width: '40%',
      },
    },

    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },

    formInputFieldContainer: {
      paddingTop: 8,
      paddingBottom: 32,
      paddingLeft: 16,
      paddingRight: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '80%',
    },

    formInputField: {
      width: '100%',
    },

    formHelper: {
      maxWidth: '80%',
    },

    media: {
      borderRadius: 4,
      marginTop: 16,
      marginBottom: 32,
      marginLeft: 16,
      marginRight: 16,
    },

    resetPasswordNoticeContainer: {
      display: 'flex',
      flexDirection: 'row',

      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      paddingTop: 8,
    },

    needHelpLink: {},

    formButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap-reverse',

      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-around',
      },
    },

    passwordRequirementsHeadline: {
      marginBottom: 16,
      marginTop: 16,
      alignSelf: 'flex-start',
    },

    formButton: {
      [theme.breakpoints.down('md')]: {
        marginTop: 16,
      },
    },
  };
});

export interface ResetPasswordFormValues {
  password: string;
  repeatedPassword: string;
}

interface Props {
  authService?: any;

  onSubmit: (
    values: ResetPasswordFormValues,
    helpers: FormikHelpers<ResetPasswordFormValues>,
  ) => void;
  enabled: boolean;
}

const testIfPasswordHasEightCharacters = (password: string) => {
  const eightCharactersLongRegex = new RegExp('^(?=.{8,})');
  return eightCharactersLongRegex.test(password);
};

const testIfPasswordHasLowercaseLetter = (password: string) => {
  const lowerCaseAlphabeticalLetterRegex = new RegExp('[a-z]+');

  return lowerCaseAlphabeticalLetterRegex.test(password);
};

const testIfPasswordHasUpperCaseLetter = (password: string) => {
  const upperCaseAlphabeticalLetterRegex = new RegExp('[A-Z]+');
  return upperCaseAlphabeticalLetterRegex.test(password);
};

const testIfPasswordHasSpecialCharacter = (password: string) => {
  const specialCharacterRegexp = new RegExp('^(?=.*[!@#$%^&*])');
  return specialCharacterRegexp.test(password);
};

const testIfPasswordHasNumber = (password: string) => {
  const numberRegex = new RegExp('^(?=.*[0-9])');
  return numberRegex.test(password);
};

const ResetPasswordForm = (props: Props) => {
  const [sessionToken] = useState();
  const classes = useStyles({});
  const { t } = useTranslation();
  const history = useHistory();
  const { onSubmit } = props;

  function handleAbortPasswortReset() {
    history.goBack();
  }

  if (sessionToken) {
    // Hide form while sessionToken is converted into id/access tokens
    return null;
  }

  return (
    <Container className={classes.formContainer} style={{ marginBottom: 16 }}>
      <Formik
        initialValues={{
          password: '',
          repeatedPassword: '',
        }}
        validate={values => {
          const errors: Partial<ResetPasswordFormValues> = {};

          if (!values.password) {
            errors.password = t(
              'resetPasswordScreen-password-required-message',
            );
          }

          // TODO: Validate password strength - assign
          // TOO_SHORT
          // NO_NUMBER
          // NO_UPPERCASE_LETTER
          // NO_LOWERCASE_LETTER
          // NO_SPECIAL_LETTER
          // to errors.password

          /**
           * From: https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
           * ^	The password string will start this way
           * (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
           * (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
           * (?=.*[0-9])	The string must contain at least 1 numeric character
           * (?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
           * (?=.{8,})	The string must be eight characters or longer
           */

          const passwordIsStrongEnough =
            testIfPasswordHasEightCharacters(values.password) &&
            testIfPasswordHasLowercaseLetter(values.password) &&
            testIfPasswordHasUpperCaseLetter(values.password) &&
            testIfPasswordHasNumber(values.password) &&
            testIfPasswordHasSpecialCharacter(values.password);

          /*         const mediumPasswordStrengthRegex = new RegExp(
            '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})',
          );
          const strongPasswordStrengthRegex = new RegExp(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
          );

          const passwordStrengthIsMedium = mediumPasswordStrengthRegex.test(
            values.password,
          );
          const passwordStrengthIsHigh = strongPasswordStrengthRegex.test(
            values.password,
          );
          const passwordStrengthIsLow =
            !passwordStrengthIsHigh && !passwordStrengthIsMedium; 

          if (passwordStrengthIsLow) {
            errors.password = 'STRENGTH_0';
          }
          if (passwordStrengthIsMedium) {
            errors.password = 'STRENGTH_1';
          }
          if (passwordStrengthIsHigh) {
            errors.password = null;
          }
          */

          // Regex for at least 8 digits, one uppercase, one lowercase letter, one digit
          if (!passwordIsStrongEnough) {
            errors.password = t(
              'resetPasswordScreen-password-too-weak-message',
            );
          }

          if (!values.repeatedPassword) {
            errors.repeatedPassword = t(
              'resetPasswordScreen-repeated-password-required-message',
            );
          }

          if (values.password !== values.repeatedPassword) {
            errors.repeatedPassword = t(
              'resetPasswordScreen-passwords-dont-match-message',
            );
          }

          return errors;
        }}
        onSubmit={(values, helpers) => {
          onSubmit(values, helpers);
        }}
      >
        {({ values, submitForm, isSubmitting, errors }) => (
          <Form>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              className={classes.form}
            >
              <Card className={classes.card}>
                <Typography
                  variant={'h4'}
                  style={{ paddingTop: 32, paddingBottom: 16 }}
                >
                  {t('resetPasswordScreen-headline')}
                </Typography>

                <Grid xs={12} className={classes.formInputFieldContainer}>
                  <Field
                    component={TextField}
                    className={classes.formInputField}
                    type="password"
                    label={t('resetPasswordScreen-password-field-label')}
                    name="password"
                    variant="outlined"
                    color="secondary"
                  />
                </Grid>

                <Grid xs={12} className={classes.formInputFieldContainer}>
                  <Field
                    component={TextField}
                    className={classes.formInputField}
                    type="password"
                    label={t('resetPasswordScreen-repeat-password-field-label')}
                    name="repeatedPassword"
                    variant="outlined"
                    color="secondary"
                  />
                </Grid>

                <Grid xs={12} className={classes.formInputFieldContainer}>
                  <FZFieldLabel
                    className={classes.passwordRequirementsHeadline}
                    label={t(
                      'resetPasswordScreen-passwordRequirements-headline',
                    )}
                  ></FZFieldLabel>

                  <FZToggleLabel
                    toggleEnabled={
                      !testIfPasswordHasEightCharacters(values.password)
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-eight-characters-message',
                    )}
                  ></FZToggleLabel>

                  <FZToggleLabel
                    toggleEnabled={
                      !testIfPasswordHasLowercaseLetter(values.password)
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-one-lowercase-letter-message',
                    )}
                  ></FZToggleLabel>

                  <FZToggleLabel
                    toggleEnabled={
                      !testIfPasswordHasUpperCaseLetter(values.password)
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-one-uppercase-letter-message',
                    )}
                  ></FZToggleLabel>

                  <FZToggleLabel
                    toggleEnabled={!testIfPasswordHasNumber(values.password)}
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-one-digit-message',
                    )}
                  ></FZToggleLabel>

                  <FZToggleLabel
                    toggleEnabled={
                      !testIfPasswordHasSpecialCharacter(values.password)
                    }
                    message={t(
                      'resetPasswordForm-password-must-contain-at-least-one-special-letter-message',
                    )}
                  ></FZToggleLabel>
                </Grid>

                {isSubmitting && (
                  <FZSpinner
                    color={colors.secondary}
                    statusMessage={t(
                      'resetPasswordScreen-reset-in-progress-status-message',
                    )}
                  />
                )}

                <Grid
                  xs={12}
                  className={clsx(
                    classes.formInputFieldContainer,
                    classes.formButtonContainer,
                  )}
                >
                  <Button
                    className={classes.formButton}
                    variant="outlined"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={handleAbortPasswortReset}
                  >
                    {t('resetPasswordScreen-abort-label')}
                  </Button>
                  <Button
                    className={classes.formButton}
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {t('resetPasswordScreen-resetPasswordButton-label')}
                  </Button>
                </Grid>

                <Grid xs={12} className={classes.formInputFieldContainer}>
                  <Link
                    className={classes.needHelpLink}
                    href="/help"
                    color="secondary"
                  >
                    {t(
                      'resetPasswordScreen-do-you-need-help-resetting-your-password-label',
                    )}
                  </Link>
                </Grid>
              </Card>
              <Box className={classes.resetPasswordNoticeContainer}>
                <Link href="/login" color="primary" style={{ paddingLeft: 8 }}>
                  {t('resetPasswordScreen-back-to-sign-in-label')}
                </Link>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
export default ResetPasswordForm;
