class InputValidationService {
  /**
   * Validates the input parameter "email" to match the patterns allowed in modern email-adresses.
   * Be aware that this may exclude actual, in-use emails since it only implements RFC-Email-Format specifications.
   * @param {} email
   */
  validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  /**
   * Validates a given username to match the requirements set forward by the service.
   * @param {string} userName - the username to validate
   */
  validateUserName(userName: string) {
    return;
  }

  /**
   * Validates a given password to match the requirements set forward by the service.
   * @param {string} password - the password to validate against basic syntax rules
   */
  validatePassword(password: string) {
    return;
  }

  /**
   * validates that a given string has at least a given number of characters
   * @param {string} stringToTest
   * @param {number} minimumNumberOfCharacters
   */
  testIfStringHasMinimumLength = (
    stringToTest: string,
    minimumNumberOfCharacters: number,
  ) => {
    const minCharactersLongRegex = new RegExp(
      `^(?=.{${minimumNumberOfCharacters},})`,
    );
    return minCharactersLongRegex.test(stringToTest);
  };

  /**
   * Tests if a string is within a maximum character limit
   * @param {string} stringToTest
   * @param {number} maximumNumberOfCharacters
   */
  testIfStringIsWithinMaximimumLenth = (
    stringToTest: string,
    maximumNumberOfCharacters: number,
  ) => {
    return stringToTest.length <= maximumNumberOfCharacters;
  };

  /**
   * Test if a password has a minimum of eight characters in lnegth
   * @param {string} password
   */
  testIfPasswordHasEightCharacters = (password: string) => {
    const eightCharactersLongRegex = new RegExp('^(?=.{8,})');
    return eightCharactersLongRegex.test(password);
  };

  /**
   * Test if a password has at least one lowercase english letter
   * @param {string} password
   */
  testIfPasswordHasLowercaseLetter = (password: string) => {
    const lowerCaseAlphabeticalLetterRegex = new RegExp('[a-z]+');

    return lowerCaseAlphabeticalLetterRegex.test(password);
  };

  /**
   * Test if a password has at least one uppercase english letter
   * @param {string} password
   */
  testIfPasswordHasUpperCaseLetter = (password: string) => {
    const upperCaseAlphabeticalLetterRegex = new RegExp('[A-Z]+');
    return upperCaseAlphabeticalLetterRegex.test(password);
  };

  /**
   * Test if a password ahs at least one special character
   * @param {string} password
   */
  testIfPasswordHasSpecialCharacter = (password: string) => {
    const specialCharacterRegexp = new RegExp('^(?=.*[!@#$%^&*])');
    return specialCharacterRegexp.test(password);
  };

  /**
   * Test if a password has at least one numerical character in it.
   * @param {string} password
   */
  testIfPasswordHasNumber = (password: string) => {
    const numberRegex = new RegExp('^(?=.*[0-9])');
    return numberRegex.test(password);
  };
}

export default InputValidationService;
