import { makeStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import messageSentIllustration from '../../../../assets/images/message_sent_illustration.png';
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from '../../../../common/redux/ui/ui.actions';
import { UpdateUserProfileDto } from '../../../../common/redux/users/types';
import { updateUserProfileRequest } from '../../../../common/redux/users/user.actions';
import { selectPrimaryUserEmail } from '../../../../common/redux/users/user.selectors';
import FZEditSettingPageTemplate from '../../../04_templates/EditSettingPageTemplate/FZEditSettingPageTemplate';
import EditEmailForm, { EditEmailFormValues } from './EditEmailForm';

const useStyles = makeStyles({
  imageClassName: {
    paddingLeft: 60,
    paddingTop: 30,
    width: '60%',
    height: 'auto',
    transform: 'scaleX(-1)',
    float: 'right',
  },
});

interface Props {}

export default function EditEmailScreen(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const primaryUserEmail: string = useSelector(selectPrimaryUserEmail);
  //const classes = useStyles();
  const history = useHistory();
  const classes = useStyles();

  const handleFinishEditingButtonClick = React.useCallback(
    (
      values: EditEmailFormValues,
      helpers: FormikHelpers<EditEmailFormValues>,
    ) => {
      const profileValuesToUpdate: UpdateUserProfileDto = {
        email: values.newEmail,
      };

      helpers.setSubmitting(true);

      dispatch(
        updateUserProfileRequest(
          profileValuesToUpdate,
          () => {
            history.push('/profile/settings/edit-email-confirmation-sent');
            helpers.setSubmitting(false);
            showSuccessSnackbar(
              t('editEmailScreen-email-update-successful-message'),
              true,
            );
          },
          () => {
            helpers.setSubmitting(false);
            showErrorSnackbar(
              t('editEmailScreen-email-update-unsuccessful-message'),
              true,
            );
          },
        ),
      );
    },
    [history, dispatch, t],
  );

  return (
    <FZEditSettingPageTemplate
      backgroundImageSource={messageSentIllustration}
      headline={t('editEmailScreen-headline')}
      subheadline={t('editEmailScreen-subheadline')}
      backDestinationLabel={t('editEmailScreen-navigate-back-to-label')}
      imageClassName={classes.imageClassName}
    >
      <EditEmailForm
        currentUserEmail={primaryUserEmail}
        onSubmit={handleFinishEditingButtonClick}
      ></EditEmailForm>
    </FZEditSettingPageTemplate>
  );
}
