import { FormHelperText, makeStyles } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import { fieldToTextField, TextFieldProps } from 'formik-material-ui';
import React from 'react';
import colors from '../../00_Constants/colors';
import FZButton from '../FZButton/FZButton';

const useStyles = makeStyles({
  root: {
    '& label.Mui-focused': {
      color: (props: Props) => props.color,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: (props: Props) => props.color,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: (props: Props) => props.color,
      },
      '&:hover fieldset': {
        borderColor: (props: Props) => props.color,
      },
      '&.Mui-focused fieldset': {
        borderColor: (props: Props) => props.color,
      },
    },
  },

  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',

    width: (props: Props) => (props.disableFullWidth ? null : '100%'),
  },

  formHelperText: {
    marginLeft: 32,
  },

  fieldActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
  },

  copyPlaceholderToValueButton: {},
});

type Props = TextFieldProps & {
  color: string;
  backgroundColor: string;

  displayCopyPlaceholderToValueButton?: boolean;
  displayCopyPlaceholderToValueButtonLabel?: string;

  displayCharacterCount?: boolean;
  characterLimit?: number;
  enforceCharacterLimit?: boolean;
  onCharacterLimitHit?: () => void;

  disableFullWidth: boolean;
  // 'Indexer'
  [x: string]: any;
};

export default function FZTextInputField(props: Props) {
  const {
    color,
    displayCharacterCount,
    characterLimit,
    displayCopyPlaceholderToValueButton,
    displayCopyPlaceholderToValueButtonLabel,
    enforceCharacterLimit,
    backgroundColor,
    disableFullWidth,
    onCharacterLimitHit,
    ...textFieldProps
  } = props;

  const classes = useStyles(props);

  const onChange = React.useCallback(
    (event: any) => {
      const { value } = event.target;

      if (enforceCharacterLimit) {
        if (value.length <= characterLimit) {
          textFieldProps.form.setFieldValue(textFieldProps.field.name, value);

          if (value.length === characterLimit) {
            onCharacterLimitHit && onCharacterLimitHit();
          }
        } else {
          return;
        }
      } else {
        textFieldProps.form.setFieldValue(textFieldProps.field.name, value);
      }
    },
    [
      textFieldProps,
      characterLimit,
      enforceCharacterLimit,
      onCharacterLimitHit,
    ],
  );

  return (
    <div className={classes.fieldContainer}>
      <MuiTextField
        classes={{ root: classes.root }}
        {...fieldToTextField(textFieldProps)}
        onChange={onChange}
      />
      <div className={classes.fieldActionsContainer}>
        {displayCopyPlaceholderToValueButton &&
          displayCopyPlaceholderToValueButtonLabel && (
            <FZButton
              className={classes.copyPlaceholderToValueButton}
              label={displayCopyPlaceholderToValueButtonLabel}
              labelColor={colors.textMedium}
              variant="text"
              onClick={() => {
                props.form.setFieldValue(props.field.name, props.placeholder);
              }}
            ></FZButton>
          )}
        {displayCharacterCount && characterLimit && (
          <FormHelperText
            className={classes.formHelperText}
          >{`${props.field.value.length}/${characterLimit}`}</FormHelperText>
        )}
      </div>
    </div>
  );
}
