import { LinearProgress, Typography } from '@material-ui/core';
import React from 'react';
import './FZProgress.css';

interface FZProgressProps {
  value: number;
  statusMessage?: string;
}

function FZProgress(props: FZProgressProps) {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <LinearProgress
        value={props.value}
        variant="determinate"
        color="secondary"
      ></LinearProgress>
      {props.statusMessage && (
        <Typography style={{ marginTop: 16, marginBottom: 16 }} component="p">
          {props.statusMessage}
        </Typography>
      )}
    </div>
  );
}

export default FZProgress;
