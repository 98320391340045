/**
 * A smart component capable of scrolling trhough a given list of testimonials.
 */
import { makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement } from 'react';
import fzColorfulBackgroundImage from '../../../assets/images/FZ_colorful_background.jpg';
import colors from '../../00_Constants/colors';
import FZTypography from '../../01_atoms/FZTypography/FZTypography';

export interface Testimonial {
  creator: string;
  creatorMeta: string;
  content: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      backgroundColor: (props: Props) => {
        return props.backgroundColor;
      },

      //minHeight: 290,

      [theme.breakpoints.up('md')]: {
        minHeight: 190,
      },

      padding: 16,
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',

      /* Need position to allow stacking of pseudo-elements */
      position: 'relative',

      backgroundImage: `url(${fzColorfulBackgroundImage})`,
      backgroundSize: 'cover',
    },

    contentContainer: {
      backgroundColor: '#FFF',
      border: '1px solid #FFF',
      borderRadius: 8,
      maxWidth: '100%',
    },

    quoteContent: {
      color: colors.textStrong,
      padding: '32px 32px',
      lineHeight: 1.4,
      minHeight: 200,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '&::before': {
        content: '"❞"',
        width: 16,
        height: 16,
        borderRadius: 16,
        color: '#343434',
        display: 'inline',
        lineHeight: 0,
        position: 'relative',
        fontSize: '2em',
      },
    },

    quoteCreatorContainer: {
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },

    quoteCreator: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },

    testimonialImage: {
      width: 128,
      height: 'auto',
      borderRadius: 64,
    },
  };
});

interface Props {
  testimonial: Testimonial;
  backgroundColor: string;
}

export default function FZTestimonialQuote(props: Props): ReactElement {
  const classes = useStyles(props);
  const { testimonial } = props;

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <FZTypography variant="h5" className={classes.quoteContent}>
          {testimonial.content}
        </FZTypography>
      </div>
    </div>
  );
}
