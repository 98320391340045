import { AnyAction } from 'redux';
import { authenticationActionTypes } from '../login/login.actions';
import { UiReducerState } from './types';

const blockSiteNavigation = () => {
  window.onbeforeunload = (event: any) => {
    return false;
  };
};

const unblockSiteNavigation = () => {
  window.onbeforeunload = null;
};

const initialState: UiReducerState = {
  successSnackbarOpen: false,
  successSnackbarMessage: '',
  successSnackbarShouldAutoClose: true,
  errorSnackbarOpen: false,
  errorSnackbarMessage: '',
  errorSnackbarShouldAutoClose: true,
  infoSnackbarOpen: false,
  infoSnackbarMessage: '',
  infoSnackbarShouldAutoClose: true,
  navbarOptions: {
    displayBackIcon: false,
  },
  shouldBlockNavigation: false,
};

// reducers/uiReducer.js
const uiReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'SNACKBAR_SUCCESS': {
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.payload.message,
        successSnackbarShouldAutoClose: action.payload.shouldAutoClose,
      };
    }

    case 'SNACKBAR_INFO': {
      return {
        ...state,
        infoSnackbarOpen: true,
        infoSnackbarMessage: action.payload.message,
        infoSnackbarShouldAutoClose: action.payload.shouldAutoClose,
      };
    }

    case 'SNACKBAR_ERROR': {
      return {
        ...state,
        errorSnackbarOpen: true,
        errorSnackbarMessage: action.payload.message,
        errorSnackbarShouldAutoClose: action.payload.shouldAutoClose,
      };
    }

    case 'SNACKBAR_CLEAR': {
      return {
        ...state,
        successSnackbarOpen: initialState.successSnackbarOpen,
        successSnackbarMessage: initialState.successSnackbarMessage,
        successSnackbarShouldAutoClose:
          initialState.successSnackbarShouldAutoClose,

        infoSnackbarOpen: initialState.infoSnackbarOpen,
        infoSnackbarMessage: initialState.successSnackbarMessage,
        infoSnackbarShouldAutoClose:
          initialState.successSnackbarShouldAutoClose,

        errorSnackbarOpen: initialState.errorSnackbarOpen,
        errorSnackbarMessage: initialState.errorSnackbarMessage,
        errorSnackbarShouldAutoClose: initialState.errorSnackbarShouldAutoClose,
      };
    }

    case 'SET_SHOULD_BLOCK_NAVIGATION': {
      action.payload.shouldBlockNavigation
        ? blockSiteNavigation()
        : unblockSiteNavigation();

      return {
        ...state,
        shouldBlockNavigation: action.payload.shouldBlockNavigation,
      };
    }

    case 'SET_NAVBAR_OPTIONS': {
      return {
        ...state,
        navbarOptions: action.payload.navbarOptions,
      };
    }

    case authenticationActionTypes.LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default uiReducer;
