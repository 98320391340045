// Custom HOCs
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import throttle from 'lodash/throttle';
import { rootReducer, rootSaga } from '.';
import { ArticleService } from '../../services';
import UserService from '../../services/UserService';
import applicationConfig from '../../config/application_config';
import FamilyService from '../../services/FamilyService';
import ImageService from '../../services/ImageService';
import NewspaperService from '../../services/NewspaperService';
import FeedService from '../../services/FeedService';
import AccountService from '../../services/AccountService';

const sagaMiddleware: SagaMiddleware = createSagaMiddleware({
  context: {
    accountService: new AccountService(),
    articleService: new ArticleService(),
    userService: new UserService(),
    familyService: new FamilyService(),
    newspaperService: new NewspaperService(),
    imageService: new ImageService(),
    feedService: new FeedService(),
  },
});

const middleware = [sagaMiddleware];

const persistConfig = {
  key: '@dieFamilienzeitung',
  storage,
  blacklist: ['app', 'feed', 'ui'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const devTools =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware));

// Create a redux store, adding redux-thunk middleware to allow async actions like data-fetching in redux actions
const store: Store = createStore(
  persistedReducer,
  /* preloadedState, */ devTools,
  // other store enhancers if any
);

// Start watchers on all defined sagas, so each component can just dipatch plain actions.
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

if (applicationConfig.FEATURE_CONFIG.authentication.persistLoginStateOnClose) {
  // Persist login state in localstorage before the user closes the tab/window
  window.addEventListener('beforeunload', ev => {
    persistor.persist();
  });
}

if (
  applicationConfig.FEATURE_CONFIG.authentication.persistLoginStatePeriodically
) {
  // Writing to local storage periodically is expensive, since its using JSON.stringify internally.
  // We use lodashs "throttle" to execute the function only every 300ms, no matter how often
  // the redux-store subscription (on every change in redux store) triggers.
  store.subscribe(
    throttle(() => {
      persistor.persist();
    }, applicationConfig.FEATURE_CONFIG.authentication.persistLoginStatePeriodicallyDurationInMilliseconds),
  );
}

export { store, persistor };
