import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { Field } from 'formik';
import React, { ReactElement } from 'react';
import colors from '../../00_Constants/colors';
import FZFieldLabel from '../../01_atoms/FZFieldLabel/FZFieldLabel';
import FZTextInputField from '../../01_atoms/FZTextInputField/FZTextInputField';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: { width: '100%' },

    horizontalRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    formInputField: {
      width: '100%',
      marginBottom: 16,
    },

    invitationSubjectLabel: {
      marginTop: 34,
    },

    invitationMessageLabel: {},
  };
});

export interface InvitateFamilyMemberFormValues {
  invitationSubject: string;
  invitationMessage: string;
}

interface Props {
  children?: ReactElement;

  // Form Props
  invitationSubjectFieldName: string;
  invitationMessageFieldName: string;
  invitationMessageFieldCharacterLimit: number;

  // Labels and Placeholders
  invitationSubjectFieldLabel: string;
  invitationSubjectFieldPlaceholder: string;
  invitationMessageFieldLabel: string;
  invitationMessageFieldPlaceholder: string;
  copyPlaceholderToValueButtonLabel?: string;

  // Style Props
  invitationSubjectLabelClassName?: string;
  invitationSubjectFieldClassName?: string;
  invitationMessageLabelClassName?: string;
  invitationMessageFieldClassName?: string;
}

export default function FZInviteFamilyMemberForm({
  // Form Props
  invitationSubjectFieldName,
  invitationMessageFieldName,
  invitationMessageFieldCharacterLimit,

  // Labels and Placeholders
  invitationSubjectFieldLabel,
  invitationSubjectFieldPlaceholder,
  invitationMessageFieldLabel,
  invitationMessageFieldPlaceholder,
  copyPlaceholderToValueButtonLabel,

  // Style Props
  invitationSubjectLabelClassName,
  invitationSubjectFieldClassName,
  invitationMessageLabelClassName,
  invitationMessageFieldClassName,
}: Props): ReactElement {
  /*  const { t } = useTranslation(); */
  //const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.horizontalRow}>
        <FZFieldLabel
          label={invitationSubjectFieldLabel}
          className={clsx(
            classes.invitationSubjectLabel,
            invitationSubjectLabelClassName,
          )}
        ></FZFieldLabel>
      </div>

      <Field
        id={invitationSubjectFieldName}
        name={invitationSubjectFieldName}
        data-testid={invitationSubjectFieldName}
        component={FZTextInputField}
        className={clsx(
          classes.formInputField,
          invitationSubjectFieldClassName,
        )}
        placeholder={invitationSubjectFieldPlaceholder}
        color={colors.secondary}
        backgroundColor={colors.white}
      />
      <FZFieldLabel
        label={invitationMessageFieldLabel}
        className={clsx(
          classes.invitationMessageLabel,
          invitationMessageLabelClassName,
        )}
      ></FZFieldLabel>
      <Field
        id={invitationMessageFieldName}
        name={invitationMessageFieldName}
        data-testid={invitationMessageFieldName}
        component={FZTextInputField}
        className={clsx(
          classes.formInputField,
          invitationMessageFieldClassName,
        )}
        placeholder={invitationMessageFieldPlaceholder}
        color={colors.secondary}
        backgroundColor={colors.white}
        multiline
        rows={10}
        rowsMax={20}
        displayCharacterCount
        characterLimit={invitationMessageFieldCharacterLimit}
      />
    </div>
  );
}
