import { ApplicationState } from '../main/types';

/**
 *
 * @param {ApplicationState} state
 * @returns wether or not the app has successfully loaded for the first time after login
 */
export const selectFirstLoadFinished = (state: ApplicationState) => {
  return state.app.firstLoadFinished;
};

/**
 *
 * @param {ApplicationState} state
 * @returns wether or not the app is currently performing any work in the background
 */
export const selectIsLoading = (state: ApplicationState) => {
  return state.app.isLoading;
};

export const selectLoadingMessage = (state: ApplicationState) => {
  return state.app.loadingMessage;
};
