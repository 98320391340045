import { AnyAction, Reducer } from 'redux';
import { authenticationActionTypes } from '../login/login.actions';
import { appActionTypes } from './app.actions';

import { AppReducerState } from './types';

const initialState: AppReducerState = {
  firstLoadFinished: false,
  isLoading: false,
  loadingMessage: '',
};

const appReducer: Reducer<AppReducerState> = (
  state = initialState,
  action: AnyAction,
) => {
  // redux calls reducers with an "undefined" state, when they are called for the first time
  // this allows to set the initial state
  switch (action.type) {
    case appActionTypes.SET_FIRST_LOAD_FINISHED:
      return {
        ...state,
        firstLoadFinished: action.payload,
      };

    case appActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        loadingMessage: action.payload.loadingMessage,
      };

    case authenticationActionTypes.LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      // ALWAYS have a default case in a reducer which returns the previous state
      // in case an unknown action is triggered
      return state;
  }
};

export default appReducer;
