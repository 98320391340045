import { AnyAction } from 'redux';
import { call } from 'redux-saga/effects';
import { FZApiErrorResponse } from './types';

/**
 * A set of utilities used to recurring api operations
 */
class ApiUtilities {
  static handleApiError(error: any): FZApiErrorResponse | any {
    if (error.response) {
      // client received an error response (5xx, 4xx)
      return {
        statusCode: error.response.status,
        errorCode: error.response.data?.errorCode,
        errorSummary: error.response.data?.errorSummary,
      };
    } else if (error.request) {
      // client never received a response, or request never left
      return error;
    } else {
      return error;
    }
  }

  static *callSuccessCallbackIfDefined(action: AnyAction) {
    if (action.meta && action.meta.successCallback) {
      yield call(action.meta.successCallback);
    }
  }

  static *callErrorCallbackIfDefined(
    action: AnyAction,
    error?: FZApiErrorResponse,
  ) {
    if (action.meta && action.meta.errorCallback) {
      yield call(action.meta.errorCallback);
    }
  }
}

export default ApiUtilities;
