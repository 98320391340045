/**
 * A component that can be included in Formik forms to automatically debounce submissions
 * @author Tobias Gerstenberg
 * from : https://github.com/formium/formik/blob/e51f09a318cba216a1ba3932da0906202df0b979/examples/DebouncedAutoSave.js
 */

import debounce from '@material-ui/core/utils/debounce';
import { useFormikContext } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../common/redux/app/app.actions';

type Props = {
  debounceMs: number;
  loadingMessage: string;
  shouldSave: () => boolean;
};

export const FZAutoSavingForm = ({
  debounceMs,
  loadingMessage,
  shouldSave,
}: Props) => {
  const formik = useFormikContext();
  const dispatch = useDispatch();

  const debouncedSubmit = React.useCallback(
    debounce(
      () => {
        const formShouldSave = shouldSave();
        if (formShouldSave) {
          dispatch(setLoading(true, ''));
          formik.submitForm().then(() => {
            dispatch(setLoading(false, ''));
          });
        }
      },

      debounceMs,
    ),
    [debounceMs, formik.submitForm],
  );

  React.useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return <></>;
};
