import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import FZModal from '../../02_molecules/FZModal/FZModal';

interface Props {
  when: boolean;
  navigate: any;
  shouldBlockNavigation: (location: any) => boolean;
  confirmTitle: string;
  confirmMessage: string;
  onNavigationConfirmed: (nextDestination: string) => void;
}

interface State {
  modalVisible: boolean;
  lastLocation: any | null;
  confirmedNavigation: boolean;
}

class FZRouteLeavingGuard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      modalVisible: false,
      lastLocation: null,
      confirmedNavigation: false,
    };
  }

  showModal = (location: any) =>
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });

  closeModal = (callback: () => void) => {
    if (callback instanceof Function) {
      this.setState(
        {
          modalVisible: false,
        },
        callback,
      );
    } else {
      this.setState({
        modalVisible: false,
      });
    }
  };

  handleBlockedNavigation = (nextLocation: any) => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };
  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { navigate } = this.props;
      const { lastLocation } = this.state;

      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          () => {
            // Navigate to the previous blocked location with your navigate function
            navigate(lastLocation.pathname);
          },
        );

        this.props.onNavigationConfirmed(lastLocation.pathname);
      }
    });
  render() {
    const { when, confirmTitle, confirmMessage } = this.props;
    const { modalVisible } = this.state;
    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <FZModal
          open={modalVisible}
          onModalClosed={this.closeModal}
          onModalConfirmed={this.handleConfirmNavigationClick}
          type="confirm"
          confirmTitle={confirmTitle}
          confirmMessage={confirmMessage}
        ></FZModal>
      </>
    );
  }
}
export default FZRouteLeavingGuard;
